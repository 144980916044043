import React, { useState, useEffect } from 'react';
import { ML_Tag, ML_TagLink } from "../globals/dto";
import * as tgdb from '../db/TagData';
import { toast } from 'react-toastify';


//parameters
//INPUT: props.tagLink = ML_TagLink
//INPUT: props.tagType = 1 -supplier, 2- cuosteomer, 3-broker
//OUTPUT event: props.getTagLink(ML_TagLink)

export const Tags = (props: any) => {

    const [tags, setTags] = useState<ML_Tag[]>();
    const [tagLink, setTagLink] = useState<ML_TagLink>();
    const [newTagName, setNewTagName] = useState('');
    //const [selectedTag, setSelectedTag] = useState<ML_Tag>(new ML_Tag());
    //const [tagID, setTagID] = useState(0);
    //const [tagType, setTagType] = useState(0);
    const [UIMode, setUIMode] = useState(0); //0 - dropdown, 1-edit, 2-add new

    const DROPDOWN = 0;
    const EDIT = 1;
    //const ADD = 2;

    useEffect(() => {
        if (props.tagLink)
            setTagLink(props.tagLink);
        LoadAllTags();
    }, [])


    const LoadAllTags = () => {
        tgdb.GetAll(props.tagType).then(res => {
            setTags(res.data);
        });
    }

    const updateTagLink = (old_taglink: ML_TagLink, newTagID: number) => {
        if (old_taglink.TagID !== newTagID) {

            if (old_taglink.TagID > 0) {
                tgdb.UnLinkWithTag(tagLink.TagID, tagLink.EntityID);
            }

            if (newTagID === 0) {
                setTagLink({ ...tagLink, "TagID": 0 });
                toast.warn("Ungrouped.");
            }

            if (newTagID > 0 && old_taglink.TagID !== newTagID) {

                tgdb.LinkWithTag({ ...old_taglink, "TagID": newTagID }).then(res => {
                    let newTgLinkId = +res.data;
                    let latestTagLink = { ...tagLink, "TGID": newTgLinkId, "TagID": newTagID };
                    props.getTagLink(latestTagLink);
                    if (newTgLinkId > 0)
                        toast.success("New group info saved.");
                    setTagLink({ ...latestTagLink });
                    return { ...latestTagLink };
                });
            }
        }
    }

    const save = () => {
        if (newTagName.trim().length > 0) {

            let newTag = new ML_Tag();
            newTag.TagType = tagLink.TagType;
            newTag.TagName = newTagName;

            tgdb.Save(newTag).then(res => {

                //Reload dropdown list
                LoadAllTags();

                updateTagLink(tagLink, +res.data);

                // //Unlink old tag assosiation
                // tgdb.UnLinkWithTag(tagLink.TagID, tagLink.EntityID).then(res => {

                //     console.log("New tag ID: " + res);
                //     //Update link object with newly created group/tag
                //     setTagLink((prev: ML_TagLink) => { return { ...prev, TagID: newTagID } });

                //     console.log("Entity ID:" + tagLink.EntityID);

                //     //Link only if entity/supplier/customer/broker ID exist
                //     if (tagLink.EntityID > 0) {
                //         //Link with new tag
                //         tgdb.LinkWithTag(tagLink).then(res => {
                //             console.log("Link ID: " + res.data);
                //             setTagLink((prev: ML_TagLink) => { return { ...prev, TGID: +res.data } });
                //             //call back the parent
                //             props.getTagLink(tagLink);
                //         });
                //     }
                //     setUIMode(DROPDOWN);
                // })

                setUIMode(DROPDOWN);
            })
        }
    }

    const cancel = () => {
        setUIMode(DROPDOWN);
    }

    const handleChange = (e: any) => {
        let name = e.target.name;
        let val = e.target.value;

        if (name === 'tagger') {
            updateTagLink(tagLink, +val);
            // if (tagLink.EntityID > 0 && tagLink.TagID != +val) {
            //     tgdb.UnLinkWithTag(tagLink.TagID, tagLink.EntityID).then(res => {
            //         setTagLink((prev: ML_TagLink) => { return { ...prev, TagID: +val } });
            //         let newTagLink = { ...tagLink, "TagID": +val };
            //         if (+val > 0) {
            //             tgdb.LinkWithTag(newTagLink).then(res => {
            //                 setTagLink((prev: ML_TagLink) => {
            //                     let newEntry = { ...prev, "TGID": res.data };
            //                     props.getTagLink(newEntry);
            //                     toast.success('Group change saved.');
            //                     return { ...newEntry }
            //                 });
            //             });
            //         }
            //     });
            // }
            // setTagLink((prev: ML_TagLink) => { return { ...prev, TagID: +val } });

            if (+val === -1) {
                setUIMode(EDIT);
            }
        } else if (name === 'tagNewName') {
            setNewTagName(val);
        }
    }

    return <>
        <div className="row form-group">
            <div className="col-md-12">
                {UIMode === DROPDOWN && tagLink &&
                    <select className="form-control form-select" id="tagger" name="tagger" value={tagLink.TagID} onChange={handleChange}>
                        <option key={0} value="0">  </option>
                        <option key={-1} value="-1"> -- Create New Group -- </option>
                        {tags &&
                            tags.map((t: ML_Tag) => {
                                return (
                                    <option key={t.GRPID} value={t.GRPID}>{t.TagName}</option>
                                );
                            })
                        }
                    </select>
                }

                {UIMode >= EDIT &&

                    // <input type="text" id="TagName" name="TagName" value={selectedTag.TagName} className="form-control"/>

                    <div className="input-group">
                        <input type="text" className="form-control" placeholder="New Group name"
                            id="tagNewName" name="tagNewName" onChange={handleChange} />
                        <div className="input-group-append">
                            <button className="btn btn-success" type="button" onClick={save}>Save</button>
                            <button className="btn btn-primary" type="button" onClick={cancel}>Cancel</button>
                        </div>
                    </div>

                }
            </div>

        </div>

    </>
}