import axios from 'axios';
import * as global from '../globals/ApiUrlConsts';
import {DebitPayAgentView, DebitTransactionVM, SaleBillView} from "../globals/dto";
import * as dateUtils from '../globals/AppDateUtils';

const web_api_url = global.global_api_url_base + '/api/sales';

export const GetSaleBills = (start : Date, end : Date) => {
    return axios.get(web_api_url + '/GetSaleBills',{params:{start: dateUtils.SanitizeDate(start), end:dateUtils.SanitizeDate(end)}});
}

export const GetSaleBill = async (sbid :number) => await axios.get(web_api_url + '/GetSaleBill?sbid='+sbid);

export const Save = async (bill : SaleBillView) => await axios.post(web_api_url + '/save',bill)

export const CancelBill = async (billID : number) => await axios.post(web_api_url + '/CancelBill',null,{params: {sbid:billID}})

export const UpdateBillStatus = async (bid:number, entry:string, value:boolean) => await axios.post(web_api_url + '/UpdateStatus',null,{params: {bid, entry, value}})

export const ChangeBillPayAmount = async (sbid:number, amount:number, changeReason:string) =>
    await axios.post(web_api_url + '/UpdatePay',null,{params: {sbid,amount,changeReason}})

//export const StockAlloted = async (saleBillView : SaleBillView) => await axios.post(web_api_url + '/stocksalloted')

export const getAllDebitorsEx = async () =>  await axios.get<any>(web_api_url + '/GetDebitors')

export const getCustomerBillWiseDebitStatment = async  (cid: number, start: Date, end: Date) =>
    await axios.get(web_api_url + '/GetCustomerBillWiseDebitStatment', {params : {cid : cid, start :dateUtils.SanitizeDate(start), end : dateUtils.SanitizeDate(end)}})

export const getPendingDebitSaleBillItemsEx = async (cid: number) =>
    await axios.get(web_api_url +'/GetPendingDebitSaleBillItemsEx?cid=' + cid)

export const SaveDebitPayments = async (entry: DebitPayAgentView) =>
        await  axios.post(web_api_url +'/SaveDebitPayments', entry)

export const CollectCredit = async (entry: DebitTransactionVM) =>
        await axios.post(web_api_url +'/CollectCredit', entry)

export const searchSaleBill = async (cid : number, billnum : string)=>
    await axios.get(web_api_url + '/GetSaleBillOnCustomer',{params :{ cid : cid, billnum : billnum }})

