import React, { useEffect, useState } from 'react';
import * as AppAct from "../globals/AppActions";
import * as dateUtils from '../globals/AppDateUtils';
import { ML_Company } from "../globals/dto";
import StoresSelect from "../shared/StoresSelect";
import StocksByStore from "./StocksByStore";
import * as cdb from '../db/CompanyData';
import * as _ from 'lodash';
import { useDispatch } from 'react-redux';

const SaleStocks = (props: any) => {
    const dispatch = useDispatch();

    const [state, setState] = useState({
        storeSelected: new ML_Company(),
        stores: new Array<ML_Company>(),
        searchText: ''
    });

    useEffect(() => {
        let nowDate = new Date();
        dispatch(AppAct.ChangeMainTitle("Current Stock : <span class='text-indigo main-title'>"
            + dateUtils.FormatDate(nowDate) + " " + nowDate.toLocaleTimeString() + '</span>'));

        GetAllStores();

    }, [])

    const filter = (e: any) => {
        let term = e.target.value;
        setState({ ...state, searchText: term });
    }

    const GetAllStores = () => {
            //dispatch(AppAct.ToggleProgress(true));
            cdb.GetAll().then(res => {
                //dispatch(AppAct.ToggleProgress(false));
                setState({ ...state, stores: res.data });
            });
    }

    const refreshPage = ()=>{
        let list = _.clone(state.stores);
        setState({...state,stores:[]});
        setState({...state,stores:[...list]});
    }

    const handleStoreChange = (e: ML_Company) => {
        setState({ ...state, storeSelected: { ...e } });
    }


    return <>

        <div className="row mb-4">
            <div className="col-md-6">
                <StoresSelect onChange={handleStoreChange} />
            </div>
            <div className="offset-md-2 col-md-4">
                <input type="search" className="form-control mt-3 mt-md-0" autoFocus={true}
                    placeholder="Search product" onChange={filter} value={state.searchText} />
            </div>

        </div>


        {(state.storeSelected?.CMPID === 0 || JSON.stringify(state.storeSelected) === '{}') &&
            state.stores.map((store: ML_Company, idx: number) => {
                return (<StocksByStore key={idx} company={store} searchText={state.searchText} refreshAll={refreshPage} />)
            })
        }

        {state.storeSelected.CMPID > 0 &&
            <StocksByStore company={state.storeSelected} searchText={state.searchText} refreshAll={refreshPage} />
        }

        {/* Stocks from all stores */}
        {(state.storeSelected?.CMPID === 0 || JSON.stringify(state.storeSelected) === '{}') &&
            <StocksByStore company={null} searchText={state.searchText} refreshAll={refreshPage} />
        }

    </>

}

export default SaleStocks;
