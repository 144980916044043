import React, { useEffect, useState } from 'react';
import * as AppAct from '../globals/AppActions';
import { mapStateToProps } from '../globals/AppActions';
import * as tdb from '../db/TransactionsData';
import { connect, useDispatch, useSelector } from "react-redux";
import * as utils from '../globals/Utils';
import * as dateUtils from '../globals/AppDateUtils';
import { LedgerTransView } from "../globals/dto";
import { AppReduxState } from '../infra-redux/AppStateReduxStore';
import { SaveButtonUI } from '../globals/AppControls/SaveButtonUI';

const ChequeClearance = (props: any) => {
    const [allPending, setAllPending] = useState(new Array<LedgerTransView>());
    const appDisp = useDispatch();
    const reduxState = useSelector((state: AppReduxState) => state);

    useEffect(() => {
        appDisp(AppAct.ChangeMainTitle("Pending Cheques"));
        loadAll();
    }, []);

    const loadAll = () => {
        appDisp(AppAct.ToggleProgress(true));
        tdb.getPendingCheques().then((res: any) => {
            setAllPending(res.data);
            appDisp(AppAct.ToggleProgress(false));
        })
    }

    const saveAll = () => {
        appDisp(AppAct.ToggleProgress(true));
        tdb.saveChequeStatus(allPending).then((res: any) => {
            loadAll();
        })
    }

    const handleCheck = (li: number, ti: number, e: any) => {
        allPending[li].AllTrans[ti].IsPaymentRealized = e.target.checked;
        setAllPending([...allPending]);
    }

    if (!allPending) return <></>

    return <>
        <h5 className="az-content-label mb-4">Mark realized cheques in the bank</h5>
        <div className="row">
            <div className="col-md-12">
                <div className="table-responsive">
                    <table className="table table-striped mg-b-0">
                        <thead className="border-top">
                            <tr>
                                <th>Ledger</th>
                                <th className="text-right">Cheque / Ref Number</th>
                                <th className="text-right border-left">Date</th>
                                <th className="text-right">Amount</th>
                                <th className="text-right">Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {allPending.map((l: any, idx: number) => {
                                return (
                                    l.AllTrans.map((t: any, tidx: number) => {
                                        return (
                                            <tr key={idx}>
                                                <th>{l.Ledger.LedgerName}</th>
                                                <td className="text-right">{t.Details}</td>
                                                <td className="text-right">{dateUtils.FormatDate(t.TransDate)}</td>
                                                <td className="text-right">{utils.formatCurrencyValue(reduxState, t.TotalAmount)}</td>
                                                <td style={{ width: "100px" }}>
                                                    <div className="float-right">
                                                        <label className="ckbox">
                                                            <input type="checkbox"
                                                                onChange={(e: any) => handleCheck(idx, tidx, e)}
                                                                value={t.IsPaymentRealized} /><span>Cleared</span>
                                                        </label>
                                                    </div>
                                                </td>
                                            </tr>)
                                    })
                                )
                            })}


                        </tbody>
                        <tfoot>
                            <tr>
                                <td colSpan={5} className="text-right">
                                    {/* <input type="button" value="Save All" className="btn btn-success" onClick={saveAll}/> */}
                                    {allPending && allPending.length > 0 &&
                                    <SaveButtonUI accessKey={null} buttonText="Save All" onClick={saveAll} />
                                    }
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
        </div>

    </>
}

export default ChequeClearance;
