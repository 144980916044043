import React from "react";
import { CheckmarkOutline } from 'react-ionicons';
import { preProcessFile } from "typescript";

export const SuccessButtonUI = (props: any) => {
    return <button {...props}
        className={props.className + ' btn btn-success px-3 '}
    >
        {props.buttonText && <span dangerouslySetInnerHTML={{ __html: props.buttonText }}/>}
    </button>
}
