import React, {useEffect, useState } from 'react';
import {mapStateToProps} from "../globals/AppActions";
import {connect, useDispatch} from "react-redux";
import {ML_Inward, ML_Transaction} from "../globals/dto";
import {TransactionEntry} from "../shared/TransactionEntry";
import AsyncSelect from 'react-select/async';
import {components, OptionProps} from 'react-select';
import * as idb from '../db/InwardsData';
import * as factory from '../globals/factory';
import * as AppAct from '../globals/AppActions';


const CreditorPay = (props: any) => {
    const AppDisp = useDispatch();
    //const [trans, setTrans] = useState(factory.createTransactionML());
    const [billSerachTerm, setBillSerachTerm] = useState('');
    const [inwardsFound, setInwardsFound] = useState(new Array<ML_Inward>());
    const [selectedInward, setSelectedInward] = useState(new ML_Inward());

    const indicatorsContainer = (props :any) => {
        return (
                <components.IndicatorsContainer {...props} >
                    <span></span>
                </components.IndicatorsContainer>
        );
    };

    const indicatorSeparator =(props:any)=>{
        return <span style={{display:"none"}} />;
    }

    const dropdownIndicator = (props: any) => {
        return (<></>
            /*<components.DropdownIndicator {...props}>
                <span></span>
            </components.DropdownIndicator>*/
        );
    };

    const noOptionsMessage = (props:any) => {
        return (
                <components.NoOptionsMessage {...props}>
                    No matching invoices found.
                </components.NoOptionsMessage>
        );
    };

    const optionTemplate = (props: any) => (
            <components.Option {...props}>
                {props.data.SupplierInoviceNumber}&nbsp;&nbsp;(Rs.<b>{props.data.PayableTotalAmount}</b>)
            </components.Option>
        )

    const selectedTemplate = (props: any) => (
        <components.SingleValue {...props}>
            {props.data.SupplierInoviceNumber}&nbsp;&nbsp;(Rs.<b>{props.data.PayableTotalAmount}</b>)
        </components.SingleValue>)


    const searchInwards = (searchTerm:string, callback:any) => {
        idb.searchSupplierInward(props.supplier.SPID, searchTerm).then((res:any)=>{

            if(res.data && res.data.length > 0){
                return callback(res.data)
            }
                return callback([]);
        })
    }

    const savePayment = (tranEntry : ML_Transaction) => {
        
        let ipay = factory.createInwardTrasactionVM();
        ipay.TransactionEntry = {...tranEntry};

        ipay.InwardPay.SupplierID=props.supplier.SPID;

        if(selectedInward)
            ipay.InwardPay.InwardID = selectedInward.INWID;

        AppDisp(AppAct.ToggleProgress(true));
        console.log(ipay);
        idb.payCredit(ipay).then((res:any)=>{
            props.saved();
            AppDisp(AppAct.ToggleProgress(false));
            props.close();
        })

    }

    return <>
        <div className="row form-group">
            <div className="col-12">
                <label className="form-label">Supplier Inward (Optional)</label>
                <AsyncSelect classNamePrefix="srushti"
                    cacheOptions
                    isClearable={true}
                    placeholder={"Type to serach supplier invoice..."}
                    loadOptions={searchInwards}
                    theme={theme => ({...theme, borderRadius: 0})}
                    getOptionValue={option => option.INWID.toString()}
                    defaultOptions={new Array<ML_Inward>()}
                    components={{
                        Option: optionTemplate,
                        SingleValue: selectedTemplate,
                        NoOptionsMessage: noOptionsMessage,
                        IndicatorSeparator: indicatorSeparator,
                        DropdownIndicator  : dropdownIndicator

                    }}
                    onChange={(e: any) => {
                        setSelectedInward(e)
                    }}
                />
            </div>
        </div>

        <TransactionEntry trans={factory.createTransactionML()}
        LedgerLabel="Withdraw from Ledger"
        isLedgerEditable={true}
                          closed={() => props.close(false)}
                          saved={(tran: ML_Transaction) => {
                              savePayment(tran);
                              props.close(false)
                          }
                          }
        />

    </>
}

export default CreditorPay;
