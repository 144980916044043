import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from "react-redux";
import * as AppAct from '../globals/AppActions';
import { mapStateToProps } from '../globals/AppActions';
import * as stdb from '../db/SettingsDB';
import { GlobalSettingsVM, ML_AppSetting } from "../globals/dto";
import { DateControlEx } from "../globals/DateControlEx";
import * as utils from "../globals/Utils";
import { WorkflowSelectorDropDown } from '../shared/WorkflowSelectorDropDown';
import { toast } from "react-toastify";
import * as glob from '../globals/GlobalConsts';
import { SaveButtonUI } from '../globals/AppControls/SaveButtonUI';

export const Settings = (props: any) => {

    const AppDisp = useDispatch();
    const [globSettings, setGlobSettings] = useState<GlobalSettingsVM>();

    useEffect(() => {
        AppDisp(AppAct.ChangeMainTitle(""));
        stdb.GetGlobalSetting().then((res: any) => {
            if (res.data.SettingValue === null) {
                setGlobSettings(new GlobalSettingsVM());
            } else {
                let parsed = JSON.parse(res.data.SettingValue);
                parsed = {...parsed, 
                    FinancialYearStartDate: new Date(parsed.FinancialYearStartDate ? new Date() : parsed.FinancialYearStartDate)
                };
                setGlobSettings({
                    ...parsed,
                    FinancialYearStartDate: new Date(parsed.FinancialYearStartDate ? new Date() : parsed.FinancialYearStartDate)
                });
                AppDisp(AppAct.SetGlobalSettings(JSON.stringify(parsed)));
            }
        })
    }, [])

    const saveSettings = () => {
        if (globSettings.EnableProductionTracking && (+globSettings.GlobalWorkflowId === 0 || !globSettings.GlobalWorkflowId)) {
            toast.error('Settings not saved! You must select the default workflow.');
        } else {
            AppDisp(AppAct.ToggleProgress(true));

            let obj = new ML_AppSetting();
            obj.UserID = 0;
            obj.SettingKey = 'GLOBAL_SETTING';
            obj.SettingValue = JSON.stringify(globSettings);
            //console.log(obj.SettingValue);

            stdb.SaveSettingObject(obj).then((res: any) => {
                let stringVal: string = JSON.stringify(globSettings);
                AppDisp(AppAct.SetGlobalSettings(stringVal));
                AppDisp(AppAct.ToggleProgress(false));
                //localStorage.setItem(AppAct.GLOBAL_SETTINGS,obj.SettingValue);
                toast.success("Settings saved!");
            });
        }
    }

    const resolveCurrencyRegion = (currName: string) => {

        setGlobSettings({ ...globSettings, CurrencyRegion: glob.CurrencyRegionMappingMaster[currName] });

        // if (currName === 'INR')
        // setGlobSettings((prev: any) => { return { ...prev, CurrencyRegion: 'en-IN' } });

        // else if (currName === 'USD')
        // setGlobSettings((prev: any) => { return { ...prev, CurrencyRegion: 'en-US' } });

        // else if (currName === 'EUR')
        //     setGlobSettings((prev: any) => { return { ...prev, CurrencyRegion: 'fr-FR' } });

        // else if (currName === 'GBP')
        //     setGlobSettings((prev: any) => { return { ...prev, CurrencyRegion: 'en-GB' } });

        // else if (currName === 'AED')
        //     setGlobSettings((prev: any) => { return { ...prev, CurrencyRegion: 'ar_AE' } });

        // else if (currName === 'SAR')
        //     setGlobSettings((prev: any) => { return { ...prev, CurrencyRegion: 'ar_SA' } });

        // else{
        //     setGlobSettings((prev: any) => { return { ...prev, CurrencyRegion: '', CurrencyName:'' } });
        // }
    }

    const handleChange = (e: any) => {

        let name = e.target.name;
        let val = e.target.value;
        console.log(name + "::" + val);
        setGlobSettings((prev: any) => { return { ...prev, [name]: val } });
        if (name === 'CurrencyName')
            resolveCurrencyRegion(val);
    }

    const handleCheckBoxChange = (e: any) => {
        let name = e.target.name;
        let val = e.target.checked;
        console.log(name + "::" + val);
        setGlobSettings((prev: any) => { return { ...prev, [name]: val } });
    }

    if (!globSettings) return <></>

    return (
        <div className="row">
            <div className="offset-md-2 col-md-8">
                <div className="row">
                    <div className='col-12 main-title text-indigo pb-3'>
                        Global Settings
                    </div>
                    <hr/>
                    <div className="col">
                        <h3>Invoices</h3>
                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-md-4 col-form-label">Financial Year Start Date</label>
                    <div className="col-md-4">
                        <DateControlEx
                            datevalue={new Date(globSettings.FinancialYearStartDate)}
                            onDateChange={(e: any) => setGlobSettings((prev: any) => { return { ...prev, FinancialYearStartDate: new Date(e) } })}
                        />
                    </div>
                </div>

                <div className="form-group row">
                    <label className="col-md-4 col-form-label">Currency Setting</label>
                    <div className="col-md-4">
                        <select className="form-control form-select" value={globSettings.CurrencyName} name="CurrencyName" onChange={handleChange}>
                            <option key="m0" value=""></option>
                            <option key="m1" value="INR">Indian Rupees</option>
                            <option key="m2" value="USD">US Dollar</option>
                            <option key="m3" value="EUR">Euro</option>
                            <option key="m4" value="GBP">UK Pound</option>
                            <option key="m5" value="AED">Dirham</option>
                            <option key="m6" value="SAR">Saudi Riyal</option>
                        </select>
                        {/* <input className="form-control text-black"
                                        name="SaleBillPrefix"
                                        value={globSettings.CurrencyRegion}
                                        onChange={handleChange} /> */}
                    </div>
                </div>



                <div className="form-group row">
                    <label className="col-md-4 col-form-label">Sale Bill Prefix</label>
                    <div className="col-md-2">
                        <input className="form-control text-black"
                            name="SaleBillPrefix"
                            value={globSettings.SaleBillPrefix}
                            onChange={handleChange}
                        />
                    </div>
                </div>

                {/*                            <div className="form-group row">
                                <label className="col-md-4 col-form-label">Inward Bill Prefix</label>
                                <div className="col-md-2">
                                    <input className="form-control text-black"
                                           name="InwardBillPrefix"
                                           value={globSettings.InwardBillPrefix}
                                           onChange={handleChange}/>
                                </div>
                            </div>*/}
                <div className="form-group row">
                    <div className="col">
                        <hr />
                    </div>
                </div>

                <div className="row">
                    <div className="col">
                        <h3>Products</h3>
                    </div>
                </div>
                <div className="form-group row">
                    <div className="col">
                        <div className="custom-control custom-switch pointer-cursor">
                            <input type="checkbox"
                                name="EnableProductBatches"
                                checked={globSettings.EnableProductBatches}
                                onChange={handleCheckBoxChange}
                                className="custom-control-input" id="EnableProductBatches" />
                            <label className="custom-control-label text-black" htmlFor="EnableProductBatches">Manage
                                Products with Batches</label>
                        </div>
                    </div>
                    {/*                             <div className="col">
                                    <div className="custom-control custom-switch pointer-cursor">
                                        <input type="checkbox"
                                               name="EnableAutoBatchNaming"
                                               checked={globSettings.EnableAutoBatchNaming}
                                               onChange={handleCheckBoxChange}
                                               className="custom-control-input" id="EnableAutoBatchNaming"/>
                                        <label className="custom-control-label text-black" htmlFor="EnableAutoBatchNaming">Auto
                                            Generate Unique Batch Names</label>
                                    </div>
                                </div>*/}
                </div>
                <div className="form-group row">
                    <div className="col">
                        <hr />
                    </div>
                </div>

                <div className="row">
                    <div className="col">
                        <h3>Production Tracking</h3>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="custom-control custom-switch pointer-cursor">
                            <input type="checkbox" className="custom-control-input"
                                name="EnableProductionTracking"
                                checked={globSettings.EnableProductionTracking}
                                onChange={handleCheckBoxChange}
                                id="EnableProductionTracking" />
                            <label className="custom-control-label text-black" htmlFor="EnableProductionTracking">Allow
                                workflow tracking</label>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="d-flex flex-row align-items-center">
                            <div className="pr-2"><b>Set default workflow</b></div>
                            <div className="flex-grow-1"><WorkflowSelectorDropDown wfid={globSettings.GlobalWorkflowId} onChange={(id: number) => {
                                setGlobSettings({ ...globSettings, GlobalWorkflowId: id });
                            }}></WorkflowSelectorDropDown></div>
                        </div>
                    </div>
                </div>

                {/*{globSettings.EnableProductionTracking &&*/}
                {/*    <>*/}
                {/*<div className="form-group row">*/}
                {/*    <div className="col">*/}
                {/*        <hr/>*/}
                {/*    </div>*/}
                {/*</div>*/}

                {/*<div className="row">*/}
                {/*    <div className="col">*/}
                {/*        <h3>Wages per Bag</h3>*/}
                {/*    </div>*/}
                {/*</div>*/}
                {/*<div className="form-group row">*/}
                {/*    <div className="col-3">*/}
                {/*        <label>Cutting</label>*/}
                {/*        <input type="number" name="CuttingPerBagRate" value={globSettings.CuttingPerBagRate} onChange={handleChange} className="form-control text-right"/>*/}
                {/*    </div>*/}
                {/*    <div className="col-3">*/}
                {/*        <label>Packing</label>*/}
                {/*        <input type="number" name="PackingPerBagRate" value={globSettings.PackingPerBagRate} onChange={handleChange} className="form-control text-right"/>*/}
                {/*    </div>*/}
                {/*</div>*/}
                {/*</>*/}
                {/*}*/}
                <div className="form-group row">
                    <div className="col">
                        <hr />
                    </div>
                </div>




                <div className="row">
                    <div className="col-md-12">
                        <SaveButtonUI buttonText="Save All" className="btn-lg" onClick={saveSettings} />
                    </div>
                </div>

            </div>
        </div>
    );
}
