import React, { useEffect, useState } from 'react';
import { CompanyView, InwardLineItemView, ML_Company, ML_ProductBatch } from "../globals/dto";
import * as utils from '../globals/Utils';
import * as dateUtils from '../globals/AppDateUtils';
import Swal from "sweetalert2";
import * as prdb from '../db/ProcessingData';
import * as iwdb from '../db/InwardsData';
import { Modal, ModalBody } from "react-bootstrap";
import { AppProgressBar } from "../globals/AppProgressBar";
import ModalHeader from "react-bootstrap/ModalHeader";
import { mapStateToProps } from "../globals/AppActions";
import { connect, useSelector, useStore } from "react-redux";
import * as AppAct from '../globals/AppActions';
import TextBoxNumeric from '../ui-controls/TextBoxNumeric';
import { AppReduxState } from '../infra-redux/AppStateReduxStore';
import { WarningButtonUI } from "../globals/AppControls/WarningButtonUI";
import { CloseButtonUI } from "../globals/AppControls/CloseButtonUI";
import { SuccessButtonUI } from "../globals/AppControls/SuccessButtonUI";
import * as cmpdb from '../db/CompanyData';
import { stopCoverage } from 'v8';

const InwardVerify = (props: any) => {

    const reduxState = useSelector((state: AppReduxState) => state);


    const [state, setState] = useState({
        inward: props.inw,
        checkIndexes: new Array<number>(),
        batches: new Array<ML_ProductBatch>(),
        progressBar: false,
    });

    useEffect(() => {
        let lineItems = state.inward.LineItems.slice();
        let chxi = new Array<number>();
        lineItems.forEach((x: InwardLineItemView, cix: number) => {
            /*if(x.LineItem.InternalQty <= 0) {
                x.LineItem.InternalQty = x.LineItem.BulkQty;
            }*/
            chxi.push(cix);
            chxi[cix] = 0;
        });
        setState({
            ...state,
            inward: { ...state.inward, LineItems: [...lineItems] },
            checkIndexes: [...chxi],
        });

        LoadAllBatches();
    }, [])

    const save = () => {
        setState({ ...state, progressBar: true });
        iwdb.UpdateInternalQtyAndBatch(state.inward).then(res => {
            setState({
                ...state,
                progressBar: false,
                //isAllVerified: props.IsVerified(state.inward.LineItems)
            });
            props.onClose(true);
        });
    }

    const SendAllToSales = () => {

        Swal.fire({
            title: 'Move all inward items to Sales Stock',
            html: "This action <u>cannot be undone or reversed</u>, <br/><br/>Are you sure <b>verified all properly</b>?",
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonColor: "red",
            focusCancel: true,

        }).then((res) => {
            if (res.value) {
                setState({ ...state, progressBar: true });
                prdb.MoveInwardToSales(state.inward.InwardEntry.INWID).then(res => {
                    setState({ ...state, progressBar: false });
                    props.onClose(true);
                });
            }
        });
    }

    const LoadAllBatches = () => {
        prdb.GetBatchList().then((res: any) => {
            setState({ ...state, batches: res.data });
        });
    }

    const CreateNewBatch = () => {
        prdb.NewBatch().then((res: any) => LoadAllBatches());
    }

    const markCheck = (tog: boolean, idx: number) => {
        let copyChecks = [...state.checkIndexes];
        copyChecks[idx] = tog ? 0 : 1;

        let items = state.inward.LineItems.slice();

        if (items[idx].LineItem.InternalQty === 0 || !items[idx].LineItem.InternalQty)
            items[idx].LineItem.InternalQty = items[idx].LineItem.BulkQty;

        setState({
            ...state,
            inward: { ...state.inward, LineItems: [...items] },
            checkIndexes: [...copyChecks]
        });
    }

    const handleChange = (e: any, index: number) => {
        let items = state.inward.LineItems.slice();

        //console.log("IQ before: " + items[index].LineItem.InternalQty);
        if (e.target.name === 'qty' + index)
            items[index].LineItem.InternalQty = +e.target.value;
        else if (e.target.name === 'batch' + index)
            items[index].LineItem.InternalBatchID = e.target.value;

        //console.log("IQ after: " + items[index].LineItem.InternalQty);
        setState({ ...state, inward: { ...state.inward, LineItems: items } });
    }

    return <>
        <Modal size={'lg'} show={props.showModal} dialogClassName="modal-corner"
            onHide={() => props.onClose(false)}
            backdrop={'static'}>
            {state.progressBar && <AppProgressBar />}
            <ModalBody>
                <h4 className="az-content-label mb-4">Verify Inward Delivery</h4>
                <div className="row">
                    <div className="col-md-12">
                        <div className="d-flex justify-content-between flex-column flex-md-row">
                            <div>
                                <div className="font-weight-bold text-black-50">{state.inward.SupplierDetails.CompanyName}</div>
                                <h5 className="mb-0 pb-0">Inward
                                    No: {state.inward.InwardEntry.SupplierInoviceNumber}</h5>
                                <span>Date: {dateUtils.FormatDate(state.inward.InwardEntry.InvoiceReceivedDate)}</span>
                            </div>
                            <div style={{lineHeight:'1.2em'}}>
                                <InwardPurchasedStoreInfo id={state.inward.InwardEntry.CompanyID}/>
                            </div>
                        </div>
                    </div>
                    {/*<div className="col-md-4">

                        </div>*/}
                    <div className="col-12">
                        <table className="table mt-4">
                            <thead className="border-top">
                                <tr>
                                    <th>Items</th>
                                    <th className="wd-150-f">Received Qty</th>
                                    <th>Batch</th>
                                    {/*<th className="text-center">Received All</th>*/}
                                </tr>
                            </thead>
                            <tbody>
                                {state.inward.LineItems.map((t: InwardLineItemView, index: number) => {
                                    return (
                                        <tr key={'t' + index}>
                                            <td>
                                                <label className="mg-b-0">{t.LineProduct.Product.ProductName}</label>
                                                <h4 className="mg-b-0">
                                                    {t.LineItem.BulkQty}&nbsp;
                                                    {t.LineItem.ProcessedQty > 0 &&
                                                        <span
                                                            className="tx-18-f font-weight-bold text-primary">{t.LineItem.ProcessedQty}</span>}
                                                </h4>
                                                <p className="font-small">{t.LineProduct.UnitMeasure.BulkMeasureName}</p>
                                            </td>

                                            <td>
                                                <div className="d-flex">
                                                    <div className="mr-1">
                                                        <TextBoxNumeric name={"qty" + index} key={"qty" + index}
                                                            value={t.LineItem.InternalQty}
                                                            className="form-control"
                                                            onChange={(e: any) => handleChange(e, index)}
                                                            disabled={state.checkIndexes[index] ? true : false}
                                                        />
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="d-flex flex-row wd-150-f">
                                                    <div>
                                                        <select name={"batch" + index} className="form-control form-select"
                                                            value={t.LineItem.InternalBatchID}
                                                            onChange={(e: any) => handleChange(e, index)}>
                                                            <option value={0}>&nbsp;</option>
                                                            {state.batches.map((b: ML_ProductBatch, bi: number) => {
                                                                return (
                                                                    <option key={'BD' + index + "-" + bi}
                                                                        value={b.BATID.toString()}>{b.BatchName}</option>
                                                                )
                                                            })}
                                                        </select>
                                                    </div>
                                                    <div className="w-75-f">
                                                        <button className="btn btn-sm btn-secondary"
                                                            onClick={CreateNewBatch}>+
                                                        </button>
                                                    </div>
                                                </div>
                                            </td>
                                            {/*<td className="text-center">
                                        <CheckBoxEx style={{width:"32px", height:"32px"}} className="pointer-cursor"
                                                    onChange={(e:boolean)=>{markCheck(e,index)}}
                                                    value={state.checkIndexes[index] ? true : false}/></td>*/}
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                    <div className="col-md-12" >
                        <WarningButtonUI className="mr-3" onClick={save} buttonText={"Save Verified Info"} />
                        <CloseButtonUI onClick={() => props.onClose(true)} />
                        {props.IsAllVerified && !utils.isProductionTrackingEnabled(reduxState.appReduxer.GlobalSettings) &&
                            <SuccessButtonUI className="float-right" onClick={SendAllToSales} buttonText={"Send All To Sales"} />
                        }
                        {/*<button className="btn btn-warning text-white mr-3" onClick={save}>Save Verified Info*/}
                        {/*</button>*/}
                        {/*<button className="btn btn-secondary text-white"*/}
                        {/*    onClick={() => props.onClose(true)}>Close*/}
                        {/*</button>*/}
                        {/*{props.IsAllVerified && !utils.isProductionTrackingEnabled(reduxState.appReduxer.GlobalSettings) &&*/}
                        {/*    <button className="btn btn-success float-right" onClick={SendAllToSales}>Send All To Sales</button>*/}
                        {/*}*/}
                    </div>
                </div>

            </ModalBody>
        </Modal>
    </>

}

export default InwardVerify;

const InwardPurchasedStoreInfo = (props:{id:number})=> {
    const [store, setStore] = useState<CompanyView>(null);
    useEffect(()=>{
        cmpdb.GetOnId(props.id).then(res=>{
            setStore(res.data);
        })
    },[])

    if(!store)
        return <></>

    return<>
        <div className='mt-3 mt-md-0'>Store</div>
        <h5>{store.Company.CompanyName}</h5>
    </>
}


