import React, { useEffect, useState } from 'react';
import * as AppAct from '../globals/AppActions';
import { mapStateToProps } from '../globals/AppActions';
import { connect, useDispatch, useSelector } from "react-redux";
import { ML_Customer } from "../globals/dto";
import * as sdb from '../db/SaleBillData';
import DebtorStatement from "./DebtorStatement";
import * as utils from '../globals/Utils';
import * as cdb from '../db/CustomersData';
import { AppReduxState } from '../infra-redux/AppStateReduxStore';
import { GetAllStoresStocks } from '../db/StocksData';

const DebtorsList = (props: any) => {

    const [allTrans, setAllTrans] = useState({ trans: new Array<any>(), totalBilled: 0, totalPaid: 0, totalBal: 0 });
    const [selectedCustomer, setSelectedCustomer] = useState(new ML_Customer());
    const [showDebtStatement, setShowDebtStatement] = useState(false);

    const AppDisp = useDispatch();
    const reduxState = useSelector((state: AppReduxState) => state);

    const pageTitle = 'All Debtors Summary Statement';

    useEffect(() => {
        AppDisp(AppAct.ChangeMainTitle(pageTitle));
        LoadAllDepts();
    }, [])

    const LoadAllDepts = () => {

        AppDisp(AppAct.ToggleProgress(true));

        sdb.getAllDebitorsEx().then((res: any) => {
            // setAllTrans((prev: any) => {
            //     return {...prev, trans: res.data,
            //         totalBilled: res.data.map((o :any)=>o.Billed).reduce((tot :number = 0 , obj:number)=> tot + obj),
            //         totalPaid: res.data.map((o :any)=>o.Debited).reduce((tot :number = 0 , obj:number)=> tot + obj),
            //         totalBal:res.data.map((o :any)=>o.CurrentStatus).reduce((tot :number = 0 , obj:number)=> tot + obj)
            //     }
            // });

            if (res.data) {
                if (res.data.length > 0) {
                    let billed = res.data.map((o: any) => o.BilledTotal).reduce((tot: number = 0, obj: number) => tot + obj);
                    let paid = res.data.map((o: any) => o.Paid).reduce((tot: number = 0, obj: number) => tot + obj);

                    allTrans.totalBilled = billed;
                    allTrans.totalPaid = paid;
                    allTrans.totalBal = paid - billed;

                    setAllTrans({
                        ...allTrans, trans: res.data,
                        totalBilled: billed,
                        totalPaid: paid,
                        totalBal: paid - billed,
                    });
                }

                // res.data.forEach((x: any) => {
                //     setAllTrans((prev: any) => {
                //         return {
                //             ...prev,
                //             totalBilled: prev.totalBilled + x.BilledTotal,
                //             totalPaid: prev.totalPaid + x.Paid,
                //             totalBal: (prev.totalPaid + x.Paid) - (prev.totalBilled + x.BilledTotal)
                //         }
                //     });
                // });
            }
            AppDisp(AppAct.ToggleProgress(false));
        });
    }

    const openStatement = (c: ML_Customer) => {
        setSelectedCustomer(c);
        setShowDebtStatement(true);
    }

    const openCustomerStatement = (cid: number) => {
        //AppDisp(AppAct.ToggleProgress(true));
        cdb.GetCustomerById(cid).then(res => {
            //AppDisp(AppAct.ToggleProgress(false));
            if (res.data) {
                setSelectedCustomer(res.data);
                setShowDebtStatement(true);
            }
        });
    }

    const closeStatement = (refreshlist: boolean = false) => {
        setShowDebtStatement(false);
        AppDisp(AppAct.ChangeMainTitle(pageTitle));
        if (refreshlist)
            LoadAllDepts();
    }

    if (showDebtStatement)
        return <DebtorStatement customer={selectedCustomer} close={closeStatement} />

    if (!showDebtStatement)
        return <>
            <div className="row">
                <div className="col-md-12">
                    <div className="table-responsive">
                        <table className="table table-striped mg-b-0">
                            <thead>
                                <tr>
                                    <th className="w-50">Customer</th>
                                    <th className="text-right">Account Status</th>
                                    <th className="text-right border-left">Billed</th>
                                    <th className="text-right">Debited</th>
                                    {/*<th className="text-right">Balance</th>*/}
                                </tr>
                            </thead>
                            <tbody>
                                {allTrans.trans.map((c: any, index: number) => {
                                    return (
                                        // <tr key={index}>
                                        //     <th scope="row" className="pointer-cursor text-primary"
                                        //     onClick={()=>openCustomerStatement(c.CID)}>
                                        //         <b className="text-indigo">{c.CompanyName}</b>
                                        //     </th>
                                        //     <td className="text-right">{utils.formatCurrencyValue(c.CurrentStatus)}</td>
                                        //     <td className="text-right border-left">{utils.formatCurrencyValue(c.Paid)}</td>
                                        //     <td className="text-right">{utils.formatCurrencyValue(c.Debited)}</td>
                                        //     {/*<td className="text-right">{utils.formatCurrencyValue((c.Paid - c.BilledTotal))}</td>*/}
                                        // </tr>
                                        <tr key={index}>
                                            <th scope="row" className="pointer-cursor text-primary"
                                                onClick={() => openStatement(c.CustomerDetails)}>
                                                <b className="text-indigo">{c.CustomerDetails.CompanyName}</b>
                                            </th>
                                            <td className="text-right">{utils.formatCurrencyValue(reduxState, c.Balance)}</td>
                                            <td className="text-right border-left">{utils.formatCurrencyValue(reduxState, c.BilledTotal)}</td>
                                            <td className="text-right">{utils.formatCurrencyValue(reduxState, c.Paid)}</td>
                                            {/*<td className="text-right">{utils.formatCurrencyValue((c.Paid - c.BilledTotal))}</td>*/}
                                        </tr>
                                    )
                                })}
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td></td>
                                    <td className="text-right font-weight-bold">{utils.formatCurrencyValue(reduxState, allTrans.totalBal)}</td>
                                    <td className="text-right border-left font-weight-bold">{utils.formatCurrencyValue(reduxState, allTrans.totalBilled)}</td>
                                    <td className="text-right font-weight-bold">{utils.formatCurrencyValue(reduxState, allTrans.totalPaid)}</td>
                                    {/*<td className="text-right font-weight-bold">{utils.formatCurrencyValue(allTrans.totalBal)}</td>*/}
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>


        </>
}

export default DebtorsList;
