import React, { useEffect, useState } from 'react';
import * as sbdb from '../db/SaleBillData';
import { toast } from 'react-toastify';
import TextBoxNumeric from '../ui-controls/TextBoxNumeric';
import * as utils from "../globals/Utils";
import { useSelector } from 'react-redux';
import { AppReduxState } from '../infra-redux/AppStateReduxStore';

type attributes = {
    Amount: number,
    saleBillId: number,
    onUpdate(amount: number): void
}

const AmountPayableManager = (props: attributes) => {

    const reduxState = useSelector((state:AppReduxState)=>state);
    
    const [isEditPayableAmount, setIsEditPayableAmount] = useState(false);
    const [PayableAmount, setPayableAmount] = useState(props.Amount);

    useEffect(()=>{setPayableAmount(props.Amount)},[props.Amount])    

    const onSaveAmountClick = (e: any) => {
        sbdb.ChangeBillPayAmount(props.saleBillId, PayableAmount,
            'Bill final amount updated/adjusted manually')
            .then((res: any) => {
                if (res.data) {
                    toast.success('Payable amount updated!');
                    setIsEditPayableAmount(false);
                    props.onUpdate(PayableAmount);
                }
                else {
                    toast.error('Sorry,Unable to update');
                    setPayableAmount(props.Amount);
                }
            })
    }

    const onCancelAmountChange = () => {
        setPayableAmount(props.Amount);
        setIsEditPayableAmount(false);
    }

    return (<>

        {isEditPayableAmount &&
            <td>
                <div className="d-flex flex-row">
                    <button className="btn-simple btn-primary mr-2"
                        onClick={onCancelAmountChange}>
                        Cancel</button>
                    <button className="btn-simple btn-success"
                        onClick={onSaveAmountClick}>Save</button>
                </div>

            </td>
        }

        {!isEditPayableAmount &&
            <td className="text-right">Payable:</td>
        }

        <td className="text-right font-weight-bolder pr-0 wd-md-150">

            {(props.saleBillId === 0 || isEditPayableAmount) &&
                <TextBoxNumeric
                    style={{ precision: 2 }}
                    className="form-control tx-20 font-weight-bold w-100 text-right"
                    name="PayableTotalAmount"
                    onChange={(e: any) => setPayableAmount(+e.target.value)}
                    value={PayableAmount} />
            }

            {props.saleBillId > 0 &&
                <span className="text-indigo pointer-cursor tx-20" onClick={() => {
                    setIsEditPayableAmount(true)
                }}>
                    {utils.formatCurrencyValue(reduxState,props.Amount)}
                </span>
            }
        </td>

    </>);
}

export default AmountPayableManager;