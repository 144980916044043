import React, { useEffect, useState } from 'react';
import { FilterDropdown } from '../../ui-controls/FilterDropdown';
import { FilterDropdownItem } from '../../globals/ViewModels';
import { FilterAppliedItemsView } from '../../ui-controls/FilterAppliedItemsView';
import * as utils from '../../globals/Utils';

export const DropdownSelectorSection = (props: {
    mainTitle: string,
    selectionTitle: string,
    listItems: FilterDropdownItem[],
    selectedIds: number[],
    onUpdateSelectedIds(ids: number[]): void
}) => {

    const addId = (id: number) => {
        let addedList = [...props.selectedIds, id];
        props.onUpdateSelectedIds(addedList);
    }

    const removeId = (id: number) => {
        let removedList = props.selectedIds.filter(f => f !== id);
        props.onUpdateSelectedIds(removedList);
    }

    const pickOnlySelectedIdItems = (ids: number[]) => {
        if (!props.listItems || utils.isObjectUndefined(ids) || ids.length == 0)
            return [];
        else
            return props.listItems?.filter(f => ids.findIndex(i => i == f.id) !== -1);
    }

    return <>{props.listItems && props.listItems?.length > 0 &&
        <>
            <label>{props.mainTitle}</label>
            <FilterDropdown
                masterList={props.listItems}
                userSelectedIDs={props.selectedIds}
                onSelected={addId}
            />
            <div className='mt-3'>
                <FilterAppliedItemsView
                    items={pickOnlySelectedIdItems(props.selectedIds)}
                    title={props.selectionTitle}
                    removeId={removeId}
                />
            </div>
        </>
    }</>
}
