import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from "react-redux";
import { mapStateToProps } from "../globals/AppActions";
import * as AppAct from '../globals/AppActions';
import * as global from '../globals/GlobalConsts';
import { ML_Customer, CustomerView, AliasMemberView, ML_TagLink } from "../globals/dto";
import * as cdb from '../db/CustomersData';
import { toast } from "react-toastify";
import { AxiosResponse } from "axios";
import { AliasAddress } from "./AliasAddress";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.bubble.css';
import * as tgdb from '../db/TagData';
import { Tags } from '../shared/Tags';
import TextBoxNumeric from '../ui-controls/TextBoxNumeric';
import * as glob from '../globals/GlobalConsts';
import { SaveButtonUI } from '../globals/AppControls/SaveButtonUI';
import { CloseButtonUI } from '../globals/AppControls/CloseButtonUI';

//props:
// data = ML_Customer()
// close()

const CustomerDetails = (props: any) => {

    const appDisp = useDispatch();

    const [customer, setCustomer] = useState(new ML_Customer());
    const [alias, setAlias] = useState(new Array<AliasMemberView>());
    const [openBal, setOpenBal] = useState(0);
    const [validity, setValidity] = useState({
        CompanyNameErr: ''
    });
    const [tagLink, setTagLink] = useState<ML_TagLink>(new ML_TagLink());


    useEffect(() => {
        appDisp(AppAct.ChangeMainTitle('Customer Details'));
        setCustomer(props.data);

        setAlias(props.data.AliasVersions && props.data.AliasVersions.trim().length > 0 ?
            JSON.parse(props.data.AliasVersions) :
            new Array<AliasMemberView>());

        tgdb.GetCustomerTagLink(props.data.CID).then(res => {
            let theTagLink: ML_TagLink = res.data;
            theTagLink.EntityType = 2; //customer
            theTagLink.EntityID = props.data.CID;
            setTagLink(theTagLink);
        });

        window.scrollTo(0, 0);
    }, []);

    const newTagLinked = (tglink: ML_TagLink) => {
        setTagLink(tglink);
    }

    const handleChange = (e: any) => {
        let name = e.target.name;
        let val = e.target.value;

        if (name === 'openBal') {
            setOpenBal(val);
        } else {
            setCustomer({ ...customer, [name]: val });

            if (name === "CompanyName") {
                setValidity({ ...validity, [name + "Err"]: ((!val || val.length === 0) ? global.CLASS_INPUT_ERROR : '') });
            }
        }
    }

    const addNewAlias = () => {
        setAlias([...alias, new AliasMemberView()]);
    }

    const handleAliasInput = (e: any, idx: number) => {
        let name = e.target.name;
        let val = e.target.value;
        let k = new AliasMemberView();
        k.FirmName = alias[idx].FirmName;
        k.FullAddress = alias[idx].FullAddress;
        alias[idx] = { ...k, [name]: val };
        setAlias([...alias]);
    }

    const handleAliasRichInput = (e: any, idx: number) => {
        //let name = e.target.name;
        //let val = e.target.value;
        let k = new AliasMemberView();
        k.FirmName = alias[idx].FirmName;
        k.FullAddress = alias[idx].FullAddress;
        alias[idx] = { ...k, "FullAddress": e };
        setAlias([...alias]);
    }

    const remove = (id: number) => {
        alias.splice(id, 1);
        setAlias([...alias]);
    }

    const IsUserInputOK = () => {
        if (!customer.CompanyName || customer.CompanyName.length === 0) {
            setValidity({ ...validity, "CompanyNameErr": global.CLASS_INPUT_ERROR });
            return false;
        }
        return true;
    }

    const save = async () => {
        console.log(customer);
        if (IsUserInputOK() === false) {
            toast.error('Please enter required field');
        } else {
            appDisp(AppAct.ToggleProgress(true));
            let payload = new CustomerView();
            payload.CustomerDetails = customer;

            if (!payload.CustomerDetails.DateCreated)
                payload.CustomerDetails.DateCreated = new Date(0);

            payload.OpeningBalance = openBal;
            payload.CustomerDetails.AliasVersions = JSON.stringify(alias);
            await cdb.Save(payload).then((res: AxiosResponse<number>) => {
                appDisp(AppAct.ToggleProgress(false));
                if (res.data === 0) {
                    toast.error('Unable to save, something is worng');
                } else {
                    setCustomer({ ...customer, "CID": res.data });
                    setTagLink({ ...tagLink, "EntityID": res.data });
                    toast.success("Customer saved.");
                    props.close();
                }
            });
        }
    }

    if (!customer && !validity) { return <></> }

    return (
        <>
            <div className="row">
                <div className=" col-md-12 text-right">
                    {/*<Link to={'/suppliers?q=dhan'} className="btn btn-primary ml-3">Back</Link>*/}
                    {/* <button type="button" className="btn btn-lg btn-success" onClick={save}>Save</button>
                    <button className="btn btn-lg btn-primary ml-3" onClick={props.close}>Close</button> */}

                    <SaveButtonUI onClick={save} className="mr-3" />
                    <CloseButtonUI onClick={props.close} />
                </div>
            </div>

            <h5 className="az-content-label">Business Details</h5>
            <hr />
            <div className="row">
                <div className="col-md-2 text-center">
                    <i className="fas fa-university fa-3x pt-2 text-warning mb-2"></i>
                </div>
                <div className="col-md-10">
                    <div className="d-flex flex-md-row flex-column justify-content-between">
                        <div className="pr-3 pl-2 flex-md-fill">
                            <label className="form-label">Company/Business Name<span className="tx-danger">*</span></label>
                            <input type="text" className={"form-control " + validity.CompanyNameErr}
                                name="CompanyName"
                                autoFocus
                                value={customer.CompanyName}
                                onChange={handleChange}
                            />
                        </div>
                        {/*<div className="pr-3 pl-2 flex-md-fill">
                                    <label className="form-label">Work Phone</label>
                                    <input type="text" className="form-control"
                                           value={this.state.customer.PrimaryPhones}/>
                                </div>*/}

                    </div>

                    <div className="mt-3 d-flex flex-md-row flex-column justify-content-between">
                        <div className="pr-3 pl-2 flex-md-fill">
                            <label className="form-label">Address - 1</label>
                            <input type="text" className="form-control"
                                name="AddressOne"
                                value={customer.AddressOne}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="pr-3 pl-2 flex-md-fill">
                            <label className="form-label">Address - 2</label>
                            <input type="text" className="form-control"
                                value={customer.AddressTwo}
                                name="AddressTwo"
                                onChange={handleChange}
                            />
                        </div>
                    </div>

                    <div className="mt-3 pb-4 d-flex flex-md-row flex-column justify-content-between">
                        <div className="pr-3 pl-2 flex-md-fill">
                            <label className="form-label">City</label>
                            <input type="text" className="form-control"
                                value={customer.GeoCity}
                                name="GeoCity"
                                onChange={handleChange}
                            />
                        </div>
                        <div className="pr-3 pl-2 flex-md-fill">
                            <label className="form-label">PIN Code</label>
                            <input type="text" className="form-control"
                                value={customer.GeoBusinessCode}
                                name="GeoBusinessCode"
                                onChange={handleChange}
                            />
                        </div>
                        <div className="pr-3 pl-2 flex-md-fill">
                            <label className="form-label">State</label>
                            <input type="text" className="form-control"
                                value={customer.GeoState}
                                name="GeoState"
                                onChange={handleChange}
                            />
                        </div>
                    </div>


                    <div
                        className="pt-3 pl-3 pb-3 mr-3 d-flex flex-md-row flex-column justify-content-between bg-gray-100"
                        style={{ borderTop: "1px dotted #cdd4e0" }}>
                        <div className="pr-3 pl-2 flex-md-fill">
                            <label className="form-label">Company PAN</label>
                            <input type="text" className="form-control"
                                value={customer.CompanyPAN}
                                name="CompanyPAN"
                                onChange={handleChange}
                            />
                        </div>
                        <div className="pr-3 pl-2 flex-md-fill">
                            <label className="form-label">GST</label>
                            <input type="text" className="form-control"
                                value={customer.GSTN}
                                name="GSTN"
                                onChange={handleChange}
                            />
                        </div>
                        <div className="pr-3 pl-2 flex-md-fill">
                            <label className="form-label">CST No.</label>
                            <input type="text" className="form-control"
                                value={customer.CSTNo}
                                name="CSTNo"
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                </div>
            </div>


            <h5 className="az-content-label mt-5">Business Owner's Details</h5>
            <hr />
            <div className="row">
                <div className="col-md-2 text-center">
                    <i className="fas fa-address-card fa-3x pt-2  text-info mb-2"></i>
                </div>
                <div className="col-md-10">
                    <div className="d-flex flex-md-row flex-column justify-content-between">
                        <div className="pr-3 pl-2 flex-md-fill">
                            <label className="form-label">Full Name</label>
                            <input type="text" className="form-control"
                                value={customer.PrimaryContactPersonName}
                                name="PrimaryContactPersonName"
                                onChange={handleChange}
                            />
                        </div>
                        <div className="pr-3 pl-2 flex-md-fill">
                            <label className="form-label">Mobile Phone</label>
                            <input type="text" className="form-control"
                                value={customer.PrimaryPhones}
                                name="PrimaryPhones"
                                onChange={handleChange}
                            />
                        </div>

                    </div>

                    <div className="mt-3 d-flex flex-md-row flex-column justify-content-between">
                        <div className="pr-3 pl-2 flex-md-fill">
                            <label className="form-label">PAN</label>
                            <input type="text" className="form-control"
                                value={customer.PAN}
                                name={"PAN"}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="pr-3 pl-2 flex-md-fill">
                            <label className="form-label">Email</label>
                            <input type="text" className="form-control"
                                value={customer.PrimaryEmailAddress}
                                name={"PrimaryEmailAddress"}
                                onChange={handleChange}
                            />
                        </div>
                    </div>

                </div>
            </div>

            <h5 className="az-content-label mt-5">Opening Balance</h5>
            <hr />
            <div className="row">
                <div className="col-md-2 text-center">
                    <i className="fas fa-rupee-sign fa-3x pt-2  text-indigo mb-2"></i>
                </div>
                <div className="col-md-4">
                    <div className="d-flex flex-md-row flex-column justify-content-between">
                        <div className="pr-3 pl-2 flex-md-fill">
                            <label className="form-label">Amount</label>
                            <TextBoxNumeric className="form-control"
                                value={openBal}
                                name={"openBal"}
                                onChange={handleChange}
                            />
                            <span>(Enter -ve value for credit/pending amount)</span>
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    {tagLink.EntityID > 0 &&
                        <>
                            <label>Reporting Group</label>
                            <Tags tagLink={tagLink} tagType={0} getTagLink={newTagLinked} />
                        </>
                    }
                </div>
            </div>


            <div className="d-flex flex-md-row justify-content-between align-items-center mt-5">
                <div><h5 className="az-content-label pb-0 mb-0">Alias Billing Address (Optional)</h5></div>
                <div><button className="btn btn-primary float-right" onClick={addNewAlias}>+ Add New</button></div>
            </div>
            <hr />
            <div className="row">
                <div className="col-md-2 text-center">
                    {alias &&
                        alias.length > 0 &&
                        <i className="fas fa-address-card fa-3x pt-2  text-info mb-2"></i>
                    }
                </div>
                <div className="col-md-10">
                    <div className="row">
                        {alias &&
                            alias.length > 0 &&
                            alias.map((al: AliasMemberView, idx: number) => {
                                return (
                                    <div className="col-md-6" key={'ac' + idx}>
                                        <div className="row mb-5">
                                            <div className="col-md-12">
                                                <label className="form-label">Firm / Person Name</label>
                                                <input type="text" className="form-control"
                                                    name="FirmName"
                                                    value={al.FirmName}
                                                    onChange={(e: any) => handleAliasInput(e, idx)}
                                                />
                                            </div>
                                            <div className="col-md-12">
                                                <label className="form-label">Full Address</label>
                                                <ReactQuill modules={glob.QuillTabBinding} theme="bubble" value={al.FullAddress} onChange={(e: any) => handleAliasRichInput(e, idx)} />
                                                {/*<textarea rows={4} className="form-control"
                                                      name="FullAddress"
                                                      value={al.FullAddress}
                                                      onChange={(e:any)=>handleAliasInput(e,idx)}
                                            ></textarea>*/}
                                            </div>
                                            <div className="col-md-12 text-right text-danger pointer-cursor" onClick={() => remove(idx)}>
                                                <i className="fa fa-trash-alt tx-16 pt-2 pr-1"></i>Delete
                                            </div>
                                        </div>


                                        {/*<AliasAddress  key={'al' + idx} postback={aliasPostBack} custAlias={al} custIndex={idx}>*/}
                                        {/*</AliasAddress>*/}
                                    </div>
                                )
                            })}
                    </div>
                </div>
            </div>
        </>
    )
}

export default CustomerDetails;