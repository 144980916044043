import React from "react";
import { CheckmarkOutline } from 'react-ionicons';

export const SaveButtonUI = (props: any) => {
    return <button {...props}
        className={props.className + ' btn btn-success pr-3 '}
        onClick={props.onClick}
        accessKey={props.accessKey ? props.accessKey : ( !props.buttonText ? "s" : "")}
    >
        <CheckmarkOutline style={{ 'paddingBottom': '2px' }} color={'white'} />
        {!props.buttonText && <span><u>S</u>ave</span>}
        {props.buttonText && <span dangerouslySetInnerHTML={{ __html: props.buttonText }}/>}
    </button>
}
