import React, { useEffect, useState } from "react";
import { Pen } from "react-bootstrap-icons";
import { CheckmarkCircle, CheckmarkCircleOutline, CloseCircleOutline, CloseOutline, TrashBinOutline } from "react-ionicons";
import { CallActionResult, ML_Company, ML_Product, ML_ProductBatch, ML_ProductTransits, ML_UnitMeasure } from "../globals/dto";
import StoresSelect from "../shared/StoresSelect";
import TextBoxNumeric from "../ui-controls/TextBoxNumeric";
import * as consts from './../globals/GlobalConsts';
import { toast } from 'react-toastify'

import * as pdb from '../db/ProcessingData';
import * as rdb from '../db/ReportsData';
import * as dateUtils from '../globals/AppDateUtils';
import { AxiosResponse } from "axios";
import { TableRowDeleteButtonUI } from "../globals/AppControls/TableRowDeleteButtonUI";

type attributes = {
    prodid: number,
    onClose(): void,
    productName: string,
    store: ML_Company

    // compid: number,
    // qty: number
}

class ResultData {
    Product: ML_Product;
    Unit: ML_UnitMeasure;
    Company: ML_Company;
    Transit: ML_ProductTransits
    Batch: ML_ProductBatch

}

const AddOpeningStock = (props: attributes) => {

    const [qty, setQty] = useState(0);
    const [store, setStore] = useState<ML_Company>(props.store);
    const [showAddNew, setShowAddNew] = useState(false);

    const [stocks, setStocks] = useState<ResultData[]>([]);


    useEffect(() => {
        GetOpeningStocks();
    }, []);

    const storeSelected = (c: ML_Company) => {
        setStore(c);
    }

    const GetOpeningStocks = () => {
        rdb.GetOpeningStocksForProduct(props.prodid).then(res => {
            setStocks(res.data);
        })
    }

    const AddNewStock = () => {
        pdb.AddOpeningStock(props.prodid, store.CMPID, qty).then(res => {
            setQty(0);
            setStore(new ML_Company());
            GetOpeningStocks();
            setShowAddNew(false);
        })
    }

    const deleteQty = (stkid: number) => {
        pdb.DeleteOpeningStock(stkid).then((res: AxiosResponse<CallActionResult>) => {
            if (res.data.StatusCode === consts.CALL_RESPONSE_STATUS_ERROR)
                toast.error(res.data.Message);
            else
                GetOpeningStocks();
        })
    }

    const closeWindow = () => {
        setShowAddNew(false);
        props.onClose();
    }

    return (<>
        <div className="row">
            <div className="col-10">
                <div className="az-content-label tx-15 mb-3 text-black-50">Opening Stocks</div>
                <div className="az-content-label tx-20 mb-3">{props.productName}</div>
            </div>

            {/* <div className="col-6 py-1">
                
            </div> */}

            <div className="col-2 py-1 text-right">
                <button className="btn btn-secondary" onClick={closeWindow}>&lt; Back</button>
            </div>
        </div>
        {/* </div>
            <div className="card-body"> */}
        <div className="table-responsive mt-3">
            <table className="table table-hover mg-b-0"  >
                <thead className="border-top">
                    <tr>
                        <th style={{ width: '40%' }}>Store</th>
                        <th style={{ width: '25%' }} className="text-right">Date</th>
                        <th style={{ width: '20%' }} className="text-right">Qty</th>
                        <th style={{ width: '15%' }} className="text-right"></th>
                    </tr>
                </thead>
                <tbody>
                    {!showAddNew &&
                        <tr><td colSpan={4}>
                            <button className="btn btn-primary" onClick={() => setShowAddNew(true)}> + Add Stock</button>
                        </td></tr>
                    }

                    {showAddNew &&
                        <>
                            <tr>
                                <td colSpan={2} className="pt-4">
                                    <StoresSelect onChange={storeSelected} company={store} />
                                </td>
                                <td colSpan={2} className="pt-4">
                                    <TextBoxNumeric
                                        className="form-control text-right"
                                        placeholder="Qty"
                                        value={qty}
                                        onChange={(e: any) => setQty(+e.target.value)}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={4} className="text-right border-top-0 pb-4">

                                    <button className="btn btn-irs-outline p-0 mr-3" onClick={() => AddNewStock()}>
                                        <CheckmarkCircleOutline width="26px" height="26px" color={'green'} /> Save
                                    </button>
                                    <button className="btn btn-irs-outline p-0" onClick={() => setShowAddNew(false)}>
                                        <CloseCircleOutline width="26px" height="26px" /> Cancel
                                    </button>

                                </td>
                            </tr>
                        </>
                    }

                    {stocks.map((s: ResultData, idx: number) => {
                        return (
                            <tr key={'st' + idx}>
                                <td>{s.Company.CompanyName}</td>
                                <td className="text-right">{dateUtils.FormatDateTime(s.Transit.CreateDate)}</td>
                                <td className="text-right">{s.Transit.ToQty}</td>
                                <td className="text-right">
                                <TableRowDeleteButtonUI cssClasses="" onClick={() => deleteQty(s.Transit.ToStockID)}/>
                                    {/* <button className="btn btn-irs-outline p-0" onClick={() => deleteQty(s.Transit.ToStockID)}>
                                        <TrashBinOutline width="24px" height="24px" color={'#ff7a7a'} />
                                    </button> */}
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>
    </>)
}

export default AddOpeningStock;
