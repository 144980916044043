import React, { useEffect, useState } from 'react';
import { ReportSettingDto } from "./ReportDTOs";
import * as ldgdb from '../../db/LedgerData';
import * as tgdb from '../../db/TagData';
import { ML_Ledger, ML_Tag } from '../../globals/dto';
import { FilterDropdownItem } from '../../globals/ViewModels';
import * as consts from '../../globals/GlobalConsts';
import { DropdownSelectorSection } from '../FilterUI/DropdownSelectorSection';

export const LedgerSummaryFilters = (props: {
    filtersDTO: ReportSettingDto,
    onSettingUpdate(s: ReportSettingDto): void
}) => {

    const [ledgers, setLedgers] = useState<FilterDropdownItem[]>();
    const [tags, setTags] = useState<FilterDropdownItem[]>();

    useEffect(() => {
        ldgdb.getAll().then(res => {
            setLedgers(res.data.map((d: ML_Ledger) => {
                let t = new FilterDropdownItem();
                t.id = d.LDGID;
                t.value = d.LedgerName;
                return t;
            }))
        });

        tgdb.GetAll(consts.TagTypes.TRANSACTION_GROUP).then(res => {
            setTags(res.data.map((d: ML_Tag) => {
                let t = new FilterDropdownItem();
                t.id = d.GRPID;
                t.value = d.TagName;
                return t;
            }))
        })
    }, [])

    const updateLedgersSelection = (newIds: number[]) => {
        props.onSettingUpdate({ ...props.filtersDTO, ledgers: [...newIds] });
    }

    const updateTagsSelection = (newIds: number[]) => {
        props.onSettingUpdate({ ...props.filtersDTO, groups: [...newIds] });
    }

    return <>{ledgers && ledgers?.length > 0 &&
        <>
            {ledgers && ledgers.length > 0 && <div>
                <DropdownSelectorSection mainTitle='Ledgers list'
                    selectionTitle='Ledgers selected for reporting'
                    listItems={ledgers}
                    selectedIds={props.filtersDTO.ledgers}
                    onUpdateSelectedIds={updateLedgersSelection}
                />
            </div>
            }

            {tags && tags.length > 0 && <div>
                <hr />
                <DropdownSelectorSection mainTitle='Transaction Groups'
                    selectionTitle='Groups selected for reporting'
                    listItems={tags}
                    selectedIds={props.filtersDTO.groups}
                    onUpdateSelectedIds={updateTagsSelection}
                />
            </div>
            }
        </>
    }</>
}