import React, {useState} from 'react';
import {MachineDetails} from "./MachineDetails";
import * as utils from '../globals/Utils';

export const MachineInfo = (props:any)=> {

    const[showMachineModal, setShowMachineModal] =useState(false);

    const getStatusColor  =(percent:number) => {

        if(percent < 90){
            if(percent > 50)
                return  'bg-primary';
            else
                return  'bg-success';
        } else
            return 'bg-warning';

        // if(percent > 50){
        //         return 'bg-primary';
        // }else {
        //     if(percent <75)
        //         return 'bg-warning';
        //     else
        //         return 'bg-success';
        // }
    }

    return<>
        <div>
        <div className="d-flex flex-row justify-content-between">
            <div><b className="text-capitalize">{props.machine.MachineDetails.MachineName}</b></div>
            <div className="text-right">
                {props.machine.CurrentProcessingQty}/{props.machine.MachineDetails.IntakeCapacity}
            </div>
        </div>
        <div className="progress">
            <div className={"progress-bar active "+ (getStatusColor(props.machine.OccupayInPercentage))}
                role="progressbar" aria-valuenow={props.machine.OccupayInPercentage} aria-valuemin={0} aria-valuemax={100}
                style={{width: props.machine.OccupayInPercentage+"%", height: 5}}>
            </div>
        </div>
            <MachineDetails key={utils.uuidv4()} machine={props.machine} show={true}/>
        </div>
    </>
}