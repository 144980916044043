import React, {useEffect} from 'react';
import {DateControlEx} from "../globals/DateControlEx";
import {Modal, ModalBody} from "react-bootstrap";
import ModalHeader from "react-bootstrap/ModalHeader";
import {connect} from "react-redux";
import {mapStateToProps} from "../globals/AppActions";
import * as AppAct from  '../globals/AppActions';
import DateRangeControlEx from "../globals/DateRangeControlEx";

const ProcessLogs = (props: any) => {
    return <>
        <Modal show={props.showModal} size={"lg"} dialogClassName="modal-corner">
            <div className="card">
                <div className="card-header">
                    <div className="d-flex flex-column flex-md-row justify-content-between">
                        <div>
                            <h5>Activity Logs History</h5>
                        </div>
                        <div>
                            <DateRangeControlEx start={new Date()} end ={new Date()} onChange={()=>{}} />
                        </div>
                    </div>


                </div>
                <div className="card-body">
                    <table className="table mb-0">
                        <thead className="border-top">
                        <tr>
                            <th style={{width: "30%"}}>
                                <label>Log Time</label>
                                <DateControlEx datevalue={new Date()} onDateChange={()=>{}}/>
                            </th>
                            <th style={{width:"50%"}}>
                                <label>Notes/Comments</label>
                                <input type="text" className="form-control"/>
                            </th>
                            <th style={{width:"20%"}} className="text-right">
                                <label>Track</label><br/>
                                <button className="btn btn-sm btn-success">Save Entry</button>
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>23-Mar-2020 01:32 PM</td>
                            <td colSpan={2}>Moved in</td>
                        </tr>
                        <tr>
                            <td>23-Mar-2020 03:32 PM</td>
                            <td colSpan={2}>Verified</td>
                        </tr>
                        <tr>
                            <td>23-Mar-2020 04:32 PM</td>
                            <td colSpan={2}>checked it</td>
                        </tr>
                        <tr>
                            <td>23-Mar-2020 06:32 PM</td>
                            <td colSpan={2}>Made it dry</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div className="card-footer">
                    <button className="btn btn-primary float-right" onClick={() => props.close()}>Close</button>
                </div>
            </div>
        </Modal>
    </>
}

export default ProcessLogs;
