import React, { useEffect, useState } from 'react';
import * as AppAct from '../globals/AppActions';
import { connect, useDispatch } from "react-redux";
import doubleright from '../assets/images/chevron-double-right.svg';
import * as pdb from '../db/ProcessingData';
import { GlobalSettingsVM, MachineView, ML_Product, ProductView, TransitVM } from '../globals/dto';
import * as dateUtils from '../globals/AppDateUtils';
import { toast } from "react-toastify";
import { SubProductEntry } from "./SubProductEntry";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import * as colors from '../globals/colors';
import StoresSelect from "../shared/StoresSelect";
import { ProductsDropDown } from "../products/ProductsDropDown";
import TextBoxNumeric from '../ui-controls/TextBoxNumeric';
import ReviewTab from './ReviewTab';
import {CloseButtonUI} from "../globals/AppControls/CloseButtonUI";
import {SuccessButtonUI} from "../globals/AppControls/SuccessButtonUI";

type attributes = {
    lineItemID: number,
    workflowID: number,
    processID: number,
    close(): void
}

const TransitManager = (props: attributes) => {
    const AppDisp = useDispatch();
    const [showLoggerModal, setShowLoggerModal] = useState(false);
    const [transVM, setTransVM] = useState(new TransitVM());
    const [moveInfo, setMoveInfo] = useState({ ParentProcessID: 0, DestFlowID: 0, MoveQty: 0, ProdID: 0, BatchID: 0 });
    const [storeID, setStoreID] = useState(0);
    const [nextProduct, setNextProduct] = useState(new ProductView());
    const [globalSetting, setGlobalSetting] = useState(new GlobalSettingsVM());
    const [executionOrder, setExecutionOrder] = useState(-1);
    const [allRawItemsConsumed, setIsAllRawItemsConsumed] = useState(false);

    useEffect(() => {

        // pdb.GetExecutionOrderForProcessor(props.processID).then(res=>{
        //     let exeOrder = +res.data;
        //     setExecutionOrder(exeOrder);

        console.log('TransitMangaer', props);
        //Inward ---------> machine move
        if (props.processID === 0) {
            console.log("useEffect ~ props", props);
            pdb.PrepareTransitFromInward(props.workflowID, props.lineItemID).then((res: any) => {
                setTransVM(res.data);
                setNextProduct(res.data.SourceMachine.ProcessorViewsList[0].InwardDetails.LineItems[0].LineProduct);
                setMoveInfo({
                    DestFlowID: 0,
                    MoveQty: res.data.SourceMachine.ProcessorViewsList[0].ProcessEntry.ProcessingInItemsCount,
                    ParentProcessID: res.data.SourceMachine.ProcessorViewsList[0].ProcessEntry.PID,
                    ProdID: res.data.SourceMachine.ProcessorViewsList[0].ProcessEntry.ProcessedProductID,
                    BatchID: res.data.SourceMachine.ProcessorViewsList[0].Batch?.BATID
                });

            })
        }
        else { //Machine ---------> machine move
            //AppDisp(AppAct.ToggleProgress(true));
            pdb.PrepareTransitFromMachine(props.processID).then((res: any) => {
                setTransVM(res.data);
                setNextProduct(res.data.SourceMachine.ProcessorViewsList[0].InwardDetails.LineItems[0].LineProduct);
                setMoveInfo({
                    DestFlowID: 0,
                    MoveQty: res.data.SourceMachine.ProcessorViewsList[0].ProcessEntry.ProcessingInItemsCount,
                    ParentProcessID: res.data.SourceMachine.ProcessorViewsList[0].ProcessEntry.PID,
                    ProdID: res.data.SourceMachine.ProcessorViewsList[0].ProcessEntry.ProcessedProductID,
                    BatchID: res.data.SourceMachine.ProcessorViewsList[0].Batch?.BATID
                });
                //AppDisp(AppAct.ToggleProgress(false));
            })
        }
        //})
    }, [])

    const closeLoggerModal = () => {
        setShowLoggerModal(false);
    }

    const gotoDashBoard = () => {
        AppDisp(AppAct.ShowTransitPage(false, 0, 0));
    }

    const handleChange = (e: any) => {
        let name = e.target.name;
        let val = e.target.value;
        switch (name) {
            case 'flowid':
                setMoveInfo((prev: any) => {
                    return { ...prev, DestFlowID: +val }
                });
                break;
            case 'qty':
                setMoveInfo((prev: any) => {
                    return { ...prev, MoveQty: +val }
                });
                break;
        }
    }

    const handleStoreChange = (e: any) => {
        if (e)
            setStoreID(e.CMPID);
    }

    const handleProductChange = (e: any) => {
        if (e) {
            //This is for dropdown UI purpsoe
            setNextProduct(e);

            //Used for actual logic
            setMoveInfo((prev: any) => { return { ...prev, ProdID: e.Product.PRDID } });
        }
    }

    const IsItemFromInward = () => {
        //Inward ---------> machine move
        return props.processID === 0;
        //return executionOrder === 1;

    }

    const canMoveToStore = () => {
        return moveInfo.MoveQty > 0 && storeID > 0;
    }

    const moveToStore = () => {
        pdb.MoveProductionToStore(moveInfo.ParentProcessID, storeID, moveInfo.ProdID, moveInfo.MoveQty)
            .then((res: any) => {
                props.close();
            })
    }

    const canMove = () => {
        return moveInfo.DestFlowID > 0 &&
            moveInfo.MoveQty > 0 &&
            moveInfo.MoveQty <= transVM.SourceMachine.CurrentProcessingQty
    }

    const moveToNextMachine = () => {
        if (canMove()) {
            if (IsItemFromInward()) {
                //Inward ---------> machine move
                pdb.InwardMoveToMachineEx(props.lineItemID, moveInfo.MoveQty, moveInfo.DestFlowID, moveInfo.BatchID).
                    then((res: any) => {
                        props.close();
                    })
            } else {
                //Machine --------> to Machine move
                pdb.MoveToNextMachineEx(moveInfo.ParentProcessID,
                    moveInfo.DestFlowID,
                    moveInfo.MoveQty,
                    moveInfo.BatchID ? moveInfo.BatchID : 0,
                    moveInfo.ProdID,
                    false,
                    allRawItemsConsumed)
                    .then((res: any) => {
                        //AppAct.ToggleProgress(false);
                        //gotoDashBoard();
                        props.close();
                    })
            }
        } else {
            toast.error("Enter value for 'Qty' and 'Next process machine' properly.");
        }
    }

    if (!transVM.SourceMachine)
        return <div className="alert alert-primary m-3" role="alert">
            Loading please wait...
        </div>

    return <>
        <div className="card">
            <div className="card-header">
                <div className="d-flex flex-column flex-md-row justify-content-between align-items-center">
                    <div className="flex-fill">
                        {IsItemFromInward() &&
                            <h3 className="tx-bold mb-0">From Inward Bay</h3>}
                        {!IsItemFromInward() &&
                            <h3 className="tx-bold mb-0">{transVM.SourceMachine.PlantDetails.PlantName} / {transVM.SourceMachine.MachineDetails.MachineName}</h3>
                        }
                    </div>
                    <div className="text-center mt-2 mt-sm-0">
                        <CloseButtonUI onClick={props.close}/>
                        {/*<button className="btn btn-primary ml-3" onClick={props.close}>Close</button>*/}
                    </div>
                </div>

                <table className="table mt-3 mb-0 pb-0 table-bordered">
                    <thead className="border-top">
                        <tr className="bg-transparent">
                            <th>Supplier</th>
                            <th>Avail Qty</th>
                            <th>Brand</th>
                            <th>In Time</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className="bg-white">
                            <td>
                                {transVM.SourceMachine.ProcessorViewsList[0].InwardDetails.SupplierDetails.CompanyName}
                                <br /><span
                                    className="tx-gray-800">Inward # {transVM.SourceMachine.ProcessorViewsList[0].InwardDetails.InwardEntry.SupplierInoviceNumber}</span>
                            </td>
                            <td>
                                <h5 className="mg-b-0 tx-inverse tx-20-f">
                                    {transVM.SourceMachine.ProcessorViewsList[0].ProcessEntry.ProcessingInItemsCount}
                                </h5>
                                <small
                                    className="tx-13 tx-gray-600">{transVM.SourceMachine.ProcessorViewsList[0].InwardDetails.LineItems[0].LineProduct.Product.ProductName}</small>
                            </td>
                            <td>
                                <div>
                                    <h5 className="mg-b-0  mg-t-3 tx-inverse tx-20-f">{transVM.SourceMachine.ProcessorViewsList[0].Batch?.BatchName}</h5>
                                    <small className="tx-13 tx-gray-600">Batch</small>
                                </div>
                            </td>
                            <td>{dateUtils.FormatDateTime(transVM.SourceMachine.ProcessorViewsList[0].InwardDetails.InwardEntry.SupplierDated)}</td>
                        </tr>
                    </tbody>
                </table>


            </div>
            <div className="card-body">
                <Tabs>
                    <TabList>
                        {transVM.DestinationPlant &&
                            <Tab><b style={{ color: colors.colors.mediumvioletred }}>Move to next machine</b></Tab>}

                        {!transVM.DestinationPlant &&
                            <Tab><b className="text-success">Move to Sales</b></Tab>
                        }
                        {!IsItemFromInward() &&
                            <Tab><b className="text-primary">Sub-Product Output</b></Tab>
                        }

                        {!IsItemFromInward() &&
                            <Tab><b className="text-warning">Review/QC</b></Tab>}
                    </TabList>

                    {/*Machine--> to ---> Machine*/}
                    {transVM.DestinationPlant &&
                        <TabPanel style={{ background: colors.colors.u }}>
                            <div className="row justify-content-center">
                                <div className="col-md-12">
                                    <div className="d-flex flex-grow-1 flex-column flex-md-row justify-content-between">
                                        {/* <div className="p-3">
                                        <u>Current Process</u>
                                        <h3 className="tx-gray-500">{transVM.SourceMachine.PlantDetails.PlantName}</h3>
                                        <b>{transVM.SourceMachine.MachineDetails.MachineName}</b>
                                    </div>
                                    <div className="p-3 text-center justify-content-center">
                                        <u>From Current Process</u>
                                        <h3 className="tx-gray-500">Move Qty</h3>
                                        <input type="text" name="qty"
                                               className="form-control text-center align-self-center"
                                               value={moveInfo.MoveQty}
                                               onChange={handleChange}/>
                                    </div>*/}

                                        <div className="flex-fill pl-2 py-3 text-left">
                                            <label>Output Product</label>
                                            {nextProduct.Product?.PRDID > 0 &&
                                                <ProductsDropDown product={nextProduct}
                                                    onChange={handleProductChange}
                                                    showOnlySaleable={false} />
                                            }
                                        </div>
                                        <div className="py-3 px-1 text-left" style={{ width: 80 }}>
                                            <label>Qty</label>
                                            <TextBoxNumeric name="qty"
                                                className="form-control text-center align-self-center"
                                                value={moveInfo.MoveQty}
                                                onChange={handleChange} />
                                            {/* <div className="form-check form-check-inline ml-0 mt-1">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    id="inlineCheckbox1"
                                                    style={{ width: 20, height: 20 }}
                                                    onChange={(e: any) => {
                                                        setIsAllRawItemsConsumed(e.target.value === 'on');
                                                    }}
                                                />
                                                <label className="form-check-label" htmlFor="inlineCheckbox1">All_Consumed</label>
                                            </div> */}
                                        </div>

                                        <div className="pr-2 align-self-center text-center text-success">
                                            <img className="text-success" src={doubleright} width={48} height={48} />
                                        </div>
                                        <div className="flex-grow-1 py-3 pr-2">
                                            {/*<u>Next Process</u><br/>
                                        <h3 className="tx-success">{transVM.DestinationPlant.PlantDetails.PlantName}</h3>*/}
                                            <label className="tx-success">{transVM.DestinationPlant.PlantDetails.PlantName}</label>
                                            <select className="form-control form-select" onChange={handleChange} name="flowid">
                                                <option value={"0"}>-- Select --</option>
                                                {transVM.DestinationPlant.MachineViewsList.map((mc: MachineView, mdx: number) => {
                                                    return (
                                                        <option key={mdx}
                                                            value={mc.ProcessFlow.PFLID}>{mc.MachineDetails.MachineName}</option>
                                                    )
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 text-center">
                                    <hr />
                                    <SuccessButtonUI className="mb-3" onClick={moveToNextMachine} buttonText="Move to Next Process &gt;"/>
                                    {/*<button className="btn btn-success mb-3" onClick={moveToNextMachine}>Move to Next*/}
                                    {/*    Process &gt;</button>*/}
                                </div>
                            </div>
                        </TabPanel>
                    }

                    {/*Machine--> to ---> Store*/}
                    {!transVM.DestinationPlant &&
                        <TabPanel style={{ background: colors.colors.tomato }}>
                            <div className="row justify-content-center">
                                <div className="col-md-12 text-center">
                                    <div className="d-flex flex-grow-1 flex-column flex-md-row justify-content-between">
                                        <div className="flex-fill p-3 text-left">
                                            <label>Output Product</label>
                                            {nextProduct.Product?.PRDID > 0 &&
                                                <ProductsDropDown product={nextProduct}
                                                    onChange={handleProductChange}
                                                    showWithStocks={false}
                                                    showOnlySubProducts={true}
                                                    showOnlySaleable={false}
                                                />
                                            }
                                        </div>
                                        <div className="p-3 wd-100 text-left">
                                            <label>Qty</label>
                                            <TextBoxNumeric name="qty"
                                                className="form-control text-center align-self-center"
                                                value={moveInfo.MoveQty}
                                                onChange={handleChange} />
                                        </div>
                                        {/*<div className="p-3">
                                        <u>Current Process</u>
                                        <h3 className="tx-gray-500">{transVM.SourceMachine.PlantDetails.PlantName}</h3>
                                        <b>{transVM.SourceMachine.MachineDetails.MachineName}</b>
                                    </div>
                                    <div className="p-3 text-center justify-content-center">
                                        <u>From Current Process</u>
                                        <h3 className="tx-gray-500">Move Qty</h3>
                                        <input type="text" name="qty"
                                               className="form-control text-center align-self-center"
                                               value={moveInfo.MoveQty}
                                               onChange={handleChange}/>
                                    </div>*/}
                                        <div className="p-3 align-self-center text-center text-success">
                                            <img className="text-success" src={doubleright} width={48} height={48} />
                                        </div>
                                        <div className="flex-grow-1 p-3">
                                            <label className="text-success">To the Store</label>
                                            <StoresSelect onChange={handleStoreChange} />
                                        </div>
                                    </div>
                                    <hr />
                                    <button className="btn btn-success mb-3"
                                        onClick={moveToStore}>Move to Sales &gt;</button>
                                </div>
                            </div>
                        </TabPanel>
                    }

                    {/*Machine--> to ---> SubProuct ----> Machine*/}
                    {!IsItemFromInward() &&
                        <TabPanel style={{ background: colors.colors.l }}>
                            <SubProductEntry
                                workflowID={props.workflowID}
                                parentProcessID={moveInfo.ParentProcessID}
                                batchID={moveInfo.BatchID}
                                prodID={moveInfo.ProdID}
                                close={props.close}
                            />
                        </TabPanel>
                    }

                    {!IsItemFromInward() &&
                        <TabPanel style={{ background: colors.colors.warning }}>
                            <ReviewTab
                                workflowId={props.workflowID}
                                currentProcessId={props.processID}
                                lineItemId={props.lineItemID}

                                parentProcessId={moveInfo.ParentProcessID}
                                batchId={moveInfo.BatchID}
                                prodId={moveInfo.ProdID}
                                qty={moveInfo.MoveQty}
                            />
                        </TabPanel>
                    }

                </Tabs>
            </div>

        </div>

    </>
}

export default TransitManager;
