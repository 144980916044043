import React from 'react';
import {FilterDropdownItem} from "../globals/ViewModels";
import {CloseCircleOutline} from "react-ionicons";

export const FilterAppliedItemsView = (props: {
    items: FilterDropdownItem[],
    title: string,
    removeId(id:number):void
}) => {

    return <>
        <div className="tx-14-f mb-1 text-black">
            {props.title && props.title.length > 0 ? props.title : 'Filters Applied'}
        </div>
        <div className="tx-14-f" style={{lineHeight: '2.2em'}}>
            {props.items.map((item: FilterDropdownItem, index: number) => {
                return (
                    <span className="badge badge-pill badge-light tx-14-f mr-1 py-1 px-2 mb-2"
                          onClick={()=>props.removeId(item.id)}>
                        <div className="d-flex align-items-center pointer-cursor">
                            <div>{item.value}</div>
                            <CloseCircleOutline
                                cssClasses="ml-1" width={'22px'} height={'22px'}
                            />
                        </div>
                    </span>
                )
            })}
            {props.items.length === 0 && <span className='text-black-50'>--None--</span>}
        </div>
    </>
}
