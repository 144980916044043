import React, {createRef, useEffect, useRef, useState} from 'react';
import {Modal} from "react-bootstrap";
import * as pdb from '../db/ProductsData';
import {ML_Company, ML_Product, ML_Tag, ML_UnitMeasure, ProductView} from '../globals/dto';
import * as global from '../globals/GlobalConsts';
import {mapStateToProps} from "../globals/AppActions";
import * as AppAct from '../globals/AppActions';
import {connect, useDispatch} from "react-redux";
import {toast} from "react-toastify";
import Switch from 'react-switch';
import StoresSelect from "../shared/StoresSelect";
import {AxiosResponse} from "axios";
import * as prsdb from '../db/ProcessingData';
import AddOpeningStock from './AddOpeningStock';
import {UnitsManager} from "../shared/UnitsManager";
import Toggler from '../globals/Toggler';
import TextBoxNumeric from '../ui-controls/TextBoxNumeric';
import {SaveButtonUI} from '../globals/AppControls/SaveButtonUI';
import {CloseButtonUI} from '../globals/AppControls/CloseButtonUI';
import {CheckToggleUI} from "../reports/FilterUI/CheckToggleUI";
import {TagsDropdown} from '../tags/TagsDropdown';
import * as consts from '../globals/GlobalConsts';
import {WarningButtonUI} from "../globals/AppControls/WarningButtonUI";

export const ProductDetailsEx = (props: any) => {


    const [selectedStore, setSelectedStore] = useState<ML_Company>(new ML_Company());
    const [qty, setQty] = useState(0);
    const [showOpeningStockSection, setShowOpeningStockSection] = useState(false);

    const [Product, setProduct] = useState<ML_Product>(null);
    const [UnitMeasure, setUnitMeasure] = useState<ML_UnitMeasure>(null);

    const [validity, setValidity] = useState({
        ProductNameErr: '',
        BulkMeasureNameErr: '',
        RetailQtyErr: '',
        RetailMesaureNameErr: ''
    });

    useEffect(() => {
        if (props.id && props.id > 0) {
            pdb.GetProduct(props.id).then(res => {
                setProduct(res.data.Product);
                setUnitMeasure(res.data.UnitMeasure);
            });
        } else {
            setProduct(new ML_Product());
            setUnitMeasure(new ML_UnitMeasure());
        }
    }, [])

    /*
    //https://blog.jakoblind.no/react-redux-hooks/ <-GEM
    const disp = useDispatch();
    disp(AppAct.ChangeMainTitle('Product edit'));*/

    const handleProductEntryChange = (e: any) => {
        let name = e.target.name;
        let val = e.target.type === 'checkbox' ? e.target.checked : e.target.value;

        setProduct({...Product, [name]: val});
        setValidity({...validity, [name + "Err"]: (val.length === 0 ? global.CLASS_INPUT_ERROR : '')});

    }

    const handleCheckChanges = (val: boolean, name: string) => {
        setProduct({...Product, [name]: val});
    }

    const handleToggleChanges = (val: boolean, name: string) => {
        setProduct({...Product, [name]: val});
    }

    const ValidateUserInput = () => {
        if (!Product.ProductName || Product.ProductName.length === 0) {
            setValidity({...validity, ProductNameErr: (global.CLASS_INPUT_ERROR)});
            return false;
        } else if (!UnitMeasure.RetailMesaureName || UnitMeasure.RetailMesaureName.length === 0) {
            setValidity({...validity, RetailMesaureNameErr: (global.CLASS_INPUT_ERROR)});
            return false;
        } else if (!UnitMeasure.RetailQty || UnitMeasure.RetailQty === 0) {
            setValidity({...validity, RetailQtyErr: (global.CLASS_INPUT_ERROR)});
            return false;
        } else if (!UnitMeasure.BulkMeasureName || UnitMeasure.BulkMeasureName.length === 0) {
            setValidity({...validity, BulkMeasureNameErr: (global.CLASS_INPUT_ERROR)});
            return false;
        }
        return true;
    }

    const save = async () => {

        if (ValidateUserInput()) {
            //props.toggleModalProgBar(true);
            let data = new ProductView();
            data.Product = Product;
            data.UnitMeasure = UnitMeasure;

            await pdb.SaveProduct(data).then(res => {
                let prodID = +res.data;
                if (prodID > 0) {
                    setProduct({...Product, "PRDID": prodID});
                    if (selectedStore.CMPID > 0 && qty > 0) {
                        triggerAddStock(prodID, selectedStore.CMPID, qty);
                    }
                }
                toast.success('Details saved!');
                //props.toggleModalProgBar(false);
                props.refreshList();
            });
        } else {
            toast.error('Please enter mandatory fields');
        }
    }

    const closeWin = () => {
        toast.dismiss();
        props.closePop();
        props.refreshList();
    }

    const AddStock = () => {
        triggerAddStock(Product.PRDID, selectedStore.CMPID, qty);
    }

    const triggerAddStock = (prdid: number, storeid: number, stock_qty: number) => {
        if (storeid === 0 || stock_qty === 0 || prdid === 0) {
            toast.error('Enter Store and Qty information properly');
            return;
        }
        prsdb.AddOpeningStock(prdid, storeid, stock_qty).then((res: AxiosResponse<number>) => {
            if (res.data > 0)
                toast.success('Opening stock added.');
        });
    }

    const productCategorySelected = (e: ML_Tag) => {
        if (e) 
            setProduct({...Product, "ProductCategoryID": e.GRPID})
    }

    return <>
        {!showOpeningStockSection && Product && UnitMeasure &&
            <div>
                <div>
                    <h4 className="az-content-label mg-b-5 mb-4">Product Details</h4>
                    <div className="row form-group">
                        <div className="col-md-8">
                            <label className="form-label">Product Name <span
                                className="tx-danger">*</span></label>
                            <input type="text"
                                   className={"form-control " + validity.ProductNameErr}
                                   name="ProductName"
                                   value={Product.ProductName}
                                   onChange={handleProductEntryChange}
                                   autoFocus={true}
                                   
                            />
                        </div>
                        <div className="col-md-4">
                            <label className="form-label">GST HSN/SAC<span
                                className="tx-danger">*</span></label>
                            <input type="text"
                                   className={"form-control"}
                                   name="HSNSACNumber"
                                   value={Product.HSNSACNumber}
                                   onChange={handleProductEntryChange}
                                   
                                   
                            />
                        </div>
                    </div>

                    <div className="row g-2">
                        <div className="col-md-6">
                            <CheckToggleUI keyValue={"pd3"}
                                           idStringValue={"CanBeSold"}
                                           checked={Product.CanBeSold}
                                           label={"Can be Sold"}
                                           onChecked={(e: boolean) => handleCheckChanges(e, "CanBeSold")}
                            />
                        </div>
                        <div className="col-md-6">
                            <CheckToggleUI keyValue={"pd3"}
                                           idStringValue={"CanBePurchased"}
                                           checked={Product.CanBePurchased}
                                           label={"Can be Purchased"}
                                           onChecked={(e: boolean) => handleCheckChanges(e, "CanBePurchased")}
                            />
                        </div>
                        <div className="col-md-6">
                            <CheckToggleUI keyValue={"pd3"}
                                           idStringValue={"IsActive"}
                                           checked={Product.IsActive}
                                           label={"Is Active"}
                                           onChecked={(e: boolean) => handleCheckChanges(e, "IsActive")}
                            />
                        </div>
                        <div className="col-md-6">
                            <CheckToggleUI keyValue={"pd4"}
                                           idStringValue={"IsInternalProduct"}
                                           checked={Product.IsInternalProduct}
                                           label={"Is By-Product"}
                                           onChecked={(e: boolean) => handleCheckChanges(e, "IsInternalProduct")}
                            />
                        </div>
                    </div>
                </div>

                <div className="az-content-label mg-b-5 mt-4">Product Group</div>


                {Product && <div className='row'>
                    <div className='col-md-6 col-12'>
                        <TagsDropdown 
                            tagType={consts.TagTypes.PRODUCT_GROUP}
                            selectedTagId={Product.ProductCategoryID}
                            isError={false}
                            onSelected={productCategorySelected}/>
                    </div>
                </div>
                }

                <div className="az-content-label mg-b-5 mt-4">Unit of Measure</div>
                <UnitsManager unitID={UnitMeasure.UCID}
                              GetSelectedUnit={(e: ML_UnitMeasure) => {
                                  setUnitMeasure(e);
                              }
                              }
                              showCreateNewOption={true}/>
                {/* <hr className="mg-y-30 mg-lg-y-30" />

                <div className="az-content-label mg-b-5 mb-3">Opening Stock</div>

                <div className="row align-items-end">
                    <div className="col-md-7">
                        <label>Store</label>
                        <StoresSelect onChange={(e: ML_Company) => { if (e) setSelectedStore(e); }} company={selectedStore} />
                    </div>
                    <div className="col-md-3">
                        <label>Qty</label>
                        <TextBoxNumeric className="form-control" placeholder="Qty"
                            onChange={(e: any) => setQty(+e.target.value)} />
                    </div>

                </div> */}

                <div className="row">
                    <div className="col-md-6 mt-3">
                        {Product.PRDID > 0 &&
                            <WarningButtonUI buttonText="Manage Opening Stocks"
                                             onClick={() => setShowOpeningStockSection(true)}
                            />}

                    </div>
                    <div className="col-md-6 mt-3 text-right">
                        {/* <button accessKey={"s"} type="submit" className="btn btn-success" tabIndex={10} onClick={save}><u>S</u>ave</button>
                        <button accessKey={"c"} className="btn btn-outline-secondary text-black ml-3" tabIndex={11} onClick={closeWin}><u>C</u>lose</button> */}
                        <SaveButtonUI onClick={save} className="mr-3"/>
                        <CloseButtonUI onClick={closeWin}/>
                    </div>
                </div>
            </div>}

        {showOpeningStockSection &&
            <AddOpeningStock productName={Product.ProductName} prodid={Product.PRDID} store={null}
                             onClose={() => setShowOpeningStockSection(false)}/>}

    </>
}
