import React, { useEffect, useState } from 'react';
import * as AppAct from '../globals/AppActions';
import { mapStateToProps } from '../globals/AppActions';
import * as sdb from '../db/SaleBillData';
import { connect, useDispatch, useSelector } from "react-redux";
import * as utils from '../globals/Utils';
import DateRangeControlEx from "../globals/DateRangeControlEx";
import { Modal, ModalBody, ModalFooter } from "react-bootstrap";
import ModalHeader from "react-bootstrap/ModalHeader";
import DebtorPay from "./DebtorPay";
import { SaleInvoiceView } from "../sales/SaleInvoiceView";
import { ML_Transaction, TransactionLineView } from "../globals/dto";
import { AxiosResponse } from 'axios';
import { AppReduxState } from '../infra-redux/AppStateReduxStore';
import * as dateUtils from '../globals/AppDateUtils';
import { SaleBillBadgeUI } from '../globals/PopTags/SaleBillBadgeUI';
import { InvoiceViewer } from '../globals/InvoiceViewer';
import { CloseButtonUI } from "../globals/AppControls/CloseButtonUI";
import { SuccessButtonUI } from "../globals/AppControls/SuccessButtonUI";

const DebtorStatement = (props: any) => {
    const [allTrans, setAllTrans] = useState({ trans: new Array<TransactionLineView>(), grandBillingTotal: 0, grandPaidTotal: 0 });
    const [dates, setDates] = useState({ start: dateUtils.MinusDays(new Date(), 180), end: new Date() });
    const [showPayModal, setShowPayModal] = useState(false);
    //set this as true when any data is updated.
    const [showBillNumber, setShowBillNumber] = useState(0);

    const AppDisp = useDispatch();
    const reduxState = useSelector((state: AppReduxState) => state);

    useEffect(() => {
        AppDisp(AppAct.ChangeMainTitle('Customer Debt Statement'));
    }, []);

    useEffect(() => {
        LoadCustomerBills();
    }, [dates]);

    const handleDateChange = (start: Date, end: Date) => {
        setDates({ start: start, end: end });
    }

    const debitTotal = (allRow: Array<any>) => {
        if (allRow && allRow.length > 0) {
            return allRow.reduce((total, t) => {
                //if(isDebitEntry(t)){
                return total + (t.DebitAmount ? t.DebitAmount : 0);
                //}
                //return total;
            }, 0);
        }
        return 0;
    }

    const creditTotal = (allRow: Array<any>) => {
        if (allRow && allRow.length > 0) {
            return allRow.reduce((total, t) => {
                //if(isCreditEntry(t)){
                return total + (t.CreditAmount ? t.CreditAmount : 0);
                // }
                // return total;
            }, 0);
        }
        return 0;
    }



    const LoadCustomerBills = () => {
        AppDisp(AppAct.ToggleProgress(true));
        sdb.getCustomerBillWiseDebitStatment(props.customer.CID, dates.start, dates.end)
            .then((res: AxiosResponse<TransactionLineView[]>) => {
                setAllTrans({
                    ...allTrans,
                    trans: [...res.data],
                    grandBillingTotal: creditTotal(res.data),
                    grandPaidTotal: debitTotal(res.data)
                });
                AppDisp(AppAct.ToggleProgress(false));
            });
    }

    return <>


        {/* <Modal size={"lg"} dialogClassName="modal-corner" show={(showBillNumber > 0)} keyboard={true}
            onHide={() => { setShowBillNumber(0) }}
            onEscapeKeyDown={() => { setShowBillNumber(0) }} >
            <ModalBody>
                <SaleInvoiceView sbid={showBillNumber} />
            </ModalBody>
        </Modal> */}

        <InvoiceViewer inwardID={0} saleBillID={showBillNumber} onClose={() => { setShowBillNumber(0) }} />

        {showPayModal &&
            <Modal show={showPayModal} dialogClassName="modal-corner">
                <div className="card">
                    <div className="card-body">
                        <h4 className="az-content-label mb-3">Customer Receipts</h4>
                        <DebtorPay
                            customer={props.customer}
                            close={() => setShowPayModal(false)}
                            saved={() => {
                                LoadCustomerBills()
                            }}
                        />
                    </div>
                </div>
            </Modal>
        }

        <div className="row">
            <div className="col-md-12 text-black">
                <span className="font-weight-bold tx-20-f">{props.customer.CompanyName}</span>
                &nbsp;|&nbsp;
                <span className="font-weight-bold">{props.customer.PrimaryContactPersonName}</span>
                &nbsp;|&nbsp;
                <span className="font-weight-bold">{props.customer.PrimaryPhones}</span>
            </div>
        </div>
        <hr />
        <div className="row mb-3">
            <div className="col-md-8">
                <DateRangeControlEx start={dates.start} end={dates.end} onChange={handleDateChange} />
            </div>
            <div className="col-md-4 text-right mt-3 mt-md-0">
                {/*<button className="btn btn-success font-weight-bold"*/}
                {/*    onClick={() => setShowPayModal(true)}>*/}
                {/*    Receipt*/}
                {/*</button>*/}
                {/*<button className="btn btn-primary ml-3" onClick={() => props.close(true)}>&lt; Back</button>*/}
                <SuccessButtonUI className="mr-2" onClick={() => setShowPayModal(true)} buttonText="Receipt" />
                <CloseButtonUI onClick={() => props.close(true)} />
            </div>
        </div>
        <div className="row">
            <div className="col-md-12">
                <div className="table-responsive">
                    <table className="table table-bordered table-striped mg-b-0">
                        <thead className="border-top">
                            <tr>
                                <th>Date</th>
                                <th>Description</th>
                                <th>Mode</th>
                                <th className="text-right">Debit</th>
                                <th className="text-right">Credit</th>
                                <th className="text-right">Balance</th>
                            </tr>
                        </thead>
                        <tbody>
                            {allTrans.trans.map((il: TransactionLineView, idx: number) => {
                                return (
                                    <tr key={idx}>
                                        <td>{dateUtils.FormatStringDate(il.TranDate)}</td>

                                        {/*Description SALE/payments/receipts invoice*/}
                                        <td>
                                            {il.Details ? <>{il.Details}</> : <>{il.AdditionDetails}</>}
                                            {il.InvoiceID > 0 && <>
                                                <SaleBillBadgeUI key={"sbui" + Math.random()} id={il.InvoiceID} showRequest={() => setShowBillNumber(il.InvoiceID)} />
                                            </>
                                            }

                                        </td>
                                        <td>{il.PayMode}</td>
                                        {/*Debit*/}
                                        <td className="text-right">
                                            {/* {isDebitEntry(il) && <div>{il.Trans.TotalAmount}</div>} */}
                                            {(il.DebitAmount > 0) && <div>{utils.formatCurrencyValue(reduxState, il.DebitAmount)}</div>}
                                            {/*{il.Trans.LedgerID > 0 &&
                                                <div>{il.Trans.TotalAmount}</div>}
                                                {il.SaleBillPay.IsAdjustmentEntry && !il.Trans.IsExpense &&
                                                <div>{il.Trans.TotalAmount}</div>}*/}
                                        </td>
                                        {/*Credit*/}
                                        <td className="text-right">
                                            {/* {isCreditEntry(il) && <div>{il.Trans.TotalAmount}</div>} */}
                                            {(il.CreditAmount > 0) && <div>{utils.formatCurrencyValue(reduxState, il.CreditAmount)}</div>}
                                            {/*{il.Trans.LedgerID === 0 && il.SaleBillPay.IsAdjustmentEntry === false &&
                                                <div>{il.Trans.TotalAmount}</div>}
                                                {il.Trans.LedgerID === 0 && il.SaleBillPay.IsAdjustmentEntry && il.Trans.IsExpense &&
                                                <div>{il.Trans.TotalAmount}</div>}*/}
                                        </td>

                                        {/*Balance*/}
                                        <td className="text-right">{utils.formatCurrencyValue(reduxState, il.BalanceAmount)}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                        <tfoot>
                            <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td className="text-right font-weight-bold">{utils.formatCurrencyValue(reduxState, allTrans.grandPaidTotal)}</td>
                                {/*<td className="text-right font-weight-bold">{debitTotal()}</td>*/}
                                <td className="text-right font-weight-bold">{utils.formatCurrencyValue(reduxState, allTrans.grandBillingTotal)}</td>
                                {/*<td className="text-right font-weight-bold">{creditTotal()}</td>*/}
                                <td className="text-right font-weight-bold">
                                    {allTrans && allTrans.trans && allTrans.trans.length > 0 &&
                                        <b>{utils.formatCurrencyValue(reduxState, allTrans.trans[allTrans.trans.length - 1].BalanceAmount)}</b>
                                    }
                                </td>
                            </tr>
                        </tfoot>
                    </table>

                </div>
            </div>
        </div>
    </>
}

export default DebtorStatement;
