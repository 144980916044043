import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from "react-redux";
import { mapStateToProps } from "../globals/AppActions";
import * as AppAct from '../globals/AppActions';
import { ML_Tax } from "../globals/dto";
import { Modal } from "react-bootstrap";
import { AppProgressBar } from "../globals/AppProgressBar";
import * as tdb from '../db/TaxData';
import { TaxDetails } from "./TaxDetails";
import cloneDeep from 'lodash/cloneDeep'
import { Link } from "react-router-dom";
import { AddNewButtonUI } from '../globals/AppControls/AddNewButtonUI';
const TaxList = (props: any) => {

    const dispatch = useDispatch();
    const [showTaxDetails, setShowTaxDetails] = useState(false);

    const [state, setState] = useState({
        taxes: new Array<ML_Tax>(),
        taxSelected: new ML_Tax(),
        localProgress: false
    });

    useEffect(() => {
        dispatch(AppAct.ChangeMainTitle("Taxes"));
        GetAll();
    }, [])

    const edit = (t: ML_Tax) => {
        setState({ ...state, taxSelected: t });
        setShowTaxDetails(true);
    }

    const newTax = () => {
        setState({ ...state, taxSelected: new ML_Tax() });
        setShowTaxDetails(true);
    }

    const closeModal = () => setShowTaxDetails(false);

    const GetAll = () => {
        dispatch(AppAct.ToggleProgress(true));
        tdb.GetAll().then((res) => {
            setState({ ...state, taxes: res.data });
            dispatch(AppAct.ToggleProgress(false));
        });
    }


    return <>
        <div className="row mb-3">
            <div className="col-md-12">
                {/* <button className="btn btn-az-primary" onClick={newTax}>
                    <i className="fas fa-file-invoice"></i> New Tax
                </button> */}

                <AddNewButtonUI
                    onClick={newTax}
                />
            </div>
        </div>
        <div className="row">
            {state.taxes.map((t: ML_Tax, idx: number) => {
                return (
                    <div className="col-md-3 pointer-cursor" key={idx}>
                        <div className="card card-dashboard-thirteen shadow-sm mb-4" onClick={() => edit(t)}>
                            <div className="media mb-0">
                                <div className="media-body text-center pointer-cursor">
                                    <h2 className="mb-0">{t.TaxGroupName}</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            })}
        </div>

        <Modal show={showTaxDetails} dialogClassName="modal-corner"
            onHide={closeModal}
            backdrop={'static'}>
            {state.localProgress && <AppProgressBar />}
            <Modal.Header className="bg-gray-200"><h3 className="pb-0 mb-0">Tax Settings</h3></Modal.Header>
            <Modal.Body>
                <TaxDetails
                    data={cloneDeep(state.taxSelected)}
                    toggleProgress={(tog: boolean) => setState({ ...state, localProgress: tog })}
                    close={closeModal}
                    closeWithRefresh={() => { closeModal(); GetAll(); }}
                />

            </Modal.Body>
        </Modal>
    </>;

}

export default TaxList;
