import React, { useEffect, useState } from 'react';
import * as AppAct from '../globals/AppActions';
import { mapStateToProps } from '../globals/AppActions';
import { InwardLineItemView, InwardView } from "../globals/dto";
import { connect, useDispatch } from "react-redux";
import * as idb from '../db/InwardsData';
import InwardVerify from "./InwardVerify";
import cloneDeep from 'lodash/cloneDeep';
import TransitManager from "../workflows/TransitManager";
import { Modal } from 'react-bootstrap';
import { MachinesSelect } from "../shared/MachinesSelect";
import * as utils from '../globals/Utils';
import * as dateUtils from '../globals/AppDateUtils';
import {PrimaryButtonUI} from "../globals/AppControls/PrimaryButtonUI";
import {SecondaryButtonUI} from "../globals/AppControls/SecondaryButtonUI";

const InwardBayStores = (props: any) => {

    const dispatch = useDispatch();

    const [state, setState] = useState({
        bayInwards: new Array<InwardView>(),
        inwardSelected: new InwardView(),       
        lineItemID: 0
    });

    const [showModal,setShowModal] = useState(false);
    const [showTransitModal, setShowTransitModal] = useState(false);
    const [isAllVerified, setIsAllVerified] = useState(false);

    useEffect(() => {
        dispatch(AppAct.ChangeMainTitle("Inwards Bay: Items awaiting verification"));
        GetBayAreaInfo();
    }, [])

    const GetBayAreaInfo = () => {
        dispatch(AppAct.ToggleProgress(true));
        idb.GetBayInwards().then((res: any) => {
            setState({ ...state, bayInwards: [...res.data] });
            dispatch(AppAct.ToggleProgress(false));
        });
    }

    const IsInwardVerified = (lineItems: InwardLineItemView[]): boolean => {
        var total = 0;
        lineItems.forEach((x) => {
            if (x.LineItem.InternalQty > 0)
                total = total + 1;
        });
        return total === lineItems.length;
    }

    const verifyInward = (inw: InwardView) => {
        setIsAllVerified(IsInwardVerified(inw.LineItems));
        setState({ ...state, inwardSelected: inw});
        setShowModal(true);
    }

    const closeModal = (refreshList: boolean) => {
        setShowModal(false);
        if (refreshList)
            GetBayAreaInfo();
    }

    const moveInwardToMachine = (lineItemID: number) => {
        setState({
            ...state,
            lineItemID: lineItemID
        });
        setShowTransitModal(true);
    }

    const closeTransitModal = () => {
        setShowTransitModal(false);
        GetBayAreaInfo();
    }

    if (!state.bayInwards) return <></>

    return (<>
        <Modal show={showTransitModal} size={"lg"} dialogClassName="modal-corner">
            <TransitManager processID={0} workflowID={0}  lineItemID={state.lineItemID} close={closeTransitModal} />
        </Modal>

        {showModal &&
            <InwardVerify
                showModal={showModal}
                IsAllVerified={isAllVerified}
                onClose={closeModal}
                inw={cloneDeep(state.inwardSelected)} />
        }

                <div className="az-contacts-list">
                    <div className="az-contact-label font-medium font-weight-bold text-black pt-0 mt-0">
                        Invoices Pending for Verification: {state.bayInwards.length}
                    </div>
                    {state.bayInwards.map((a: InwardView, idx: number) => {
                        const isVerified = IsInwardVerified(a.LineItems);
                        return (
                            <div className="az-contact-item" style={{ cursor: "default" }} key={'a' + idx}>
                                <div
                                    className="az-avatar-nodot bg-gray-600">{a.SupplierDetails.CompanyName.charAt(0).toUpperCase()}</div>
                                <div className="az-contact-body">
                                    <div className="row align-items-center">
                                        <div className="col-md-3">
                                            <h6 className="font-weight-bold text-black-50">{a.SupplierDetails.CompanyName}</h6>
                                            <h5 className="mb-0 pb-0">Inward
                                                No: {a.InwardEntry.SupplierInoviceNumber}</h5>
                                            <span>Date: {a.InwardEntry.InvoiceReceivedDate}</span>
                                            <br />
                                            {isVerified ?
                                                (<div className="tx-success">
                                                    <i className="icon ion-md-checkmark-circle tx-15-f"></i>&nbsp;Verified
                                                </div>)
                                                : (<div className="tx-danger">
                                                    <i className="icon ion-md-close-circle tx-15-f"></i>&nbsp;Not
                                                    Verified
                                                </div>)
                                            }
                                        </div>


                                        <div className="col-md-7">
                                            <table className="table">
                                                <thead className="border-top">
                                                    <tr>
                                                        <th>Product</th>
                                                        <th>Billed Qty</th>
                                                        <th>Verified</th>
                                                        {/* {isVerified && <th>Stocks</th>} */}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {a.LineItems.map((t: InwardLineItemView, vdx: number) => {
                                                        return (
                                                            <>
                                                                <tr key={vdx}>
                                                                    <td>{t.LineProduct.Product.ProductName}</td>
                                                                    <td>{t.LineItem.BulkQty}&nbsp;{t.LineProduct.UnitMeasure.BulkMeasureName}</td>
                                                                    <td>{t.LineItem.InternalQty}</td>
                                                                    {/* {isVerified && <td>
                                                                        <a className='btn-link' onClick={()=>moveInwardToMachine(t.LineItem.INLID)}>Import</a>
                                                                        </td>} */}
                                                                </tr>
                                                            </>
                                                        )
                                                    })}
                                                </tbody>
                                            </table>

                                        </div>
                                        <div className="col-md-2 text-right">
                                            {(isVerified) ?
                                                (<SecondaryButtonUI onClick={() => verifyInward(a)} buttonText={"Re-Verify"}/>)
                                                :(<PrimaryButtonUI onClick={() => verifyInward(a)} buttonText={"Verify"}/>)
                                                //)
                                                // : (<button onClick={() => verifyInward(a)}
                                                // className="btn btn-primary text-white mt-2">Verify</button>)
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            
    </>)

}

export default InwardBayStores;
