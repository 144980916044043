import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from "react-redux";
import { mapStateToProps } from "../globals/AppActions";
import * as AppAct from '../globals/AppActions';
import * as global from '../globals/GlobalConsts';

import { InwardPaymentView, ML_Supplier, ML_InwardPayment, ML_TagLink } from "../globals/dto";
import * as sdb from '../db/SupplierData';
import * as tgdb from '../db/TagData';
import { toast } from "react-toastify";
import { AxiosResponse } from "axios";
import { Tags } from '../shared/Tags';
import TextBoxNumeric from '../ui-controls/TextBoxNumeric';
import * as factory from './../globals/factory';
import { SaveButtonUI } from '../globals/AppControls/SaveButtonUI';
import { CloseButtonUI } from '../globals/AppControls/CloseButtonUI';

//props
//data = ML_Supplier
//close()


const SupplierDetails = (props: any) => {

    const dispatch = useDispatch();

    const [state, setState] = useState({
        openBal: 0,
        validity: {
            CompanyNameErr: ''
        },
        tagLink: new ML_TagLink()
    });

    const [supplier, setSupplier] = useState({ ...props.data });

    useEffect(() => {
        console.log(supplier);
        dispatch(AppAct.ChangeMainTitle('Supplier Details'));
        tgdb.GetSupplierTagLink(supplier.SPID).then(res => {
            console.log(res.data);
            let theTagLink: ML_TagLink = res.data;
            theTagLink.EntityType = 1; //supplier
            theTagLink.EntityID = supplier.SPID;
            setState({ ...state, tagLink: { ...theTagLink } });
            window.scrollTo(0, 0);
            /*let obj = qs.parse(props.location.search);*/
        });
    }, []);

    const newTagLinked = (tglink: ML_TagLink) => {
        setState({ ...state, tagLink: { ...tglink } });
    }

    const handleChange = (e: any) => {
        let name = e.target.name;
        let val = e.target.value;
        console.log(name + " : " + val);
        if (name === 'openBal') {
            setState({ ...state, openBal: val });
        } else {
            setSupplier({ ...supplier, [name]: val });

            if (name === "CompanyName") {
                setState({ ...state, validity: { ...state.validity, [name + "Err"]: ((!val || val.length === 0) ? global.CLASS_INPUT_ERROR : '') } });
            }
        }
    }

    const IsUserInputOK = () => {
        console.log(supplier);
        if (!supplier.CompanyName || supplier.CompanyName.length === 0) {
            setState({ ...state, validity: { ...state.validity, "CompanyNameErr": global.CLASS_INPUT_ERROR } });
            return false;
        }
        return true;
    }

    const save = async () => {
        if (IsUserInputOK() === false) {
            toast.error('Please enter required field');
        } else {
            dispatch(AppAct.ToggleProgress(true));
            let payload = factory.createInwardPaymentView();
            payload.Supplier = { ...supplier };
            payload.Supplier.DateCreated = !payload.Supplier.DateCreated ? new Date(0) : payload.Supplier.DateCreated;
            payload.InwardPayment.Balance = state.openBal;
            console.log(payload);
            await sdb.Save(payload).then((res: AxiosResponse<number>) => {
                dispatch(AppAct.ToggleProgress(false));
                if (res.data === 0) {
                    toast.error('Unable to save, something is wrong');
                } else {
                    setState({ ...state, tagLink: { ...state.tagLink, "EntityID": res.data } });
                    setSupplier({ ...supplier, "SPID": res.data })
                    toast.success("Supplier saved.");
                    props.close();
                }
            });
        }
    }

    if (!supplier && !state.validity) { return <></> }
    return <>
        <div className="row">
            <div className=" col-md-12 text-right">
                {/* <button type="button" className="btn btn-lg btn-success" accessKey={"s"} onClick={save}>Save</button>
                <button className="btn btn-lg btn-primary ml-3" accessKey={"c"} onClick={props.close}>Close</button> */}
                {/*<Link to={'/suppliers?q=dhan'} className="btn btn-primary ml-3">Back</Link>*/}

                <SaveButtonUI  onClick={save} className="mr-3" />
                <CloseButtonUI onClick={props.close} />
            </div>
        </div>



        <h5 className="az-content-label">Business Details</h5>
        <hr />

        <div className="row">
            <div className="col-md-2 text-center">
                <i className="fas fa-university fa-3x pt-2 text-warning mb-2"></i>
            </div>
            <div className="col-md-10">
                <div className="d-flex flex-md-row flex-column justify-content-between">
                    <div className="pr-3 pl-2 flex-md-fill">
                        <label className="form-label">Company/Business Name<span className="tx-danger">*</span></label>
                        <input type="text" className={"form-control " + state.validity.CompanyNameErr}
                            name="CompanyName"
                            autoFocus
                            value={supplier.CompanyName}
                            onChange={handleChange}
                        />
                    </div>
                    {/*<div className="pr-3 pl-2 flex-md-fill">
                                    <label className="form-label">Work Phone</label>
                                    <input type="text" className="form-control"
                                           value={supplier.PrimaryPhones}/>
                                </div>*/}

                </div>

                <div className="mt-3 d-flex flex-md-row flex-column justify-content-between">
                    <div className="pr-3 pl-2 flex-md-fill">
                        <label className="form-label">Address - 1</label>
                        <input type="text" className="form-control"
                            name="AddressOne"
                            value={supplier.AddressOne}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="pr-3 pl-2 flex-md-fill">
                        <label className="form-label">Address - 2</label>
                        <input type="text" className="form-control"
                            value={supplier.AddressTwo}
                            name="AddressTwo"
                            onChange={handleChange}
                        />
                    </div>
                </div>

                <div className="mt-3 pb-4 d-flex flex-md-row flex-column justify-content-between">
                    <div className="pr-3 pl-2 flex-md-fill">
                        <label className="form-label">City</label>
                        <input type="text" className="form-control"
                            value={supplier.GeoCity}
                            name="GeoCity"
                            onChange={handleChange}
                        />
                    </div>
                    <div className="pr-3 pl-2 flex-md-fill">
                        <label className="form-label">PIN Code</label>
                        <input type="text" className="form-control"
                            value={supplier.GeoBusinessCode}
                            name="GeoBusinessCode"
                            onChange={handleChange}
                        />
                    </div>
                    <div className="pr-3 pl-2 flex-md-fill">
                        <label className="form-label">State</label>
                        <input type="text" className="form-control"
                            value={supplier.GeoState}
                            name="GeoState"
                            onChange={handleChange}
                        />
                    </div>
                </div>


                <div
                    className="pt-3 pl-3 pb-3 mr-3 d-flex flex-md-row flex-column justify-content-between bg-gray-100"
                    style={{ borderTop: "1px dotted #cdd4e0" }}>
                    <div className="pr-3 pl-2 flex-md-fill">
                        <label className="form-label">Company PAN</label>
                        <input type="text" className="form-control"
                            value={supplier.CompanyPAN}
                            name="CompanyPAN"
                            onChange={handleChange}
                        />
                    </div>
                    <div className="pr-3 pl-2 flex-md-fill">
                        <label className="form-label">GST</label>
                        <input type="text" className="form-control"
                            value={supplier.GSTN}
                            name="GSTN"
                            onChange={handleChange}
                        />
                    </div>
                    <div className="pr-3 pl-2 flex-md-fill">
                        <label className="form-label">CST No.</label>
                        <input type="text" className="form-control"
                            value={supplier.CSTNo}
                            name="CSTNo"
                            onChange={handleChange}
                        />
                    </div>
                </div>

            </div>
        </div>



        <h5 className="az-content-label mt-5">Business Owner's Details</h5>
        <hr />

        <div className="row">
            <div className="col-md-2 text-center">
                <i className="fas fa-address-card fa-3x pt-2  text-info mb-2"></i>
            </div>
            <div className="col-md-10">
                <div className="d-flex flex-md-row flex-column justify-content-between">
                    <div className="pr-3 pl-2 flex-md-fill">
                        <label className="form-label">Full Name</label>
                        <input type="text" className="form-control"
                            value={supplier.PrimaryContactPersonName}
                            name="PrimaryContactPersonName"
                            onChange={handleChange}
                        />
                    </div>
                    <div className="pr-3 pl-2 flex-md-fill">
                        <label className="form-label">Mobile Phone</label>
                        <input type="text" className="form-control"
                            value={supplier.PrimaryPhones}
                            name="PrimaryPhones"
                            onChange={handleChange}
                        />
                    </div>

                </div>

                <div className="mt-3 d-flex flex-md-row flex-column justify-content-between">
                    <div className="pr-3 pl-2 flex-md-fill">
                        <label className="form-label">PAN</label>
                        <input type="text" className="form-control"
                            value={supplier.PAN}
                            name={"PAN"}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="pr-3 pl-2 flex-md-fill">
                        <label className="form-label">Email</label>
                        <input type="text" className="form-control"
                            value={supplier.PrimaryEmailAddress}
                            name={"PrimaryEmailAddress"}
                            onChange={handleChange}
                        />
                    </div>
                </div>

            </div>
        </div>



        <h5 className="az-content-label mt-5">Opening Balance</h5>
        <hr />

        <div className="row">
            <div className="col-md-2 text-center">
                <i className="fas fa-rupee-sign fa-3x pt-2  text-indigo mb-2"></i>
            </div>
            <div className="col-md-4">
                <div className="d-flex flex-md-row flex-column justify-content-between">
                    <div className="pr-3 pl-2 flex-md-fill">
                        <label className="form-label">Amount</label>
                        <TextBoxNumeric className="form-control"
                            value={state.openBal}
                            name={"openBal"}
                            onChange={handleChange}
                        />
                        <span>(Enter -ve value for credit/pending amount)</span>
                    </div>
                </div>
            </div>
            <div className="col-md-6">
                {state.tagLink.EntityID > 0 &&
                    <>
                        <label>Reporting Group</label>
                        <Tags tagLink={state.tagLink} tagType={0} getTagLink={newTagLinked} />
                    </>
                }
            </div>
        </div>



        <h5 className="az-content-label mt-5">Bank Details</h5>
        <hr />

        <div className="row">
            <div className="col-md-2 text-center">
                <i className="fas fa-piggy-bank fa-3x pt-2  text-danger mb-2"></i>
            </div>
            <div className="col-md-10">
                <div className="d-flex flex-md-row flex-column justify-content-between">
                    <div className="pr-3 pl-2 flex-md-fill">
                        <label className="form-label">Bank Name</label>
                        <input type="text" className="form-control"
                            value={supplier.CompanyBankName}
                            name={"CompanyBankName"}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="pr-3 pl-2 flex-md-fill">
                        <label className="form-label">Branch Name/Location</label>
                        <input type="text" className="form-control"
                            value={supplier.BranchName}
                            name={"BranchName"}
                            onChange={handleChange}
                        />
                    </div>

                </div>

                <div className="mt-3 d-flex flex-md-row flex-column justify-content-between">
                    <div className="pr-3 pl-2 flex-md-fill">
                        <label className="form-label">Account Number</label>
                        <input type="text" className="form-control"
                            value={supplier.CompanyAccountNumber}
                            name={"CompanyAccountNumber"}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="pr-3 pl-2 flex-md-fill">
                        <label className="form-label">IFSC Code</label>
                        <input type="text" className="form-control"
                            value={supplier.BranchIFSCCode}
                            name={"BranchIFSCCode"}
                            onChange={handleChange}
                        />
                    </div>

                </div>

            </div>
        </div>


    </>;
}

export default SupplierDetails;