import React from 'react';
import FileAttached from "./FileAttached";
import {FileAttachment, ML_ProcessTracker, ReviewComment} from "../globals/dto";
import * as dateUtils from './../globals/AppDateUtils';

type attributes = {
    reviewsList : ML_ProcessTracker[]
}

const ReviewHistory = (props: attributes) => {

    return (<>
        {props.reviewsList?.map((r:ML_ProcessTracker,i:number)=>{
            let comment : ReviewComment[] = JSON.parse(r.MoreTrackingJson);
            return (
            <div className="mt-3">
                <div className="media">
                    <div className="media-icon mr-3">
                        <div className="rounded-circle py-2 px-3 bg-gray-100 tx-black">R</div>
                    </div>
                    <div className="media-body rounded-10 bg-gray-100 p-3">
                        <div><span className="font-small tx-gray-900">Reviewer</span></div>
                        <div className="py-2">{comment[0].Details}</div>
                        <div><span className="font-small tx-gray-600">{dateUtils.FormatDateTime(comment[0].ReviewedDateTime)}</span> </div>

                            {comment[0].Attachments?.map((f:FileAttachment,fi:number)=>{
                                return <div className="mr-2 mt-2 d-inline-block"> <FileAttached key={"FLK"+fi} fileInfo={f} onDelete={()=>{}} isReadOnly={true}/></div>
                            })}

                    </div>
                </div>
            </div>
            )
        })}
    </>);
}

export default ReviewHistory;