import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { ML_Company, ML_Product, ML_ProductTransits, ML_UnitMeasure } from "../../globals/dto";
import * as rpt from './../../db/ReportsData';
import * as AppAct from '../../globals/AppActions';
import * as dateUtils from './../../globals/AppDateUtils';
import { ArrowForwardCircle, BarcodeOutline, CheckmarkDoneCircle, CheckmarkDoneCircleOutline, CheckmarkDoneOutline, CodeSlashOutline, CopyOutline } from "react-ionicons";

class SplitStockResult {
    FromProduct: ML_Product;
    FromUnit: ML_UnitMeasure;
    FromCompany: ML_Company;
    ToProduct: ML_Product;
    ToUnit: ML_UnitMeasure;
    Transit: ML_ProductTransits
}

const ProductSplitReport = (props: any) => {

    const AppDisp = useDispatch();
    const [prodSplits, setProdSplits] = useState<SplitStockResult[]>([]);


    useEffect(() => {
        GetAllSplitStocks();
    }, []);

    const GetAllSplitStocks = () => {
        rpt.GetAllSplitStocks().then(res => {
            if (res.data)
                setProdSplits(res.data);
        })
    }

    return (<>

        <div className="az-content-label mg-b-5 tx-22-f d-flex align-items-center">
            <BarcodeOutline width={'28px'} height={'28px'}/> &nbsp; Product Splits
        </div>

        <div className="table-responsive">
            <table className="table table-bordered table-striped">
                <thead className="border-top">
                    <tr>
                        <th>Date/Time</th>
                        <th>From Store</th>
                        <th>From Qty</th>
                        <th>From Product</th>
                        <th>From Unit</th>
                        <th></th>
                        <th>To Qty</th>
                        <th>To Product</th>
                        <th>To Unit</th>
                    </tr>
                </thead>
                <tbody>
                    {prodSplits.map((row: SplitStockResult, idx: number) => {
                        return (
                            <tr key={'GSS' + idx}>
                                <td>{dateUtils.FormatDateTime(row.Transit.CreateDate)}</td>
                                <td>{row.FromCompany.CompanyName}</td>
                                <td>{row.Transit.FromQty}</td>
                                <td>{row.FromProduct.ProductName}</td>
                                <td>1 {row.FromUnit.BulkMeasureName} = {row.FromUnit.RetailQty} {row.FromUnit.RetailMesaureName} </td>
                                <td className="text-center"><ArrowForwardCircle /></td>
                                <td>{row.Transit.ToQty}</td>
                                <td>{row.ToProduct.ProductName}</td>
                                <td>1 {row.ToUnit.BulkMeasureName} = {row.ToUnit.RetailQty} {row.ToUnit.RetailMesaureName} </td>
                            </tr>);
                    })}
                </tbody>
            </table>
        </div>


    </>)
}

export default ProductSplitReport;
