import React from "react";
import * as consts from '../GlobalConsts';


type attribs = {
    showRequest(type: string, id: number):void,
    id: number
}

export const InwardBadgeUI = (props: attribs) => {
    return (
        <span onClick={() => props.showRequest(consts.PopTagOwner.InwardBill, props.id)}
            className={"badge badge-pill mr-2 mb-1 pointer-cursor bg-primary"}>
            Inward Bill
        </span>
    );
}