export class BrokerPaymentVM {

    public Broker: ML_Broker = null;
    public TransactionEntry: ML_Transaction = null;
    public BrokerPay: ML_BrokerPayment = null;
    public Inward: ML_Inward = null;
}


export class CreditPayAgentView {

    public Supplier: ML_Supplier = null;
    public TotalSelected: number = 0;
    public PayMode: string = '';
    public PayNote: string = '';
    public CreditItems: PaymentAgentLineItemView[] = [];
}


export class CustomerView {

    public CustomerDetails: ML_Customer = null;
    public OpeningBalance: number = 0;
}


export class DayBookItemView {

    public Group: string = '';
    public Company: string = '';
    public BillRef: string = '';
    public Ledger: ML_Ledger = null;
    public Tran: ML_Transaction = null;
    public Category: ML_Tag = null;
}


export class DebitPayAgentView {

    public Customer: ML_Customer = null;
    public TotalSelected: number = 0;
    public PayMode: string = '';
    public PayNote: string = '';
    public CreditItems: PaymentAgentLineItemView[] = [];
}


export class DebitTransactionVM {

    public Customer: ML_Customer = null;
    public TransactionEntry: ML_Transaction = null;
    public SaleBillPay: ML_SaleBillPayment = null;
    public SaleBillExpense: ML_SaleBillExpense = null;
}


export class GlobalConstantsVM {

    public EXPENSE_PURCHASE: number = 0;
    public EXPENSE_SALES: number = 0;
    public EXPENSE_PRODUCTION: number = 0;
    public EXPENSE_BROKERAGE: number = 0;
    public BILLTYPE_SALE: string = '';
    public BILLTYPE_SALEORDER: string = '';
    public BILLTYPE_CASH: string = '';
    public BILLTYPE_CANCEL: string = '';
    public PAYMODE_CHQ: string = '';
    public PAYMODE_CASH: string = '';
    public PAYMODE_DD: string = '';
    public PAYMODE_NET: string = '';
}


export class InwardLineItemView {

    public LineItem: ML_InwardLineItem = null;
    public LineProduct: ProductView = null;
    public ProcessingProduct: ProductView = null;
    public SupplierDetails: ML_Supplier = null;
    public Batch: ML_ProductBatch = null;
    public TaxDetails: TaxAppliedView = null;
}


export class InwardPaymentsVM {

    public Payments: InwardPaymentView[] = [];
    public TotalPaid: number = 0;
    public SupplierIDReuqestFor: number = 0;
    public Supplier: ML_Supplier = null;
}


export class InwardPaymentView {

    public InwardPayment: ML_InwardPayment = null;
    public Supplier: ML_Supplier = null;
    public Inward: ML_Inward = null;
}


export class InwardTransactionVM {

    public Supplier: ML_Supplier = null;
    public TransactionEntry: ML_Transaction = null;
    public InwardPay: ML_InwardPayment = null;
    public InwardExpense: ML_InwardExpense = null;
}


export class InwardView {

    public Company: ML_Company = null;
    public InwardEntry: ML_Inward = null;
    public LineItems: InwardLineItemView[] = [];
    public SupplierDetails: ML_Supplier = null;
    public BrokerPayVM: BrokerPaymentVM = null;
    public AdditionalExpense: InvoiceExpenseView[] = [];
    public AdditionalExpTotal: number = 0;
    public CashPayTrans : ML_Transaction = null;
    // public AmountPaid: number = 0;
    // public CashCounterLedgerID: number = 0;
    // public DefaultLedgerEntryCategory : number = 0;
}


export class InwardVM {

}


export class LedgerTransView {

    public Ledger: ML_Ledger = null;
    public AllTrans: ML_Transaction[] = [];
    public InwardTotal: number = 0;
    public OutwardTotal: number = 0;
    public GrandTotal: number = 0;
}


export class MachineView {
    public PlantDetails: ML_Plant = null;
    public MachineDetails: ML_Machine = null;
    public ProcessorViewsList: ProcessorView[] = [];
    public ProcessFlow: ML_ProcessFlow = null;
    public CurrentProcessingQty: number = 0;
    public OccupayInPercentage: number = 0;
}


export class ML_AppSetting {

    public STID: number = 0;
    public UserID: number = 0;
    public SettingKey: string = '';
    public SettingValue: string = '';
}


export class ML_Broker {

    public BRKID: number = 0;
    public BrokerName: string = '';
    public ContactNumber: string = '';
    public EmailAddress: string = '';
    public AddressLine1: string = '';
    public AddressLine2: string = '';
    public GeoCity: string = '';
    public GeoState: string = '';
    public GeoCityPIN: string = '';
    public CommissionAmount: number = 0;
    public CommissionUnitID: number = 0;
}


export class ML_BrokerPayment {

    public BRTID: number = 0;
    public BrokerID: number = 0;
    public InvoiceID: number = 0;
    public LineItemID: number = 0;
    public TransactionID: number = 0;
    public TotalQty: number = 0;
    public IsPaid: boolean = false;
    public DatePaid: Date = null;
    public CurrentBalance: number = 0;
    public DateCreated: Date = new Date();
    public IsInwardEntry: boolean = false;
    public IsSaleBillEntry: boolean = false;
    public CommissionMode: number = 0;

}


export class ML_Customer {

    public CID: number = 0;
    public CompanyName: string = '';
    public GSTN: string = '';
    public PAN: string = '';
    public AddressOne: string = '';
    public AddressTwo: string = '';
    public GeoState: string = '';
    public GeoCity: string = '';
    public GeoBusinessCode: string = '';
    public VATNo: string = '';
    public CSTNo: string = '';
    public CompanyPAN: string = '';
    public PrimaryContactPersonName: string = '';
    public PrimaryPhones: string = '';
    public PrimaryEmailAddress: string = '';
    public DateCreated: Date;
    public LastUpdated: Date;
    public CustomerNotes: string = '';
    public KTNumber: string = '';
    public IsAdminOnly: boolean = false;
    public AliasVersions: string = '';
}


export class ML_Inward {

    public INWID: number = 0;
    public CompanyID: number = 0;
    public SupplieID: number = 0;
    public SupplierInoviceNumber: string = '';
    public SupplierDated: Date = new Date();
    public DeliveryNote: string = '';
    public BuyerOrderNumber: string = '';
    public InvoiceReceivedDate: Date = new Date();
    public BuyerOrderDated: Date = new Date();
    public DespatchedThrough: string = '';
    public Destination: string = '';
    public TotalBulkQty: number = 0;
    public TotalRetailQty: number = 0;
    public GrandTotal: number = 0;
    public PayableTotalAmount: number = 0;
    public RowCreateDate: Date = null;
    public RowUpdateDate: Date = null;
    public LastUpdateUserID: number = 0;
    public MiscItemsJson: string = '';
    public AmountUnTaxed: number = 0;
    public AmountTax: number = 0;
    public AmountTaxDetailsJson: string = '';
    public IsWhite: boolean = false;
    public IsExportable: boolean = false;
    public IsCanceled: boolean = false;
    public DiscountPercentage: number = 0;
    public DiscountAmount: number = 0;
    public IsPaid: boolean = false;
}


export class ML_InwardExpense {

    public INXID: number = 0;
    public InwardID: number = 0;
    public LineItemID: number = 0;
    public TransactionID: number = 0;
    public SubtractInInvoice: number = 0;
}


export class ML_InwardLineItem {

    public INLID: number = 0;
    public InwardID: number = 0;
    public SupplierID: number = 0;
    public LineItemOrderNumber: number = 0;
    public ProductID: number = 0;
    public BulkQty: number = 0;
    public RetailQty: number = 0;
    public Rate: number = 0;
    public LineTotal: number = 0;
    public HSNSACNumber: string = '';
    public ProcessedQty: number = 0;
    public InternalQty: number = 0;
    public InternalQtyMeasureID: number = 0;
    public InProcessQty: number = 0;
    public AmountTaxed: number = 0;
    public AmountUntaxed: number = 0;
    public DiscountAmount: number = 0;
    public DiscountPercentage: number = 0;
    public TaxPercentage: number = 0;
    public AmountTaxDetailsJson: string = '';
    public InternalBatchID: number = 0;
    public OnFlyItem: string = '';
    public OnFlyItemPrice: number = 0;
    public OnFlyItemQty: number = 0;
    public CreatedDate: Date = null;
    public IsCanceled: boolean = false;
}


export class ML_InwardPayment {

    public IWPID: number = 0;
    public SupplierID: number = 0;
    public InwardID: number = 0;
    public LineItemID: number = 0;
    public TransactionID: number = 0;
    public Balance: number = 0;
    public DateUpdated: Date;
    public IsInwardEntry: boolean = false;
}


export class ML_InwardToStockMoveLog {

    public IBLID: number = 0;
    public InwardID: number = 0;
    public InwardLineID: number = 0;
    public StockID: number = 0;
    public BatchID: number = 0;
    public QtyMoved: number = 0;
    public DateCreated: string = '';
}


export class ML_Ledger {

    public LDGID: number = 0;
    public ParentLedgerID: number = 0;
    public LedgerName: string = '';
    public IsBankLedger: boolean = false;
    public LedgerBio: string = '';
    public IsActive: boolean = false;
    public IsConsiderInPNL: boolean = false;
}


export class ML_Machine {

    public MCID: number = 0;
    public MachineName: string = '';
    public IntakeCapacity: number = 0;
    public ExpectedProcessingHours: number = 0;
}


export class ML_Plant {

    public PLTID: number = 0;
    public PlantName: string = '';
    public PlantAddress: string = '';
}


export class ML_PlantProduct {

    public PPID: number = 0;
    public PlantID: number = 0;
    public InternalProductID: number = 0;
}


export class ML_ProcessFlow {

    public PFLID: number = 0;
    public WorkflowID: number = 0;
    public WorkPlantMachineID: number = 0;
    public ExecutionOrder: number = 0;
}


export class ML_Processor {

    public PID: number = 0;
    public ProcessFlowID: number = 0;
    public InwardID: number = 0;
    public InwardLineItemID: number = 0;
    public ProcessedProductID: number = 0;
    public BatchID: number = 0;
    public ProcessingInItemsCount: number = 0;
    public ProcessFinishedCount: number = 0;
    public ItemInTime: Date = null;
    public ItemFinishedTime: Date = null;
}


export class ML_Product {

    public PRDID: number = 0;
    public ProductName: string = '';
    public ProductCategoryID: number = 0;
    public IsInternalProduct: boolean = false;
    public UnitMeasureID: number = 0;
    public IsActive: boolean = false;
    public ProductDescription: string = '';
    public InternalCode: string = '';
    public BarCode: string = '';
    public CanBeSold: boolean = false;
    public CanBePurchased: boolean = false;
    public ProductImageIDs: string = '';
    public InternalNotes: string = '';
    public LogisticWeightKGs: number = 0;
    public LogisticSpaceCubicMeters: number = 0;
    public ApproxManufacturingTimeInDays: number = 0;
    public HasExtraAttributes: boolean = false;
    public CompanyID: number = 0;
    public CreatorUserID: number = 0;
    public CreateDateTime: Date = null;
    public UpdaterUserID: number = 0;
    public UpdateDateTime: Date = null;
    public HSNSACNumber: string = '';
}


export class ML_ProductBatch {

    public BATID: number = 0;
    public BatchName: string = '';
    public CreateDate: string = '';
    public IsActive: boolean = false;
}


export class AliasMemberView {

    public FirmName: string = '';
    public FullAddress: string = '';
}

export class ML_SaleBill {

    public SBID: number = 0;
    public BillNumber: string = '';
    public CustomerID: number = 0;
    public PhantomCustomer: string = '';
    public CompanyID: number = 0;
    public PaymentStatus: string = '';
    public MiscItemsJson: string = '';
    public AmountUntaxed: number = 0;
    public AmountTax: number = 0;
    public AmountTotal: number = 0;
    public PayableTotalAmount: number = 0;
    public CreateDate: Date;
    public BillingDate: Date;
    public DispatchVehicleNumber: string = '';
    public VehicleInfo: string = '';
    public BillType: string = '';
    public AmountTaxDetailsJson: string = '';
    public TotalBulkQty: number = 0;
    public IsWhite: boolean = false;
    public IsExportable: boolean = false;
    public DiscountAmount: number = 0;
    public DiscountPercentage: number = 0;
    public IsPaid: boolean = false;
}


export class ML_SaleBillExpense {

    public SBXID: number = 0;
    public SaleBillID: number = 0;
    public LineItemID: number = 0;
    public TransactionID: number = 0;
    public SubtractInInvoice: number = 0;
}


export class ML_ReportUserSettings {
    public RPTFID: number = 0;
    public SettingName: string = '';
    public ReportType: string = '';
    public SettingJSON: string = '';
    public IsActive: boolean = false;
}


export class ML_SaleBillLine {

    public SLITID: number = 0;
    public SaleBillID: number = 0;
    public LineItemOrderNumber: number = 0;
    public IsShipped: boolean = false;
    public IsPaid: boolean = false;
    public IsCanceled: boolean = false;
    public ProductID: number = 0;
    public UnitID: number = 0;
    public BulkQty: number = 0;
    public RetailQty: number = 0;
    public PricePerBulkUnit: number = 0;
    public AmountUntaxed: number = 0;
    public AmountTaxed: number = 0;
    public SubTotal: number = 0;
    public DiscountedAmount: number = 0;
    public DiscountPercentage: number = 0;
    public CreateDate: Date;
    public OnFlyItem: string = '';
    public OnFlyItemPrice: number = 0;
    public OnFlyItemQty: number = 0;
    public AmountTaxDetailsJson: string = '';
    public IsCalcDoneOnBulkQty: number = 0;
}


export class ML_SaleBillLineStockBatch {
    public STMVID: number = 0;
    public StockID: number = 0;
    public SaleBillID: number = 0;
    public SaleLineID: number = 0;
    public ProductID: number = 0;
    public BatchID: number = 0;
    public CompanyID: number = 0;
    public SoldQty: number = 0;
    public IsShippingCompleted: boolean = false;
    public StatusNote: string = '';
    public CreateDate: Date;
    public UpdateDate: Date;
}


export class ML_SaleBillPayment {

    public SBPID: number = 0;
    public CustomerID: number = 0;
    public SaleBillID: number = 0;
    public LineItemID: number = 0;
    public TransactionID: number = 0;
    public Balance: number = 0;
    public DateUpdated: Date;
    public IsSaleBillEntry: boolean = false;
}


export class ML_Stock {

    public STKID: number = 0;
    public ProductID: number = 0;
    public BatchID: number = 0;
    public ProductCreateDate: string = '';
    public SaleableQty: number = 0;
    public IsExhausted: boolean = false;
    public RowLastUpdate: string = '';
}


export class ML_Supplier {

    public SPID: number = 0;
    public CompanyName: string = '';
    public GSTN: string = '';
    public PAN: string = '';
    public AddressOne: string = '';
    public AddressTwo: string = '';
    public GeoState: string = '';
    public GeoCity: string = '';
    public GeoBusinessCode: string = '';
    public CSTNo: string = '';
    public CompanyPAN: string = '';
    public CompanyBankName: string = '';
    public CompanyAccountNumber: string = '';
    public BranchIFSCCode: string = '';
    public BranchName: string = '';
    public PrimaryContactPersonName: string = '';
    public PrimaryPhones: string = '';
    public PrimaryEmailAddress: string = '';
    public DateCreated: Date = new Date();
    public LastUpdated: Date = null;
    public SupplierType: string = '';
    public IsAdminOnly: boolean = false;
}


export class ML_Tax {

    public TXID: number = 0;
    public TaxGroupName: string = '';
    public SlabSettingsJson: string = '';
    public TotalTaxValue: number = 0;
}


export class ML_Transaction {

    public EXPID: number = 0;
    public CategoryID: number = 0;
    public RefData: string = '';
    public Details: string = '';
    public TotalAmount: number = 0;
    public LedgerBalance: number = 0;
    public IsExpense: boolean = false;
    public LedgerID: number = 0;
    public FromLedgerID: number = 0;
    public TransDate: Date = new Date();
    public LastUpdateDate: Date = null;
    public PayMode: string = '';
    public IsPaymentRealized: boolean = false;
    public TrackDetails: string = ''
}


export class ML_UnitMeasure {

    public UCID: number = 0;
    public BulkMeasureName: string = '';
    public RetailMesaureName: string = '';
    public RetailQty: number = 0;
    public IsActive: number = 0;
    public RoundingPrecision: number = 0;
    public CompanyID: number = 0;
    public CreatorUserID: number = 0;
    public CreateDateTime: Date = null;
    public UpdaterUserID: number = 0;
    public UpdateDateTime: Date = null;
}


export class ML_Workflow {

    public WFID: number = 0;
    public WorkflowName: string = '';
}


export class ML_WorkPlantMachine {

    public WPMID: number = 0;
    public WorkflowID: number = 0;
    public PlantID: number = 0;
    public MachineID: number = 0;
}


export class PaymentAgentLineItemView {

    public MainBillDBKeyID: number = 0;
    public MainBillNum: string = '';
    public LineItemDbKeyID: number = 0;
    public LineItemDesc: string = '';
    public Amount: number = 0;
    public IsSelected: boolean = false;
}


export class PlantView {

    public PlantDetails: ML_Plant = null;
    public MachineViewsList: MachineView[] = [];
    public RelationLink: ML_WorkPlantMachine = null;
    public ProcessFlow: ML_ProcessFlow = null;
}


export class ProcessorView {

    public InwardDetails: InwardView = null;
    public ProcessEntry: ML_Processor = null;
    public SellingProductDetails: ProductView = null;
    public Batch: ML_ProductBatch = null;
    public ProcessedQtyInput: number = 0;
    public MoveOutQtyInput: number = 0;
    public ToNextFlowID: number = 0;
}


export class ProductView {

    public Product: ML_Product = null;
    public Category: ML_Tag = null;
    public UnitMeasure: ML_UnitMeasure = null;
    public OnHand: number = 0;
}


export class ProductVM {

    public ProductsList: ProductView[] = [];
    public UnitsList: ML_UnitMeasure[] = [];
    public CategoryList: ML_Tag[] = [];
    public ProductObj: ProductView = null;
}


export class QL_ErrorLog {

    public ERID: number = 0;
    public ErrMessage: string = '';
    public ErrDetails: string = '';
    public ErrStamp: string = '';
}


export class QL_User {

    public AID: number = 0;
    public UName: string = '';
    public PWord: string = '';
    public CreationStamp: string = '';
    public Active: number = 0;
    public UserType: number = 0;
    public LastLoginStamp: string = '';
    public ShowName: string = '';
}


export class SaleBillLineItemView {

    public LineItem: ML_SaleBillLine = null;
    public LineProduct: ProductView = null;
    public CustomerDetails: ML_Customer = null;
    public AllotedStockBatchItems: SaleLineProductBatchView[] = [];
    public TaxDetails: TaxAppliedView = null;
}


export class SaleBillView {
    public Company: ML_Company = null;
    public SaleBill: ML_SaleBill = null;
    public LineItems: SaleBillLineItemView[] = [];
    public CustomerDetails: ML_Customer = null;
    public BrokerPayVM: BrokerPaymentVM = null;
    public AdditionalExpense: InvoiceExpenseView[] = [];
    public CashPayTrans :ML_Transaction = null;    
    public AdditionalExpTotal: number = 0;
}


export class SaleLineProductBatchView {

    public StockEntry: ML_SaleBillLineStockBatch = null;
    public Stock: ML_Stock = null;
    public Batch: ML_ProductBatch = null;
    public OnHandQty: number = 0;
}


export class StockLineView {

    public Stock: ML_Stock = null;
    public Product: ProductView = null;
    public ProductBatch: ML_ProductBatch = null;
    public Supplier: ML_Supplier = null;
    public TotalStockOnMove: number = 0;
    public TotalStockSold: number = 0;
}


export class TaxAppliedDTO {

    public TaxName: string = '';
    public TaxValue: number = 0;
    public TotalTaxedAmount: number = 0;
}


export class TaxAppliedView {

    public TaxSetting: ML_Tax = null;
    public SlabsApplied: TaxAppliedDTO[] = [];
}


export class TaxSlabDTO {

    public TaxName: string = '';
    public TaxValue: number = 0;
}


export class TaxView {

    public TaxSetting: ML_Tax = null;
    public Slabs: TaxSlabDTO[] = [];
}


export class TransitVM {

    public SourceMachine: MachineView = null;
    public DestinationPlant: PlantView = null;
}


export class UserVM {

    public UserDetails: QL_User = new QL_User();
}


export class ML_Company {

    public CMPID: number = 0;
    public CompanyName: string = '';
    public AliasName: string = '';
    public CompanyAddress: string = '';
    public GeoCountry: string = '';
    public GeoState: string = '';
    public GeoPinCode: string = '';
    public PhoneNumber: string = '';
    public MobileNumber: string = '';
    public FaxNumber: string = '';
    public EmailAdddress: string = '';
    public WebSiteAddress: string = '';
    public SettingsJSON: string = '';
}

export class CompanyView {

    public Company: ML_Company = null;
    public CompanySettings: CompanySettingsVM = null;
}

export class CompanySettingsVM {
    public GSTN: string = '';
    public PAN: string = '';
    public SaleBillPrefix: string = '';
    public SaleBillNumberSize: number = 0;
    public DefaultCashCounterLedgerID: number = 0;
    public DefaultLedgerEntryCategory : number = 0;
}


export class ML_ProductPrice {

    public PSID: number = 0;
    public ProductID: number = 0;
    public CompanyID: number = 0;
    public SetByUserID: number = 0;
    public SalePricePerUnit: number = 0;
    public LastUpdateDateTime: string = '';
}

export class GlobalSettingsVM {

    public FinancialYearStartDate: Date = new Date(0);
    public SaleBillPrefix: string = null;
    public InwardBillPrefix: string = null;
    public EnableProductBatches: boolean = false;
    public EnableManualBatchNaming: boolean = false;
    public EnableProductionTracking: boolean = false;
    public GlobalWorkflowId: number = 0;
    public CurrencyRegion: string = null;
    public CurrencyName: string = null;
}


export class InvoiceExpenseView {

    public Transaction: ML_Transaction = null;
    public IsDeductInInvoice: number = null;
    public InoviceID: number = 0;
    public KeyID: number = 0;
}


export class ML_Tag {

    public GRPID: number = 0;
    public TagName: string = '';
    public TagType: number = 0;
}


export class ML_TagLink {

    public TGID: number = 0;
    public TagID: number = 0;
    public EntityID: number = 0;
    public EntityType: number = 0;
    public TagType: number = 0;
}

export class TransactionLineView {

    public TranID: number = 0;
    public InvoiceID: number = 0;
    public PartyID: number = 0;
    public TranDate: string = '';
    public Details: string = '';
    public PayMode: string = '';
    public CreditAmount: number = 0;
    public DebitAmount: number = 0;
    public BalanceAmount: number = 0;
    public MoreInfoRefID: number = 0;
    public MoreInfoTypeFlag: string = '';
    public AdditionDetails: string = '';
}

export class WageConfigView {

    public WageConfig: ML_LabourWageSetting = null;
    public Machine: MachineView = null;
    public Product: ML_Product = null;
    public UnitMeasure: ML_UnitMeasure = null;
}


export class ML_LabourWageSetting {

    public PSID: number = 0;
    public MachineID: number = 0;
    public UnitMeasureID: number = 0;
    public WagePerUnit: number = 0;
    public ProductID: number = 0;
}


export class ML_ReportDataset {

    public RPTDID: number = 0;
    public DatasetName: string = null;
    public DatasetQuery: string = null;
}


export class ML_Report {

    public RPTID: number = 0;
    public ReportDatasetID: number = 0;
    public ReportName: string = null;
    public ReportConfigDetails: string = null;
}


export class ReportFilter {
    public uuid: string = null;
    public FilterName: string = null;
    public ColumnName: string = null;
    public ColumnDataType: string = null;
    public Operator: string = null;
    public FilterValue: string = null;
    public isEnabled: boolean = false;
}


export class ReportSettings {

    public Columns: ReportTableColumn[] = [];
    public CountColumns: ReportTableColumn[] = [];
    public SumColumns: ReportTableColumn[] = [];
    public Filters: ReportFilter[] = [];
    public GroupBy: ReportTableColumn = null;
}


export class ReportTableColumn {

    public ColumnActualName: string = null;
    public ColumnUserName: string = null;
    public ColumnDataType: string = null;
    public SortOrder: number = 0;
    public isEnabled: boolean = false;
}


export class ML_ProductTransits {
    public PTID: number;
    public FromStockID: number;
    public FromBatchID: number;
    public FromCompnayID: number;
    public FromQty: number;
    public FromProductID: number;
    public FromUnitID: number;
    public ToStockID: number;
    public ToBatchID: number;
    public ToCompnayID: number;
    public ToQty: number;
    public ToProductID: number;
    public ToUnitID: number;
    public TransitReason: string;
    public CreateDate: Date = new Date();
}

export class CallActionResult {
    public StatusCode: number;
    public Message: string;
    public Data: any;

}


export class FileAttachment {

    public FileName: string = '';
    public UniqueFileName: string = '';
    public DateUploaded: Date = new Date(0);
}


export class ReviewComment {

    public Details: string = '';
    public ReviewedDateTime: Date = new Date(0);
    public Attachments: FileAttachment[] = [];
    public ReviewerUserID: number = 0;
}

export class ML_ProcessTracker {

    public PLID: number = 0;
    public ExecuteOrderNumber: number = 0;
    public InwardID: number = 0;
    public InwardLineItemID: number = 0;
    public WorkPlantMachineID: number = 0;
    public BatchID: number = 0;
    public ProcessedProductID: number = 0;
    public ProcessedQty: number = 0;
    public LogDate: Date = new Date(0);
    public LogDetails: string = '';
    public ProcessStatus: string = '';
    public MoreTrackingJson: string = '';
    public EntryCreateDateTime: Date = new Date(0);
}

export class TrackDetails {
    public SourceLedgerID: number = 0;
    public DestinationLedgerID: number = 0;
    public SaleBillID: number = 0;
    public InwardID: number = 0;
    public CustomerID: number = 0;
    public SupplierID: number = 0;
    public BrokerID: number = 0;
    public StoreID: number = 0;
    public UserID: number = 0;
    public BatchID: number = 0;
    public StockID: number = 0;
    public WorkflowID: number = 0;
}
