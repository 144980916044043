import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import * as AppAct from '../globals/AppActions';
import { mapStateToProps } from "../globals/AppActions";
import * as pdb from '../db/ProductsData';
import { ML_Product, ProductView } from '../globals/dto';
import { Modal, ModalBody } from "react-bootstrap";
import ModalHeader from "react-bootstrap/ModalHeader";
import * as utils from '../globals/Utils';
import { ProductDetailsEx } from "./ProductDetailsEx";
import { AppProgressBar } from "../globals/AppProgressBar";
import { AddNewButtonUI } from '../globals/AppControls/AddNewButtonUI';
import { BadgeWhiteUI } from '../globals/AppControls/BadgeWhiteUI';

const ProductsList = (props: any) => {

    const dispatch = useDispatch();

    const [state, setState] = useState({
        products: new Array<ProductView>(),
        MasterList: new Array<ProductView>(),
        FilterString: '',
        productSelected: new ProductView(),
        hash: '',
        modalBar: false
    });

    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        dispatch(AppAct.ChangeMainTitle('Products Master'));
        getAllProducts();
    }, []);

    const getAllProducts = () => {
        dispatch(AppAct.ToggleProgress(true));
        pdb.GetAllProducts().then((res) => {
            console.log(res.data);
            setState({ ...state, products: [...res.data], MasterList: [...res.data] });
            dispatch(AppAct.ToggleProgress(false));
        });
    }

    const handleChange = (e: any) => {
        let search = e.target.value;
        filter(search);
    }

    const filter = (s: string) => {
        if (s !== '') {
            setState({
                ...state,
                FilterString: s,
                products: state.MasterList.filter(
                    (x: any) => x.Product.ProductName.toLowerCase().includes(s.toLowerCase())),
            });
        } else {
            setState({ ...state, products: state.MasterList, FilterString: s });
        }
    }

    const newProd = () => {
        let newProd = new ProductView();
        newProd.Product = new ML_Product();
        newProd.Product.IsActive = true;
        newProd.Product.CanBePurchased = true;
        newProd.Product.CanBeSold = true;
        setState({ ...state, productSelected: newProd, hash: utils.generateHash().toString() });
        modalSwitch(true);
    }
    const editProd = (prod: ProductView) => {
        console.log(prod);
        setState({ ...state, productSelected: prod, hash: utils.generateHash().toString() });
        modalSwitch(true);
    }
    const modalSwitch = (tog: boolean) => setShowModal(tog);
    const closeModal = () => modalSwitch(false);
    const toggleModalBar = (tog: boolean) => setState({ ...state, modalBar: tog });


    return (
        <>{showModal &&
            <Modal show={true} size="lg" dialogClassName="modal-corner"
                onHide={closeModal}
                backdrop={'static'}>
                {state.modalBar && <AppProgressBar />}
                {/*<ModalHeader className="bg-gray-200"><h3 className="pb-0 mb-0">Product Details</h3></ModalHeader>*/}
                <ModalBody>
                    <ProductDetailsEx
                        closePop={closeModal}
                        toggleModalProgBar={toggleModalBar}
                        refreshList={getAllProducts}
                        id={state.productSelected.Product.PRDID}
                    />
                </ModalBody>
            </Modal>
        }
            <div className="row mb-3">
                <div className="col-md-8">
                    {/* <input type="button" className="btn btn-az-primary" onClick={newProd}
                        value="+ New Product" /> */}
                    <AddNewButtonUI onClick={newProd} />
                </div>
                <div className="col-md-4">
                    <div className="az-header-center m-0">
                        <span style={{ "display": state.FilterString.length === 0 ? 'block' : 'none' }}
                            className="btn"><i className="fas fa-search"></i></span>
                        <input type="search" className="form-control" placeholder="Search for anything..."
                            value={state.FilterString}
                            autoFocus={true}
                            onChange={handleChange}
                        />

                    </div>
                </div>

            </div>

            <div className="table-responsive">
                <table className="table table-hover mg-b-0">
                    <thead className="border-top">
                        <tr>
                            <th>Product Name</th>
                            <th>Units: Bulk / Retail</th>
                            <th>Trade Type</th>
                            <th className="text-right text-black bg-light">Total : {state.products.length}</th>
                        </tr>
                    </thead>
                    <tbody>

                        {state.products.map((p: ProductView, idx) => {
                            return (
                                <tr key={idx}>
                                    <td>
                                        {/*<a className="mr-3 text-info pointer-cursor" title="Edit Product"><i
                                                    className="fas fa-pen"></i></a>*/}
                                        <span className="font-weight-bold mr-2">{p.Product.ProductName}</span>
                                        {p.Category && p.Category.GRPID > 0 &&
                                            <BadgeWhiteUI title={p.Category.TagName} />}
                                    </td>
                                    <td>
                                        1 {p.UnitMeasure.BulkMeasureName} / {p.UnitMeasure.RetailQty} {p.UnitMeasure.RetailMesaureName}
                                    </td>
                                    <td>
                                        {!p.Product.IsActive &&
                                            <span className="badge badge-warning font-small mr-2">Inactive</span>}
                                        {p.Product.CanBeSold &&
                                            <span className="badge badge-success font-small mr-2">Sell-able</span>}
                                        {p.Product.CanBePurchased &&
                                            <span
                                                className="badge badge-secondary font-small mr-2">Purchase-able</span>}
                                        {p.Product.IsInternalProduct &&
                                            <span className="badge badge-primary font-small mr-2">By-Product</span>}
                                    </td>
                                    <td className="text-right">

                                        {/*<a className="text-danger pointer-cursor" title="Delete Product"><i
                                                    className="fas fa-trash"></i></a>*/}
                                        <button className="btn-simple pointer-cursor" onClick={() => editProd(p)}><i className="typcn typcn-pen mr-2"></i>Edit</button>
                                        {/*<a className="link text-danger"><i className="typcn typcn-trash"></i>Delete</a>*/}
                                    </td>
                                </tr>);
                        })}
                    </tbody>
                </table>
            </div>
        </>
    );
}

export default ProductsList;
