import React from "react";

export const AppProgressBar = () => {
    return <div className="progress">
        <div
            className="progress-bar progress-bar-animated progress-bar-striped active bg-warning"
            role="progressbar" aria-valuenow={50} aria-valuemin={0} aria-valuemax={100}
            style={{width: "100%", height: 6}}>
        </div>
    </div>
}