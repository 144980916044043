import axios from 'axios';
import * as global from '../globals/ApiUrlConsts';

const web_api_base = global.global_api_url_base + '/api/settings';

export const GetSetting = async (userID, key)=> await axios.get(web_api_base + '/GetSetting',{params:{userID,key}})

export const GetGlobalSetting = async ()=> await axios.get(web_api_base + '/GetSetting',
    {params:{userID: 0,key:'GLOBAL_SETTING'}})

export const SaveSetting = async (userid, key, json)=>
    await axios.post(web_api_base + '/SaveSetting',null,{params: {userID: userid, key, json}})

export const SaveSettingObject = async (obj)=>
    await axios.post(web_api_base + '/SaveSettingObject',obj)
