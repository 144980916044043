import React from 'react';
import * as _ from 'lodash';
import { InwardView } from '../globals/dto';
import BrokerCommission, { BrokerUpdateEventArgs } from '../sales/BrokerCommission';

type attributes = {
    inwardView : InwardView,
    onInwardViewUpdate(iv:InwardView):void
}

const PurchaseBrokerage = (props:attributes)=> {

    const handleBrokerChange = (e: BrokerUpdateEventArgs) => {
        if (e.brokerSelected) {
            let copy = _.clone(props.inwardView);
            
            //Resets commission calculation entries in BrokCommission component.
            copy.BrokerPayVM.Broker = e.brokerSelected;
            
            if (e.brokerSelected.BRKID === 0) {
                copy.BrokerPayVM.TransactionEntry.TotalAmount = 0;
            } else {
                copy.BrokerPayVM.BrokerPay.CommissionMode = e.commMode;
                if(e.commMode === 2){
                    copy.BrokerPayVM.TransactionEntry.TotalAmount = e.lumpSumAmount;
                } else if (e.commMode === 1){
                    copy.BrokerPayVM.TransactionEntry.TotalAmount =
                    copy.BrokerPayVM.Broker.CommissionAmount * copy.InwardEntry.TotalBulkQty;
                }
            }
            props.onInwardViewUpdate(copy);
        }
    }

    return (<>
    
    <BrokerCommission
                broker={props.inwardView.BrokerPayVM.Broker}
                commissionMode={props.inwardView.BrokerPayVM.BrokerPay.CommissionMode}
                totalAmount={props.inwardView.BrokerPayVM.TransactionEntry.TotalAmount}
                totalQty={props.inwardView.InwardEntry.TotalBulkQty}
                onUpdate={handleBrokerChange}
            />
    </>);

}

export default PurchaseBrokerage;