import React, {useEffect, useState} from 'react';
import * as stdb from '../db/StocksData';
import Select, {OptionProps, components} from 'react-select';
import {ProductView} from "../globals/dto";
import {AxiosResponse} from "axios";

type attributes = {
    showWithStocks : boolean,
    showOnlyPurchasable : boolean,
    showOnlySubProducts : boolean,
    showOnlySaleable : boolean,
    product : ProductView,
    onChange(prdid:number):void      
}

//INPUT :
// showWitStocks
// showOnlyPurchasable
// showOnlySubProducts
// showOnlySaleable
// props.data : ProductView

//OUTPUT:
//onChange : e.value => PRDID
export const ProductsDropDown = (props: any) => {
    const [products, setProducts] = useState(new Array<ProductView>());
    const [isShowOnlySubProducts, setIsShowOnlySubProducts] = useState(false);
    const [isShowOnlyPurchasable,setIsShowOnlyPurchasable ] = useState(false);
    const [isShowWithStocks, setIsShowWithStocks] = useState(true);
    const [isShowOnlySaleable,setIsShowOnlySaleable ] = useState(true);

    useEffect(() => {

        if(props.showWithStocks !== null) setIsShowWithStocks(props.showWithStocks);
        if(props.showOnlyPurchasable !== null) setIsShowOnlyPurchasable(props.showOnlyPurchasable);
        if(props.showOnlySubProducts !== null) setIsShowOnlySubProducts(props.showOnlySubProducts);
        if(props.showOnlySaleable !== null) setIsShowOnlySaleable(props.showOnlySaleable);

        stdb.GetAllProductsWithStocks().then( (res:AxiosResponse<ProductView[]>) => {
            if(isShowOnlyPurchasable){
                setProducts(res.data.filter(x=>x.Product.CanBePurchased && x.Product.IsActive));
            } else if(isShowOnlySubProducts){
                setProducts(res.data.filter(x=>x.Product.IsInternalProduct && x.Product.IsActive));
            } else if (isShowOnlySaleable){
                setProducts(res.data.filter(x=>x.Product.CanBeSold && x.Product.IsActive));
            } else
                setProducts(res.data);
        })
    }, []);

    const optionTemplate = (props: any) => (
            <components.Option {...props}>
                {isShowWithStocks &&
                <div><b>{props.data.Product.ProductName}</b>
                    <span style={{float: "right"}}><span
                        className="font-small">In-Stock:</span> <b>{props.data.OnHand}</b>&nbsp;{props.data.UnitMeasure.BulkMeasureName}</span>
                </div>
                }
                { !isShowWithStocks &&
                    <div><b>{props.data.Product.ProductName}</b></div>
                }
            </components.Option>
    )

    const singleSelectTemplate = (props : any) => (
        <components.SingleValue {...props}>
            <b>{props.data.Product.ProductName}</b>
        </components.SingleValue>
    );

    const productFilter = (option : any , search : string)=>{
        return option.data.Product.ProductName.toLowerCase().includes(search.toLowerCase());
    }


    return (

            <Select classNamePrefix="srushti"
                value={props.product}
                options={products}
                components={{Option: optionTemplate, SingleValue: singleSelectTemplate}}
                getOptionValue={option => option.Product.PRDID.toString()}
                onChange={props.onChange}
                filterOption={productFilter}></Select>

    )
}