import React, { useEffect, useState } from 'react';
import { ML_Supplier, ProductView } from "../globals/dto";
import { connect, useDispatch } from "react-redux";
import * as AppAct from '../globals/AppActions';
import * as sdb from '../db/SupplierData';
import { mapStateToProps } from "../globals/AppActions";
import SupplierDetails from "./SupplierDetails";
import * as graphic from "../globals/colors";
import { AddNewButtonUI } from '../globals/AppControls/AddNewButtonUI';

const SuppliersList = (props: any) => {

    const dispatch = useDispatch();

    const [state, setState] = useState({
        suppliers: new Array<ML_Supplier>(),
        suppliersMaster: new Array<ML_Supplier>(),
        validity: {},
        selectedSupplier: new ML_Supplier(),
        filterString: '',
    });

    const [showSupplierDetails, setShowSupplierDetails] = useState(false);

    useEffect(() => {
        showSuppliersList();
    }, [])

    const filterSuppliers = (s: string) => {
        s = s.toLowerCase();
        if (s !== '') {
            setState({
                ...state, filterString: s,
                suppliers: state.suppliersMaster.filter((sf) =>
                    sf.CompanyName.toLowerCase().includes(s))
            });
        } else {
            return setState({ ...state, filterString: '', suppliers: state.suppliersMaster });
        }
    }

    const loadAllSuppliers = () => {
        dispatch(AppAct.ToggleProgress(true));
        sdb.GetAll().then((res) => {
            setState({ ...state, suppliers: res.data, suppliersMaster: res.data });
            dispatch(AppAct.ToggleProgress(false));
        });
    }

    const handleSearch = (e: any) => {
        filterSuppliers(e.target.value);
    }

    const edit = (selSup: ML_Supplier) => {
        setShowSupplierDetails(true);
        setState({ ...state, selectedSupplier: selSup });
    }


    const showSuppliersList = () => {
        setShowSupplierDetails(false);
        dispatch(AppAct.ChangeMainTitle('Suppliers'));
        loadAllSuppliers();
    }


    return (
        <>
            {showSupplierDetails === false &&
                <>
                    <div className="row mb-4">
                        <div className="col-md-8">
                            {/* <input type="button" className="btn btn-az-primary"
                                value="+ New Supplier"
                                onClick={() => edit(new ML_Supplier())}
                            /> */}
                            <AddNewButtonUI onClick={() => edit(new ML_Supplier())} />
                        </div>
                        <div className="col-md-4">
                            <div className="az-header-center m-0">
                                <span style={{ "display": state.filterString.length === 0 ? 'block' : 'none' }}
                                    className="btn"><i className="fas fa-search"></i></span>
                                <input type="search" className="form-control"
                                    placeholder="Search by supplier name..."
                                    value={state.filterString}
                                    autoFocus={true}
                                    onChange={handleSearch}
                                />

                            </div>
                        </div>
                    </div>


                    <div className="table-responsive">
                        <table className="table table-hover">
                            <thead className="border-top">
                                <tr>
                                    <th>Company name</th>
                                    <th>Owner Name</th>
                                    <th>Contact</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>

                                {state.suppliers.map((s: ML_Supplier, idx: number) => {
                                    return (
                                        <tr key={idx}>
                                            <td>
                                                <div style={{
                                                    display: "inline-block",
                                                    padding: "5px",
                                                    color: "black",
                                                    fontWeight: "bold",
                                                    width: 32,
                                                    textAlign: "center",
                                                    borderRadius: 3,
                                                    marginRight: "4px",
                                                    backgroundColor: graphic.colors[s.CompanyName.charAt(0).toLowerCase()]
                                                }}>
                                                    {s.CompanyName.charAt(0).toUpperCase()}
                                                </div>
                                                {/*<i className="fas fa-university fa-1x pt-2 text-indigo mr-1"></i>*/}
                                                <b>{s.CompanyName}</b>
                                            </td>
                                            <td>
                                                <i className="fas fa-user-alt fa-1x pt-2 text-dark mr-1"></i>
                                                {s.PrimaryContactPersonName}
                                            </td>
                                            <td>
                                                <i className="fas fa-phone fa-1x pt-2 text-info mr-1"></i>
                                                {s.PrimaryPhones}
                                            </td>
                                            <td className="text-right">
                                                <button className="btn-simple pointer-cursor"
                                                    onClick={() => edit(s)}><i
                                                        className="typcn typcn-pen mr-2"></i>Edit
                                                </button>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>

                </>}

            {showSupplierDetails && <SupplierDetails
                q={state.filterString}
                data={state.selectedSupplier}
                close={showSuppliersList}
            />

            }
        </>
    );

}

export default SuppliersList;
