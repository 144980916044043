import React, {useState, useEffect, Children} from "react"

import {ML_Supplier} from '../globals/dto';
import Select,{ OptionProps, components } from "react-select";
import * as sdb from '../db/SupplierData';

//INPUT : props.supplierSelected
//OUTPUT : props.supplierChanged(ML_Supplier)

export const SupplierSelect = (props:any)=>{
    
    const [supplierLoading, setSupplierLoading] = useState(false);
    const [suppliers, setSuppliers] = useState<ML_Supplier[]>();
    const [supplierSelected, setSupplierSelected] = useState<ML_Supplier>(new ML_Supplier());

    useEffect(()=>{
        setSupplierLoading(true);
        sdb.GetAll().then(res=>{setSuppliers(res.data);
        setSupplierLoading(false);
        });
    },[])

    useEffect(()=>{
        if(supplierSelected.SPID != props.supplierSelected.SPID)
            setSupplierSelected(props.supplierSelected);
    },[props.supplierSelected])

    const supplierSingleOption = (optProps: any) => (
        <components.Option {...optProps}>
            {optProps.data.SPID === -1 &&
                <div style={{"borderBottom":"1px dashed gray", "paddingBottom":"7px"}}><b className="text-primary">{optProps.data.CompanyName}</b></div>}
    
            {optProps.data.SPID > 0 &&
                <>
                <b>{optProps.data.CompanyName}</b>
                {optProps.data.PrimaryContactPersonName &&
                <span>&nbsp;|&nbsp;<i>{optProps.data.PrimaryContactPersonName}</i></span>
                }
                {optProps.data.PrimaryPhones &&
                <span>&nbsp;|&nbsp;Ph:{optProps.data.PrimaryPhones}</span>
                }
                </>
            }
        </components.Option>)

    const supplierSingleValue = (prop: any) => (
        <components.SingleValue {...prop}>
            <b>{prop.data.CompanyName}</b>
        </components.SingleValue>
    )

    const handleSupplierChange = (e: any) => {
        let sup = e;
        setSupplierSelected(sup);
        props.supplierChanged(sup);
    }

    const supplierCustomSelectFilter = (option: any, search: string) => {
        return option.data.CompanyName.toLowerCase().includes(search.toLowerCase());
    }

    return(
        <>

        <Select classNamePrefix="srushti"
                options={suppliers}
                isLoading={supplierLoading}
                value={supplierSelected}
                //defaultValue={supplierSelected}
                theme={theme => ({...theme, borderRadius: 0})}
                components={{
                    Option: supplierSingleOption,
                    SingleValue: supplierSingleValue
                }}
                getOptionValue={option => option.SPID.toString()}
                onChange={handleSupplierChange}
                filterOption={supplierCustomSelectFilter}
                ></Select>



            {/*<Select options={suppliers}*/}
            {/*    isLoading={supplierLoading}*/}
            {/*    value={supplierSelected}*/}
            {/*    defaultValue={suppliers[0]}*/}
            {/*    theme={theme => ({ ...theme, borderRadius: 0 })}*/}
            {/*    components={{*/}
            {/*        Option: helper.supplierSingleOption,*/}
            {/*        SingleValue: helper.supplierSingleValue*/}
            {/*    }}*/}
            {/*    getOptionValue={option => option.SPID.toString()}*/}
            {/*    onChange={handleSupplierChange}*/}
            {/*    filterOption={helper.supplierCustomSelectFilter}></Select>*/}
        </>
    )
}