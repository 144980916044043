import React, { useEffect, useState } from 'react';
import { Modal, ModalBody } from "react-bootstrap";
import { toast } from "react-toastify";
import { CloseButtonUI } from '../globals/AppControls/CloseButtonUI';
import { SaveButtonUI } from '../globals/AppControls/SaveButtonUI';

export const OtherLineItem = (props: any) => {

    const [oItem, setOItem] = useState<any>();

    useEffect(() => {
        setOItem({ price: +props.item.price, desc: props.item.desc });
    }, [props.show])

    const handleChange = (e: any) => {
        let name = e.target.name;
        let val = e.target.value;
        setOItem({ ...oItem, [name]: val });
    }

    const onSave = () => {
        if (oItem.desc.trim().length > 0)
            props.save(oItem);
        else
            toast.error('Enter all fields');
    }


    return <>
        <Modal show={props.show} dialogClassName="modal-corner">
            <div className="card">
                <div className="card-body">
                <h4 className="az-content-label mb-3">Other item</h4>
                    <div className="row mb-2">
                        <label className="col-md-4 form-label">Description</label>
                        <div className="col-md-8">
                            <input type="text" className="form-control"
                                value={oItem?.desc}
                                onChange={handleChange}
                                name="desc"
                                autoFocus={true}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <label className="col-md-4 form-label">Price</label>
                        <div className="col-md-4">
                            <input type="number" className="form-control"
                                value={oItem?.price}
                                onChange={handleChange}
                                name="price"
                            />
                        </div>
                    </div>
                </div>
                <div className="d-flex justify-content-between p-3 text-right">
                    {/* <button className="btn btn-success mr-3" onClick={onSave}>Save</button>
                    <button className="btn btn-primary" onClick={props.close}>Cancel</button> */}

                    <SaveButtonUI onClick={onSave} className="mr-3" />
                    <CloseButtonUI onClick={props.close} />
                </div>
            </div>

        </Modal>

    </>

}
