import React, {useState} from 'react';
import * as utils from '../globals/Utils';
import * as dateUtils from '../globals/AppDateUtils';
import {useDispatch} from "react-redux";
import {ProcessorView} from '../globals/dto';
import * as AppAct from '../globals/AppActions';
import {ProcessLineItemLogs} from "./ProcessLineItemLogs";

type attribute = {
    machine : any,
    show :boolean
}

export const MachineDetails = (props: attribute) => {
    const AppDisp = useDispatch();
    const [machineDetails] = useState(props.machine);

    //--process log
    const [showItemProcessLog, setShowItemProcessLog] = useState(false);
    const [logItemID, setLogItemID] = useState(0);
    const showItemLogModal=(liid:number)=> {
        setLogItemID(liid);
        setShowItemProcessLog(!showItemProcessLog);
    }
    //--process log

    const showTransitScreen = (pid: number, lineItemId : number) => {
        AppDisp(AppAct.ShowTransitPage(true, pid, lineItemId));
    }

    const showLogScreen = (mcid : number) => {
        AppDisp(AppAct.ShowActivityPage(true,mcid));
    }

    const showSubProdPage = (mcid : number)=> {
        AppDisp(AppAct.ShowSubProdPage(true,mcid));
    }

    if (!machineDetails) return <></>;

    return <>

        <ProcessLineItemLogs showModal={showItemProcessLog}
                             lineItemID = {logItemID}
                             workflowId={machineDetails.ProcessFlow.WorkflowID}
                             close={()=>showItemLogModal(0)}
        />

        {props.show &&
        <div className="table-responsive mt-2" key={utils.uuidv4()}>
            <table className="table table-hover table-bordered">
                <thead className="border-top">
                <tr className="border-bottom">
                    <th>Supplier</th>
                    <th>In Qty</th>
                    <th className="text-right">In Time</th>
                </tr>
                </thead>
                <tbody>
                {machineDetails.ProcessorViewsList.map((pr: ProcessorView, pdx:number) => {
                    let li = pr.InwardDetails.LineItems.filter(x => x.LineItem.INLID === pr.ProcessEntry.InwardLineItemID)[0];
                    // pr.InwardDetails?.LineItems.map((li: any, ldx: number) => {
                    return (
                        <tr key={pdx} className="border-bottom">
                            <td>
                                <h6 className="mg-b-0 tx-inverse">{pr.InwardDetails.SupplierDetails.CompanyName}</h6>
                                <small className="tx-13 tx-gray-600">Inward
                                    #{pr.InwardDetails.InwardEntry.SupplierInoviceNumber}</small>
                                {pr.Batch &&
                                <h5 className="mb-0">{pr.Batch.BatchName}</h5>}
                                <small className="tx-13 tx-gray-600">Batch</small>
                            </td>
                            <td className="pointer-cursor" onClick={()=>showItemLogModal(pr.ProcessEntry.InwardLineItemID)}>
                                <small className="tx-13 tx-gray-600">Processing / Verified</small>
                                <h5 className="mg-b-0 tx-inverse tx-20-f">{pr.ProcessEntry.ProcessingInItemsCount} / {li.LineItem.InternalQty}</h5>
                                <small
                                    className="tx-13 tx-gray-800">{li.ProcessingProduct.Product.ProductName} / {li.LineProduct.Product.ProductName}</small>
                            </td>
                            <td className="text-right">
                                {dateUtils.FormatDateTime(pr.ProcessEntry.ItemInTime)}<br/>
                                <button onClick={() => showTransitScreen(pr.ProcessEntry.PID, pr.ProcessEntry.InwardLineItemID)}
                                        className="btn-simple btn-outline-primary font-weight-bold mt-2">
                                    Manage
                                    </button>
                            </td>
                        </tr>
                    )
                })

                }
                </tbody>
                {/*<tfoot>*/}
                {/*<tr>*/}
                {/*    <td colSpan={3} className="pt-2">*/}
                {/*        <button className="btn btn-outline-info font-weight-bold mr-3" onClick={()=>showLogScreen(machineDetails.MachineDetails.MCID)}>Activity Tracker</button>*/}

                {/*        {machineDetails.ProcessorViewsList.length > 0 && machineDetails.ProcessorViewsList[0].InwardDetails &&*/}
                {/*        <>*/}
                {/*            <button className="btn btn-outline-primary font-weight-bold float-right">Manage All*/}
                {/*                <i className="icon ion-ios-arrow-round-forward tx-11 mg-l-3"></i></button>*/}
                {/*        </>*/}
                {/*        }*/}
                {/*    </td>*/}
                {/*</tr>*/}
                {/*</tfoot>*/}
            </table>
        </div>
        }
    </>
};
