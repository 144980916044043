import React, { useEffect, useState } from 'react';
import { ML_UnitMeasure } from "../globals/dto";
import * as pdb from '../db/ProductsData';
import * as global from "../globals/GlobalConsts";
import { toast } from 'react-toastify';
import { AxiosResponse } from "axios";
import { UnitsSelect } from "./UnitsSelect";
import { DateTime } from 'luxon';

//INPUT :
// props.unitID
// props: showCreateNewOption -> true/false -> default is true
//OUTPUT : props.GetSelectedUnit(ML_UnitMeasure)
export const UnitsManager = (props: any) => {
    //const [units, setUnits] = useState<ML_UnitMeasure[]>();
    const [selectedUnit, setSelectedUnit] = useState<ML_UnitMeasure>(new ML_UnitMeasure());


    useEffect(() => {
        setSelectedUnit({ ...selectedUnit, UCID: props.unitID });
        //     pdb.GetAllUnits().then((res:AxiosResponse<ML_UnitMeasure[]>)=>{
        //         setUnits(res.data);
        //
        //         if(props.unitID > 0){
        //             setSelectedUnit(res.data.find(x=>x.UCID === props.unitID));
        //         }
        //     })
    }, [props.unitID])

    const newUnitMeasure = () => {
        setSelectedUnit(new ML_UnitMeasure());
    }

    const handleUnitEntryChange = (e: any) => {
        let name = e.target.name;
        let val = e.target.value;

        setSelectedUnit({ ...selectedUnit, [name]: val });
    }

    // const handleSelectChange=(e:any)=>{
    //     let unitID = +e.target.value;
    //     if(unitID > 0) {
    //         let unt = units.find(s => s.UCID === unitID);
    //         setSelectedUnit(unt);
    //         props.GetSelectedUnit(unt);
    //     } else if (unitID === -1) {
    //         let unt = new ML_UnitMeasure();
    //         unt.UCID = -1;
    //         setSelectedUnit(unt);
    //     }
    // }

    const handleSelectChange = (e: ML_UnitMeasure) => {
        if (e.UCID > 0) {
            setSelectedUnit(e);
            props.GetSelectedUnit(e);
        } else if (e.UCID === -1) {
            let unt = new ML_UnitMeasure();
            unt.UCID = -1;
            setSelectedUnit(unt);
        }
    }

    const saveUnit = () => {
        if (!selectedUnit.BulkMeasureName ||
            !selectedUnit.RetailMesaureName ||
            selectedUnit.RetailQty <= 0) {
            toast.error("Not saved, missing mandatory data.");
        } else {
            let toSave = { ...selectedUnit, UCID: selectedUnit.UCID < 0 ? 0 : selectedUnit.UCID }; //-1 save fails API call
            console.log('Unit measure', toSave);
            // if(toSave.UCID === 0){
            //     toSave.CreateDateTime = DateTime.utc(2020,1,1,0,0,0,0).toISODate();
            //     toSave.UpdateDateTime = DateTime.utc(2020,1,1,0,0,0,0).toISODate();
            // }
            pdb.SaveUnit(toSave).then(res => {
                let newUnitSaved = { ...selectedUnit, UCID: res.data };
                setSelectedUnit(newUnitSaved);
                props.GetSelectedUnit(newUnitSaved);
                toast.success("New Unit of Measure created!");
            });
        }
    }


    return (
        <>
            <div className="row form-group">
                {selectedUnit.UCID !== -1 &&
                    <div className="col-md-6">
                        <UnitsSelect onChange={handleSelectChange}
                            UnitID={selectedUnit.UCID}
                            showCreateNewOption={props.showCreateNewOption} />
                        {/*<select className="form-control" value={selectedUnit.UCID} onChange={handleSelectChange}>*/}
                        {/*    <option value={0}>&nbsp;</option>*/}
                        {/*    <option value={-1}>-- Create New --</option>*/}
                        {/*    {units && units.map((u:ML_UnitMeasure, index :number)=>{*/}
                        {/*        return(*/}
                        {/*        <option value={u.UCID}>1 {u.BulkMeasureName} / {u.RetailQty} {u.RetailMesaureName}</option>*/}
                        {/*        )*/}
                        {/*    })}*/}
                        {/*</select>*/}
                    </div>
                }

                {selectedUnit.UCID === -1 &&
                    <div className="col-md-12">
                        <div className="row align-items-end">
                            <div className="col-md-3">
                                <label className="form-label">Bulk Measure Name <span
                                    className="tx-danger">*</span></label>
                                <input type="text" name="BulkMeasureName" tabIndex={7}
                                    className={"form-control"}
                                    value={selectedUnit.BulkMeasureName}
                                    onChange={handleUnitEntryChange}
                                    placeholder="Ex: Quintal, Load..etc" />
                            </div>
                            <div className="col-md-3">
                                <label className="form-label">Retail Qty <span
                                    className="tx-danger">*</span></label>
                                <input type="text" name="RetailQty" tabIndex={8}
                                    className={"form-control"}
                                    onChange={handleUnitEntryChange}
                                    value={selectedUnit.RetailQty} />
                            </div>
                            <div className="col-md-3">
                                <label className="form-label">Retail Measure Name <span
                                    className="tx-danger">*</span></label>
                                <input type="text" name="RetailMesaureName" tabIndex={9}
                                    className={"form-control"}
                                    onChange={handleUnitEntryChange}
                                    value={selectedUnit.RetailMesaureName} />
                            </div>
                            <div className="col-md-3 mt-3 mt-md-0">
                                <button className="btn btn-primary mr-1" onClick={saveUnit}>Save</button>
                                <button className="btn btn-secondary" onClick={newUnitMeasure}>Cancel</button>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </>
    );
}
