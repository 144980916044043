import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import * as udb from '../db/UsersData';
import { toast } from "react-toastify";
import { totalmem } from 'os';
import { AppProgressBar } from '../globals/AppProgressBar';
import { Button, Input } from 'antd';


//https://medium.com/@ryanchenkie_40935/react-authentication-how-to-store-jwt-in-a-cookie-346519310e81
const Login = (props: any) => {

  const [uname, setUname] = useState('');
  const [pass, setPass] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);

  const navigate = useNavigate();

  const login = () => {

    if (!uname || !pass){
      toast.error('Please enter login info');
    } else {
      setIsProcessing(true);
      udb.Login(uname, pass).then(res => {
        setIsProcessing(false);
        if (res.data == 'ok') {
          props.onOk();
          //navigate('/welcome');
        } else {
          toast.error(res.data);
        }
      }).catch(err => toast.error(err));
    }
  }

  const onEnterKey = (e: any) => {
    if (e.key === 'Enter')
      login();
  }

  return (<>
    <div className="az-body">
      <div style={{height:'6px'}}>
      {isProcessing && <AppProgressBar />}
      </div>
      <div className="az-signin-wrapper standard-border-radius">

        <div className="card-login shadow-2">
          <div>
            <h1 className="az-logo text-capitalize tx-darkblue mb-0 pb-0">Tracker<span>+</span></h1>
            <small>Enterprise Resource Planning</small>

          </div>
          <div className="az-signin-header">

            <div className="form-group">
              <label className='form-label'>Username</label>
              {/* <Input autoFocus  onKeyPress={onEnterKey} onChange={(e: any) => { setUname(e.target.value) }}/> */}
              <input autoFocus={true} type="text" className="form-control" onKeyPress={onEnterKey} onChange={(e: any) => { setUname(e.target.value) }} />
            </div>{/* form-group */}
            <div className="form-group">
              <label className='form-label'>Password</label>
              {/* <Input type="password" onKeyPress={onEnterKey} onChange={(e: any) => { setPass(e.target.value) }} /> */}
              <input type="password" className="form-control" onKeyPress={onEnterKey} onChange={(e: any) => { setPass(e.target.value) }} />
            </div>{/* form-group */}
            <button className="btn btn-az-darkblue btn-block" onClick={login}>Sign In
            </button>

          </div>{/* az-signin-header */}
          <div className="az-signin-footer">
            <p><a className="pointer-cursor" onClick={() => toast.info('Contact your system administrator')}>Forgot password?</a></p>
            {/* <p>Don't have an account? <a href="page-signup.html">Create an Account</a></p> */}

          </div>{/* az-signin-footer */}
        </div>{/* az-card-signin */}
      </div>{/* az-signin-wrapper */}
    </div>
  </>);
}

export default Login;