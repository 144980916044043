import React, { useEffect, useState } from 'react';
import { ML_Ledger } from "./dto";
import * as ldb from "../db/LedgerData";
import Select, { components, OptionProps, StylesConfig } from "react-select";
import * as consts from './../globals/GlobalConsts';

type attributes = {
    selectLedgerId: number,
    isDisabled: boolean,
    onLedgerSelected(e: ML_Ledger): void,
    isError:boolean
}

const LedgersDropDownControl = (props: attributes) => {

    const [ledgers, setLedgers] = useState(new Array<any>());
    const [initialized, setInitialized] = useState(false);
    const [ledgerSelected, setLedgerSelected] = useState(new ML_Ledger());
    const [isLoading, setIsLoading] = useState(false);
    // const style = { control: (st: any) => ({ ...st, minWidth: 100 }) };
    const [errorStyle, setErrorStyle] = useState({});

    useEffect(() => {
        LoadAllLedgersWithBalance();
    }, [])

    useEffect(() => {
        console.log(props.selectLedgerId);
        getLedgerObject();
    }, [initialized, props.selectLedgerId]);

    
    /* ERROR GLOW STYLE */
    useEffect(() => {
        setErrorStyle({ control: (style: any) => notifyError(props.isError, style) });
    }, [props.isError])

    const notifyError = (tog: boolean, preStyle: any) => {
        if (tog) {
            return {
                ...preStyle,
                backgroundColor: consts.CONTROL_ERROR_BACKGROUND_COLOR,
                border: "1px solid " + consts.CONTROL_ERROR_BORDER_COLOR
            }
        }
        return { ...preStyle };
    }
    /* ERROR GLOW STYLE */


    const LoadAllLedgersWithBalance = () => {
        setIsLoading(true);
        ldb.getAll().then(res => {
            console.log('simple DDL loaded');
            let allLedgers: ML_Ledger[] = res.data;
            let blank: ML_Ledger = new ML_Ledger();
            blank.LedgerName = '';
            blank.LDGID = 0;
            allLedgers.push(blank);
            setLedgers(allLedgers);
            //setLedgers(res.data);
            setIsLoading(false);
            setInitialized(!initialized);
        });
    }

    const getLedgerObject = () => {
        //if (props.selectLedgerId) {
        let ldg = ledgers.find((m: any) => m.LDGID === +props.selectLedgerId);
        ldg ? setLedgerSelected(ldg) : setLedgerSelected(new ML_Ledger());
        props.onLedgerSelected(ldg);
        //}
    }

    const handleLedgerSelect = (e: any) => {
        if (e) {
            props.onLedgerSelected(e);
            setLedgerSelected(e);
        }
    }

    const optionTemplate = (props: any) => (
        <components.Option {...props}>
            <b>{props.data.LedgerName}</b>
        </components.Option>)


    const selectedTemplate = (props: any) => {
        return (<components.SingleValue {...props}>
            <b>{props.data.LedgerName}</b>
        </components.SingleValue>);
    }

    return (
        //{control: (style:any)=>notifyError(props.isError,style)}
        //minWidth:100
        //styles={control: (style)=>{...style, minWidth:100}}
        <Select styles={errorStyle}
            classNamePrefix="srushti"
            options={ledgers}
            isDisabled={props.isDisabled}
            value={ledgerSelected}
            theme={theme => ({ ...theme })}
            isLoading={isLoading}
            getOptionValue={option => option.LDGID.toString()}
            components={{ Option: optionTemplate, SingleValue: selectedTemplate }}
            onChange={handleLedgerSelect}
        />)
}

export default LedgersDropDownControl;
