import axios from 'axios';
import * as global from '../globals/ApiUrlConsts';
import * as dateUtils from '../globals/AppDateUtils';
import {ML_ReportUserSettings} from "../globals/dto";

const web_api_url = global.global_api_url_base + '/api/reports';

export const SaveReportSetting = async (data:ML_ReportUserSettings) =>
    await axios.post(web_api_url + '/saveReportSetting',data)

export const getAllReportSettingsByType = (typeName:string) =>
    axios.get<ML_ReportUserSettings[]>(web_api_url + '/GetReportSettingsByTypeName',{params:{typeName}})

export const getInwardsStatement = async (start: Date, end: Date) =>
    await axios.get(web_api_url + '/inwardstatement', { params: { start: dateUtils.SanitizeDate(start), end: dateUtils.SanitizeDate(end) } })

export const getAllSummary = async () => await axios.get(web_api_url + '/allaccounts')

export const getDayBookStatement = async (today: Date) =>
    await axios.get(web_api_url + '/daybook', { params: { today: dateUtils.SanitizeDate(today) } })

export const getCashSalesList = async (data:any) =>
    await axios.post(web_api_url + '/GetCashSalesList',null, {params:{req:JSON.stringify(data)}})

export const getCashPurchasesList = async (start: Date, end: Date) =>
    await axios.get(web_api_url + '/GetCashPurchaseList', { params: { st: dateUtils.SanitizeDate(start), ed: dateUtils.SanitizeDate(end) } })

export const GetAllOpenedStocks = async () =>
    await axios.get(web_api_url + '/GetAllOpeningStocks')

export const GetOpeningStocksForProduct = async (pid:number) =>{
    //console.log('pid',pid);
    return await axios.get(web_api_url + '/GetOpeningStocksForProduct',{params:{prodID:pid}})
}

export const GetAllSplitStocks = async () =>
    await axios.get(web_api_url + '/GetAllSplitStocks')

export const GetAllStoreMovedStocks = async () =>
    await axios.get(web_api_url + '/GetAllStoreMovedStocks')

export const GetLedgersSummary = (settingJson:string)=>
    axios.post(web_api_url + '/GetLedgersSummary', JSON.parse(settingJson));
