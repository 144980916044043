import React, { useEffect, useState } from 'react';
import * as bdb from '../db/BrokersData';
import * as AppAct from '../globals/AppActions';
import { connect, useDispatch, useSelector } from "react-redux";
import { mapStateToProps } from "../globals/AppActions";
import { ML_Broker } from "../globals/dto";
import BrokerStatement from "./BrokerStatement";
import * as utils from '../globals/Utils';
import { AppReduxState } from '../infra-redux/AppStateReduxStore';

const BrokersCreditList = (props: any) => {
    const AppDisp = useDispatch();
    const [stats, setStats] = useState(new Array<any>());
    const [totals, setTotals] = useState({ totalBilled: 0, totalPaid: 0, totalBal: 0 });
    const [showStatement, setShowStatement] = useState(false);
    const [selectedBroker, setSelectedBroker] = useState(new ML_Broker());

    const reduxState = useSelector((state: AppReduxState) => state);

    useEffect(() => {
        AppDisp(AppAct.ChangeMainTitle("Broker Credits"));
        LoadAllBrokersStatement();
    }, []);

    const LoadAllBrokersStatement = () => {
        AppDisp(AppAct.ToggleProgress(true));

        setTotals({
            ...totals,
            //totalPaid: 0,
            //totalBilled: 0,
            totalBal: 0
        });

        bdb.GetAllBrokersStatementEx().then((res: any) => {
            setStats(res.data);
            res.data.forEach((x: any) => {
                setTotals({
                    ...totals,
                    //totalPaid: prev.totalPaid + x.Paid,
                    //totalBilled: prev.totalBilled + x.BilledTotal,
                    totalBal: res.data.reduce((total: number, item: any) => total + item.Balance, 0)
                });
            })
            AppDisp(AppAct.ToggleProgress(false));
        })
    }

    const showBrokerStatement = (broker: ML_Broker) => {
        setSelectedBroker(broker);
        setShowStatement(true);
    }

    const closeStatementView = (refresh: boolean = false) => {
        if (refresh)
            LoadAllBrokersStatement();
        setShowStatement(false);
    }

    if (showStatement)
        return <BrokerStatement broker={selectedBroker} close={closeStatementView} />

    return <>

        <div className="row">
            <div className="col-md-12">
                <div className="table-responsive">
                    <table className="table table-striped mg-b-0">
                        <thead className="border-top">
                            <tr>
                                <th>Broker</th>
                                {/*<th className="text-right">Account Status</th>
                                    <th className="text-right border-left">Billed</th>
                                    <th className="text-right">Paid</th>*/}
                                <th className="text-right">Balance</th>
                            </tr>
                        </thead>
                        <tbody>
                            {stats.map((c: any, idx: number) => {
                                return (
                                    <tr key={idx}>
                                        <th scope="row" className="pointer-cursor text-primary"
                                            onClick={() => showBrokerStatement(c.BrokerDetails)}>
                                            <b className="text-indigo">{c.BrokerDetails.BrokerName}</b>
                                        </th>
                                        {/*<td className="text-right">{c.Balance}</td>
                                            <td className="text-right border-left">{c.BilledTotal}</td>
                                            <td className="text-right">{c.Paid}</td>*/}
                                        <td className="text-right">{utils.formatCurrencyValue(reduxState, c.Balance)}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                        <tfoot>
                            <tr>
                                <td></td>
                                {/*<td></td>
                                    <td className="text-right border-left font-weight-bold">{totals.totalBilled}</td>
                                    <td className="text-right font-weight-bold">{totals.totalPaid}</td>*/}
                                <td className="text-right font-weight-bold">{utils.formatCurrencyValue(reduxState, totals.totalBal)}</td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
        </div>

    </>
}

export default BrokersCreditList;
