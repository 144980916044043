import React, {useEffect, useState} from "react";
import {ML_Machine} from "../globals/dto";
import * as wdb from '../db/WorkflowData';
import {toast} from 'react-toastify';
import {Modal, ModalBody} from "react-bootstrap";
import ModalHeader from "react-bootstrap/ModalHeader";
import Swal from "sweetalert2";
import { SaveButtonUI } from "../globals/AppControls/SaveButtonUI";
import { CloseButtonUI } from "../globals/AppControls/CloseButtonUI";

export const LabelMachineName = (props:any)=>{
    return (<h4>{props.name}</h4>);
}
export const ButtonNewMachine = (props:any)=>{
    const[showModal, setShowModal]  = useState(false);
    const handleClosed = (id:number)=>{
        setShowModal(false);
        props.saved(id);
    }
    return(
        <span key={new Date().getMilliseconds()}>
            <MachineModal machine={new ML_Machine()} saved={handleClosed} show={showModal}/>
            <a className="pointer-cursor text-success font-weight-bold mt-2"
               onClick={()=>setShowModal(true)}>+Add Machine</a>
        </span>
    );
}

export const ButtonEditMachine = (props: any) => {
    const[showModal, setShowModal]  = useState(false);
    const handleClosed = (id:number)=>{
        setShowModal(false);
        props.saved(id);
    }

    return (
        <span key={new Date().getMilliseconds()}>
            <MachineModal machine={props.machine} show={showModal} saved={handleClosed}/>
            <a className="pointer-cursor text-secondary font-weight-bold mr-3"
               title={"Edit machine"}
               onClick={()=>setShowModal(true)}>
                <i className="fa fa-ellipsis-h text-primary"/>
            </a>
        </span>);
};

export const ButtonDeleteMachine = (props:any) => {

    const clickDelete = () => {

        Swal.fire({
            title: 'Delete Machine',
            html: "Are you sure want to delete this machine?",
            showCancelButton: true,
            confirmButtonColor: 'red',
            focusCancel: true,
        }).then((res: any) => {
            if (res.value) {
                wdb.deleteMachine(props.MachineID).then(res => {
                    if (res.data) {
                        props.saved();
                    }
                });
            }
        });
    }

    return (<a className="pointer-cursor text-danger font-weight-bold mr-3"  title={"Delete machine"} onClick={clickDelete}>
        <i className="fa fa-trash text-danger"/>
    </a>);
}

export const MachineModal=(props:any)=> {
    return(
        <>
        <Modal show={props.show} dialogClassName="modal-corner"
               onHide={()=>props.saved(0)}
               keyboard={true}>
            <ModalHeader className="bg-gray-200 p-3">
                <h4 className="pb-0 mb-0 font-weight-bold">Machine Details</h4>
            </ModalHeader>
            <ModalBody>
                <MachineDetails machine={props.machine} saved={props.saved}/>
            </ModalBody>
        </Modal>
        </>);
}

export const MachineDetails = (props:any) => {
    const [machine, setMachine] = useState(props.machine);

    const saveMachine = ()=> {
        wdb.saveMachine(machine).then(res => {
            if(+res.data>0)
                props.saved(+res.data); //success
            else
                props.saved(-1); //error
        });
    }

    const handleChange =(e:any)=>{
        let name = e.target.name;
        let val = e.target.value;
        setMachine({...machine, [name]:val});
    }

    return(<>
        <div className="row">
            <div className="col-8">
                <label>Machine name</label>
                <input type="text" maxLength={35} id={"MachineName"} name={"MachineName"} className="form-control"
                value={machine.MachineName}
                       onChange={handleChange} />
            </div>
            <div className="col-4">
                <label>Capacity</label>
                <input type="number" id={"IntakeCapacity"} name={"IntakeCapacity"} className="form-control"
                       value={machine.IntakeCapacity}
                       onChange={handleChange} />
            </div>
            <div className="col-12 text-center mt-3">
                {/* <button className="btn btn-success mr-3" onClick={saveMachine}>Save</button>
                <button className="btn btn-secondary" onClick={()=>props.saved(0)}>Cancel</button> */}

                <SaveButtonUI  onClick={saveMachine} className="mr-3" />
                <CloseButtonUI onClick={() => props.saved(0)} />
            </div>
        </div>
    </>);

}
