import React, {useEffect, useState} from 'react';
import * as wdb from '../db/WorkflowData';
import Select, {components, OptionProps} from "react-select";
import {MachineView, ML_Machine, PlantView} from "../globals/dto";

type attributs = {
    workflowId:number;
    onProcessFlowIdSelected(pfid: number):void
}

//output : props.onChange(e) = e.value -> ProcessFlowID
export const MachinesSelect = (props: attributs) => {

    const [machines, setMachines] = useState(new Array<any>());
    const [selectedFlowID, setSelectedFlowID] = useState(0);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        LoadWorkflowMachinesWithProcessFlow(props.workflowId);
    }, [props.workflowId])

    const LoadWorkflowMachinesWithProcessFlow =(wfId:number)=>{
        setLoading(true);
        wdb.GetWorkflowDetails(wfId).then((res: any) => {
            if (res) {
                setLoading(false);
                setMachines(
                    res.data.map((p: PlantView) => {
                    return {
                        label: p.PlantDetails.PlantName,
                        options: p.MachineViewsList.map((mv: MachineView) => {
                            return {value: mv.ProcessFlow.PFLID.toString(), label: mv.MachineDetails.MachineName}
                        })
                    }
                }));

            }
        })
    }


    const handleSelected = (e: any) => {
        //console.log(machines);
        let id = +e.value;
        setSelectedFlowID(id);
        props.onProcessFlowIdSelected(id);
    }


    return <Select classNamePrefix="srushti"
                    options={machines}
                   isClearable={false}
                   isLoading={loading}
                   defaultValue={machines[0]}
                   theme={theme => ({...theme, borderRadius: 0, width: "100%"})}
                   placeholder={"Select machine..."}
                   onChange={handleSelected}

    ></Select>;
}