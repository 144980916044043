import React, { useEffect, useState } from 'react';
import { mapStateToProps } from "../globals/AppActions";
import { connect, useDispatch } from "react-redux";
import { ML_SaleBill, ML_Transaction } from "../globals/dto";
import { TransactionEntry } from "../shared/TransactionEntry";
import AsyncSelect from 'react-select/async';
import { components, OptionProps } from 'react-select';
import * as bdb from '../db/BrokersData';
import * as factory from '../globals/factory';
import * as AppAct from '../globals/AppActions';


const BrokerPay = (props: any) => {
    const AppDisp = useDispatch();

    const savePayment = (tranEntry: ML_Transaction) => {
        let ipay = factory.createBrokerVM();
        ipay.TransactionEntry = { ...tranEntry };
        ipay.Broker = props.broker;

        AppDisp(AppAct.ToggleProgress(true));
        bdb.PayBrokerCredit(ipay).then((res: any) => {
            props.saved();
            AppDisp(AppAct.ToggleProgress(false));
            props.close();
        })
    }

    return <>

        <TransactionEntry trans={factory.createTransactionML()}
            LedgerLabel="Withdraw from Ledger"
            isLedgerEditable={true}
            closed={() => props.close(false)}
            saved={(tran: ML_Transaction) => {
                //setTrans(tran); //no shit use of this.
                savePayment(tran);
                props.close(false)
            }
            }
        />

    </>
}

export default BrokerPay;
