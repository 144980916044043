import React, { useEffect, useState } from 'react';
import { InwardLineItemView, InwardView, ML_Company, ML_Supplier, SaleBillView } from "../globals/dto";
import * as AppAct from '../globals/AppActions';
import { mapStateToProps } from '../globals/AppActions';
import * as idb from '../db/InwardsData';
import { connect, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import * as utils from '../globals/Utils';
import * as dateUtils from '../globals/AppDateUtils';
import InwardInvoiceEntry from "../inwards/InwardInvoiceEntry"
import { Modal, ModalBody } from 'react-bootstrap';
import { InwardInvoiceView } from '../inwards/InwardInvoiceView';
import DateRangeControlEx from "../globals/DateRangeControlEx";
import StoresSelect from "../shared/StoresSelect";
import { ProcessLineItemLogs } from "./ProcessLineItemLogs";

const ProcessingHistory = (props: any) => {



    const [suppliers, setSuppliers] = useState(new Array<ML_Supplier>());
    const [allInwards, setAllInwards] = useState(new Array<InwardView>());
    const [allInwardsMasterList, setAllInwardsMasterList] = useState(new Array<InwardView>());
    const [selectedInward, setSelectedInward] = useState(new InwardView());
    const [startDate, setStartDate] = useState(dateUtils.MinusDays(new Date(), 1));
    const [endDate, setEndDate] = useState(new Date());
    const [searchText, setSearchText] = useState('');
    const [refreshList, setRefreshlist] = useState(false);
    const [openInward, setOpenInward] = useState(0);
    const [showInwardEdit, setShowInwardEdit] = useState(false);
    const [viewInward, setViewInward] = useState(false);
    const [selectedStore, setSelectedStore] = useState(new ML_Company());

    const appDisp = useDispatch();


    const [showItemProcessLog, setShowItemProcessLog] = useState(false);
    const [logItemID, setLogItemID] = useState(0);
    const showItemLogModal = (liid: number) => {
        setLogItemID(liid);
        setShowItemProcessLog(!showItemProcessLog);
    }


    useEffect(() => {
        appDisp(AppAct.ChangeMainTitle('Production History'));
        LoadAllInvoices(startDate, endDate);
    }, []);

    const LoadAllInvoices = (start: Date, end: Date) => {

        if (start.getTime() <= start.getTime()) {
            appDisp(AppAct.ToggleProgress(true));
            idb.GetInwardsWithLinteItems(start, end).then((res) => {
                setAllInwards([...res.data]);
                setAllInwardsMasterList([...res.data]);
                //filterInvoices();
                appDisp(AppAct.ToggleProgress(false));
            });
        } else {
            toast.error('FROM date should be before TO Date.');
        }
    }

    const filterInvoicesList = (iv: InwardView) => {

        if (!iv) return false;
        if (!iv.LineItems) return false;

        let searchFound = false;
        let companyFound = false;
        companyFound = selectedStore.CMPID > 0 ? (iv.InwardEntry?.CompanyID === selectedStore.CMPID) : selectedStore.CMPID === 0 ? true : false;
        searchFound = searchText.length > 0 ? iv.SupplierDetails.CompanyName.toLowerCase().includes(searchText.toLowerCase()) : false;
        let res = searchFound || companyFound;
        return res;
    }

    const newInward = () => {
        //props.history.push('/inwarddetails');
        setOpenInward(0);
        setShowInwardEdit(true);
    }

    const editInward = (id: number) => {
        setOpenInward(id);
        setShowInwardEdit(true);
        //setViewInward(true);
    }

    const showInward = (id: number) => {
        setOpenInward(id);
        setViewInward(true);
    }

    const closeEditInwardView = () => {
        setShowInwardEdit(false);
        LoadAllInvoices(startDate, endDate);
    }

    const handleSearch = (e: any) => {
        setSearchText(e.target.value);
        //filterInvoices();
    }

    const handleDateRange = (sd: Date, ed: Date) => {
        setStartDate(sd);
        setEndDate(ed);
        LoadAllInvoices(sd, ed);
    }

    const handleStoreSelectChange = (e: any) => {
        if (!e) {
            setSelectedStore(new ML_Company());
        } else {
            setSelectedStore(e);
        }
    }

    return (
        <>

            <ProcessLineItemLogs showModal={showItemProcessLog}
                lineItemID={logItemID}
                workflowId={0}
                close={() => showItemLogModal(0)}
            />


            <Modal show={viewInward} dialogClassName="modal-corner"
                size="lg"
                onHide={() => setViewInward(false)}>
                <ModalBody className="p-3">
                    <InwardInvoiceView iwid={openInward} />
                </ModalBody>
            </Modal>


            {showInwardEdit &&
                <InwardInvoiceEntry show={showInwardEdit} store={selectedStore} inwardId={openInward} close={closeEditInwardView} />
            }
            {!showInwardEdit &&
                <>
                    
                    <h5 className='az-content-label'>Production History</h5>
                    <hr />
                    
                    <div className="row mt-3 mb-3 border-bottom">
                        <div className="col-md-12 pb-3">
                            <div className="d-flex flex-wrap justify-content-between">
                                <div className="mb-3 mb-md-0 align-content-sm-center">
                                    <DateRangeControlEx start={startDate} end={endDate} onChange={handleDateRange} />
                                </div>
                                {/*<div className="pr-1 wd-450 wd-md-200">*/}
                                {/*    <StoresSelect onChange={handleStoreSelectChange} company={selectedStore}/>*/}
                                {/*</div>*/}
                                <div className="d-none d-sm-none d-md-block">
                                    <input type="search" className=" form-control float-right"
                                        placeholder=" Search by supplier..."
                                        value={searchText}
                                        onChange={handleSearch} />
                                </div>

                                {/*<div className="d-none d-sm-none  d-md-block">*/}
                                {/*    <button className="btn btn-az-primary float-right" onClick={newInward} accessKey={"n"}>*/}
                                {/*        <i className="fas fa-file-invoice"></i> <u>N</u>ew Purchase*/}
                                {/*    </button>*/}
                                {/*</div>*/}
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <div className="table-responsive">
                                <table className="table table-sm table-hover mg-b-3">
                                    <thead className="border-top">
                                        {/* <tr>
                                        <td colSpan={5}>Total Invoices: {allInwards.filter(filterInvoicesList).length}</td>
                                    </tr>*/}
                                        <tr>
                                            <th>Bill #</th>
                                            <th>Bill Date</th>
                                            <th>Supplier</th>
                                            <th className="text-right">Total Qty</th>
                                            <th className="text-right">Total Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {allInwards.filter(filterInvoicesList).map((b: InwardView, index: number) => {
                                            return (
                                                <>

                                                    <tr key={index} title="View Inward" className="pointer-cursor bg-light"
                                                        onClick={() => showInward(b.InwardEntry.INWID)}>
                                                        <td className="bg-gray-400">
                                                            {b.InwardEntry.SupplierInoviceNumber}
                                                            {b.InwardEntry.IsCanceled &&
                                                                <span className="badge badge-danger ml-2">Canceled</span>}
                                                        </td>
                                                        <td className="bg-gray-100">{dateUtils.FormatDate(b.InwardEntry.InvoiceReceivedDate)}</td>
                                                        <td className="bg-gray-100">{b.SupplierDetails.CompanyName}</td>
                                                        <td className="text-right bg-gray-100">{b.InwardEntry.TotalBulkQty}</td>
                                                        <td className="text-right bg-gray-100">Rs.&nbsp;{b.InwardEntry.PayableTotalAmount}</td>
                                                    </tr>

                                                    {b.LineItems.map((ine: InwardLineItemView, ieIndex: number) => {
                                                        return (
                                                            <tr key={"ineln" + ieIndex} className="pointer-cursor" onClick={() => showItemLogModal(ine.LineItem.INLID)}>
                                                                <td colSpan={2}></td>
                                                                <td className="text-right">{ine.LineProduct.Product.ProductName}</td>
                                                                <td className="text-right">{ine.LineItem.InternalQty} {ine.LineProduct.UnitMeasure.BulkMeasureName}</td>
                                                                <td></td>
                                                            </tr>
                                                        )
                                                    })}
                                                </>)
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </>
            }
        </>)
}

export default ProcessingHistory;
