//KLP
export const global_api_url_base = 'https://lab.srushti-soft.com';

export const CLASS_INPUT_ERROR = ' input-error';
export const DATE_FORMAT = 'DD-MMM-YYYY';

export const PROCESS_STATUS_START = "START";
export const PROCESS_STATUS_PROCESSING = "PROCESSING";
export const PROCESS_STATUS_WAITING = "WAITING";
export const PROCESS_STATUS_END = "END";

export const DATATYPE_STRING = "string";
export const DATATYPE_INT = "int";

export const OPERATOR_EQUAL = 'eq';
export const OPERATOR_NOT_EQUAL = 'neq';
export const OPERATOR_GREATER = 'gt';
export const OPERATOR_LESSER = 'lt';
export const OPERATOR_CONTAINS = 'in';
export const OPERATOR_NOT_CONTAINS = 'nin';
