import React, { useState } from 'react';
import * as AppAct from "../globals/AppActions";
import { mapStateToProps } from "../globals/AppActions";
import { connect, useDispatch } from "react-redux";
import { ML_SaleBill, ML_Transaction } from "../globals/dto";
import { TransactionEntry } from "../shared/TransactionEntry";
import AsyncSelect from 'react-select/async';
import { components, OptionProps } from 'react-select';
import * as sdb from '../db/SaleBillData';
import * as factory from '../globals/factory';


const DebtorPay = (props: any) => {
    const AppDisp = useDispatch();
    // const [billSerachTerm, setBillSerachTerm] = useState('');
    // const [salesFound, setSalesFound] = useState(new Array<ML_SaleBill>());
    const [selectedSaleBill, setSelectedSaleBill] = useState(new ML_SaleBill());

    const indicatorsContainer = (props: any) => {
        return (
            <components.IndicatorsContainer {...props} >
                <span></span>
            </components.IndicatorsContainer>
        );
    };

    const indicatorSeparator = (props: any) => {
        return <span style={{ display: "none" }} />;
    }

    const dropdownIndicator = (props: any) => {
        return (<></>
        );
    };

    const noOptionsMessage = (props: any) => {
        return (
            <components.NoOptionsMessage {...props}>
                No matching invoices found.
            </components.NoOptionsMessage>
        );
    };

    const optionTemplate = (props: any) => (
        <components.Option {...props}>
            {props.data.BillNumber}&nbsp;&nbsp;(Rs.<b>{props.data.AmountTotal}</b>)
        </components.Option>
    )

    const selectedTemplate = (props: any) => (
        <components.SingleValue {...props}>
            {props.data.BillNumber}&nbsp;&nbsp;(Rs.<b>{props.data.AmountTotal}</b>)
        </components.SingleValue>)


    const searchSaleBill = (searchNum: string, callback: any) => {
        sdb.searchSaleBill(props.customer.CID, searchNum).then((res: any) => {
            if (res.data && res.data.length > 0) {
                return callback(res.data)
            }
            return callback([]);
        })
    }

    const savePayment = (tranEntry: ML_Transaction) => {
        let ipay = factory.createDebitTransactionVM();
        ipay.TransactionEntry = { ...tranEntry };

        ipay.SaleBillPay.CustomerID = props.customer.CID;

        if (selectedSaleBill)
            ipay.SaleBillPay.SaleBillID = selectedSaleBill.SBID;

        AppDisp(AppAct.ToggleProgress(true));
        sdb.CollectCredit(ipay).then((res: any) => {
            props.saved();
            AppDisp(AppAct.ToggleProgress(false));
            props.close();
        })

    }

    return <>
        <div className="row form-group">
            <div className="col-12">
                <label className="form-label">Sale Invoice (Optional)</label>
                <AsyncSelect
                    classNamePrefix="srushti"
                    cacheOptions
                    isClearable={true}
                    placeholder={"Type to serach sale bill..."}
                    loadOptions={searchSaleBill}
                    theme={theme => ({ ...theme, borderRadius: 0 })}
                    getOptionValue={option => option.SBID.toString()}
                    defaultOptions={new Array<ML_SaleBill>()}
                    components={{
                        Option: optionTemplate,
                        SingleValue: selectedTemplate,
                        NoOptionsMessage: noOptionsMessage,
                        IndicatorSeparator: indicatorSeparator,
                        DropdownIndicator: dropdownIndicator

                    }}
                    onChange={(e: any) => {
                        setSelectedSaleBill(e)
                    }}
                />
            </div>
        </div>

        <TransactionEntry
            trans={factory.createTransactionML()}
            LedgerLabel="Deposit in Ledger"
            isLedgerEditable={true}
            closed={() => props.close(false)}
            saved={(tran: ML_Transaction) => {
                console.log(tran);
                savePayment(tran);
                props.close(false)
            }
            }
        />

    </>
}

export default DebtorPay;
