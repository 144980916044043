import React, {useEffect, useState} from 'react';
import * as AppAct from '../globals/AppActions';
import {connect, Provider, useDispatch, useSelector} from "react-redux";
import {PlantInfo} from "./PlantInfo";
import * as wdb from '../db/WorkflowData';
import {GlobalSettingsVM, ML_AppSetting, PlantView} from "../globals/dto";
import TransitManager from "./TransitManager";
import {Modal, ModalBody} from 'react-bootstrap';
import ProcessLogs from "./ProcessLogs";
import * as stdb from '../db/SettingsDB';

import {SubProductEntry} from "./SubProductEntry";
import {ProcessLineItemLogs} from "./ProcessLineItemLogs";
import {AxiosResponse} from "axios";
import {toast} from "react-toastify";
import { WorkflowSelectorDropDown } from '../shared/WorkflowSelectorDropDown';
import { AppReduxState } from '../infra-redux/AppStateReduxStore';

const Dashboard = (props: any) => {
    const appDisp = useDispatch();
    const appSharedState = useSelector((state: AppReduxState) => state.appReduxer);

    const [plants, setPlants] = useState(new Array<PlantView>());
    const [workflowId, setWorkflowId] = useState(0);
    const [refreshChart, setRefreshChart] = useState(true);

    useEffect(() => {
        console.log("Dashboard",props);
        appDisp(AppAct.ChangeMainTitle('Production Track'));
        LoadWorkflowFromSettings();
    }, []);

    const LoadWorkflowFromSettings = () => {
        stdb.GetGlobalSetting().then((res: AxiosResponse<ML_AppSetting>) => {
            let settings: GlobalSettingsVM = JSON.parse(res.data.SettingValue);
            setWorkflowId(settings.GlobalWorkflowId);
            LoadAllPlants(settings.GlobalWorkflowId);
        });
    }

    const LoadAllPlants = (wfId: number) => {
        appDisp(AppAct.ToggleProgress(true));
        //setRefreshChart(!refreshChart);
        if (wfId > 0) {
            wdb.GetAllPlants(wfId).then((res: any) => {
                setPlants(res.data);
                appDisp(AppAct.ToggleProgress(false));
            });
        } else {
            appDisp(AppAct.ToggleProgress(false));
            //toast.error('No default workflow present');
        }
    }

    const closeLog = () => {
        appDisp(AppAct.ShowActivityPage(false,0));
    }

    const closeSubProdPage = () => {
        appDisp(AppAct.ShowSubProdPage(false, 0));
        LoadAllPlants(workflowId);
    }

    const closeTransitScreen = () => {
        appDisp(AppAct.ShowTransitPage(false, 0,0));
        LoadAllPlants(workflowId);
    }

    const onWorkflowChanged = (wid: number) => {
        setWorkflowId(wid);
        LoadAllPlants(wid);
    }

    return (<>
        {appSharedState.ShowTransitPage && 
        <Modal show={true} size={"lg"} dialogClassName="modal-corner">
            <TransitManager
            lineItemID={appSharedState.LineItemID}
            processID={appSharedState.WFProcessID}
            workflowID={workflowId} 
            close={closeTransitScreen} />
        </Modal>
        }

        <ProcessLogs showModal={appSharedState.ShowProcessLogPage}
                     macID = {appSharedState.WFMacID}
                     close={closeLog}
        />



       {/* <SubProductEntry showModal={props[AppAct.WF_SHOW_SUBPROD]}
                         macID = {props[AppAct.WF_MAC_ID]}
                         close={closeSubProdPage}/>*/}

        <div className="mb-3 w-50 d-flex justify-content-start align-items-center">
            <div className="mr-1">
                Workflow
            </div>
            <div className="flex-grow-1">
                <WorkflowSelectorDropDown
                    selectedId={workflowId}
                    onChange={onWorkflowChanged}
                />
            </div>
        </div>

        {plants &&
            plants.map((p: PlantView, pdx: number) => {
                return (
                    <PlantInfo key={'PLT'+pdx} plant={p} RefreshChart={refreshChart} plantId={p.PlantDetails.PLTID} />
                )
            })
            }
    </>)
}

function mapDashBoardStateToProps(state: any) {
    return {
        [AppAct.WF_SHOW_TRANSIT]: state[AppAct.WF_SHOW_TRANSIT],
        [AppAct.WF_SHOW_ACTIVITY_LOG]:state[AppAct.WF_SHOW_ACTIVITY_LOG],
        [AppAct.WF_MAC_ID] : state[AppAct.WF_MAC_ID],
        [AppAct.WF_SHOW_SUBPROD]: state[AppAct.WF_SHOW_SUBPROD]
    };
}

export default Dashboard;
