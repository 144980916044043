import { AxiosResponse } from "axios";
import React, { useEffect, useState } from 'react';
import { Modal, ModalBody } from 'react-bootstrap';
import { FunnelOutline } from "react-ionicons";
import { useDispatch } from "react-redux";
import * as rdb from '../../db/ReportsData';
import * as AppAct from '../../globals/AppActions';
import { CloseButtonUI } from '../../globals/AppControls/CloseButtonUI';
import { SaveButtonUI } from '../../globals/AppControls/SaveButtonUI';
import * as dateUtils from "../../globals/AppDateUtils";
import DateRangeControlEx from "../../globals/DateRangeControlEx";
import { ML_ReportUserSettings } from "../../globals/dto";
import { ReportSettingsType } from "../../globals/GlobalConsts";
import { LedgerSummaryFilters } from './LedgerSummaryFilters';
import { ReportSettingDto } from "./ReportDTOs";
import * as utils from '../../globals/Utils';

export const LedgersReporterMain = (props: any) => {
    const dispatch = useDispatch();
    const [settings, setSettings] = useState<ReportSettingDto>(null);
    const [data, setData] = useState<any>();

    useEffect(() => {
        dispatch(AppAct.ChangeMainTitle("Ledgers Summary"));
        GetActiveSettingFromDB((f: ReportSettingDto) => setSettings(f));
    }, [])

    const onFiltersUpdated = (f: ReportSettingDto) => {
        setSettings(f);
        SavePreference(f);
        GetReport(JSON.stringify(f));
    }

    const SavePreference = (dto: ReportSettingDto) => {
        rdb.getAllReportSettingsByType(ReportSettingsType.LEDGERS_SUMMARY)
            .then((res: AxiosResponse<ML_ReportUserSettings[]>) => {
                console.log('res', res.data);
                let f = null;
                if (res.data || res.data.length > 0)
                    f = res.data.filter(f => f.IsActive)[0];

                if (!f) {
                    f = new ML_ReportUserSettings();
                    f.IsActive = true;
                    f.ReportType = ReportSettingsType.LEDGERS_SUMMARY;
                    f.SettingName = "default";
                }
                console.log('f', f);
                f.SettingJSON = JSON.stringify(dto);
                rdb.SaveReportSetting(f);
            });
    }

    const GetReport = (json: string) => {
        console.log('json', json);
        rdb.GetLedgersSummary(json).then(res => {
            console.log(res.data);
            setData(res.data);
        })
    }

    return <>
        {settings &&
            <FilterPopUp filterSettings={settings}
                onFiltersUpdated={(e: ReportSettingDto) => onFiltersUpdated(e)}
            />
        }

        {data && <div className="table-responsive mt-3">
            <table className="table">
                <thead className="border-top">
                    <tr>
                        <th className="w-50">Category</th>
                        <th className="w-25 text-right">Outwards</th>
                        <th className="w-25 text-right">Inwards</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((d:any, index:number)=>{return(<tr>
                        <td>{d.Category.TagName}</td>
                        <td className="text-right">{d.OutwardAmount}</td>
                        <td className="text-right">{d.InwardAmount}</td>
                    </tr>)})}
                </tbody>
                <tfoot>
                    <tr>
                        <td>Total</td>
                        <td className="text-right font-weight-bold">{data.reduce((total:number,item:any)=> total + item.OutwardAmount,0)}</td>
                        <td className="text-right font-weight-bold">{data.reduce((total:number,item:any)=> total + item.InwardAmount,0)}</td>
                    </tr>
                    <tr>
                        <td colSpan={2} className="text-right">(Inwards - Outwards)</td>
                        <td className="text-right font-weight-bold bg-light">
                            {data.reduce((total:number,item:any)=> total + item.OutwardAmount,0)-
                            data.reduce((total:number,item:any)=> total + item.InwardAmount,0)}
                        </td>
                    </tr>
                </tfoot>
            </table></div>}
    </>
}



const GetActiveSettingFromDB = (onGetSetting: any) => {
    rdb.getAllReportSettingsByType(ReportSettingsType.LEDGERS_SUMMARY)
        .then((res: AxiosResponse<ML_ReportUserSettings[]>) => {
            if (res.data && res.data.length > 0) {
                let findActive = res.data.filter(f => f.IsActive);
                if (findActive.length > 0) {
                    let savedSetting: ReportSettingDto = JSON.parse(findActive[0].SettingJSON);
                    if (utils.isObjectUndefined(savedSetting.ledgers))
                        savedSetting.ledgers = [];
                    if (utils.isObjectUndefined(savedSetting.groups))
                        savedSetting.groups = [];
                    onGetSetting(savedSetting);
                }
            } else {
                let st = new ReportSettingDto();
                st.start = dateUtils.MinusDays(new Date(), 7);
                st.end = new Date();
                onGetSetting(st);
            }
        })
}

const FilterPopUp = (props: {
    filterSettings: ReportSettingDto,
    onFiltersUpdated(filterSettings: ReportSettingDto): void,
}) => {
    const [showFilters, setShowFilters] = useState(false);
    const [settings, setSettings] = useState<ReportSettingDto>({ ...props.filterSettings });

    const handleDateChange = (sd: Date, ed: Date) => {
        sd = dateUtils.SanitizeDate(sd);
        ed = dateUtils.SanitizeDate(ed);
        let up = { ...props.filterSettings, start: sd, end: ed };
        props.onFiltersUpdated(up)
    }

    const onSettingUpdatesEvent = (f: ReportSettingDto) => {
        setSettings(f);
    }

    const onApply = () => {
        props.onFiltersUpdated(settings);
        setShowFilters(false);
    }

    const onCancel = () => {
        setShowFilters(false);
        setSettings({ ...props.filterSettings });
    }

    return <>
        {showFilters &&
            <Modal
                size={"lg"}
                dialogClassName="modal-corner"
                show={true}
                backdrop={'static'}
                backdropClassName={'modal-backdrop-ex'}
                animation={true}
                onHide={() => setShowFilters(false)}
                onEscapeKeyDown={() => setShowFilters(false)}>
                <ModalBody>
                    <h3 className="card-title text-black-50">Filter Criteria</h3>
                    <LedgerSummaryFilters filtersDTO={settings} onSettingUpdate={onSettingUpdatesEvent} />
                    <div className="d-flex mt-4 gap-3 justify-content-start">
                        <SaveButtonUI buttonText={'Apply'} onClick={onApply} />
                        <CloseButtonUI onClick={onCancel} />
                    </div>
                </ModalBody>
            </Modal>
        }

        <div className="row">
            <div className="col-md-6">
                <DateRangeControlEx
                    start={props.filterSettings.start}
                    end={props.filterSettings.end}
                    onChange={handleDateChange}
                />
            </div>
            <div className='col-md-6 text-right'>
                <button
                    className={'btn btn-az-primary pr-3'}
                    accessKey={"r"}
                    onClick={() => setShowFilters(true)}
                >
                    <FunnelOutline style={{ 'paddingBottom': '2px', 'marginRight': '2px' }} color={'white'} />
                    Filte<u>r</u>s
                </button>
            </div>
        </div>
    </>
}
