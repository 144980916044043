import React, { useState, useEffect } from 'react';
import * as global from '../globals/GlobalConsts';
import * as rdb from '../db/ReportsData';
import * as AppAct from '../globals/AppActions';
import { useDispatch, useSelector } from 'react-redux';
import { connect } from 'react-redux';
import { mapStateToProps } from '../globals/AppActions';
import * as utils from '../globals/Utils';
import * as dateUtils from '../globals/AppDateUtils';
import { AppReduxState } from '../infra-redux/AppStateReduxStore';

const AllAccountsSummary = (props: any) => {
    const [summary, setSummary] = useState<any>({});
    const [loading, setLoading] = useState(true);
    const AppDisp = useDispatch();
    const reduxState = useSelector((state: AppReduxState) => state);

    useEffect(() => {
        AppDisp(AppAct.ChangeMainTitle(""));
        //AppDisp(AppAct.ToggleProgress(true));
        rdb.getAllSummary().then((res: any) => {
            setSummary(res.data);
            setLoading(false);
            //AppDisp(AppAct.ToggleProgress(false));
        });
    }, []);

    const formatCurrencyValue = (num: number) => {
        return utils.formatCurrencyValue(reduxState, num);
        // if(num !== undefined)
        //     return num.toFixed(2);
        // else
        //     return '';
    }


    if (!summary) return <></>;

    return <>
            <div className="row justify-content-center mg-b-20">
                <div className="col-md-8">

                    <div className="az-content-label mg-b-5 tx-18-f text-black-50">All Accounts Summary</div>
                    <p className="mb-0">
                        Statement of all transactions till <b>{dateUtils.FormatDate(new Date())}</b></p>

                    <hr />


                    <div className="row">
                        <div className="col-md-12">
                            <div className="text-uppercase font-weight-bold tx-20-f tx-gray-500">Purchase and Sales</div>
                            <div className="table-responsive mt-3">
                                <table className="table table-hover">
                                    <thead className="border-top">
                                        <tr>
                                            <th></th>
                                            <th className="text-right">Bill Amount</th>
                                            <th className="text-right">Bill Expenses</th>
                                            <th className="text-right">Total</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        <tr>
                                            <td>All Purchases</td>
                                            <td className="text-right">{formatCurrencyValue(summary.TotalPurchases)}</td>
                                            <td className="text-right">{formatCurrencyValue(summary.TotalPurchaseExpenses)}</td>
                                            <td className="text-right">{formatCurrencyValue(summary.TotalPurchases + summary.TotalPurchaseExpenses)}</td>
                                        </tr>
                                        <tr>
                                            <td>All Sales</td>
                                            <td className="text-right">{formatCurrencyValue(summary.TotalSales)}</td>
                                            <td className="text-right">{formatCurrencyValue(summary.TotalSaleExpenses)}</td>
                                            <td className="text-right">{formatCurrencyValue(summary.TotalSales - summary.TotalSaleExpenses)}</td>
                                        </tr>
                                    </tbody>

                                    <tfoot>
                                        <tr>
                                            <td colSpan={2}></td>
                                            <td className="text-right tx-16-f">Final PnL</td>
                                            <td className="text-right font-weight-bold tx-16-f">
                                                {formatCurrencyValue((summary.TotalSales - summary.TotalSaleExpenses) - (summary.TotalPurchases + summary.TotalPurchaseExpenses))}</td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>
                    </div>

                    <div className="row mt-4">
                        <div className="col-md-12">
                            <div className="text-uppercase font-weight-bold tx-20-f tx-gray-500">Taxes</div>
                            <div className="table-responsive mt-3">
                                <table className="table table-hover">
                                    <thead className="border-top">
                                        <tr>
                                            <th>Source</th>
                                            <th className="text-right">Total</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr >
                                            <td>Purchase Tax</td>
                                            <td className="text-right font-weight-bold">{formatCurrencyValue(summary.TotalPurchaseTaxes)}</td>
                                        </tr>
                                        <tr >
                                            <td>Sales Tax</td>
                                            <td className="text-right font-weight-bold">{formatCurrencyValue(summary.TotalSaleTaxes)}</td>
                                        </tr>
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <td className="tx-16-f">Tax Liability</td>
                                            <td className="text-right font-weight-bold tx-16-f">{formatCurrencyValue(summary.TotalSaleTaxes - summary.TotalPurchaseTaxes)}</td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>
                    </div>

                    <div className="row mt-4">
                        <div className="col-md-12">
                            <div className="text-uppercase font-weight-bold tx-20-f tx-gray-500">Ledgers</div>
                            <div className="table-responsive mt-3">
                                <table className="table table-hover">
                                    <thead className="border-top">
                                        <tr>
                                            <th>Ledger</th>
                                            <th className="text-right">Current Balance</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {summary.AllLedgers?.map((l: any, idx: number) => {
                                            return (
                                                <tr key={idx}>
                                                    <td>
                                                        {l.Ledger.LedgerName}
                                                        {l.Ledger.IsBankLedger &&
                                                            <span className="font-small">&nbsp;(Bank Account)</span>}
                                                    </td>
                                                    <td className="text-right font-weight-bold">{formatCurrencyValue(l.Line.LedgerBalance)}</td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>



                    <div className="row mt-4">
                        <div className="col-md-12">
                            <div className="text-uppercase font-weight-bold tx-20-f tx-gray-500">Store wise Sales</div>
                            <div className="table-responsive mt-3">
                                <table className="table table-hover">
                                    <thead className="border-top">
                                        <tr>
                                            <th>Store/Company Name</th>
                                            <th className="text-right">Sales Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {summary.SalesByStore?.map((l: any, idx: number) => {
                                            return (
                                                <tr key={'sbs' + idx}>
                                                    <td>{l.CompanyName}</td>
                                                    <td className="text-right font-weight-bold">{formatCurrencyValue(l.Total)}</td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>


                    <div className="row mt-4">
                        <div className="col-md-12">
                            <div className="text-uppercase font-weight-bold tx-20-f tx-gray-500">Store wise Purchases</div>
                            <div className="table-responsive mt-3">
                                <table className="table table-hover">
                                    <thead className="border-top">
                                        <tr>
                                            <th>Store/Company Name</th>
                                            <th className="text-right">Purchase Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {summary.PurchasesByStore?.map((l: any, idx: number) => {
                                            return (
                                                <tr key={'sbs' + idx}>
                                                    <td>{l.CompanyName}</td>
                                                    <td className="text-right font-weight-bold">{formatCurrencyValue(l.Total)}</td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>



                </div>
            </div>
    </>
}

export default AllAccountsSummary;
