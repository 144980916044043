import React, { useEffect, useState } from 'react';
import * as AppAct from "../globals/AppActions";
import { connect, useDispatch } from "react-redux";
import {
    ML_Company,
    ML_Customer,
    ML_SaleBill,
    SaleBillView,
    InvoiceExpenseView,
    CompanySettingsVM,
} from "../globals/dto";
import * as sbdb from "../db/SaleBillData";
import { AxiosResponse } from "axios";
import { toast } from "react-toastify";
import StoresSelect from "../shared/StoresSelect";
import * as utils from "../globals/Utils";
import * as dateUtils from '../globals/AppDateUtils';
import { ExpenseSection } from "../shared/ExpenseSection";
import * as factory from '../globals/factory';
import { mapStateToProps } from "../globals/AppActions";
import 'react-quill/dist/quill.bubble.css';
import Swal from 'sweetalert2';
import * as _ from 'lodash';
import CustomerEntry from './CustomerEntry';
import BillDateNumberSection from './BillDateNumberSection';
import SaleItemsTable from './SaleItemsTable';
import * as helper from './SaleInoviceHelper';
import SaleBrokerage from './SaleBrokerage';
import { DeleteButtonUI } from '../globals/AppControls/DeleteButtonUI';
import { SaveButtonUI } from '../globals/AppControls/SaveButtonUI';
import { CloseButtonUI } from '../globals/AppControls/CloseButtonUI';

type attributes = {
    saleBillID: number,
    store: ML_Company;
    close(): void
}

const SalesInvoiceEntry = (props: attributes) => {
    const appDisp = useDispatch();

    const [validity, setValidity] = useState({
        SupplierInoviceNumberErr: '',
        DespatchedThroughErr: '',
    });

    const [saleBillView, setSaleBillView] = useState(new SaleBillView());

    useEffect(() => {
        if (props.saleBillID > 0) {
            appDisp(AppAct.ToggleProgress(true));
            sbdb.GetSaleBill(props.saleBillID).then((res: AxiosResponse<SaleBillView>) => {
                appDisp(AppAct.ToggleProgress(false));
                if (res && res.data) {
                    if (res.data.CustomerDetails === null)
                        res.data.CustomerDetails = factory.createCustomer();

                    if (res.data.BrokerPayVM == null)
                        res.data.BrokerPayVM = factory.createBrokerPaymentVM();

                    let sbView = res.data;
                    sbView.CashPayTrans = factory.createTransactionML();
                    console.log(sbView);
                    decideLedgerAndCategoryId(sbView);
                }
            });
        } else {
            let sbNew = new SaleBillView();
            sbNew.SaleBill = factory.createSaleBill();
            sbNew.CustomerDetails = factory.createCustomer();
            sbNew.BrokerPayVM = factory.createBrokerPaymentVM();
            sbNew.CashPayTrans = factory.createTransactionML();
            sbNew.AdditionalExpense = new Array<InvoiceExpenseView>();
            sbNew.Company = props.store;
            sbNew.SaleBill.CompanyID = props.store.CMPID;

            decideLedgerAndCategoryId(sbNew);
        }
        appDisp(AppAct.ChangeMainTitle('Sale Invoice'));
    }, []);

    const decideLedgerAndCategoryId = (iv: SaleBillView) => {
        iv.CashPayTrans = utils.GetStoreCashPaySetting(iv.Company, iv.CashPayTrans);
        setSaleBillView({ ...iv });
    }

    const updateSaleBillViewObject = (sb: SaleBillView) => setSaleBillView(sb);

    const updateSaleBillViewObjectAndTotal = (sb: SaleBillView) => {
        console.log(sb);
        calculateAllTotalsEx(sb);
    }

    const calculateAllTotalsEx = (fullBill: SaleBillView) => {

        if (fullBill.SaleBill) {
            //Invoice *****************************************
            fullBill.SaleBill.AmountTotal = 0;
            fullBill.SaleBill.TotalBulkQty = 0;
            fullBill.SaleBill.AmountUntaxed = 0;
            fullBill.SaleBill.AmountTax = 0;

            fullBill.LineItems.forEach(m => {
                //On the fly item products are set to zero
                if (m.LineItem.OnFlyItemPrice !== 0)
                    m.LineItem.ProductID = 0
                else
                    m.LineItem.ProductID = m.LineProduct.Product.PRDID;

                //Actual calculation of totals
                if (m.LineItem.IsCanceled === false) {
                    fullBill.SaleBill.AmountTotal += m.LineItem.SubTotal;
                    fullBill.SaleBill.TotalBulkQty += +m.LineItem.BulkQty;

                    fullBill.SaleBill.AmountTax += m.LineItem.AmountTaxed;
                    fullBill.SaleBill.AmountUntaxed += m.LineItem.AmountUntaxed;
                }
            });

            //if (fullBill.SaleBill.PayableTotalAmount === 0 || fullBill.SaleBill.PayableTotalAmount < fullBill.SaleBill.AmountTotal)
            fullBill.SaleBill.PayableTotalAmount = fullBill.SaleBill.AmountTotal;

            //Expense *****************************************
            fullBill.AdditionalExpTotal = 0;
            fullBill.AdditionalExpense.forEach(m => {
                fullBill.AdditionalExpTotal = fullBill.AdditionalExpTotal + +m.Transaction.TotalAmount;
                if (m.IsDeductInInvoice === 1) {
                    fullBill.SaleBill.PayableTotalAmount = fullBill.SaleBill.PayableTotalAmount - m.Transaction.TotalAmount;
                }
            });


            // if (fullBill.BrokerPayVM.Broker.BRKID > 0) {
            //     if (fullBill.BrokerPayVM.BrokerPay.CommissionMode === 1) {
            //         fullBill.BrokerPayVM.TransactionEntry.TotalAmount =
            //             fullBill.BrokerPayVM.Broker.CommissionAmount * fullBill.SaleBill.TotalBulkQty;
            //     }
            //     fullBill.BrokerPayVM.BrokerPay.TotalQty = saleBillView.SaleBill.TotalBulkQty;
            // }

            setSaleBillView({ ...fullBill });
        }
    }

    const getLatestExpenses = (trans: InvoiceExpenseView[]) => {
        let sb = _.clone(saleBillView);
        sb.AdditionalExpense = trans;
        calculateAllTotalsEx(sb);
    }

    const handleStoreSelection = (s: ML_Company) => {
        let copy = { ...saleBillView };
        copy.SaleBill.CompanyID = s.CMPID;
        copy.Company = s;
        decideLedgerAndCategoryId({ ...copy });
    }

    const saleBillSave = () => {

        if (helper.ValidateData(saleBillView)) {
            toast.dismiss();
            appDisp(AppAct.ToggleProgress(true));
            let saveCopy = { ...saleBillView };
            saveCopy.LineItems = saveCopy.LineItems.filter(f => f.LineItem.ProductID > 0 || f.LineItem.OnFlyItemPrice > 0);
            //saveCopy.SaleBill.MiscItemsJson = JSON.stringify(miscNotes);
            //saveCopy.CashCounterLedgerID = cashLedgerId;
            saveCopy.SaleBill.BillingDate = dateUtils.SanitizeDate(saveCopy.SaleBill.BillingDate);
            setSaleBillView(saveCopy);
            sbdb.Save(saveCopy).then(res => {
                if (res === null || res.data === null) {
                    toast.error('Something went wrong. retry please');
                } else {
                    appDisp(AppAct.ToggleProgress(false));
                    //props.history.push('/saleBills');
                    props.close();
                }
            });
        }
    }

    const saleBillCancel = () => {

        toast.dismiss();
        Swal.fire({
            title: 'Cancel Bill',
            html: "Are you sure want to cancel this bill?",
            showCancelButton: true,
            confirmButtonColor: 'red',
            focusCancel: true,
        }).then((res: any) => {
            if (res.value) {
                appDisp(AppAct.ToggleProgress(true));
                sbdb.CancelBill(saleBillView.SaleBill.SBID).then(res => {
                    if (res === null || res.data === null) {
                        toast.error('Something went wrong. retry please');
                    } else {
                        if (res.data === false) {
                            toast.error('Unable to cancel the bill');
                        }
                        appDisp(AppAct.ToggleProgress(false));
                        //props.history.push('/saleBills');
                        props.close();

                    }
                });
            }
        })

    }

    if (!saleBillView.SaleBill) return <></>

    return (
        <>
            {/* ----------------- HEADER AND STORE SELECTOR --------------- */}
            <div className="row mb-2 align-items-center">
                <div className="col-md-3">
                    <h4 className="az-content-label mb-0 pb-0">
                        {saleBillView.SaleBill.SBID > 0 ? `Invoice # ${saleBillView.SaleBill.SBID}` : 'New Sale Bill'}
                    </h4>
                </div>
                <div className="col-md-5">
                    <div className="d-flex gap-1 align-items-center">
                        <label className="mb-0 pb-0">Store</label>
                        <div className="flex-grow-1">
                            <StoresSelect onChange={handleStoreSelection} company={saleBillView.Company} />
                        </div>
                    </div>
                </div>

                {/* ----------------- SAVE / CANCEL / CLOSE BUTTONS --------------- */}
                <div className="col text-right">
                    {saleBillView.SaleBill.SBID > 0 &&
                        <DeleteButtonUI buttonText='Cancel Bill' className="mr-2" onClick={saleBillCancel} />}
                    <SaveButtonUI onClick={saleBillSave} className="mr-2" />
                    <CloseButtonUI onClick={() => props.close()} />
                </div>
            </div>
            
            <div className="form-row">
                <div className="col-md-7 pr-md-5">
                    {/* ----------------- CUSTOMER INFO --------------- */}
                    <CustomerEntry
                        onSaleBillViewUpdate={updateSaleBillViewObject}
                        saleBillView={saleBillView}
                    />

                </div>
                <div className="col-md-5">
                    {/* ----------------- BILL DATE AND FRIGHT INFO --------------- */}
                    <BillDateNumberSection
                        inputCheck={validity}
                        onChangeSaleBillView={updateSaleBillViewObject}
                        saleBillView={saleBillView}
                    />

                </div>
            </div>

            {/* ----------------- SALE ITEMS --------------- */}
            <SaleItemsTable
                onSaleBillViewUpdate={updateSaleBillViewObjectAndTotal}
                saleBillView={saleBillView}
            />

            {/* ----------------- BROKERAGE --------------- */}
            <SaleBrokerage
                saleBillView={saleBillView}
                onSaleBillViewUpdate={updateSaleBillViewObjectAndTotal}
            />

            {/* ----------------- OTHER EXPENSES --------------- */}
            <h5 className='mt-4 az-content-label'>Other Expenses</h5>
            <div className="form-row">
                <div className="col-md-12">
                    <ExpenseSection trans={saleBillView.AdditionalExpense} getLatest={getLatestExpenses} />
                </div>
            </div>
        </>
    );
}

export default SalesInvoiceEntry;
