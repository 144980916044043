import React, { useEffect, useState } from 'react';
import { FilterDropdownItem } from "../globals/ViewModels";
import Select, { components } from "react-select";
import * as utils from '../globals/Utils';

export const FilterDropdown = (props: {
    masterList: FilterDropdownItem[],
    userSelectedIDs: number[],
    onSelected(id: number): void
}) => {

    const [list, setList] = useState<FilterDropdownItem[]>([]);
    const [selectedItem, setSelectedItem] = useState<FilterDropdownItem>(new FilterDropdownItem());

    useEffect(() => {

        if(utils.isObjectUndefined(props.userSelectedIDs))
            setList(props.masterList);

        if (!utils.isObjectUndefined(props.userSelectedIDs) && !utils.isObjectUndefined(props.masterList)) {
            let l: FilterDropdownItem[] = props.masterList.filter((x: FilterDropdownItem) =>
                props.userSelectedIDs.findIndex(xf => xf === x.id) === -1);

            setList(l);
        }

    }, [props.userSelectedIDs])

    const onSelected = (e: any) => {
        props.onSelected(e.id);
    }

    const optionTemplate = (props: any) => (
        <components.Option {...props}>
            <b>{props.data.value}</b>
        </components.Option>
    );

    const selectedTemplate = (props: any) => (
        <components.SingleValue {...props}>
            <b>{props.data.value}</b>
        </components.SingleValue>
    );

    const userSearch = (option: any, search: string) => {
        return option.data.value.toLowerCase().includes(search.toLowerCase());
    }

    return <>
        {props.masterList &&
            <Select classNamePrefix="srushti"
                options={list}
                value={selectedItem}
                theme={theme => ({ ...theme })}
                getOptionValue={option => option.id.toString()}
                components={{ Option: optionTemplate, SingleValue: selectedTemplate }}
                onChange={onSelected}
                filterOption={userSearch}
            />}
    </>
}
