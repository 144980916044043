import React from "react";
import { CheckmarkOutline, CloseOutline } from 'react-ionicons';

export const CloseButtonUI = (props: any) => {
    return <button {...props}
        className={' btn btn-secondary pr-3 ' + props.className}
        onClick={props.onClick}
        accessKey={props.accessKey ? props.accessKey : ( !props.title ? "c" : "")}
    >
        <CloseOutline style={{ 'paddingBottom': '2px' }} color={'white'} />
        {!props.buttonText && <><u>C</u>lose</>}
        {props.buttonText && <span dangerouslySetInnerHTML={{ __html: props.buttonText }} />}
    </button>
}