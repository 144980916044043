import React, {useState} from "react";
import {FileAttachment} from "../globals/dto";
import * as global from '../globals/ApiUrlConsts';

const web_api_url = global.global_api_url_base + '/api/Reviews';

type attributes = {
    key: string,
    fileInfo: FileAttachment,
    onDelete(name: string): void,
    isReadOnly : boolean
    //onOpenFile(uqName:string, showName:string):void
}

const FileAttached = (props: attributes) => {


    return (<>
        <div className="d-inline-flex justify-content-between align-items-center px-3 py-1 bg-white border border-bd-gray-100 rounded-10" key={props.key}>
            <a href={web_api_url + "/GetFileByUniqName?uniqName=" + props.fileInfo.UniqueFileName + "&showFileName=" + props.fileInfo.FileName}
            style={{textOverflow:'ellipsis', whiteSpace:'nowrap', overflow:'hidden'}}>
                {props.fileInfo.FileName}
            </a>
            {!props.isReadOnly &&
            <i className="fa fa-trash tx-gray-400 ml-2 pointer-cursor"
               onClick={e => props.onDelete(props.fileInfo.UniqueFileName)}></i>
            }
        </div>
    </>)
}

export default FileAttached;