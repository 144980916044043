import React, {useEffect, useState} from 'react';
import {connect, useDispatch} from "react-redux";
import * as AppAct from '../globals/AppActions';
import {mapStateToProps} from '../globals/AppActions';
import * as wdb from '../db/WagesData';
import {ML_LabourWageSetting, ML_Product, ML_UnitMeasure, WageConfigView} from "../globals/dto";
import {AxiosResponse} from "axios";
import {MachinesSelect} from "../shared/MachinesSelect";
import {ProductsDropDown} from "../products/ProductsDropDown";
import {UnitsSelect} from "../shared/UnitsSelect";

export const WagesConfig = (props: any) => {

    const AppDisp = useDispatch();
    const [wagesViewList, setWagesViewList] = useState<WageConfigView[]>();
    const [wage, setWage] = useState<ML_LabourWageSetting>(new ML_LabourWageSetting());
    const [isConsiderProduct, setIsConsiderProduct] = useState(false);

    useEffect(() => {
        AppDisp(AppAct.ChangeMainTitle("Wages Setting"));
        loadAll();
    }, [])

    const loadAll = () => {
        AppDisp(AppAct.ToggleProgress(true));
        wdb.GetAll().then((res: AxiosResponse<WageConfigView[]>) => {
            setWagesViewList(res.data);
            AppDisp(AppAct.ToggleProgress(false));
        });
    }

    const saveConfig = () => {
        AppDisp(AppAct.ToggleProgress(true));
        wdb.Save(wage).then((res: any) => {
            AppDisp(AppAct.ToggleProgress(false));
            //setWage({PSID : 0, WagePerUnit : 0, ProductID:0, UnitMeasureID:0, MachineID:0});
            loadAll();
        })
    }

    const DeleteWage = (id: number) => {
        AppDisp(AppAct.ToggleProgress(true));
        wdb.Remove(id).then(res => {
            AppDisp(AppAct.ToggleProgress(false));
            loadAll()
        });
    }

    const handleChange = (e: any) => {
        let name = e.target.name;
        let val = e.target.value;
        setWage({...wage, [name]: val});
    }

    const handleFlowSelected = (e: any) => {
        let flowID = e.value;
        if (flowID > 0) {
            wdb.GetMachineID(flowID).then(res =>
                setWage({...wage, MachineID: res.data}));
        }
    }

    const handleProductSelected = (e: any) => {
        setWage({...wage, ProductID: e.Product.PRDID});
    }

    const handleProductConsider = (e: any) => {
        setIsConsiderProduct(e.target.checked);
        setWage({...wage, ProductID: 0});
    }

    const handleUnitMeasureSelect = (e: ML_UnitMeasure) => {
        setWage({...wage, UnitMeasureID: e.UCID});
    }

    return (
        <>

            <div className="form-group row">
                <div className="col-md-3">
                    <label>Production Phase</label>
                    <MachinesSelect
                        workflowId={0}
                        onProcessFlowIdSelected={handleFlowSelected}
                    />
                </div>
                <div className="col-md-3">
                    <div className="custom-control custom-checkbox mb-1 mt-1">
                        <input type="checkbox" className="custom-control-input" id="customCheck1"
                               checked={isConsiderProduct}
                               onChange={handleProductConsider}/>
                        <label className="custom-control-label" htmlFor={"customCheck1"}>Product-wise</label>
                    </div>
                    {isConsiderProduct && <ProductsDropDown showWitStocks={false} showOnlySaleable={false}
                                                            onChange={handleProductSelected}/>}
                    {!isConsiderProduct && <select className="form-control form-select" disabled={true}></select>}
                </div>
                <div className="col-md-3">
                    <label>Unit</label>
                    <UnitsSelect UnitID={wage.UnitMeasureID} onChange={handleUnitMeasureSelect}
                                 showCreateNewOption={false}/>
                </div>
                <div className="col-md-2">
                    <label>Rate</label>
                    <input type="number" className="form-control text-right"
                           name={"WagePerUnit"} id={"WagePerUnit"}
                           onChange={handleChange}/>
                </div>
                <div className="col-md-1">
                    <label>Store</label><br/>
                    <button className="btn btn-success" onClick={saveConfig}>Save</button>
                </div>
            </div>


            <div className="form-group row">
                <div className="col-12">
                    <table className="table table-striped">
                        <thead className="border-top">
                        <tr key={"wg-0"}>
                            <th>Product Phase</th>
                            <th>Product</th>
                            <th>Unit</th>
                            <th>Rate per Unit</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        {wagesViewList &&
                            wagesViewList.map((wg: WageConfigView, index: number) => {
                                return (
                                    <tr key={"wg" + index}>
                                        <td>{wg.Machine.PlantDetails.PlantName} -&gt; {wg.Machine.MachineDetails.MachineName}</td>
                                        <td>{wg.Product?.ProductName}</td>
                                        <td>1 {wg.UnitMeasure.BulkMeasureName} / {wg.UnitMeasure.RetailQty} {wg.UnitMeasure.RetailMesaureName}</td>
                                        <td>{wg.WageConfig.WagePerUnit}</td>
                                        <td>
                                            <button className="btn-danger btn-simple btn-sm float-right"
                                                    onClick={() => DeleteWage(wg.WageConfig.PSID)}>Delete
                                            </button>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                        </tbody>
                    </table>
                </div>
            </div>


        </>
    );
}
export default WagesConfig;
