
export const CLASS_INPUT_ERROR = ' input-error';
export const DATE_FORMAT = 'DD-MMM-YYYY';

export const PROCESS_STATUS_START = "START";
export const PROCESS_STATUS_PROCESSING = "PROCESSING";
export const PROCESS_STATUS_WAITING = "WAITING";
export const PROCESS_STATUS_END = "END";

export const DATATYPE_STRING = "string";
export const DATATYPE_INT = "int";

export const OPERATOR_EQUAL = 'eq';
export const OPERATOR_NOT_EQUAL = 'neq';
export const OPERATOR_GREATER = 'gt';
export const OPERATOR_LESSER = 'lt';
export const OPERATOR_CONTAINS = 'in';
export const OPERATOR_NOT_CONTAINS = 'nin';

export const CONTROL_ERROR_BACKGROUND_COLOR = '#fdf1f0';
export const CONTROL_ERROR_BORDER_COLOR = 'red';

export const CALL_RESPONSE_STATUS_OK = 0;
export const CALL_RESPONSE_STATUS_ERROR = 1;
export const PAY_MODE_CASH = "CASH";

export enum ProductTransitReasons {
    SPLIT = "SPLIT",
    BETWEEN_STORES = "BETWEEN_STORES",
    OPENING = "OPENING",
}

export enum InvoiceColumns {
    ISPAID = "paid",
    ISWHITE = "white",
    EXPORTABLE = "export",
}

export enum PopTagOwner {
    SaleBill = 'SALEBILL',
    InwardBill = 'INWARDBILL'
}

export enum TagTypes {
    PRODUCT_GROUP = 1,
    TRANSACTION_GROUP = 2,
    SUPPLIER_GROUP = 3,
}

export enum EntityTypes {
    SUPPLIER = 1,
    CUSTOMER = 2,
    BROKER = 3

}

export enum ReportSettingsType {
    LEDGERS_SUMMARY= 'LEDGERS_SUMMARY'
}

export const CurrencyRegionMappingMaster: any = {
    INR: 'en-IN',
    USD: 'en-US',
    EUR: 'fr-FR',
    GBP: 'en-GB',
    AED: 'en-US',
    SAR: 'en-US',
    // AED:'ar-AE',
    // SAR:'ar-SA',
};

export const QuillTabBinding = {
    keyboard: {
        bindings: {
            tab: {
                key: 9,
                handler: function () {
                    return true;
                }
            }
        }
    }
};


