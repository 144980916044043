import Select, {components, OptionProps} from "react-select";
import * as helper from "../sales/SaleInoviceHelper";
import React, {useEffect, useState} from "react";
import {ML_Customer} from "../globals/dto";
import * as cdb from "../db/CustomersData";

type attributes = {
    isDisabled : boolean,
    customerSelected : ML_Customer,
    onCustomerChanged(c:ML_Customer):void
}

//INPUT :
//  props.isDisabled (true / false0
//  props.customerSelected : ML_CUSTOMER
//OUTPUT:
    //props.customerChanged(ML_Customer)
export const CustomerSelect =(props:attributes)=> {

    const [customers, setCustomers] = useState(new Array<ML_Customer>());
    //const [customerSelected, setCustomerSelected] = useState(props.customerSelected);
    const [customerLoading, setCustomerLoading] = useState(false);

    useEffect(()=>{
        setCustomerLoading(true);
        cdb.GetAll().then(res=>{
            setCustomers(res.data);
            setCustomerLoading(false);
        });
    },[])

    const customerSingleOption = (cprops: any) => {
        return <components.Option {...cprops}>
            {cprops.data.CID === -1 &&<div style={{"borderBottom":"1px dashed gray", "paddingBottom":"7px"}}><b className="text-primary">{cprops.data.CompanyName}</b></div>}

            {cprops.data.CID > 0 && <>
                <b>{cprops.data.CompanyName}</b>
                {cprops.data.PrimaryContactPersonName &&
                <span>&nbsp;|&nbsp;<i>{cprops.data.PrimaryContactPersonName}</i></span>
                }
                {cprops.data.PrimaryPhones &&
                <span>&nbsp;|&nbsp;Ph:{cprops.data.PrimaryPhones}</span>
                }
            </>}
        </components.Option>
        }

    const customerSingleValue = (cprops: any) => (
        <components.SingleValue {...cprops}>
            <b>{cprops.data.CompanyName}</b>
        </components.SingleValue>
    )

    const customerCustomSelectFilter = (option: any, search: string) => {
        return option.data.CompanyName.toLowerCase().includes(search.toLowerCase());
    }

    return (
        <>
        <Select classNamePrefix="srushti"
        options={customers}
                isClearable={true}
                isDisabled={props.isDisabled}
                isLoading={customerLoading}
                value={props.customerSelected}
                //defaultValue={customers[0]}
                theme={theme => ({ ...theme, borderRadius: 0 })}
                components={{
                    Option: customerSingleOption,
                    SingleValue: customerSingleValue
                }}
                getOptionValue={option => option.CID.toString()}
                onChange={props.onCustomerChanged}
                filterOption={customerCustomSelectFilter}></Select>
        </>
    )



            }
