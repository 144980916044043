import axios from 'axios';
import * as global from '../globals/ApiUrlConsts';

const web_api_url = global.global_api_url_base + '/api/stocks';

export const GetAllStoresStocks = async () => {
    return await axios.get(web_api_url + '/status');
}

export const GetStoreStocks = async (compid) => {
    return await axios.get(web_api_url + '/GetOnCompanyID?cpid=' + compid);
}

export const GetAllProductsWithStocks = async () => {
    return await axios.get(web_api_url + '/GetAllProductsWithStocks');
}

export const GetAllProductsWithStocksByStore = async (cmpid) => {
    return await axios.get(web_api_url + '/GetAllProductsWithStocksOnStoreID?compID=' + cmpid);
}

export const GetOnProductIDCompanyID = async (cmpid, prdid) => {
    return await axios.get(web_api_url + '/GetOnProductIDCompanyID',
        { params: { cpid: cmpid, prdid: prdid } });
}

export const StockBatchWiseSold = async (saleBillLineItemID) => {
    return await axios.get(web_api_url + '/StockBatchWiseSold',
        { params: { saleBillLineItemID } });
}

export const StockBatchWiseOnHand = async (companyId, productID) => {
    return await axios.get(web_api_url + '/StockBatchWiseOnHand',
        { params: { companyId, productID } });
}

export const SplitStock = async (compid, basePrdID, baseProdStockId, inBlkQty, targetPrdId, splitQty) =>
    await axios.post(web_api_url + '/SplitStock', null, { params: { compid, basePrdID, baseProdStockId, inBlkQty, targetPrdId, splitQty } })


