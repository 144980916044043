import React, {useEffect, useState} from 'react';
import * as AppAct from '../globals/AppActions';
import {useDispatch, useSelector} from "react-redux";
import * as rpt from '../db/ReportsData';
import * as utils from '../globals/Utils';
import * as dateUtils from '../globals/AppDateUtils';
import DateRangeControlEx from "../globals/DateRangeControlEx";
import {AppReduxState} from '../infra-redux/AppStateReduxStore';
import {SaleInvoiceView} from '../sales/SaleInvoiceView';
import {ML_Company, ML_Customer, ML_SaleBill, ML_SaleBillLine, ML_Transaction} from '../globals/dto';
import {PopTags} from '../globals/PopTags/PopTags';
import {FunnelOutline} from 'react-ionicons';
import {SaleBillsFilter} from './FilterUI/SaleBillFilters';
import {InvoiceViewer} from "../globals/InvoiceViewer";
import {FiltersAppliedInfo} from "./FilterUI/FiltersAppliedInfo";
import {BadgeWhiteUI } from '../globals/AppControls/BadgeWhiteUI';

class ResultData {
    PayLine: ML_SaleBillLine;
    Trans: ML_Transaction;
    Customer: ML_Customer;
    SaleBill: ML_SaleBill;
    Store : ML_Company;
}

const CashSalesList = (props: any) => {

    const [list, setList] = useState<ResultData[]>([]);
    const [start, setStart] = useState(dateUtils.MinusDays(new Date(), 1));
    const [end, setEnd] = useState(new Date());
    const [grandTotal, setGrandTotal] = useState(0);
    const [grandQtyTotal, setGrandQtyTotal] = useState(0);
    const [showBillNumber, setShowBillNumber] = useState(0);

    const [dataFilters, setDataFilters] = useState<any>({});
    const [showFilters, setShowFilters] = useState(false);

    const AppDisp = useDispatch();
    const reduxState = useSelector((state: AppReduxState) => state);

    useEffect(() => {
        AppDisp(AppAct.ChangeMainTitle('Cash Sales Statement'));
        LoadTrans(start, end,null);
    }, []);

    const LoadTrans = (st: Date, ed: Date, filter:any) => {
        st = dateUtils.SanitizeDate(st);
        ed = dateUtils.SanitizeDate(ed);
        let title = 'Cash Sales : ' + dateUtils.FormatDate(st) + " - to - " + dateUtils.FormatDate(ed);
        console.log(title);

        AppDisp(AppAct.ChangeMainTitle(title));
        AppDisp(AppAct.ToggleProgress(true));
        let storeids:[] = filter?.stores?.map((d:ML_Company)=> d.CMPID);
        let req = {
            start:st,
            end:ed,
            stores:storeids,
            showCashBillsOnly:filter?.showCashBillsOnly,
            showCreditBillsOnly : filter?.showCreditBillsOnly,
        };
        rpt.getCashSalesList(req).then((res: any) => {
            setList(res.data);
            setGrandTotal(res.data.reduce((total: number, cv: any) => {
                return total + cv.Trans.TotalAmount;
            }, 0));

            setGrandQtyTotal(res.data.reduce((total: number, cv: any) => {
                return total + cv.SaleBill.TotalBulkQty;
            }, 0));

            AppDisp(AppAct.ToggleProgress(false));
        });
    }

    const handleDateChange = (sd: Date, ed: Date) => {
        setStart(sd);
        setEnd(ed);
        LoadTrans(sd, ed,dataFilters);
    }

    const showBill = (sbid: number) => {
        setShowBillNumber(sbid);
    }
    
    const getNewFilters = (vals:any)=> {
        console.log('updating filters')
        setShowFilters(false);
        setDataFilters(vals);
        LoadTrans(start,end,vals);
    }

    if(!list || list === null) return <></>

    return (<>
            <InvoiceViewer saleBillID={showBillNumber} inwardID={0} onClose={()=>{setShowBillNumber(0)}}/>

            {showFilters && 
            <SaleBillsFilter 
                filterOptions={dataFilters} 
                onFilterUpdate={getNewFilters}
                onClose={()=>setShowFilters(false)}
            />
            }

            <div className="row">
                <div className="col-md-6">
                    <DateRangeControlEx
                        start={start} end={end}
                        onChange={handleDateChange}/>
                </div>
                <div className='col-md-6 text-right'>
                    <button
                        className={'btn btn-az-primary pr-3'}
                        accessKey={"r"}
                        onClick={()=>setShowFilters(true)}
                    >
                        <FunnelOutline style={{'paddingBottom': '2px', 'marginRight': '2px'}} color={'white'}/>
                        Filte<u>r</u>s
                    </button>
                </div>
            </div>
            <div className="table-responsive mt-3">
                <table className="table table-hover">
                    <thead className="border-top">
                    <tr key={"head0"}>
                        <th key={"hd21"} className="wd-200-f">Date</th>
                        <th key={"hd26"}>Bill#</th>
                        {/*<th key={"hd27"}>Store</th>*/}
                         <th key={"hd22"}>Customer</th>
                        <th key={"hd23"} className="text-right">Qty</th>
                        <th key={"hd25"} className="text-right w-25">Amount</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr key={"bdt001"}>
                        <td key={"bd20"} colSpan={2}><h5
                            className="font-weight-bold">Count: {list.length}</h5></td>
                        <td key={"db21"} colSpan={2} className="text-right"><h5
                            className="font-weight-bold">Qty: {grandQtyTotal}</h5></td>
                        <td key={"bd22"} className="text-right">
                            <h5 className="font-weight-bold mb-0">{utils.formatCurrencyValue(reduxState, grandTotal)}</h5>
                            Grand Total
                        </td>
                    </tr>
                    </tbody>
                    <tbody>
                    {list.map((t: ResultData, idx: number) => {
                        return (
                            <>
                                <tr key={'tr' + idx}>
                                    <td key={"t1" + idx}>{dateUtils.FormatDateTime(t.SaleBill.BillingDate)}</td>
                                    <td key={"t7" + idx}  className="pointer-cursor text-indigo" onClick={()=>showBill(t.SaleBill.SBID)}>{t.SaleBill.BillNumber}</td>
                                    {/*<td key={"t8"+idx}>{t.Store.CompanyName}</td>*/}
                                    <td key={"t6" + idx}>
                                        {/* <small className="badge badge-light bg-white border-dark border">{t.Store.CompanyName}</small> */}
                                        <BadgeWhiteUI title={t.Store.CompanyName}/>
                                        &nbsp;
                                        <span dangerouslySetInnerHTML={{ __html: utils.DeriveCustomerName(t.SaleBill,t.Customer) }}></span>
                                    </td>
                                    {/* <td key={"t2" + idx}>{parse(t.SaleBill.PhantomCustomer)}</td> */}
                                    <td key={"t3" + idx} className="text-right">{t.SaleBill.TotalBulkQty}</td>
                                    <td key={"t5" + idx}
                                        className="text-right font-weight-bold">{utils.formatCurrencyValue(reduxState, t.Trans.TotalAmount)}
                                    </td>
                                </tr>
                            </>
                        )
                    })}
                    {/*<tr>*/}
                    {/*    <td colSpan={4} className="text-right font-weight-bold">Total Qty: {groupPrevQtyTotal}</td>*/}
                    {/*    <td colSpan={2} className="text-right font-weight-bold">Total Amount: {groupProvTotal}</td>*/}
                    {/*</tr>*/}
                    </tbody>
                    <tfoot>
                    <tr key={"ft001"}>
                        <td key={"ft20"} colSpan={2}><h5
                            className="font-weight-bold">Count: {list.length}</h5></td>
                        <td key={"ft21"} colSpan={2} className="text-right"><h5
                            className="font-weight-bold">Qty: {grandQtyTotal}</h5></td>
                        <td key={"ft22"} className="text-right">
                            <h5 className="font-weight-bold mb-0">{utils.formatCurrencyValue(reduxState, grandTotal)}</h5>
                            Grand Total
                        </td>
                    </tr>
                    </tfoot>
                </table>
            </div>
            <div>
                <FiltersAppliedInfo filters={dataFilters}/>
            </div>
        </>
    )
}

export default CashSalesList;
