import React, { useState } from 'react';
import { toast } from "react-toastify";
import { DateControlEx } from "../globals/DateControlEx";
import LedgerDetails from "../ledgers/LedgerDetails";
import { ML_Ledger, ML_Tag, ML_Transaction } from "../globals/dto";
import LedgerWithStatsDropDownControl from '../globals/LedgerWithStatsDropDownControl';
import TextBoxNumeric from '../ui-controls/TextBoxNumeric';
import { Formik } from 'formik';
import * as yup from 'yup';
import { SaveButtonUI } from '../globals/AppControls/SaveButtonUI';
import { CloseButtonUI } from '../globals/AppControls/CloseButtonUI';
import { TransactionCategoryDropdown } from '../Transactions/TransactionCategoryDropdown';
import * as consts from '../globals/GlobalConsts';
//properties to pass
//trans = ML_Transaction
//deleted()
//closed()
//saved(ML_Transaction)

enum FormFields {
    //LedgerID = "LedgerID",
    PayMode = "PayMode",
    TotalAmount = "TotalAmount",
    Details = "Details",
    TransDate = "TransDate",
    IsExpense = "IsExpense",
    CategoryID = "CategoryID",
    FromLedgerID = "FromLedgerID"
}

export const TransactionEntry = (props: {
    trans:ML_Transaction,
    saved(values:any):void,
    closed():void,
    isLedgerEditable:boolean,
    LedgerLabel : string,
}) => {

    const [showAddNewLedger, setShowAddNewLedger] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);

    const validationSchema = yup.object({
        [FormFields.FromLedgerID]: yup.number().required().moreThan(0),
        [FormFields.TotalAmount]: yup.number().required().moreThan(0),
        [FormFields.CategoryID]: yup.number().required().moreThan(0)
    });

    const closeLedgerDetails = (tog: boolean) => {
        setShowAddNewLedger(false);
    }

    if (showAddNewLedger)
        return <LedgerDetails ledger={() => new ML_Ledger()} close={closeLedgerDetails} />

    return (
        <>
            <Formik
                initialValues={props.trans}
                validationSchema={validationSchema}
                onSubmit={(values: any, action: any) => {
                    console.log('sumitted');
                    setIsSubmitted(false);
                    if (!values.PayMode)
                        values.PayMode = consts.PAY_MODE_CASH;
                    props.saved(values);
                }}
            >
                {(formikProps) => (<>

                    <div className="row form-group">
                        <div className="col-md-5">
                            <label className="form-label">Date of Expense</label>
                            <DateControlEx
                                datevalue={formikProps.values[FormFields.TransDate]}
                                onDateChange={(e: string) => formikProps.setFieldValue(FormFields.TransDate, e)} />
                        </div>
                    </div>
                    <div className="row form-group">
                        <div className="col-12">
                            {/* <label className="form-label">
                                {formikProps.values[FormFields.IsExpense] && <span>Expense Ledger</span>}
                                {!formikProps.values[FormFields.IsExpense] && <span>Deposit to Ledger</span>}
                            </label> */}
                            <label className="form-label">
                                {props.LedgerLabel ? props.LedgerLabel : 'Ledger'}
                            </label>
                            <LedgerWithStatsDropDownControl
                                isError={formikProps.errors[FormFields.FromLedgerID] && isSubmitted}
                                isEditable={props.isLedgerEditable}
                                selectLedgerId={formikProps.values[FormFields.FromLedgerID]}
                                onLedgerSelected={(e: any) =>{
                                    formikProps.setFieldValue(FormFields.FromLedgerID, e?.Ledger.LDGID)
                                }}  />
                        </div>
                    </div>
                    <div className="row form-group">
                        <div className="col-md-12">
                            <label className="form-label">Purpose</label>
                            <TransactionCategoryDropdown 
                            isError={formikProps.errors[FormFields.CategoryID] && isSubmitted}
                            catId={formikProps.values[FormFields.CategoryID]}
                                onSelected={(e:ML_Tag)=> {
                                    formikProps.setFieldValue(FormFields.CategoryID,e.GRPID)
                                }
                            } />
                        </div>
                    </div>
                    <div className="row form-group">
                        <div className="col-md-12">
                            <label className="form-label">More Details (if any)</label>
                            <input type="text"
                                className={"form-control " + (formikProps.errors[FormFields.Details] && isSubmitted ? 'err' : '')}
                                name={"Details"}
                                value={formikProps.values[FormFields.Details]}
                                onChange={formikProps.handleChange(FormFields.Details)}
                            />
                        </div>
                    </div>
                    <div className="row form-group">
                        <div className="col-md-6">
                            <label className="form-label">Payment Mode</label>
                            <select
                                className={"form-select " + (formikProps.errors[FormFields.PayMode] && isSubmitted ? 'err' : '')}
                                name={"PayMode"}
                                value={formikProps.values[FormFields.PayMode]}
                                onChange={formikProps.handleChange(FormFields.PayMode)}
                            >
                                <option value="CASH">Cash</option>
                                <option value="CHQ">Cheque</option>
                                <option value="DD">DD</option>
                                <option value="NET">NET</option>
                            </select>
                        </div>
                        <div className=" col-md-6">
                            <label className="form-label">Amount</label>
                            <TextBoxNumeric
                                className={"form-control " + (formikProps.errors[FormFields.TotalAmount] && isSubmitted ? 'err' : '')}
                                name={"TotalAmount"}
                                value={formikProps.values[FormFields.TotalAmount]}
                                onChange={formikProps.handleChange(FormFields.TotalAmount)}
                            />
                        </div>
                    </div>

                    <div className="d-flex flex-row justify-content-between mt-4">
                        <SaveButtonUI className="mr-3" onClick={() => {
                            setIsSubmitted(true);
                            formikProps.submitForm();
                            if (!formikProps.isValid)
                                toast.error('Enter missing fields');
                        }} />
                        <CloseButtonUI onClick={props.closed} />
                    </div>

                    {/*<div className="row form-group mb-0 mt-4">*/}
                    {/*    <div className={"col-md-12 text-center"}>*/}
                    {/*        <input type="submit" className="btn btn-success mr-3" value="Save" onClick={() => {*/}
                    {/*            setIsSubmitted(true);*/}
                    {/*            if (!formikProps.isValid)*/}
                    {/*                toast.error('Enter missing fields');*/}
                    {/*            else*/}
                    {/*                formikProps.submitForm();*/}
                    {/*        }} />*/}
                    {/*        <input type="button" className="btn btn-primary" value="Cancel" onClick={props.closed} /> */}
                    {/*    </div>*/}
                    {/*</div>*/}
                </>)}
            </Formik>

        </>
    );

}
