import React, { useEffect, useState } from 'react';
import * as AppAct from '../globals/AppActions';
import { mapStateToProps } from '../globals/AppActions';
import { InwardLineItemView, InwardView } from "../globals/dto";
import { useDispatch, useSelector } from "react-redux";
import * as idb from '../db/InwardsData';
import InwardVerify from "./InwardVerify";
import cloneDeep from 'lodash/cloneDeep';
import TransitManager from "../workflows/TransitManager";
import { Modal } from 'react-bootstrap';
import InwardInvoiceEntry from "./InwardInvoiceEntry";
import *  as utils from '../globals/Utils';
import { WorkflowSelectorDropDown } from '../shared/WorkflowSelectorDropDown';
import { ToggleOn } from 'react-bootstrap-icons';
import * as dateUtils from '../globals/AppDateUtils';
import { AppReduxState } from '../infra-redux/AppStateReduxStore';

const InwardBayProduction = (props: any) => {

    const dispatch = useDispatch();
    const appSharedState = useSelector((state: AppReduxState) => state.appReduxer);

    const [state, setState] = useState({
        bayInwards: new Array<InwardView>(),
        inwardSelected: new InwardView(),

        workflowID: utils.GetGlobalSettingsObject(appSharedState.GlobalSettings).GlobalWorkflowId,
        lineItemID: 0
    });
    const [showModal, setShowModal] = useState(false);
    const [showTransitModal, setShowTransitModal] = useState(false);

    useEffect(() => {
        GetBayAreaInfo();
    }, [])

    const GetBayAreaInfo = () => {
        dispatch(AppAct.ToggleProgress(true));
        idb.GetBayInwards().then((res: any) => {
            console.log(res.data);
            // res.data.forEach((x: InwardView) => {
            //     x.InwardEntry.InvoiceReceivedDate = new Date(x.InwardEntry.InvoiceReceivedDate);
            // });
            dispatch(AppAct.ChangeMainTitle("Invoices Pending for Processing"));
            setState({ ...state, bayInwards: [...res.data] });
            dispatch(AppAct.ToggleProgress(false));
        });
    }

    const IsInwardVerified = (lineItems: InwardLineItemView[]): boolean => {
        var total = 0;
        lineItems.forEach((x) => {
            if (x.LineItem.InternalQty > 0) {
                total = total + 1;
            }
        });
        return total === lineItems.length;
    }

    const CanShowReverify = (lineItems: InwardLineItemView[]): boolean => {
        let processing = 0;
        lineItems.forEach(x => {
            if (x.LineItem.InProcessQty > 0 || x.LineItem.ProcessedQty > 0)
                ++processing;
        });
        console.log("processing:" + processing);
        return !(processing > 0);
    }

    const HasAwaitingItems = (t: InwardLineItemView): boolean => {
        if (IsInwardVerified) {
            let awaiting = t.LineItem.InternalQty - (t.LineItem.InProcessQty + t.LineItem.ProcessedQty)
            if (t.LineItem.InternalQty > 0 && awaiting === 0)
                return false;
            else
                return true;
        }
        return false;
    }

    const verifyInward = (inw: InwardView) => {
        setState({ ...state, inwardSelected: inw });
        setShowModal(true);
    }

    const closeModal = (refreshList: boolean) => {
        setShowModal(false);
        if (refreshList)
            GetBayAreaInfo();
    }

    const moveIwardToMachine = (lineItemID: number) => {
        setState({
            ...state,
            lineItemID: lineItemID
        });
        setShowTransitModal(true);
    }

    const ToggleTransitModal = (tog: boolean) => {
        setShowTransitModal(tog);
    }

    const closeTransitModal = () => {
        setShowTransitModal(false);
        GetBayAreaInfo();
    }

    const changeWorkflow = (id: number) => {
        console.log("InwardBayProduction ~ id", id);
        setState({ ...state, workflowID: id });
    }
    if (!state.bayInwards) return <></>

    return (<>
        <Modal show={showTransitModal} size={"lg"} dialogClassName="modal-corner">
            <TransitManager processID={0}
                workflowID={state.workflowID}
                lineItemID={state.lineItemID}
                close={closeTransitModal}
            />
        </Modal>

        {showModal &&
            <InwardVerify
                showModal={showModal}
                IsVerified={IsInwardVerified}
                onClose={closeModal}
                inw={cloneDeep(state.inwardSelected)} />
        }

        <div className="az-contact-label font-medium font-weight-bold text-black pb-3 pt-0 mt-0">
            Invoices Pending for Processing: {state.bayInwards.length}
        </div>
        {/*<Modal show={state.showModal}
                   onHide={()=>closeModal(false)}
                   backdrop={'static'}>

                {state.modalBar && <AppProgressBar/>}
                <ModalHeader className="bg-gray-200"><h3 className="pb-0 mb-0">Verify Inward Delivery</h3></ModalHeader>
                <ModalBody>
                    <InwardVerify key={util.uuidv4()}
                                  onClose={closeModal}
                                  toggleAppBar={toggleModalBar}
                                  inw={cloneDeep(state.inwardSelected)} />
                </ModalBody>
            </Modal>*/}

        {state.bayInwards.map((a: InwardView, idx: number) => {
            const isVerified = IsInwardVerified(a.LineItems);
            const shouldShowReVerify = CanShowReverify(a.LineItems);
            return (
                <div className="card mb-3 shadow-sm" key={'a' + idx}>
                    <div className="card-body pt-0">
                        <div className="row pt-1 align-items-center">
                            <div className="col-10">
                                <div className="az-contact-item pl-0 pt-3" style={{ cursor: "default" }} >
                                    <div className="az-avatar-nodot bg-gray-600">{a.SupplierDetails.CompanyName.charAt(0).toUpperCase()}</div>
                                    <div className="az-contact-body">
                                        <h6 className="font-weight-bold text-black-50">{a.SupplierDetails.CompanyName}</h6>
                                        <h5 className="mb-0 pb-0">Inward
                                            No: {a.InwardEntry.SupplierInoviceNumber}</h5>
                                        {/* <span>Date: { dateUtils.SanitizeDate(a.InwardEntry.InvoiceReceivedDate)}</span> */}
                                        <br />
                                        {isVerified ?
                                            (<div className="tx-success font-weight-bold">
                                                <i className="icon ion-md-checkmark-circle tx-15-f"></i>&nbsp;Verified
                                            </div>)
                                            : (<div className="tx-danger font-weight-bold">
                                                <i className="icon ion-md-close-circle tx-15-f"></i>&nbsp;Not
                                                Verified
                                            </div>)
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-2">
                                {(!isVerified) ? (
                                    <button onClick={() => verifyInward(a)}
                                        className="btn btn-primary float-right text-white mt-2">Verify</button>)
                                    : (
                                        (shouldShowReVerify) ?
                                            <button onClick={() => verifyInward(a)} className="btn btn-secondary float-right mt-2">Re-Verify</button>
                                            : <></>
                                    )
                                }
                            </div>

                            <div className="col-12">
                                <table className="table table-bordered mt-3 mb-0">
                                    <thead className="border-top">
                                        <tr>
                                            {isVerified && <th>Production</th>}
                                            <th>Item</th>
                                            <th>Billed Qty</th>
                                            <th className="text-center">Verified</th>
                                            <th className="text-center">Awaiting</th>
                                            <th className="text-center">Processing</th>
                                            <th className="text-center">Finished</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {a.LineItems.map((t: InwardLineItemView, vdx: number) => {
                                            return (
                                                <>
                                                    <tr key={vdx}>
                                                        {isVerified && <td className="wd-350-f">
                                                            {t.LineItem.BulkQty > t.LineItem.InProcessQty && t.LineItem.BulkQty > t.LineItem.ProcessedQty && HasAwaitingItems(t) &&
                                                                <div className="input-group">
                                                                    <WorkflowSelectorDropDown
                                                                        selectedId={state.workflowID}
                                                                        onChange={changeWorkflow}
                                                                    />
                                                                    <div className="input-group-append">
                                                                        <button className="btn btn-warning"
                                                                            onClick={() => moveIwardToMachine(t.LineItem.INLID)}>
                                                                            Start Processing
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            }
                                                        </td>}
                                                        <td className="align-middle text-center">{t.LineProduct.Product.ProductName}</td>
                                                        <td className="align-middle text-center">{t.LineItem.BulkQty}&nbsp;{t.LineProduct.UnitMeasure.BulkMeasureName}</td>
                                                        <td className="font-weight-bold text-center align-middle">{t.LineItem.InternalQty}</td>
                                                        <td className="font-weight-bold text-center align-middle text-danger">{t.LineItem.InternalQty - (t.LineItem.InProcessQty + t.LineItem.ProcessedQty)}</td>
                                                        <td className="font-weight-bold text-center align-middle text-indigo">{t.LineItem.InProcessQty}</td>
                                                        <td className="font-weight-bold text-center align-middle text-success">{t.LineItem.ProcessedQty}</td>
                                                    </tr>
                                                </>
                                            )
                                        })}
                                    </tbody>
                                </table>


                                {/*{a.LineItems.map((t: InwardLineItemView, vdx : number) => {
                                                    return (

                                                        <div className="d-inline-block pl-1 pr-3" key={'v'+idx+'_'+vdx}>
                                                            <div className="d-inline-flex align-items-center border p-2 mt-2 mb-2">
                                                                <div className="mr-2">
                                                                    <label
                                                                        className="mg-b-0">{t.LineProduct.Product.ProductName}</label>
                                                                    <h4 className="mg-b-0">
                                                                        {t.LineItem.BulkQty}&nbsp;
                                                                    </h4>
                                                                    <p className="font-small mb-1">{t.LineProduct.UnitMeasure.BulkMeasureName}</p>
                                                                </div>
                                                                {t.LineItem.InternalQty > 0 &&
                                                                <div className="align-self-center  mr-2">
                                                                    <i className="fas fa-angle-right "></i>
                                                                </div>}

                                                                {t.LineItem.InternalQty > 0 &&
                                                                <div className=" mr-2">
                                                                    <label className="mg-b-0 tx-11-f">Verified</label>
                                                                    <h4 className="mg-b-0 font-weight-bold text-primary">
                                                                        {t.LineItem.InternalQty}
                                                                    </h4>
                                                                    <p className="font-small mb-1">Bags</p>
                                                                </div>}
                                                                {t.LineItem.InProcessQty > 0 &&
                                                                <div className="align-self-center mr-2">
                                                                    <i className="fas fa-angle-right"></i>
                                                                </div>
                                                                }
                                                                {t.LineItem.InProcessQty > 0 &&
                                                                <div className="pointer-cursor">
                                                                    <label className="mg-b-0 tx-11-f">Processing</label>
                                                                    <h4 className="mg-b-0 font-weight-bold text-warning">
                                                                        {t.LineItem.InProcessQty}
                                                                    </h4>
                                                                    <p className="font-small mb-1">Units</p>
                                                                </div>}
                                                                {t.LineItem.ProcessedQty > 0 &&
                                                                <div className="align-self-center mr-2">
                                                                    <i className="fas fa-angle-right"></i>
                                                                </div>}
                                                                {t.LineItem.ProcessedQty > 0 &&
                                                                <div>
                                                                    <label className="mg-b-0 tx-11-f">For Sale</label>
                                                                    <h4 className="mg-b-0 font-weight-bold text-success">
                                                                        {t.LineItem.ProcessedQty}
                                                                    </h4>
                                                                    <p className="font-small mb-1">Units</p>
                                                                </div>}
                                                            </div>

                                                            <div>
                                                                {t.LineItem.InProcessQty < t.LineItem.InternalQty &&
                                                                <span
                                                                    className="badge badge-warning text-white pointer-cursor">Process</span>
                                                                }
                                                                &nbsp;
                                                            </div>
                                                        </div>
                                                    )
                                                })}*/}
                            </div>


                        </div>

                    </div>
                </div>
            )
        })}
    </>)

}

export default InwardBayProduction;
