import {configureStore} from '@reduxjs/toolkit';
import _ from 'lodash';
import { AppRedux } from './AppRedux';

export const AppStateReduxStore = configureStore({
    reducer:{
        appReduxer : AppRedux.reducer
    }
})

export type AppReduxState = ReturnType<typeof AppStateReduxStore.getState>;

export class StateToggleWithID{

    constructor(_tog:boolean, _id:number){
        this.id = _id;
        this.toggle = _tog;
    }

    toggle = false;
    id = 0;
    
}