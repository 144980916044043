import React, { useEffect, useState } from 'react';
import { InvoiceExpenseView, ML_Transaction } from "../globals/dto";
import * as utils from "../globals/Utils";
import * as dateUtils from '../globals/AppDateUtils';
import ModalHeader from "react-bootstrap/ModalHeader";
import { Modal, ModalBody } from "react-bootstrap";
import { TransactionEntry } from "./TransactionEntry";
import * as factory from '../globals/factory';
import { AddNewButtonUI } from '../globals/AppControls/AddNewButtonUI';
import * as consts from '../globals/GlobalConsts';


export const ExpenseSection = (props: any) => {

    const [trans, setTrans] = useState(props.trans); //collection of InvoiceExpenseView[]
    const [tranEntry, setTranEntry] = useState<ML_Transaction>(); //selected ML_Transaction entry
    const [expenseEntry, setExpenseEntry] = useState<InvoiceExpenseView>(); //selected InvoiceExpenseView
    const [isDeductSwitch, setIsDeductSwitch] = useState(false);
    const [showPopUp, setShowPopUp] = useState(false);
    const [editingIndex, setEditingIndex] = useState(-1);
    const [grandTotal, setGrandTotal] = useState(0);

    useEffect(() => {
        if (trans && trans.length > 0) {
            let total = 0;
            trans.map((m: InvoiceExpenseView) => total += +m.Transaction.TotalAmount);
            setGrandTotal(total);
            props.getLatest(trans);
        }
        setExpenseEntry(factory.createInvoiceExpenseEntry());
    }, [showPopUp]);

    const popUpExpense = (exp: InvoiceExpenseView, eidx: number) => {
        setEditingIndex(eidx);
        console.log(eidx);
        let tx = factory.createTransactionML();

        if (eidx > -1) {
            tx = trans[eidx].Transaction;
            setExpenseEntry(exp);

            //This much code is required as there can 'null' value for exp.IsDeductInInvoice :(
            if (exp.IsDeductInInvoice === 1)
                setIsDeductSwitch(true);
            else
                setIsDeductSwitch(false);
        } else {
            tx.PayMode = consts.PAY_MODE_CASH;
            tx.IsExpense = true;
            setExpenseEntry(factory.createInvoiceExpenseEntry());
        }
        setTranEntry(tx);
        setShowPopUp(true);
    }

    const expenseSaved = (exp: ML_Transaction) => {
        if (exp) exp.IsExpense = true;

        exp.LedgerID = exp.FromLedgerID; //Trans pass selected ledger ID on FromLedgerID
        exp.FromLedgerID = 0; //used only during ledger-to-ledger trans
        expenseEntry.IsDeductInInvoice = isDeductSwitch ? 1 : 0;

        if (editingIndex > -1) {
            trans[editingIndex].Transaction = exp;
            trans[editingIndex].IsDeductInInvoice = expenseEntry.IsDeductInInvoice;
            setTrans(trans);
        } else {
            // let newExp = factory.createInvoiceExpenseEntry();
            // newExp.Transaction = exp;
            expenseEntry.Transaction = exp;
            setTrans([...trans, expenseEntry]);
        }

        setShowPopUp(false);
    }

    const handleDeductInBill = (e: any) => {
        //setTranEntry((prev:InvoiceExpenseView) => { return { ...prev, IsDeductInInvoice : (tranEntry.IsDeductInInvoice === 1) ? 0 : 1}});

        setExpenseEntry({ ...expenseEntry, IsDeductInInvoice: (isDeductSwitch) ? 0 : 1 });
        // trans[editingIndex] = expenseEntry;
        // setTrans([...trans]);
        setIsDeductSwitch(!isDeductSwitch);
    }

    const expenseDelete = (exp: ML_Transaction) => {
    }

    return (
        <>
            <Modal show={showPopUp} dialogClassName="modal-corner"
                onHide={() => setShowPopUp(false)}
                backdrop={'static'}
                keyboard={false}>
                <ModalBody className="p-3">
                    <h3 className="pb-0 mb-0 az-content-label text-danger mb-4">Expense Entry</h3>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="custom-control custom-switch">
                                <input type="checkbox" className="custom-control-input" id="affectSwitch"
                                    checked={isDeductSwitch}
                                    onChange={handleDeductInBill}
                                />
                                <label className="custom-control-label" htmlFor="affectSwitch">Deduct this amount in the invoice total</label>
                            </div>
                            <hr />
                        </div>
                    </div>

                    <TransactionEntry
                        trans={tranEntry}
                        saved={expenseSaved}
                        LedgerLabel="Withdraw from Ledger"
                        isLedgerEditable={true}
                        closed={() => setShowPopUp(false)} />
                </ModalBody>
            </Modal>

            <div className="table-responsive">
                <table className="table table-bordered">
                    <thead className="border-top">
                        <tr key={utils.uuidv4()}>
                            <td>Date</td>
                            <td>Description</td>
                            <td>Pay Mode</td>
                            <td className="text-right">Amount</td>
                        </tr>
                    </thead>
                    <tbody>
                        {trans.length > 0 &&
                            trans.map((x: InvoiceExpenseView, index: number) => {
                                return (<tr key={index} className="pointer-cursor"
                                    onClick={() => popUpExpense(x, index)}>
                                    <td>{dateUtils.FormatDate(x.Transaction.TransDate)}</td>
                                    <td>{x.Transaction.Details}</td>
                                    <td>{x.Transaction.PayMode}</td>
                                    <td className="text-right font-weight-bold">{x.Transaction.TotalAmount}</td>
                                </tr>)
                            })}
                    </tbody>
                    <tfoot>
                        <tr key={utils.uuidv4()}>
                            <td colSpan={2}>
                                {/* <button className="btn btn-indigo" accessKey={"x"}
                                    onClick={() => popUpExpense(null, -1)}>+Add
                                    E<u>x</u>penses
                                </button> */}

                                <AddNewButtonUI
                                    buttonText="Ex<u>p</u>enses"
                                    accessKey={"p"}
                                    onClick={() => popUpExpense(null, -1)}
                                />
                            </td>
                            <td> Total</td>
                            <td className="text-right font-weight-bold">{grandTotal && <span>{grandTotal}</span>}</td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </>);
}
