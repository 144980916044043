import React, { useEffect, useState } from 'react';
import { ProductsDropDown } from "../products/ProductsDropDown";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { ML_Product, TaxAppliedDTO, TaxAppliedView, TaxView } from "../globals/dto";
import { TaxDropDown } from "../tax/TaxDropDown";
import * as factory from '../globals/factory';
import { SaveButtonUI } from '../globals/AppControls/SaveButtonUI';
import { CloseButtonUI } from '../globals/AppControls/CloseButtonUI';

export const InwardLineItem = (props: any) => {

    const [lineItemView, setLineItemView] = useState(props.lineItem);
    const [taxSelected, setTaxSelected] = useState(new TaxView());
    useEffect(() => {
        setTaxSelected(factory.toTaxView(lineItemView.TaxDetails?.TaxSetting));
    }, []);

    useEffect(() => {
        calcTotal();
    }, [taxSelected?.TaxSetting?.TXID])

    const onSave = () => {
        if (+lineItemView.LineProduct.Product.PRDID > 0
            && +lineItemView.LineItem.BulkQty > 0
            && +lineItemView.LineItem.Rate > 0) {
            props.saved(lineItemView);
        } else {
            toast.error("Enter all fields");
        }
    }

    const onClose = () => {
        props.closed();
    }

    const onDelete = () => {
        Swal.fire({
            title: 'Delete Line Item',
            html: "Are you sure want to delete it?",
            showCancelButton: true,
            confirmButtonColor: 'red',
            focusCancel: true,
        }).then((res) => {
            if (res.value) {
                props.deleted(lineItemView);
                props.closed();
            }
        })
    }

    const handleProductChagne = (p: any) => {
        let ip: ML_Product = p;
        lineItemView.LineProduct = ip;
        lineItemView.LineItem.ProductID = ip.PRDID;
        setLineItemView({ ...lineItemView });
    }

    const handleChange = (e: any) => {
        if (e.target.name != '') {
            lineItemView.LineItem[e.target.name] = e.target.value;
            setLineItemView({ ...lineItemView });
            calcTotal();
        }
    }

    const calcTotal = () => {
        if (lineItemView.LineProduct && lineItemView.LineProduct.UnitMeasure && lineItemView.LineItem.BulkQty > 0) {
            var LineTotal = lineItemView.LineItem.BulkQty * lineItemView.LineItem.Rate;
            lineItemView.LineItem.RetailQty = lineItemView.LineItem.BulkQty * lineItemView.LineProduct.UnitMeasure.RetailQty;

            if (LineTotal > 0 && lineItemView.LineItem.Rate) {
                //% discount
                if (+lineItemView.LineItem.DiscountPercentage > 0) {
                    lineItemView.LineItem.DiscountAmount = (LineTotal * +lineItemView.LineItem.DiscountPercentage) / 100;
                    lineItemView.LineItem.LineTotal = LineTotal - lineItemView.LineItem.DiscountAmount;
                }
                //Amount discount
                else if (+lineItemView.LineItem.DiscountAmount > 0) {
                    lineItemView.LineItem.LineTotal = LineTotal - lineItemView.LineItem.DiscountAmount;
                } else
                    lineItemView.LineItem.LineTotal = LineTotal;
            }

            //untaxed amount
            lineItemView.LineItem.AmountUntaxed = lineItemView.LineItem.LineTotal;
            lineItemView.LineItem.AmountTaxed = 0;

            //calculate tax on Untaxed amount
            if (taxSelected) {
                lineItemView.TaxDetails = new TaxAppliedView();
                lineItemView.TaxDetails.SlabsApplied = new Array<TaxAppliedDTO>();
                lineItemView.TaxDetails.TaxSetting = taxSelected.TaxSetting;
                taxSelected.Slabs?.forEach((x) => {
                    if (x.TaxValue != 0 && lineItemView.LineItem.AmountUntaxed != 0) {
                        let aptx = new TaxAppliedDTO();
                        aptx.TaxName = x.TaxName;
                        aptx.TaxValue = x.TaxValue;
                        aptx.TotalTaxedAmount = (lineItemView.LineItem.AmountUntaxed * aptx.TaxValue) / 100;
                        lineItemView.LineItem.AmountTaxed += aptx.TotalTaxedAmount;
                        lineItemView.TaxDetails.SlabsApplied.push(aptx);
                    }
                });

                //Complete total of Untaxed amount + taxes
                lineItemView.LineItem.LineTotal =
                    lineItemView.LineItem.AmountUntaxed +
                    lineItemView.LineItem.AmountTaxed;

                //save tax amounts as JSON for future reference
                if (lineItemView.LineItem.AmountTaxed != 0) {
                    lineItemView.LineItem.AmountTaxDetailsJson = JSON.stringify(lineItemView.TaxDetails);
                }

                setLineItemView({ ...lineItemView });
            }
        }
    }

    return (
        <>
            <div className="row form-group">
                <div className="col-12">
                    <label className="form-label">Item Product</label>
                    <ProductsDropDown onChange={handleProductChagne} product={lineItemView.LineProduct} showOnlyPurchasable={true} showWithStocks={false} />
                </div>
            </div>
            <div className="row form-group">
                <label className="col-md-3 form-label">Purchase Qty</label>
                <div className="col-md-9">
                    <div className="input-group">
                        <input type="text" className="form-control"
                            value={lineItemView.LineItem.BulkQty}
                            name={"BulkQty"}
                            onChange={handleChange} />
                        <div className="input-group-append">
                            <span
                                className="input-group-text">{lineItemView.LineProduct?.UnitMeasure?.BulkMeasureName}</span>
                            <span className="input-group-text bg-white">{lineItemView.LineItem.RetailQty}</span>
                            <span
                                className="input-group-text">{lineItemView.LineProduct?.UnitMeasure?.RetailMesaureName}</span>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row form-group">
                <label className="col-md-3 form-label">Rate</label>
                <div className="col-md-6">
                    <input type="text" className="form-control"
                        value={lineItemView.LineItem.Rate}
                        onChange={handleChange}
                        name={"Rate"}
                    />
                </div>
            </div>

            <div className="row form-group">
                <label className="col-md-3 form-label">Tax</label>
                <div className="col-md-6">
                    <TaxDropDown tax={taxSelected} onChange={(e: any) => {
                        setTaxSelected({ ...e });
                        /*calcTotal();*/
                    }} />
                </div>
            </div>
            <div className="row form-group">
                <label className="col-md-3 form-label">Discounts</label>
                <div className="col-md-9">
                    <div className="input-group-append">
                        <span className="input-group-text">%</span>
                        <input type="text" className="form-control"
                            name={"DiscountPercentage"}
                            value={lineItemView.LineItem.DiscountPercentage}
                            onChange={handleChange}
                        />
                        <span className="input-group-text font-weight-bold">OR</span>
                        <span className="input-group-text">Rs.</span>
                        <input type="text" className="form-control"
                            name={"DiscountAmount"}
                            value={lineItemView.LineItem.DiscountAmount}
                            onChange={handleChange}
                            disabled={lineItemView.LineItem.DiscountPercentage > 0} />

                    </div>
                </div>
            </div>

            <div className="row form-group mb-0">
                <label className="col-md-3 form-label">Total:</label>
                <div className="col-md-6">
                    <span className="form-control font-weight-bolder bg-white">
                        {lineItemView.LineItem.LineTotal}
                    </span>
                </div>

            </div>

            <div className="d-flex flex-row justify-content-between mt-4">
                {/* <div><button className="btn btn-success" accessKey={"s"}  onClick={onSave}><u>S</u>ave</button></div>
                        <div><button className="btn btn-gray-700" accessKey={"c"} onClick={onClose}><u>C</u>ancel</button></div> */}

                <SaveButtonUI onClick={onSave} className="mr-3" />
                <CloseButtonUI onClick={onClose} />
            </div>
            {/*<button className="btn btn-danger float-right" onClick={onDelete}>Delete</button>*/}

        </>);
}