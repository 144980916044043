import React, { useState } from 'react';
import { SaleBillView } from '../globals/dto';
import * as _ from 'lodash';
import { InvoiceMiscNotes } from '../globals/data.dto';
import { DateControlEx } from '../globals/DateControlEx';

type attributes = {
    saleBillView: SaleBillView,
    inputCheck: any,
    onChangeSaleBillView(sb: SaleBillView): void,
}

const BillDateNumberSection = (props: attributes) => {

    const GetMiscNotes = (): InvoiceMiscNotes => {
        if (props.saleBillView.SaleBill.MiscItemsJson && props.saleBillView.SaleBill.MiscItemsJson.trim().length > 0) {
            return JSON.parse(props.saleBillView.SaleBill.MiscItemsJson);
        }
        return new InvoiceMiscNotes();
    }

    const onDataChange = (name: string, value: any) => {

        let sb = _.clone(props.saleBillView);

        if (name === 'BillingDate') {
            sb.SaleBill.BillingDate = value;
        } else if (name === 'DeliveryNotes') {
            let misc = GetMiscNotes();
            misc.DeliveryNotes = value;
            sb.SaleBill.MiscItemsJson = JSON.stringify(misc);
        } else {
            sb.SaleBill = { ...sb.SaleBill, [name]: value };
        }

        props.onChangeSaleBillView(sb);
    }


    return (<>
        <div className="form-row">
            <div className="col-12 pl-md-5">
                <p className="invoice-info-row pl-md-5">
                    <label className="form-label font-normal pt-2 wd-200-f">Invoice No:</label>
                    <input type="text"
                        className="form-control disabled"
                        defaultValue={props.saleBillView.SaleBill.BillNumber} />
                </p>
            </div>
        </div>
        <div className="form-row">
            <div className="col-12 pl-md-5">
                <p className="invoice-info-row pl-md-5">
                    <label className="form-label font-normal pt-2 wd-200-f">Invoice Date:<span
                        className="text-danger">*</span></label>
                    <DateControlEx
                        datevalue={props.saleBillView.SaleBill.BillingDate}
                        onDateChange={(date: string) => {
                            onDataChange('BillingDate', date)
                        }} />
                </p>
            </div>
        </div>
        <div className="form-row">
            <div className="col-12 pl-md-5">
                <p className="invoice-info-row pl-md-5">
                    <label className="form-label font-normal pt-2  wd-200-f">Vehicle No:<span
                        className="text-danger"></span></label>
                    <input type="text"
                        className={"form-control " + props.inputCheck.DespatchedThroughErr}
                        name={"DispatchVehicleNumber"}
                        value={props.saleBillView.SaleBill.DispatchVehicleNumber}
                        onChange={e => onDataChange(e.target.name, e.target.value)} />
                </p>
                <hr />
            </div>
        </div>
        
        <div className="form-row mb-2 ">
            <div className="col-12 pl-md-5">
                <p className="invoice-info-row pl-md-5">
                    <label className="form-label font-normal pt-2 wd-200-f">Delivery Notes:</label>
                    <input type="text" className="form-control"
                        id="DeliveryNotes"
                        name={"DeliveryNotes"}
                        value={GetMiscNotes().DeliveryNotes}
                        onChange={e => onDataChange(e.target.name, e.target.value)} />
                </p>
            </div>
        </div>

    </>);
}

export default BillDateNumberSection;