import React, { ReducerState, useEffect, useState } from 'react';
import * as AppAct from '../globals/AppActions';
import { LedgerTransView, ML_Ledger, ML_Tag, ML_Transaction } from "../globals/dto";
import { connect, useSelector, useDispatch } from "react-redux";
import { mapStateToProps } from "../globals/AppActions";
import * as utils from '../globals/Utils';
import * as dateUtils from '../globals/AppDateUtils';
import { Modal, ModalBody } from "react-bootstrap";
import ModalHeader from "react-bootstrap/ModalHeader";
import { TransactionEntry } from "../shared/TransactionEntry";
import * as ldb from '../db/LedgerData';
import * as tdb from '../db/TransactionsData';
import * as factory from '../globals/factory';
import { SuspenseEntryEdit } from "../shared/SuspenseEntryEdit";
import { AppReduxState } from '../infra-redux/AppStateReduxStore';
import DateRangeControlEx from '../globals/DateRangeControlEx';
import { PopTags } from '../globals/PopTags/PopTags';
import * as _ from 'lodash';
import { toast } from 'react-toastify';
import {CloseButtonUI} from "../globals/AppControls/CloseButtonUI";
import {DangerButtonUI} from "../globals/AppControls/DangerButtonUI";
import {SuccessButtonUI} from "../globals/AppControls/SuccessButtonUI";

class ResultList {
    Trans: ML_Transaction;
    Category: ML_Tag;
}

const LedgerStatement = (props: any) => {

    const [state, setState] = useState({
        LedgerTrans: new LedgerTransView(),
        start: dateUtils.MinusMonths(new Date(), 6),
        end: new Date(),
        trans: new ML_Transaction(),
        isLedgerEditable: true
    });

    const [showTransModal, setShowTransModal] = useState(false);
    const [showEditEntry, setShowEditEntry] = useState(false);
    const reduxState = useSelector((state: AppReduxState) => state);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(AppAct.ChangeMainTitle("Ledger Details"));
        if (props.ledger) {
            let ledgeType = props.ledger.IsBankLedger ? "Bank Ledger" : "General Ledger";
            // let heading = `<div><b class='tx-25-f'>${props.ledger.LedgerName}
            //     </b><br/><span class='tx-13 font-weight-normal'>${ledgeType}</span></div>`;
            // dispatch(AppAct.ChangeMainTitle(heading));
            dispatch(AppAct.ChangeMainTitle(`${props.ledger.LedgerName}`));
            LoadTransaction(dateUtils.MinusMonths(new Date(), 6), new Date());
        }
    }, [])

    const LoadTransaction = (sd: Date, ed: Date) => {
        dispatch(AppAct.ToggleProgress(true));
        ldb.getTrans(props.ledger.LDGID, sd, ed).then((res: any) => {
            setState({ ...state, start: sd, end: ed, LedgerTrans: res.data });
            setShowEditEntry(false);
            dispatch(AppAct.ToggleProgress(false));
        });
    }

    const handleDateChange = (st: Date, et: Date) => {
        LoadTransaction(st, et);
    }

    const openTransModal = (isExpense: boolean) => {
        let t = factory.createTransactionML();
        t.LedgerID = props.ledger.LDGID;
        t.IsExpense = isExpense;
        //if (isExpense) t.FromLedgerID = props.ledger.LDGID;
        setState({ ...state, trans: t, isLedgerEditable: !isExpense });
        setShowTransModal(true);
    }

    const closeTransModal = (refreshlist: boolean = false) => {
        setShowTransModal(false);
        if (refreshlist)
            LoadTransaction(state.start, state.end);
    }

    const openEditEntry = (line: ML_Transaction) => {
        setState({ ...state, trans: line });
        setShowEditEntry(true);
    }
    const saveSuspenseEditEntry = (line: ML_Transaction, partyType: number, partyID: number) => {
        console.log(JSON.stringify(line));
        console.log("Party TYPE : " + partyType + ", partyID: " + partyID);
        closeEditEntry();
    }
    const closeEditEntry = () => {
        setShowEditEntry(false)
    }

    const transConfirmed = (t: ML_Transaction) => {
        //Trans component ALWAYS deals with "FromLedgerID" AND NEVER with LedgerID
        console.log(t);
        dispatch(AppAct.ToggleProgress(true));

        if (props.ledger.LDGID !== t.FromLedgerID) {
            let tranCopy = _.clone(t);
            let depositingMoneyToLedger = 0;
            let takingMoneyFromLedger = 0;

            if (t.IsExpense) {
                //WITHDRAW
                takingMoneyFromLedger = props.ledger.LDGID;
                depositingMoneyToLedger = t.FromLedgerID;
            } else {
                //DEPOSIT
                takingMoneyFromLedger = t.FromLedgerID;
                depositingMoneyToLedger = props.ledger.LDGID;

            }

            tranCopy.LedgerID = takingMoneyFromLedger;
            tranCopy.FromLedgerID = 0;
            tranCopy.IsExpense = true; //expense
            tdb.save(tranCopy).then(resFirst => {
                tranCopy.LedgerID = depositingMoneyToLedger;
                tranCopy.FromLedgerID = takingMoneyFromLedger;
                tranCopy.IsExpense = false; //income
                tdb.save(tranCopy).then(res => {
                    closeTransModal(true);
                    dispatch(AppAct.ToggleProgress(false));
                })
            }).catch(err => toast.warning('Transaction not saved!'));
        }

        if (props.ledger.LDGID === t.FromLedgerID) {
            tdb.save(t).then(res => {
                closeTransModal(true);
                dispatch(AppAct.ToggleProgress(false));
            })
        }
    }

    return (<>

        {showEditEntry &&
            <Modal show={showEditEntry} dialogClassName="modal-corner"
                onHide={() => closeEditEntry()}
                backdrop={'static'}
                keyboard={false}
            >
                <ModalBody className="p-3 ">
                    <h5 className={"az-content-label " + (state.trans.IsExpense ? 'text-danger' : 'text-success')}>
                        {state.trans.IsExpense ? 'Expense to Ledger Entry' : 'Deposit to Ledger Entry'}
                    </h5>
                    <SuspenseEntryEdit
                        trans={state.trans}
                        save={saveSuspenseEditEntry}
                        closed={closeEditEntry} />
                </ModalBody>
            </Modal>}

        {showTransModal &&
            <Modal show={showTransModal}
                dialogClassName="modal-corner"
                onHide={() => closeTransModal(false)}
                backdrop={'static'}
                keyboard={false}>
                <ModalBody className="p-3">
                    <h5 className={"az-content-label mb-4 " + (state.trans.IsExpense ? 'text-danger' : 'text-success')}>
                        {state.trans.IsExpense ? 'Expense Entry' : 'Deposit Entry'}
                    </h5>
                    <TransactionEntry trans={state.trans}
                        LedgerLabel={state.trans.IsExpense ? 'Transfer fund to Ledger' : 'Receive fund from Ledger'}
                        isLedgerEditable={true}
                        closed={closeTransModal}
                        saved={transConfirmed}

                    />
                </ModalBody>

            </Modal>}
        <div className="row">
            <div className="col-md-6">
                <DateRangeControlEx onChange={handleDateChange} start={state.start} end={state.end} />
            </div>
            <div className="col-md-6 text-md-right text-center pt-3 pt-md-0">
                <SuccessButtonUI className={"mr-3"} onClick={() => openTransModal(false)} buttonText={"Deposit"}/>
                <DangerButtonUI className={"mr-3"} onClick={() => openTransModal(true)} buttonText="Withdraw" />
                <CloseButtonUI onClick={props.close}/>
                {/*<button className="btn btn-success mr-3" onClick={() => openTransModal(false)}>Deposit</button>*/}
                {/*<button className="btn btn-danger mr-3" onClick={() => openTransModal(true)}>Withdraw</button>*/}
                {/*<button className="btn btn-primary" onClick={props.close}>&lt; Back</button>*/}
            </div>
            <div className='col-12'><hr /></div>
        </div>



        <div className="table-responsive">
            <table className="table table-striped mg-b-0">
                <thead className="border-top">
                    <tr>
                        <th>Date</th>
                        <th>Description</th>
                        <th>Pay Mode</th>
                        <th className="text-right">Payment</th>
                        <th className="text-right">Receipt</th>
                        <th className="text-right">Balance</th>
                    </tr>
                </thead>
                <tbody>
                    {state.LedgerTrans.AllTrans.map((t: any, index: number) => {
                        return (
                            <tr key={index}>
                                <td className="pointer-cursor" title="Edit Sale Bill"
                                    onClick={() => openEditEntry(t.Trans)} >
                                    <i className="text-info fas fa-edit mr-2"></i>
                                    {dateUtils.FormatDate(t.Trans.TransDate)}
                                </td>
                                <td>
                                    {utils.FormTransDetailsMessage(t.Trans, t.Category)}
                                    &nbsp;<PopTags transaction={t.Trans} />
                                </td>
                                <td>
                                    {t.Trans.PayMode}
                                    {t.Trans.PayMode === 'CHQ' && !t.Trans.IsPaymentRealized &&
                                        <span className="badge badge-danger ml-2">Pending</span>
                                    }
                                </td>
                                <td className="text-right">
                                    {t.Trans.IsExpense &&
                                        <span>{utils.formatCurrencyValue(reduxState, t.Trans.TotalAmount)}</span>
                                    }
                                </td>
                                <td className="text-right">
                                    {!t.Trans.IsExpense &&
                                        <span>{utils.formatCurrencyValue(reduxState, t.Trans.TotalAmount)}</span>
                                    }
                                </td>
                                <td className="text-right">{utils.formatCurrencyValue(reduxState, t.Trans.LedgerBalance)}</td>
                            </tr>
                        )
                    })}
                </tbody>
                <tfoot>
                    <tr>
                        <td colSpan={3}></td>
                        <td className="text-right"><b>{utils.formatCurrencyValue(reduxState, state.LedgerTrans.OutwardTotal)}</b></td>
                        <td className="text-right"><b>{utils.formatCurrencyValue(reduxState, state.LedgerTrans.InwardTotal)}</b></td>
                        <td className="text-right"><b>{utils.formatCurrencyValue(reduxState, state.LedgerTrans.GrandTotal)}</b></td>
                    </tr>
                </tfoot>
            </table>
        </div>


    </>)

}

export default LedgerStatement;
