import React, {useEffect, useState} from 'react';
import {connect, useDispatch, useSelector} from "react-redux";
import * as AppAct from '../globals/AppActions';
import {mapStateToProps} from "../globals/AppActions";
import {CompanyView, ML_Company} from "../globals/dto";
import * as cdb from '../db/CompanyData';
import {AxiosResponse} from "axios";
import StoreDetails from "./StoreDetails";
import * as factory from '../globals/factory';
import { AddNewButtonUI } from '../globals/AppControls/AddNewButtonUI';

const StoresList = (props:any)=> {
    
    const dispatch = useDispatch();

    const [state, setState] = useState({
        complist : new Array<ML_Company>(),
        compSel : new CompanyView(),
    });

    const [showDetails, setShowDetails] = useState(false);

    useEffect(()=>{
        dispatch(AppAct.ChangeMainTitle("Companies List"));
        loadAll();
    },[])

    const loadAll = () => {
        dispatch(AppAct.ToggleProgress(true));
        cdb.GetAll().then((res:AxiosResponse<ML_Company[]>) => {
            setState({...state, complist : [...res.data]});
            dispatch(AppAct.ToggleProgress(false));
        });
    }

    const edit = (cid : number) => {
        if(cid === 0){
            setState({ ...state,compSel: factory.createCompanyView()});
            setShowDetails(true);
        } else {
            cdb.GetOnId(cid).then((res: AxiosResponse<CompanyView>) => {
                console.log(res.data);
                setState({...state,compSel : {...res.data}});
                setShowDetails(true);
            });
        }
    }

    const closeEdit = () => {
        setShowDetails(false);
        loadAll();
    }

    
        if(showDetails){
            return <StoreDetails close={closeEdit} company={state.compSel} />
        } else {
            return <>
                {/* <input type="button" className="btn btn-az-primary" value="+ Add New" autoFocus
                       onClick={() => edit(0)}
                /> */}
                <AddNewButtonUI onClick={() => edit(0)} buttonText="Add New" />
                <div className="row">
                    {state.complist.map((c: ML_Company, idx) => {
                        return (
                            <div className="col-sm-6 col-md-4 mt-3 mb-2" key={c.CMPID}>
                                <div className="card card-minimal-four pointer-cursor highlight"
                                     onClick={() => edit(c.CMPID)}>
                                    <div className="card-header smooth-corner border p-3">
                                        <div className="media">
                                            <div
                                                className="coin-logo bg-warning">
                                                <div className={"az-avatar-nodot bg-gray-800"}>
                                                    {c.CompanyName.charAt(0).toUpperCase()}</div>
                                            </div>
                                            <div className="media-body">
                                                <h6>{c.CompanyName}</h6>
                                                <p>{c.AliasName}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </>
        }
    
}

export default StoresList;