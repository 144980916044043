import {components, OptionProps} from "react-select";
import React from "react";
import {BrokerPaymentVM, InwardView, ML_Broker, ML_BrokerPayment, ML_Transaction} from "../globals/dto";
import { toast } from "react-toastify";
import * as utils from '../globals/Utils';
import { AppReduxState } from "../infra-redux/AppStateReduxStore";
import { useSelector } from "react-redux";

export const GrandTotals = (inwardView : InwardView) => {
    const reduxState = useSelector((state:AppReduxState)=>state);
    
    return (<>
        <tr key={"k1"}>
            <td className="text-rigth">Total:</td>
            <td className="text-rigth font-weight-bolder pr-0">{utils.formatCurrencyValue(reduxState,inwardView.InwardEntry.AmountUnTaxed)}</td>
        </tr>
        <tr key={"k2"}>
            <td className="text-rigth">Tax:</td>
            <td className="text-rigth font-weight-bolder pr-0">{utils.formatCurrencyValue(reduxState,inwardView.InwardEntry.AmountTax)}</td>
        </tr>
        <tr key={"k3"} className="border-top">
            <td className="text-rigth w-50">Grand Total:</td>
            <td className="text-rigth font-weight-bolder pr-0">{utils.formatCurrencyValue(reduxState,inwardView.InwardEntry.GrandTotal)}</td>
        </tr>
    </>);
}


export const validateData = (inwardView:InwardView) => {

    if (!inwardView.InwardEntry.SupplierInoviceNumber) {
        toast.error("Enter Supplier invoice number");
        return false;
    }

    if(!inwardView.InwardEntry.DespatchedThrough){
        toast.error("Enter vehicle number");
        return false;
    }

    if (inwardView.InwardEntry.SupplieID === 0) {
        toast.error("Supplier not selected");
        return false;
    }

    if (inwardView.LineItems.length === 0) {
        toast.error("No items added");
        return false;
    }

    if (inwardView.InwardEntry.CompanyID === 0) {
        toast.error("Select store");
        return false;
    }

    return true;
}



/*********************************************************************************/
/******* SUPPLIER DROP DOWN SETTINGS *************/
/*********************************************************************************/

export const supplierSingleOption = (props: any) => (
    <components.Option {...props}>
        {props.data.SPID === -1 &&
            <div style={{"borderBottom":"1px dashed gray", "paddingBottom":"7px"}}><b className="text-primary">{props.data.CompanyName}</b></div>}

        {props.data.SPID > 0 &&
            <>
            <b>{props.data.CompanyName}</b>
            {props.data.PrimaryContactPersonName &&
            <span>&nbsp;|&nbsp;<i>{props.data.PrimaryContactPersonName}</i></span>
            }
            {props.data.PrimaryPhones &&
            <span>&nbsp;|&nbsp;Ph:{props.data.PrimaryPhones}</span>
            }
            </>
        }
    </components.Option>)

export const supplierSingleValue = (props: any) => (
    <components.SingleValue {...props}>
        <b>{props.data.CompanyName}</b>
    </components.SingleValue>
)
/*

export const Option = (props: any) => {
    const {data, getStyles, innerRef, innerProps} = props;

    return (
        <span>{data.CompanyName}</span>
    );
}
*/

export const supplierCustomSelectFilter = (option: any, search: string) => {
    return option.data.CompanyName.toLowerCase().includes(search.toLowerCase());
}


/*********************************************************************************/
/******* BROKER DROP DOWN SETTINGS *************/
/*********************************************************************************/

export const brokerSingleOption = (props: any) => (
    <components.Option {...props}>
        <b>{props.data.BrokerName}</b>
        {props.data.GeoCity &&
        <span>&nbsp;|&nbsp;<i>{props.data.GeoCity}</i></span>
        }
        {props.data.ContactNumber &&
        <span>&nbsp;|&nbsp;Ph:{props.data.ContactNumber}</span>
        }
    </components.Option>)

export const brokerSingleValue = (props: any) => (
    <components.SingleValue {...props}>
        <b>{props.data.BrokerName}</b>
        {props.data.ContactNumber &&
        <span>&nbsp;|&nbsp;Ph:{props.data.ContactNumber}</span>
        }
    </components.SingleValue>
)

export const brokerCustomSelectFilter = (option: any, search: string) => {
    return option.data.BrokerName.toLowerCase().includes(search.toLowerCase());
}

/*********************************************************************************/
/******* MODAL : LINE ITEM SETTINGS/METHODS *************/
/*********************************************************************************/
