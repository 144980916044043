import React, {useState} from 'react';
import {ML_Tax, TaxSlabDTO} from "../globals/dto";
import * as tdb from '../db/TaxData';
import * as global from '../globals/GlobalConsts';
import {toast} from "react-toastify";
import Swal from "sweetalert2";
import TextBoxNumeric from '../ui-controls/TextBoxNumeric';
import { CloseButtonUI } from '../globals/AppControls/CloseButtonUI';
import { SaveButtonUI } from '../globals/AppControls/SaveButtonUI';
import { DeleteButtonUI } from '../globals/AppControls/DeleteButtonUI';

export const TaxDetails = (props: any) => {

    const [tax, setTax] = useState(props.data ? props.data : new ML_Tax());
    const [slabs, setSlabs] = useState(tax.TXID > 0 ? JSON.parse(tax.SlabSettingsJson) : new Array<TaxSlabDTO>());
    const [validity, setValidity] = useState({TaxGroupNameErr: '', SlabsErr: ''});

    const handleInput = (e: any) => {
        tax[e.target.name] = e.target.value;
        setTax({...tax});
        IsUserInputOK(tax);
    }
    const handleArrayInput = (e: any, id: number) => {
        let name = e.target.name;
        let val = e.target.value;
        slabs[id][name] = val;
        setSlabs([...slabs]);
    }

    const IsUserInputOK = (obj: ML_Tax) => {
        (!obj.TaxGroupName || obj.TaxGroupName.length === 0) ?
            validity.TaxGroupNameErr = global.CLASS_INPUT_ERROR : validity.TaxGroupNameErr = '';

        if (slabs.length === 0) validity.SlabsErr = global.CLASS_INPUT_ERROR;
        else {
            (slabs.filter((s: TaxSlabDTO) => s.TaxValue > 0 && s.TaxName && s.TaxName.length > 0)).length > 0 ?
                 validity.SlabsErr = '' :validity.SlabsErr = global.CLASS_INPUT_ERROR ;
        }
        setValidity({...validity});
        return validity.TaxGroupNameErr === '' && validity.SlabsErr === '';
    }

    const Close = () => props.close()
    const AddNewSlab = () => setSlabs([...slabs, new TaxSlabDTO()]);

    const DeleteSlab = (id: number) => {
        slabs.splice(id, 1);
        setSlabs([...slabs])
    };


    const Save = () => {
        if (IsUserInputOK(tax)) {
            let copySlabs  = slabs.filter((x: TaxSlabDTO) => x.TaxValue > 0 && x.TaxName && x.TaxName.length > 0);
            setSlabs([...copySlabs]);
            tax.SlabSettingsJson = JSON.stringify(slabs);
            setTax({...tax});
            props.toggleProgress(true);
            tdb.Save(tax).then((res: any) => {
                props.toggleProgress(false);
                props.closeWithRefresh();
            });
        } else {
            toast.error('Please enter mandatory data');
        }
    }

    const DeleteTax = () => {
        Swal.fire({
            title:'Delete Tax',
            html :"Are you sure want to delete tax setting - <b>" + tax.TaxGroupName + "</b>?",
            showCancelButton:true,
            confirmButtonColor:'red',
            focusCancel:true,
        }).then((res) => {
            if(res.value){
                props.toggleProgress(true);
                tdb.DeleteTax(tax.TXID).then((res)=>{
                    props.toggleProgress(false);
                    props.closeWithRefresh();
                });
            }
        })

    }

    return <>
        <div className="row form-row justify-content-center">
            <div className="col-md-8">
                <label className="form-label">Tax Name<span className="tx-danger">*</span></label>
                <input type="text" className={"form-control " + validity.TaxGroupNameErr} maxLength={15}
                       placeholder="like GST, VAT"
                       name={"TaxGroupName"}
                       value={tax.TaxGroupName}
                       onChange={handleInput}
                />
            </div>
        </div>

        <div className="row form-row mt-4 justify-content-center">
            <div className="col-md-10">
                <table className="table">
                    <thead className="border-top">
                    <tr>
                        <th>Slab Name<span className="tx-danger">*</span></th>
                        <th>%<span className="tx-danger">*</span></th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    {slabs.map((s: TaxSlabDTO, idx: number) => {
                        return (<tr>
                            <td>
                                <input type="text" className="form-control" maxLength={15} placeholder="like CGST"
                                       name="TaxName"
                                       value={s.TaxName}
                                       onChange={(e) => handleArrayInput(e, idx)}
                                />
                            </td>
                            <td>
                                <TextBoxNumeric className="form-control text-right" placeholder={"like 2.5"}
                                       name={"TaxValue"}
                                       value={s.TaxValue}
                                       onChange={(e:any) => handleArrayInput(e, idx)}
                                />
                            </td>
                            <td className="align-middle">
                                <i className="pointer-cursor far fa-trash-alt tx-18-f text-danger"
                                   onClick={() => DeleteSlab(idx)}></i></td>
                        </tr>)
                    })}

                    </tbody>

                    <tfoot>
                    <tr>
                        <td colSpan={3} className="align-middle text-center">
                            <button className="btn-simple bg-primary text-white" onClick={AddNewSlab}>+Add New Tax
                                Slab
                            </button>
                        </td>
                    </tr>
                    </tfoot>
                </table>
            </div>
        </div>

        <div className="row form-row">
            <div className="col-md-12">
                <hr/>
                {/* <input type="button" value="Save" className="btn btn-success mr-3" onClick={Save}/>
                <input type="button" value="Close" className="btn btn-secondary" onClick={Close}/> */}

                <SaveButtonUI  onClick={Save} className="mr-3" />
                <CloseButtonUI onClick={Close} />

                <DeleteButtonUI className="float-right" onClick={DeleteTax}/>
                
            </div>
        </div>
    </>;
}
