import React, {useEffect, useState} from 'react';
import Select, {components, OptionProps} from "react-select";
import Swal from "sweetalert2";
import {toast} from "react-toastify";
import {DateControlEx} from "../globals/DateControlEx";
import * as factory from '../globals/factory';
import LedgerDetails from "../ledgers/LedgerDetails";

import * as ldb from '../db/LedgerData';
import * as cdb from '../db/CustomersData';
import * as sdb from '../db/SupplierData';
import * as bdb from '../db/BrokersData';

import {ML_Ledger, ML_Supplier, ML_Transaction} from "../globals/dto";
import * as dateUtils from "../globals/AppDateUtils";
import {CloseButtonUI} from "../globals/AppControls/CloseButtonUI";

//properties to pass
//trans = ML_Transaction
//deleted()
//closed()
//saved(ML_Transaction)

export const SuspenseEntryEdit = (props: any) => {

    const [tran, setTran] = useState(factory.createTransactionML());
    const [ledgerSelected, setLedgerSelected] = useState({ 
        Ledger : new ML_Ledger(), 
        CurrentBalance : new ML_Transaction() 
    });
    const [initialized, setInitialized] = useState(false);
    const [partyType, setPartyType ] = useState(0);
    const [partyId, setPartyId] = useState(0);
    const [partyAccounts, setPartyAccounts] = useState([{Name: '', Id: ''}]);
    const [checkingPartyPayStatus, setCheckingPartyPayStatus] = useState(false);
    const [previouslyUtilizedFund,setPreviouslyUtilizedFund] = useState(false);

    useEffect(() => {
        if(props.trans)
            setTran({...props.trans});
        setInitialized(false);

        LoadLedgerWithBalance(props.trans.LedgerID);
        checkPartyPaymentDonePreviously(props.trans.EXPID);

    }, []);

    useEffect(()=>{
        loadPartyAccounts();
    },[partyType])

    const checkPartyPaymentDonePreviously = (transid :number) => {
        ldb.GetLinkTransactionWithParty(transid).then(res=>{
            console.log(res.data);
            if(res.data !== null){
                setPartyType(res.data.Type);
                setPartyId(res.data.Party);
                setPreviouslyUtilizedFund(true);
            } else {
                setPartyType(0);
                setPreviouslyUtilizedFund(false);
            }
        });
    }

    const LoadLedgerWithBalance = (lgid : number) => {
        ldb.getLedgerWithBalance(lgid).then(res => {
            setLedgerSelected(res.data);
            setInitialized(true)
        });
    }

    const loadPartyAccounts = () =>{
        console.log("Party type:" + partyType);
        setCheckingPartyPayStatus(true);
        if(+partyType === 1) { //supplier
            //setPartyAccounts([]);
            sdb.GetAll().then(res=>{
                console.log("supplier list");
                setPartyAccounts(res.data.map((x:any)=>{ return { Name:x.CompanyName, Id : x.SPID}}));
                setCheckingPartyPayStatus(false);
            });
        } else if(+partyType === 2) { //customer
            //setPartyAccounts([]);
            cdb.GetAll().then(res=>{
                console.log("customer list");
                setPartyAccounts(res.data.map((x:any)=>{ return { Name:x.CompanyName, Id : x.CID}}));
                setCheckingPartyPayStatus(false);
            });
        } else if(+partyType === 3){ //broker
            //setPartyAccounts([]);
            bdb.GetAll().then(res=>{
                console.log("broker list");
                setPartyAccounts(res.data.map((x:any)=>{ return { Name : x.BrokerName, Id: x.BRKID}}));
                setCheckingPartyPayStatus(false);
            });
        } else {
            setPartyAccounts([]);
            setCheckingPartyPayStatus(false);
        }
    }

    const onSave = () => {
        console.log("Paty ID:" + partyId + ", Details: " + tran.Details + ", Ledger id:" + tran.LedgerID);
        if (tran.LedgerID > 0 && partyId > 0 && tran.Details) {
            ldb.LinkTransactionWithParty(tran,partyType,partyId).then(res=>{
                if(res.data === true){
                    toast.success("Transaction is link is successful!")
                }
            })
        } else {
            toast.error("Select party and provide transaction details.");
        }
    }
    const onClose = () => {
        props.closed();
    }

    const handleChange = (e: any) => {
        let name = e.target.name;
        let val = e.target.value;
        console.log(val);
        if(name === 'partyType')
            setPartyType(val);
        else if(name === 'partyID')
            setPartyId(val);
        else 
            setTran({...tran, [name]: val});
    }

    const handleDateEntry = (e: string) => {
        tran.TransDate = new Date(e);
        setTran(tran);
    }

    if(initialized ===false)
        return <></>
    else {
        return (          
            <>
                <div className="row form-group">
                    <div className="col-md-5">
                        <label className="form-label">Date of Expense</label>
                        <DateControlEx
                            datevalue={tran.TransDate}
                            //placeholder={utils.FormatDate(tran.TransDate)}
                            onDateChange={handleDateEntry}/>
                    </div>
                </div>
                {/* <div className="row form-group">
                    <div className="col-12">
                        <label className="form-label">From Ledger</label>
                        <b>{ledgerSelected.Ledger.LedgerName}</b>
                        {ledgerSelected.CurrentBalance.TotalAmount &&
                        <span>&nbsp;&nbsp;(Balance: <b
                            className={ledgerSelected.CurrentBalance.TotalAmount > 0 ? 'text-success' : 'text-danger'}>
                {ledgerSelected.CurrentBalance.TotalAmount}</b>)</span>}
                    </div>
                </div> */}

                {checkingPartyPayStatus &&
                <div className="row form-group">
                    <div className="col-12 text-indigo font-weight-bold font-italic">
                        Checking party info, please wait...
                    </div>
                </div>
                }


                {!checkingPartyPayStatus &&
                <div className="row form-group">
                    <div className="col-md-4">
                        <label className="form-label">Party Type</label>
                        <select className="form-control form-select" id="partyType" name="partyType" value={partyType} onChange={handleChange}>
                            <option value="0"> </option>
                            <option value="1">Supplier</option>
                            <option value="2">Customer</option>
                            <option value="3">Broker</option>
                        </select>
                    </div>
                    <div className="col-md-8">
                        <label className="form-label">Party</label>
                        <select className="form-control form-select" id="partyID" name="partyID" value={partyId} onChange={handleChange}>
                            {partyAccounts.map((x:any)=> {return(
                                <option key={partyType+"_"+x.Id} value={x.Id}>{x.Name}</option>
                            )})}
                        </select>
                    </div>
                </div>
                }

                <div className="row form-group">
                    <div className="col-md-12">
                        <label className="form-label">Expense Details</label>
                        <input type="text" className="form-control"
                               name={"Details"}
                               value={tran.Details}
                               onChange={handleChange}
                        />
                    </div>
                </div>
                <div className="row form-group">
                    <div className="col-md-6">
                        <label className="form-label">Payment Mode</label>
                        <select className="form-control form-select" name={"PayMode"}
                                value={tran.PayMode} onChange={handleChange}>
                            <option value="CASH">Cash</option>
                            <option value="CHQ">Cheque</option>
                            <option value="DD">DD</option>
                            <option value="NET">NET</option>
                        </select>
                    </div>
                    <div className=" col-md-6">
                        <label className="form-label">Amount</label>
                        <input type=" text" className=" form-control" readOnly={true}
                               value={tran.TotalAmount}/>
                    </div>
                </div>

                <div className="row form-group mb-0">
                    <div className={"col-md-12 text-center"}>
                        {!checkingPartyPayStatus && !previouslyUtilizedFund &&
                            <input type="submit" className="btn btn-success mr-3" value="Save" onClick={onSave}/>
                        }
                        {/*<input type="button" className="btn btn-primary" value="Cancel" onClick={onClose}/>*/}
                        <CloseButtonUI onClick={onClose} />
                    </div>
                </div>
            </>
        );
    }
}
