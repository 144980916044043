import React, { useEffect, useState } from 'react';
import * as tdb from "../db/TransactionsData";
import Select, { components, OptionProps, StylesConfig } from "react-select";
import * as consts from './../globals/GlobalConsts';
import { ML_Tag } from '../globals/dto';
import { TagsDropdown } from '../tags/TagsDropdown';


export const TransactionCategoryDropdown = (props: {
    catId: number,
    isError: boolean,
    onSelected(cat: ML_Tag): void
}) => {
    return <TagsDropdown
        isError={props.isError}
        onSelected={props.onSelected}
        selectedTagId={props.catId}
        tagType={consts.TagTypes.TRANSACTION_GROUP}
        key={'tag110'}
    />
}
