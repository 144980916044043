import React, { useEffect, useState } from 'react';
import * as global from '../globals/GlobalConsts';
import * as ldb from '../db/LedgerData';
import { toast } from "react-toastify";
import { ML_Ledger } from "../globals/dto";
import * as utils from '../globals/Utils';
import {SaveButtonUI} from "../globals/AppControls/SaveButtonUI";
import {CloseButtonUI} from "../globals/AppControls/CloseButtonUI";

//props
//ledger = ML_Ledger()
// close(boolean);

const LedgerDetails = (props: any) => {

    const [state, setState] = useState({
        ledger: props.ledger,
        validate: { LedgerNameErr: '' }
    });

    useEffect(() => {
        if (!props.ledger) {
            let newLedger = new ML_Ledger();
            newLedger.IsActive = true;
            setState({ ...state, ledger: newLedger });
        }
    }, []);



    const save = () => {
        if (state.validate.LedgerNameErr === '') {
            state.ledger.IsBankLedger = false;
            ldb.save(state.ledger)
                .then(e => props.close(true))
                .catch(e => toast.error('Something went wrong. Try again later!'));
        } else {
            toast.error("Enter manadatory fields");
        }
    }

    const handleChange = (e: any) => {
        let name = e.target.name;
        let val = e.target.value;

        if (name === 'LedgerName') {
            setState({
                ...state,
                ledger: { ...state.ledger, [name]: val },
                validate: { "LedgerNameErr": (val.length === 0 || !val) ? global.CLASS_INPUT_ERROR : '' }
            });
        }
    }

    if (!state.ledger) return <></>

    return <>

        <div className="row form-group">
            <div className="col-md-8">
                <label className="form-label">Ledger Name<span className="text-danger">*</span></label>
                <input type="text"
                    name="LedgerName"
                    value={state.ledger.LedgerName}
                    onChange={handleChange}
                    className={"form-control " + state.validate.LedgerNameErr} />
            </div>

            {/*<div className="col-md-4">*/}
            {/*    <label className="form-label">Ledger Type</label>*/}
            {/*    <select className="form-control"*/}
            {/*            name="IsBankLedger"*/}
            {/*            value={state.ledger.IsBankLedger}*/}
            {/*            onChange={handleChange}>*/}

            {/*        <option value="false">General</option>*/}
            {/*        <option value="true">Bank Account</option>*/}
            {/*    </select>*/}
            {/*</div>*/}
        </div>

        <div className="row form-group">
            <div className="col-md-12">
                <label className="form-label">Description</label>
                <input type="text" className="form-control"
                    name="LedgerBio"
                    onChange={handleChange}
                    value={state.ledger.LedgerBio} />
            </div>
        </div>

        <div className="row">
            <div className="col-md-4">
                <div className="custom-control custom-switch pointer-cursor">
                    <input type="checkbox" className="custom-control-input" id="batchSwitch1"
                        name="IsActive"
                        onChange={handleChange}
                        checked={state.ledger.IsActive}
                    />
                    <label className="custom-control-label"
                        htmlFor="batchSwitch1">Is Active</label>
                </div>

                {/*<label className="ckbox">
                        <input type="checkbox" name="IsActive"
                               onChange={handleChange}
                               value={state.ledger.IsActive}/><span>Is Active</span>
                    </label>*/}
            </div>
            <div className="col-md-8">

                <div className="custom-control custom-switch pointer-cursor">
                    <input type="checkbox" className="custom-control-input" id="batchSwitch2"
                        name="IsConsiderInPNL"
                        onChange={handleChange}
                        checked={state.ledger.IsConsiderInPNL}
                    />
                    <label className="custom-control-label"
                        htmlFor="batchSwitch2">Consider in PnL Calculation</label>
                </div>


                {/*<label className="ckbox">
                        <input type="checkbox" name="IsConsiderInPNL"
                               onChange={handleChange}
                               value={state.ledger.IsConsiderInPNL}/>
                               <span>Consider in PnL Calculation</span>
                    </label>*/}
            </div>
        </div>

        <div className="d-flex flex-row gap-3 justify-content-between pt-4">
            <SaveButtonUI onClick={save}/>
            <CloseButtonUI onClick={() => props.close(false)}/>
        </div>

        {/*<div className="row form-group">*/}
        {/*    <div className="col-md-12 mt-3">*/}
        {/*        <button type="submit" className="btn btn-success" onClick={save}>Save</button>*/}
        {/*        <button onClick={() => props.close(false)} className="btn btn-secondary ml-3">Close*/}
        {/*        </button>*/}
        {/*    </div>*/}
        {/*</div>*/}
    </>;

}

export default LedgerDetails;
