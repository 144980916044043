import React from "react";
import { CheckmarkOutline, CloseOutline, TrashOutline } from 'react-ionicons';

export const WarningButtonUI = (props: any) => {
    return <button
        {...props}
        className={'btn btn-warning px-3 form-control-font ' + props.className}
    >
        <span dangerouslySetInnerHTML={{ __html: props.buttonText }} />
    </button>
}
