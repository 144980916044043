import React, { useEffect, useState } from 'react';
import { InwardView, ML_Company, ML_Supplier, SaleBillView } from "../globals/dto";
import * as AppAct from '../globals/AppActions';
import { mapStateToProps } from '../globals/AppActions';
import * as idb from '../db/InwardsData';
import { connect, useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import * as utils from '../globals/Utils';
import * as dateUtils from '../globals/AppDateUtils';
import InwardInvoiceEntry from "./InwardInvoiceEntry";
import { Modal, ModalBody } from 'react-bootstrap';
import { InwardInvoiceView } from './InwardInvoiceView';
import DateRangeControlEx from "../globals/DateRangeControlEx";
import StoresSelect from "../shared/StoresSelect";
import { AppReduxState } from '../infra-redux/AppStateReduxStore';
import { AddCircleOutline, AddOutline } from 'react-ionicons';
import { AddNewButtonUI } from '../globals/AppControls/AddNewButtonUI';

const InwardsList = (props: any) => {

    const [suppliers, setSuppliers] = useState(new Array<ML_Supplier>());
    const [allInwards, setAllInwards] = useState(new Array<InwardView>());
    const [allInwardsMasterList, setAllInwardsMasterList] = useState(new Array<InwardView>());
    const [selectedInward, setSelectedInward] = useState(new InwardView());
    const [startDate, setStartDate] = useState(dateUtils.MinusDays(new Date(), 1));
    const [endDate, setEndDate] = useState(new Date());
    const [searchText, setSearchText] = useState('');
    const [refreshList, setRefreshlist] = useState(false);
    const [openInward, setOpenInward] = useState(0);
    const [showInwardEdit, setShowInwardEdit] = useState(false);
    const [viewInward, setViewInward] = useState(false);
    const [selectedStore, setSelectedStore] = useState(null);

    const appDisp = useDispatch();
    const reduxState = useSelector((state: AppReduxState) => state);

    useEffect(() => {
        appDisp(AppAct.ChangeMainTitle('Purchase Inwards'));
        //setStartDate(utils.MinusDays(new Date(), 2));
        LoadAllInvoices(startDate, endDate);
    }, []);

    /*useEffect(() => {
        LoadAllInvoices();
    }, [startDate, endDate]);*/

    /* const LoadAllInvoices = () => {
         if (startDate.getTime() <= endDate.getTime()) {
             appDisp(AppAct.ToggleProgress(true));
             idb.GetInwards(startDate, endDate).then((res) => {
                 setAllInwards([...res.data]);
                 setAllInwardsMasterList([...res.data]);
                 //filterInvoices();
                 appDisp(AppAct.ToggleProgress(false));
             });
         } else {
             toast.error('FROM date should be before TO Date.');
         }
     }*/

    const LoadAllInvoices = (start: Date, end: Date) => {

        if (start.getTime() <= start.getTime()) {
            appDisp(AppAct.ToggleProgress(true));
            idb.GetInwards(start, end).then((res) => {
                setAllInwards([...res.data]);
                setAllInwardsMasterList([...res.data]);
                //filterInvoices();
                appDisp(AppAct.ToggleProgress(false));
            });
        } else {
            toast.error('FROM date should be before TO Date.');
        }
    }

    /*useEffect(() => {
        filterInvoices();
    }, [searchText, allInwardsMasterList])

    const filterInvoices = () => {
        if (allInwardsMasterList.length > 0) {
            let res = allInwardsMasterList.filter((i: InwardView) => {
                if (searchText.length > 0) {
                    return i.SupplierDetails.CompanyName.toLowerCase().includes(searchText.toLowerCase());
                } else {
                    return allInwardsMasterList;
                }
            });
            setAllInwards([...res]);
        }
    }*/

    const filterInvoicesList = (iv: InwardView) => {
        let searchFound = false;
        let companyFound = false;
        companyFound = selectedStore?.CMPID > 0 ? (iv.InwardEntry.CompanyID === selectedStore?.CMPID) : selectedStore?.CMPID === 0 ? true : false;
        if (selectedStore === null)
            companyFound = true;
        searchFound = searchText.length > 0 ? iv.SupplierDetails.CompanyName.toLowerCase().includes(searchText.toLowerCase()) : false;
        let res = searchFound || companyFound;
        return res;
    }

    const newInward = () => {
        //props.history.push('/inwarddetails');
        setOpenInward(0);
        setShowInwardEdit(true);
    }

    const editInward = (id: number) => {
        setOpenInward(id);
        setShowInwardEdit(true);
        //setViewInward(true);
    }

    const showInward = (id: number) => {
        setOpenInward(id);
        setViewInward(true);
    }

    const closeEditInwardView = () => {
        setShowInwardEdit(false);
        LoadAllInvoices(startDate, endDate);
    }

    const handleSearch = (e: any) => {
        setSearchText(e.target.value);
        //filterInvoices();
    }

    const handleDateRange = (sd: Date, ed: Date) => {
        setStartDate(sd);
        setEndDate(ed);
        LoadAllInvoices(sd, ed);
    }

    const handleStoreSelectChange = (e: any) => {
        if (!e) {
            setSelectedStore(null);
        }
        else {
            setSelectedStore(e);
        }
    }

    return (
        <>

            <Modal show={viewInward} dialogClassName="modal-corner"
                size="lg"
                onHide={() => setViewInward(false)}>
                <ModalBody className="p-3">
                    <InwardInvoiceView iwid={openInward} />
                </ModalBody>
            </Modal>


            {showInwardEdit &&
                <InwardInvoiceEntry show={showInwardEdit} store={selectedStore} inwardId={openInward} close={closeEditInwardView} />
            }
            {!showInwardEdit &&
                <>
                    <div className="row g-3 align-items-center">
                        <div className='col-md-6'>
                            <StoresSelect onChange={handleStoreSelectChange} company={selectedStore} />
                        </div>
                        <div className='col-md-6 text-right'>
                            {/* <button className="btn btn-az-primary" onClick={newInward} accessKey={"n"}>
                                <AddOutline color={'white'} width={'18px'} height={'18px'}/> <u>N</u>ew Purchase
                            </button> */}

                            {selectedStore &&
                                <AddNewButtonUI
                                    onClick={newInward}
                                />
                            }

                        </div>
                        <div className='col-md-8'>
                            <DateRangeControlEx start={startDate} end={endDate} onChange={handleDateRange} />
                        </div>

                        <div className='col-md-4'>
                            <input type="search" className=" form-control"
                                placeholder=" Search by supplier..."
                                value={searchText}
                                onChange={handleSearch} />
                        </div>
                    </div>
                    <div className="row mt-4">
                        <div className="col-12">
                            <div className="table-responsive">
                                <table className="table table-sm table-hover mg-b-3">
                                    <thead className="border-top">
                                        {/* <tr>
                                        <td colSpan={5}>Total Invoices: {allInwards.filter(filterInvoicesList).length}</td>
                                    </tr>*/}
                                        <tr>
                                            <th>Bill #</th>
                                            <th>Inward #</th>
                                            <th>Supplier</th>
                                            <th className="text-right">Total Qty</th>
                                            <th className="text-center">Bill Date</th>
                                            <th className="text-right">Total Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {allInwards.filter(filterInvoicesList).map((b: InwardView, index: number) => {

                                            return (<tr key={index}>
                                                <td>{b.InwardEntry.INWID}</td>
                                                <td className="pointer-cursor" title="Edit Inward" onClick={() => {
                                                    if (b.InwardEntry.IsCanceled)
                                                        showInward(b.InwardEntry.INWID);
                                                    else
                                                        editInward(b.InwardEntry.INWID);
                                                    //props.history.push('/inwarddetails?iwid='+b.InwardEntry.INWID);
                                                }
                                                }><i className="text-info fas fa-edit mr-2"></i> {b.InwardEntry.SupplierInoviceNumber}
                                                    {b.InwardEntry.IsCanceled && <span className="badge badge-danger ml-2">Canceled</span>}
                                                </td>
                                                <td>{b.SupplierDetails.CompanyName}</td>
                                                <td className="text-right">{b.InwardEntry.TotalBulkQty}</td>
                                                <td className="text-center">{dateUtils.FormatDate(b.InwardEntry.InvoiceReceivedDate)}</td>
                                                <td className="text-right pointer-cursor" title="View Inward"
                                                    onClick={() => {
                                                        showInward(b.InwardEntry.INWID);
                                                        //props.history.push('/inwarddetails?iwid='+b.InwardEntry.INWID);
                                                    }
                                                    }>&nbsp;{utils.formatCurrencyValue(reduxState, b.InwardEntry.PayableTotalAmount)}</td>
                                            </tr>)
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </>
            }
        </>)
}

export default InwardsList;
