import React from "react";
import {Spinner} from "react-bootstrap";

type attribs = {
    show: boolean
    children: any
}

export const LoaderUI = (props: attribs) => {
    return <div className={'loader-container '}>
        <div className={"loader-content"}>{props.children}</div>
        {props.show && <div className="d-flex align-items-center justify-content-center loader-overlay">
            <Spinner animation={"border"} variant={"secondary"}/>
        </div>}
    </div>
}
