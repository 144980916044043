import axios from 'axios';
import * as global from '../globals/ApiUrlConsts';
import {CallActionResult, LedgerTransView, ML_Transaction} from "../globals/dto";

const web_api_url = global.global_api_url_base + '/api/trans';

export const save = async (entry: ML_Transaction) =>
    await axios.post<ML_Transaction>(web_api_url + '/save', entry)

export const saveChequeStatus = async (entry: LedgerTransView[]) =>
    axios.post<boolean>(web_api_url + '/savePendingChequesStatus', entry)

export const deleteTrans = async (entry: ML_Transaction)=>
    await axios.delete<ML_Transaction>(web_api_url + '/trans/delete?=id=' + entry.EXPID)

export const getPendingCheques =async () =>
    await axios.get<LedgerTransView[]>(web_api_url + '/getPendingCheques')


