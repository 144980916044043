import React, {useEffect, useState} from 'react';
import {InwardLineItemView, InwardView, ML_Tax, TaxAppliedDTO} from "../globals/dto";
import * as idb from '../db/InwardsData';
import qs from 'query-string';
import '../globals/factory';
import * as factory from '../globals/factory';
import * as utils from '../globals/Utils';
import * as dateUtils from '../globals/AppDateUtils';
import { useSelector } from 'react-redux';
import { AppReduxState } from '../infra-redux/AppStateReduxStore';



export const InwardInvoiceView = (props:any) => {

    const [inwardView, setInwardView] = useState(new InwardView());
    const reduxState = useSelector((state:AppReduxState)=>state);

    useEffect(()=>{
        let iwid = 0;

        if(props.iwid){
            iwid = props.iwid;
        } 
        // else {
        //     let args = qs.parse(props.location.search);
        //     if (+args.iwid > 0) {
        //         iwid = +args.iwid;
        //     }
        // }
        idb.GetInwardById(iwid).then((res : InwardView)=> {
            setInwardView({...res});
        });
    },[])

        if(!inwardView.InwardEntry){
            return <></>
        }

        return (
            <>
                {/*{JSON.stringify(inwardView)}*/}
            <div className="card">
                <div className="card-body">
                <div className="row text-black pt-2">
                    <div className="col-md-6  border-top border-bottom pb-2 border-right">
                        <b>{inwardView.SupplierDetails.CompanyName}</b><br/>
                        {inwardView.SupplierDetails.AddressOne}<br/>
                        {inwardView.SupplierDetails.AddressTwo},{inwardView.SupplierDetails.GeoCity}<br/>
                        GSTIN/UIN: {inwardView.SupplierDetails.GSTN}<br/>
                        State Name : {inwardView.SupplierDetails.GeoState}, Code: {inwardView.SupplierDetails.GeoBusinessCode}
                    </div>
                    <div className="col-md-6  border-top border-bottom text-right">
                        <h1 className="invoice-title">Inward</h1>
                        <div  className="d-flex justify-content-between pt-4">
                            <div className="text-right">
                                <span className="font-weight-normal">Invoice No.</span><br/>
                                <b>#{inwardView.InwardEntry.INWID}</b>
                            </div>
                            <div className="text-right">
                                <span className="font-weight-normal">Invoice Date</span><br/>
                                <b>{dateUtils.FormatDate(inwardView.InwardEntry.SupplierDated)}</b>
                            </div>
                            <div className="text-right">
                                <span className="font-weight-normal">Received Date</span><br/>
                                <b>{dateUtils.FormatDate(inwardView.InwardEntry.InvoiceReceivedDate)}</b>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row  text-black">
                    <div className="col-md-7">
                        <u>Buyer</u><br/>
                        <b>{inwardView.Company.CompanyName}</b><br/>
                        {inwardView.Company.CompanyAddress}<br/>
                        {/*GSTIN/UIN : 234234234234<br/>
                        StateName : karnataka, Code :29*/}
                    </div>
                    <div className="col-md-5 text-right pt-2">
                        <table className="table-sm float-right">
                            <tr className='border-bottom'>
                                <td>Inward No:</td>
                                <td>{inwardView.InwardEntry.SupplierInoviceNumber}</td>
                            </tr>
                            <tr className="border-bottom">
                                <td>Vehicle No:</td>
                                <td>{inwardView.InwardEntry.DespatchedThrough}</td>
                            </tr>
                            <tr className="border-bottom">
                                <td className="wd-150">Delivery Notes:</td>
                                <td className="text-left">{inwardView.InwardEntry.DeliveryNote}</td>
                            </tr>
                        </table>
                    </div>
                </div>

                <div className="row  text-black mt-3">

                    <div className="col-md-12">
                        <div className="table-responsive">
                            <table className="table table-bordered table-hover">
                                <thead className="border-top">
                                <tr>
                                    <th>S#</th>
                                    <th>Description of Goods</th>
                                    <th>HSN/SAC</th>
                                    <th>Quantity</th>
                                    <th className="text-center">Rate</th>
                                                <th>Disc.</th>
                                                <th className="text-right">Tax</th>
                                    <th className="text-right">Amount</th>
                                </tr>
                                </thead>
                                <tbody>
                                {inwardView.LineItems.map((li: InwardLineItemView, lindx : number) => {
                                    return(
                                       <>
                                           {li.LineProduct != null &&
                                           <tr key={'li'+lindx}>
                                            <td>{lindx+1}</td>
                                            <td>{li.LineProduct.Product.ProductName}</td>
                                            <td>{li.LineProduct.Product.HSNSACNumber}</td>
                                            <td><b>{li.LineItem.BulkQty}</b> {li.LineProduct.UnitMeasure.BulkMeasureName} / <b>{li.LineItem.RetailQty}</b> {li.LineProduct.UnitMeasure.RetailMesaureName}</td>
                                               <td className="text-center"><b>{utils.formatCurrencyValue(reduxState,li.LineItem.Rate)}</b> per <b>{li.LineProduct.UnitMeasure.BulkMeasureName}</b></td>
                                                <td className="text-right">{utils.formatCurrencyValue(reduxState,li.LineItem.DiscountAmount)}</td>
                                                <td className="text-right">{utils.formatCurrencyValue(reduxState,li.LineItem.AmountTaxed)}</td>
                                            <td className="text-right">{utils.formatCurrencyValue(reduxState,li.LineItem.LineTotal)}</td>
                                           </tr>
                                           }

                                           {li.LineItem.OnFlyItemPrice != 0 &&
                                           <tr key={'li'+lindx}>
                                               <td>{lindx+1}</td>
                                               <td>{li.LineItem.OnFlyItem}</td>
                                               <td>-</td>
                                               <td>-</td>
                                               <td className="text-center">-</td>
                                               <td className="text-right">-</td>
                                               <td className="text-right">-</td>
                                               <td className="text-right">{utils.formatCurrencyValue(reduxState,li.LineItem.LineTotal)}</td>
                                           </tr>
                                           }

                                            {/*{li.TaxDetails && li.TaxDetails.SlabsApplied.length > 0 &&
                                            <tr key={'txli'+lindx}>
                                                <td></td>
                                                <td colSpan={5} className="text-right"><i>Taxes Applied</i>:
                                                    {li.TaxDetails.SlabsApplied.map((td:TaxAppliedDTO)=>{
                                                        return <span className="pl-3">
                                                            ({td.TaxValue}% {td.TaxName} = Rs.<b>{td.TotalTaxedAmount}</b>)
                                                        </span>
                                                    })}
                                                </td>
                                                <td className="text-right">{li.LineItem.AmountTaxed}</td>
                                            </tr>
                                           }*/}

                                          {/* {li.LineItem.DiscountAmount > 0 &&
                                           <tr key={'dlili'+lindx}>
                                               <td></td>
                                               <td colSpan={5} className="text-right">
                                                   Discount &nbsp;
                                                   {li.LineItem.DiscountPercentage > 0 && <span>{li.LineItem.DiscountPercentage}%</span> }
                                               </td>
                                               <td className="text-right">-</td>
                                           </tr>
                                           }*/}
                                    </>);
                                })}

                                {inwardView.AdditionalExpense.filter(x=>x.IsDeductInInvoice===1).map(ai=>{
                                    return (
                                        <tr>
                                            <td></td>
                                            <td colSpan={6}>{ai.Transaction.Details}</td>
                                            <td colSpan={6} className="text-right">-{utils.formatCurrencyValue(reduxState,ai.Transaction.TotalAmount)}</td>
                                        </tr>
                                    )
                                })}

                                </tbody>

                                <tfoot>
                                <tr>
                                    <td></td>
                                    <td colSpan={2} className="text-right"></td>
                                    <td>Total Qty: {inwardView.InwardEntry.TotalBulkQty}</td>
                                    <td colSpan={3} className="text-right">Grand Total</td>
                                    <td className="text-right"><b>{utils.formatCurrencyValue(reduxState,inwardView.InwardEntry.GrandTotal)}</b></td>
                                </tr>
                                <tr>
                                    <td colSpan={8}>Amount in words: <i className="text-capitalize">{utils.convertNumberToWords(reduxState, inwardView.InwardEntry.GrandTotal)}</i> - only.</td>
                                </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>

                    <div className="col-md-12 mt-3">
                        <div className="table-responsive">
                            <table className="table table-bordered table-hover">
                                <thead className="border-top">
                                <tr>
                                    <th>HSN/SAC</th>
                                    <th className="text-right wd-200-f">Taxable Value</th>
                                </tr>
                                </thead>
                                <tbody>
                                {inwardView.LineItems.filter(x=> x.LineProduct && x.LineProduct.Product.HSNSACNumber !== '').map((li:InwardLineItemView)=>{
                                    return (<tr>
                                    <td>{li.LineProduct.Product.HSNSACNumber}</td>
                                    <td className="text-right">{utils.formatCurrencyValue(reduxState,li.LineItem.LineTotal)}</td>
                                </tr>)
                                })}
                                </tbody>
                                <tfoot>
                                <tr>
                                    <td className="text-right">Total</td>
                                    {/* <td className="text-right"><b>{inwardView.InwardEntry.GrandTotal}</b></td> */}
                                    <td className="text-right"><b>{utils.formatCurrencyValue(reduxState,inwardView.LineItems
                                    .filter(x=>x.LineProduct && x.LineProduct.Product.HSNSACNumber !== '')
                                    .reduce((total,item)=>{
                                        total.LineItem.LineTotal = total.LineItem.LineTotal + item.LineItem.LineTotal;
                                        return total;
                                    },factory.createInwardLineItemView()).LineItem.LineTotal)}
                                    </b></td>
                                </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>

                </div>

                    <div className="row text-black">
                        <div className="col-md-6"></div>
                        <div className="col-md-6 text-right">
                            <u>Buyer Bank Details</u><br/>
                            Bank Name: <b>{inwardView.SupplierDetails.CompanyBankName}</b> <br/>
                            A/C No: <b>{inwardView.SupplierDetails.CompanyAccountNumber}</b> <br/>
                            Branch: <b>{inwardView.SupplierDetails.BranchName}</b> <br/>
                            IFSC Code: <b>{inwardView.SupplierDetails.BranchIFSCCode}</b> <br/>
                        </div>
                    </div>
                    <div className="row mt-3 border-top text-black">
                        <div className="col-md-6">
                            <u>Declaration</u><br/>
                            E-Way bill not requird as goods sold undert his invioce as expemted under rule 1289813) of a GST act.
                        </div>
                        <div className="col-md-6 text-right border-left">
                            for K L Parameshwarais & Son
                            <br/>
                            <br/>
                            <br/>
                            Authorised Signatory
                        </div>
                    </div>

                    <div className="row border-top text-black">
                        <div className="col-md-12 text-center">
                            SUBJECT TO DAVANGERE JURISDICTION <br/>
                            <span className="font-small">This is a computer generated invoice</span>
                        </div>
                    </div>

                </div>
            </div>
        </>);
}
