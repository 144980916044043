import { DateTime } from "luxon";
import { GlobalSettingsDTO } from "./data.dto";

export const ToDateString = (dt: Date) => {
    if (!dt || !(dt instanceof Date))
        return '';
    let dateobj = new Date(dt);
    return dateobj.toISOString();
}

export const FormatDate = (dt: Date) => {
    let result = '';
    if (dt === null) return result;
    //dt = new Date(dt);
    let dateObj = null;
    if (dt instanceof Date)
        dateObj = dt;
    else
        dateObj = new Date(dt);

    if (isNaN(dateObj.getTime()))
        return result;

    //return DateTime.fromJSDate(dateObj, DateTimeJSOptions).toFormat("dd-LLL-yyyy, EEEE");
    result = DateTime.fromISO((new Date(dateObj)).toISOString()).toFormat("dd-LLL-yyyy, EEEE");


    return result;
}

export const ParseToDate = (dt: string) => {
    return new Date(dt);
}


export const FormatStringDate = (dt: string) => {
    if (!dt) return '';

    let dateObj = new Date(dt);
    return DateTime.fromISO((new Date(dateObj)).toISOString()).toFormat("dd-LLL-yyyy, EEEE");
}

export const FixOneDayOffJSDateProblem = (dt: Date) => {
    return AddDays(new Date(dt), 1);
}

export const FormatDateTime = (dt: Date) => {
    if (!dt) return '';

    let dateObj = null;
    if (dt instanceof Date)
        dateObj = dt;
    else
        dateObj = new Date(dt);
    
    return DateTime.fromISO((new Date(dateObj)).toISOString()).toFormat("dd-LLL-yyyy h:m:s a");
}

export const FormatStringDateTime = (dt: string) => {
    let dateObj = new Date(dt);
    return DateTime.fromISO((new Date(dateObj)).toISOString()).toFormat("dd-LLL-yyyy h:m:s a");
}

export const MinusDays = (dt: Date, days: number) => DateTime.fromJSDate(SanitizeDate(dt)).minus({ days: days }).toJSDate()
export const AddDays = (dt: Date, days: number) => DateTime.fromJSDate(SanitizeDate(dt)).minus({ days: (days * -1) }).toJSDate()
export const MinusMonths = (dt: Date, months: number) => DateTime.fromJSDate(SanitizeDate(dt)).minus({ months: months }).toJSDate()

export const IsDateObject = (dt: any) => {
    if (!dt) return false;
    if (dt === null) return false;
    if (dt == '') return false;
    if (dt == undefined) return false;
    if (dt instanceof Date) return true;

}

export const SanitizeDate = (dt: Date) => {
    if (IsDateObject(dt)) {
        //let s = `${dt.getFullYear()}-${dt.getUTCMonth()}-${dt.getDate()}`
        let dateUtc = dt;
        let monthNum = dateUtc.getMonth() + 1;
        let s = `${dateUtc.getFullYear()}-${padZero(monthNum)}-${padZero(dateUtc.getDate())}`
        return new Date(s);
    }return new Date();
}

const padZero = (num: number) => {
    return (num) < 10 ? '0' + num : num;
}
/*
export const formatDateFn = (date : Date, format:string, local :string) => {
    return dateFnsFormat(date, format);
}

export const parseDateFn = (date :string, format:string) => {
    return dateFnsParse(date, format, new Date() );
}
*/

