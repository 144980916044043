import React, { useEffect, useState } from 'react';
import { ML_Company, ML_Product, ML_ProductBatch } from "../globals/dto";
import * as AppAct from "../globals/AppActions";
import * as stdb from "../db/StocksData";
import { mapStateToProps } from "../globals/AppActions";
import { connect, useDispatch } from "react-redux";
import { Modal, ModalBody, Spinner } from 'react-bootstrap';
import StockMove from "./StockMove";
import SplitStockSimple from "./SplitStockSimple";
import AddOpeningStock from '../products/AddOpeningStock';
import * as _ from 'lodash';
import * as utils from '../globals/Utils';
import { ProductDetailsEx } from "../products/ProductDetailsEx";
import { Menu, Dropdown, Button } from 'antd';
import { AddCircle, AddCircleOutline, BarcodeOutline, BusOutline, CreateOutline } from 'react-ionicons';
import { EditOutlined } from '@ant-design/icons';

const StocksByStore = (props: any) => {

    const dispatch = useDispatch();
    const [masterStocksList, setMasterStocksList] = useState<any[]>([]);
    const [showOpeningStock, setShowOpeningStock] = useState(false);
    const [showProductEdit, setShowProductEdit] = useState(false);
    const [productSelected, setProductSelected] = useState<ML_Product>(null);
    const [loading, setLoading] = useState(true);
    const [grouped, setGrouped] = useState<any>();

    const [state, setState] = useState({
        stocks: new Array(),
        showMoveStockModal: false,
        showSplitModal: false,
        moveStockInfo: { product: new ML_Product(), batch: new ML_ProductBatch(), store: props.company, closingQty: 0 },
        splitStockInfo: { prodId: 0, compId: 0, stockId: 0 },
        searchText: '',
        company: new ML_Company()
    });

    //when store change
    useEffect(() => {
        GetStocks();
    }, [props.company?.CMPID])

    //search filter
    useEffect(() => {
        if (props.searchText != '') {
            let filteredprods = new Array();
            if (props.searchText.length > 0) {
                filteredprods = masterStocksList.filter(x =>
                    x.Product.ProductName.toLowerCase().includes(props.searchText.toLowerCase()));
            } else {
                filteredprods = [...masterStocksList];
            }
            //setState({ ...state, stocks: [...filteredprods] });
            StockGroupBy(filteredprods)
        } else {
            //console.log('gettins stock, the master', masterStocksList);
            //setState({ ...state, stocks: [...masterStocksList] });
            StockGroupBy(masterStocksList);
        }

    }, [props.searchText])

    const GetStocks = () => {
        setLoading(true);
        if (props.company?.CMPID > 0) {
            // dispatch(AppAct.ToggleProgress(true));
            stdb.GetStoreStocks(props.company.CMPID).then((res: any) => {
                setMasterStocksList(res.data);
                //setState({ ...state, stocks: [...res.data] });
                //dispatch(AppAct.ToggleProgress(false));
                StockGroupBy(res.data);
            });
        } else {
            // dispatch(AppAct.ToggleProgress(true));
            stdb.GetAllStoresStocks().then((res: any) => {
                setMasterStocksList(res.data);
                //setState({ ...state, stocks: [...res.data] });
                //dispatch(AppAct.ToggleProgress(false));
                StockGroupBy(res.data);
            });
        }
    }

    const StockGroupBy = (stks: any[]) => {
        stks = removeZeroStocks(stks);
        let grp = _.groupBy(stks, "Category.GRPID");
        setGrouped(Object.entries(grp));
        setLoading(false);
    }

    const showMoveStock = (product: ML_Product, batch: ML_ProductBatch, closingQty: number) => {
        setState({
            ...state,
            moveStockInfo: {
                product: product, batch: batch, store: props.company, closingQty: closingQty
            }, showMoveStockModal: true
        });
    }

    const ShowOpeningStockWindow = (prod: ML_Product) => {
        setProductSelected(prod);
        setShowOpeningStock(true);
    }

    const showEdit = (prod: ML_Product) => {
        setProductSelected(prod);
        setShowProductEdit(true);
    }
    const closeAndRefreshAll = () => {
        closeModals();
        props.refreshAll();
    }

    const closeModals = () => {
        setState({ ...state, showMoveStockModal: false, showSplitModal: false });
        setShowOpeningStock(false);
        setShowProductEdit(false);
    }

    const showSplitStock = (prodId: number, stkid: number) => {
        setState({
            ...state,
            splitStockInfo: { prodId, compId: props.company.CMPID, stockId: stkid },
            showSplitModal: true
        });
    }

    const closeAndRefreshStocks = () => {
        GetStocks();
        closeModals();
    }

    const removeZeroStocks = (stocks: any[]) => stocks.filter(f => (f.MilledTotal - f.TotalSold) > 0);

    if (loading)
        return <div className="d-flex justify-content-center flex-column align-items-center flex-grow-1 p-3 border-bottom">
            <div>
                <Spinner animation={"border"} variant={"secondary"} /><br /></div>
            <div>
                {props.company && <small>Loading for {props.company.CompanyName}...</small>}
                {!props.company && <small>Please wait...</small>}
            </div>
        </div>

    if (!grouped || grouped.length == 0) return <></>

    return <>
        <Modal show={state.showMoveStockModal} dialogClassName="modal-corner">
            <StockMove product={state.moveStockInfo.product}
                store={props.company}
                batch={state.moveStockInfo.batch}
                closingQty={state.moveStockInfo.closingQty}
                close={closeAndRefreshAll}
            />
        </Modal>

        <Modal show={state.showSplitModal} dialogClassName="modal-corner">
            <ModalBody>
                <SplitStockSimple
                    compId={state.splitStockInfo.compId}
                    prodId={state.splitStockInfo.prodId}
                    stockId={state.splitStockInfo.stockId}
                    onSplit={closeAndRefreshAll} onCancel={closeModals} />
            </ModalBody>
        </Modal>

        <Modal show={showOpeningStock} dialogClassName="modal-corner" size='lg'>
            <ModalBody>
                <AddOpeningStock
                    prodid={productSelected?.PRDID}
                    store={props.company}
                    productName={productSelected?.ProductName}
                    onClose={closeAndRefreshAll}
                />
            </ModalBody>
        </Modal>

        <Modal show={showProductEdit} size="lg" dialogClassName="modal-corner"
            onHide={closeModals}
            backdrop={'static'}>
            <ModalBody>
                <ProductDetailsEx
                    closePop={closeModals}
                    toggleModalProgBar={() => { }}
                    refreshList={closeAndRefreshAll}
                    id={productSelected?.PRDID} />
            </ModalBody>
        </Modal>

        <div>
            <div className="row border-bottom">
                <div className="col-md-6 text-black">
                    {props.company &&
                        <h5 className='az-content-label'>{props.company?.CompanyName}</h5>
                    }
                    {!props.company &&
                        <h5 className='az-content-label'>All Stores Combined Stock Summary</h5>
                    }
                </div>
                <div className="offset-2 col-md-4">
                    {props.searchText &&
                        <span>Filtered by: <b>{props.searchText}</b></span>
                    }
                </div>
            </div>
            {<div className="table-responsive mb-5">
                <table className="table table-hover mg-b-0">
                    <thead className="border-top">
                        <tr>
                            <th className='wd-1'></th>
                            <th className="w-75">Product</th>
                            <th className="wd-25 text-right border-right">Retail Qty</th>
                            <th className="wd-10 text-right border-right">Inward</th>
                            <th className="wd-10 text-right border-right">Sold</th>
                            <th className="wd-10 text-right">Closing</th>
                        </tr>
                    </thead>
                    {grouped.map((entry: any[], idx: number) => {
                        return (<tbody>
                            <tr><td colSpan={5} className='bg-white'>
                                {entry[1][0].Category && <span className='font-weight-bold text-indigo'>{entry[1][0].Category.TagName}</span>}
                                {!entry[1][0].Category && <span className='font-weight-bold text-warning'>UnGrouped</span>}
                            </td></tr>
                            {entry[1].map((s: any, si: number) => {
                                return (
                                    <tr key={utils.uuidv4()}>
                                        <td key={utils.uuidv4()}>
                                            {props.company &&
                                                <Dropdown overlay={
                                                    <Menu>
                                                        <Menu.Item
                                                            icon={<CreateOutline />}
                                                            className='py-2 px-3'
                                                            key={"m1" + si}
                                                            onClick={() => showEdit(s.Product)}>
                                                            Modify Product
                                                        </Menu.Item>
                                                        <Menu.Item
                                                            icon={<BusOutline />}
                                                            className='py-2 px-3'
                                                            key={"m2" + si}
                                                            onClick={() => {
                                                                showMoveStock(
                                                                    s.Product,
                                                                    s.BatchWise[0]?.Batch,
                                                                    (s.MilledTotal - s.TotalSold))
                                                            }}>

                                                            Move to Another Store

                                                        </Menu.Item>
                                                        <Menu.Item
                                                            icon={<BarcodeOutline />}
                                                            className='py-2 px-3' key={"m3" + si} onClick={() => showSplitStock(s.Product.PRDID, 0)}>
                                                            Split Stock
                                                        </Menu.Item>
                                                        <Menu.Item
                                                            icon={<AddCircleOutline />}
                                                            className='py-2 px-3'
                                                            key={"m4" + si}
                                                            onClick={() => ShowOpeningStockWindow(s.Product)}>
                                                            Add Opening Stock
                                                        </Menu.Item>
                                                    </Menu>
                                                } trigger={['click']}>
                                                    <Button 
                                                    className='ant-btn-custom'
                                                    type='primary' ghost  
                                                    size='small'
                                                    shape='round'
                                                    onClick={e => e.preventDefault()}>
                                                        <span className="small">Edit</span>
                                                    </Button>
                                                </Dropdown>
                                                // <Dropdown title="Edit" drop="down" id={utils.uuidv4()}>
                                                //     <Dropdown.Toggle id={"btn" + idx} variant="outline-light" size={"sm"}>
                                                //         Edit
                                                //     </Dropdown.Toggle>
                                                //     <Dropdown.Menu className='pointer-cursor' id={utils.uuidv4()}
                                                //         popperConfig={{ strategy: "absolute" }}>

                                                //         <Dropdown.Item onClick={() => showEdit(s.Product)}>
                                                //             Modify Product
                                                //         </Dropdown.Item>

                                                //         <Dropdown.Item onClick={() => {
                                                //             showMoveStock(
                                                //                 s.Product,
                                                //                 s.BatchWise[0]?.Batch,
                                                //                 (s.MilledTotal - s.TotalSold))
                                                //         }}>
                                                //             Move to Another Store
                                                //         </Dropdown.Item>

                                                //         <Dropdown.Item onClick={() => showSplitStock(s.Product.PRDID, 0)}>
                                                //             Split Stock
                                                //         </Dropdown.Item>

                                                //         <Dropdown.Item onClick={() => ShowOpeningStockWindow(s.Product)}>
                                                //             Add Opening Stock
                                                //         </Dropdown.Item>

                                                //     </Dropdown.Menu>
                                                // </Dropdown>
                                            }
                                        </td>
                                        <td key={utils.uuidv4()}>
                                            <span className="tx-16-f font-weight-bold">{s.Product.ProductName}</span>
                                        </td>
                                        <td className="text-right tx-18-f border-right">{(s.MilledTotal - s.TotalSold) * s.Unit.RetailQty}</td>
                                        <td key={utils.uuidv4()} className="text-right tx-18-f border-right">{s.MilledTotal}</td>
                                        <td key={utils.uuidv4()} className="text-right tx-18-f border-right">{s.TotalSold}</td>
                                        <td key={utils.uuidv4()} className="text-right tx-18-f font-weight-bold">{s.MilledTotal - s.TotalSold}</td>
                                    </tr>
                                )
                            })}
                        </tbody>)
                    })}
                </table>
            </div>}
        </div>

    </>

}

export default StocksByStore;
