import axios from 'axios';
import * as consts from "../globals/ApiUrlConsts";
import {ML_LabourWageSetting} from "../globals/dto";
import * as dateUtils from '../globals/AppDateUtils';

const base_wage_api_url  = consts.global_api_url_base + '/api/wages';

export const GetAll = async () => await axios.get(base_wage_api_url + '/all');

export const GetMachineID = async (id:number) =>
    await axios.get(base_wage_api_url + '/GetMachineID', {params: { flowID : id}});

export const CalculateWages = async (start:Date, end:Date) =>
    await axios.get(base_wage_api_url + '/CalculateWages', 
    {params: { start:dateUtils.SanitizeDate(start), end:dateUtils.SanitizeDate(end), considerNoProductWageSettingsForCalculation:true}});

export const Save = async (wage : ML_LabourWageSetting) =>
    await axios.post(base_wage_api_url + '/saveWage',wage);

export const Remove = async (id : number) =>
    await axios.post(base_wage_api_url + "/remove", null, {params : {id}});