import React, { useEffect, useState } from 'react';
import { ML_Tag } from "../globals/dto";
import * as tgdb from "../db/TagData";
import Select, { ActionMeta, components, OptionProps, StylesConfig } from "react-select";
import CreatableSelect from 'react-select/creatable';
import * as consts from '../globals/GlobalConsts';
import { Rss } from 'react-bootstrap-icons';
import { SaveButtonUI } from '../globals/AppControls/SaveButtonUI';
import { CloseButtonUI } from '../globals/AppControls/CloseButtonUI';


export const TagsDropdown = (props: {
    tagType: consts.TagTypes,
    selectedTagId: number,
    isError: boolean,
    onSelected(cat: ML_Tag): void,
}) => {

    const [cats, setCats] = useState<ML_Tag[]>(null);
    const [options, setOptions] = useState<any[]>();
    const [optionSelected, setOptionSelected] = useState({ value: '', label: '' });
    const [isLoading, setIsLoading] = useState(false);
    const [isCreateNewTag, setIsCreateNewTag] = useState(false);

    /* -------------- ERROR GLOW CODE -------------- */
    const [errorStyle, setErrorStyle] = useState({});
    const notifyError = (tog: boolean, preStyle: any) => {
        if (tog) {
            return {
                ...preStyle,
                minWidth: 100,
                backgroundColor: consts.CONTROL_ERROR_BACKGROUND_COLOR,
                border: "1px solid " + consts.CONTROL_ERROR_BORDER_COLOR
            }
        }
        return { ...preStyle, minWidth: 100 };
    }
    useEffect(() => {
        setErrorStyle({ control: (style: any) => notifyError(props.isError, style) });
    }, [props.isError])
    /* -------------- ERROR GLOW CODE -------------- */

    useEffect(() => {
        LoadAllTags();
    }, [])

    const LoadAllTags = () => {
        setIsLoading(true);
        tgdb.GetAll(props.tagType).then(res => {
            setCats(res.data);
            let list = res.data.map((x: ML_Tag) => { return ({ value: x.GRPID.toString(), label: x.TagName }) });
            //list = [{ value: '-1', label: '+ Create New' }, ...list, { value: '0', label: '-- NONE --' }];
            list = [{ value: '0', label: '-- NONE --' },...list];
            setOptions(list);
            selectDefault(list);
            setIsLoading(false);
        });
    }

    const handleTagSelect = (value: any) => {
        if (value.value === '-1') {
            setIsCreateNewTag(true);
            selectDefault(cats);
        }
        else {
            setOptionSelected(value);
            let c = cats.filter((c: ML_Tag) => c.GRPID.toString() === value.value);
            if (c.length > 0)
                props.onSelected(c[0]);
            else
                props.onSelected(new ML_Tag());
        }
    }

    const handleCreateNewEvent = (tagName: string) => {
        if (tagName !== '') {
            let newTag: ML_Tag = new ML_Tag();
            newTag.GRPID = 0;
            newTag.TagName = tagName;
            newTag.TagType = props.tagType;

            tgdb.Save(newTag).then(res => {
                if (res.data.StatusCode === consts.CALL_RESPONSE_STATUS_OK) {
                    props.onSelected(res.data.Data);
                    let createdNewTag = { value: res.data.Data.GRPID, label: newTag.TagName }
                    options.push(createdNewTag);
                    setOptionSelected(createdNewTag);
                }
            });
        }
        setIsCreateNewTag(false);
    }


    const selectDefault = (list: ML_Tag[]) => {
        let c: any = list.filter((opt: any) => opt.value === props.selectedTagId.toString());
        if (c && c.length > 0) {
            setOptionSelected(c[0]);
        }
    }

    return <>

        {isCreateNewTag && <NewTagCreator onDone={handleCreateNewEvent} />}


        {cats && !isCreateNewTag &&
            <CreatableSelect
                styles={errorStyle}
                classNamePrefix="srushti"
                theme={theme => ({ ...theme })}

                options={options}
                value={optionSelected}
                isLoading={isLoading}
                onChange={handleTagSelect}
                onCreateOption={handleCreateNewEvent}
            />}
    </>
}


const NewTagCreator = (props: {
    onDone(val: string): void
}) => {
    const [name, setName] = useState('');

    return <div className="d-flex flex-column flex-md-row">
        <input type="text" className="form-control" onChange={(e: any) => setName(e.target.value)} />
        <div>
            <SaveButtonUI onClick={() => props.onDone(name)} />
            <CloseButtonUI onClick={() => props.onDone('')} />
        </div>

    </div>
}