import axios from 'axios';
import * as global from '../globals/ApiUrlConsts';
import {CallActionResult, ML_ProductBatch} from "../globals/dto";

const web_api = global.global_api_url_base + '/api/process';

export const MoveInwardToSales = async (inwardID) => 
    await axios.post(web_api + '/MoveInwardToSales',null,{params: {iwid: inwardID}});

export const MoveToOtherStore = async ( fromCompID,  toCompID, ProdID, batchID, qty) => 
    await axios.post(web_api + '/MoveToOtherStore',null,{params:
            { fromCompID : fromCompID,  toCompID : toCompID, ProdID:ProdID, batchID:batchID, qty:qty}
    });

export const AddOpeningStock = async (prdid, compid, qty) =>
    await axios.post(web_api + '/AddOpeningStock',null, {params: { prdid, compid, qty }});

export const DeleteOpeningStock = async (stkid) =>
    await axios.post(web_api + '/DeleteOpeningStock',null, {params: { stkid }});
export const MoveToNextMachineEx = async (parentProcesID , destProcessFlowID, moveOutQty, 
    batchID = 0, productID = 0, isReRouted = false, isAllRawItemsConsumed) =>
    await axios.post(web_api + '/MoveToNextMachineEx', null ,
        {params : {parentProcesID , destProcessFlowID, moveOutQty, batchID, productID,isReRouted, isAllRawItemsConsumed}})

export const MoveSubProductToAnotherMachineEx = async (parentProcesID , destProcessFlowID, moveOutQty, batchID = 0, productID = 0) =>
    await axios.post(web_api + '/MoveSubProductToAnotherMachineEx', null ,
        {params : {parentProcesID , destProcessFlowID, moveOutQty, batchID, productID}})

export const MoveProductionToStore = async (pid , compID, prdID, qty) =>
    await axios.post(web_api + '/MoveProductionToStore', null ,{params : {pid , compID, prdID, qty}})

export const InwardMoveToMachineEx = async (lineitemID , moveOutQty, nextFlowID, batchID = 0) =>
    await axios.post(web_api + '/InwardMoveToMachineEx', null ,
        {params : {lineitemID , moveOutQty,nextFlowID, batchID}})

export const PrepareTransitFromMachine  = async (pid) => 
    await axios.get(web_api + '/PrepareTransitFromMachine',{params:{pid:pid, lineItemID:0, macid:0}});

export const PrepareTransitFromInward = async (wfid, lineItemID) =>{
    console.log("lineItemID", lineItemID);
    return await axios.get(web_api + '/PrepareTransitFromInward', {params:{workflowId : wfid, lineItemID:lineItemID}});
}

export const NewBatch = async () => await axios.get(web_api + '/GenerateNewBatch')

export const CloseBatch = async (batid) =>await axios.get(web_api + '/CloseBatch?batid=' + batid)

export const GetBatchList = async () =>  await axios.get(web_api + '/GetBatches')

export const GetLogsOfLineItem = async (liid) =>
    await axios.get(web_api + '/GetProcessingLogsOnItem', {params: {liid}});

export const GetReviewByTrackId = async (logid) =>
    await axios.get(web_api + '/GetReviewByTrackId', {params: {logid}});

export const GetProcessorView = async (pid) =>
    await axios.get(web_api + '/GetProcessorView', {params: {pid}});

export const GetExecutionOrderForProcessor = async (pid) =>
    await axios.get(web_api + '/GetExecutionOrderForProcessor', {params: {pid}});