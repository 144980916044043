import React, { useEffect, useState } from 'react';
import Flatpickr from 'react-flatpickr';
import * as utils from "../globals/Utils";
/*import "flatpickr/dist/themes/material_blue.css";*/
import "flatpickr/dist/themes/light.css";

export const DateControlEx = (props:{
    datevalue:Date,
    onDateChange(e:string):void
}) => {
    
    const [showDate, setShowDate] = useState(new Date());
    useEffect(()=>{
        setShowDate(new Date(props.datevalue));
    },[])

    const handleChange = (e:any, strDate:string, inst:any) => {
        // setShowDate(e);
        // console.log("Date obj: " + JSON.stringify(e));
        // console.log("Date str: " + str);
        // console.log("Date derived: " + new Date(str));
        //let selDate =new Date(e[0]);
        props.onDateChange(strDate);
    }

    return <>
        <Flatpickr className="form-control text-center bg-white pointer-cursor" onChange={handleChange} value={showDate}
                   options={{dateFormat :"Y-M-d", altInput:true, altFormat:'d-M-Y, l'}} {...props}/>
        <style>
            {`
            .numInput {
                font-size:1.2rem !important;
                font-weight:bold !important;
            }
            .numInputWrapper {
                width:65px !important;
            }
`}
        </style>
    </>
}
