import React, {useState} from "react";
import {ML_Workflow} from "../globals/dto";
import * as wdb from '../db/WorkflowData';
import {Modal, ModalBody} from "react-bootstrap";
import ModalHeader from "react-bootstrap/ModalHeader";
import Swal from "sweetalert2";
import {WorkflowSelectorDropDown} from "../shared/WorkflowSelectorDropDown";
import {toast} from 'react-toastify';
import { AddNewButtonUI } from "../globals/AppControls/AddNewButtonUI";
import { CloseButtonUI } from "../globals/AppControls/CloseButtonUI";
import { SaveButtonUI } from "../globals/AppControls/SaveButtonUI";


export const ButtonNewWorkflow = (props:any)=>{
    const [showModal,setShowModal] = useState(false);
    return (<div {...props}>
        <WorkflowModal workflow={new ML_Workflow()} show={showModal} saved={(id:number)=> {
            props.saved(id);
            setShowModal(false);
        }}/>
        {/* <button className="btn btn-az-primary" onClick={()=>setShowModal(true)}>
            +New Workflow
        </button> */}
        <AddNewButtonUI onClick={()=>setShowModal(true)}/>
        </div>
    );
}

export const ButtonEditWorkflow = (props:any)=>{
    const [showModal,setShowModal] = useState(false);
    return (<>
            <WorkflowModal workflow={props.workflow} show={showModal} saved={(id:number)=> {
                props.saved(id);
                setShowModal(false);
            }}/>
            <a className="pointer-cursor text-secondary font-weight-bold"
               title={"Edit Workflow"}
               onClick={()=>setShowModal(true)}>
                <i className="fa fa-ellipsis-h text-primary"/>
            </a>
        </>
    );
}

export const ButtonDeleteWorkflow = (props:any)=> {
    const clickDelete = () => {

        Swal.fire({
            title: 'Delete Worfklow',
            html: "Are you sure want to delete this workflow?",
            showCancelButton: true,
            confirmButtonColor: 'red',
            focusCancel: true,
        }).then((res: any) => {
            if (res.value) {
                wdb.deleteWorkflow(props.wfid).then(res => {
                    if(res.data !== 'OK')
                        toast.error(res.data)
                    else
                        props.saved();
                });
            }
        });
    }

    return (
    <a className="pointer-cursor text-danger font-weight-bold mr-3"  title={"Delete workflow"} onClick={clickDelete}>
        <i className="fa fa-trash text-danger"/>
    </a>
    );
}

export const WorkflowHeader  = (props:any)=>{
    return (
        <>
            <div className="row">
                <div className="col-8">
                    <span className="tx-gray-500">Workflow</span>
                    <h4 className="font-weight-bold mb-0 pb-0">{props.workflow.WorkflowName}</h4>
                </div>
                <div className="col-4 text-right mt-3">
                    <ButtonDeleteWorkflow wfid={props.workflow.WFID} saved={()=>{
                        window.location.href='/wflist';
                    }} />
                    <ButtonEditWorkflow workflow={props.workflow} saved={props.saved}/>
                </div>
            </div>
        </>
    );
}

export const WorkflowModal = (props:any)=> {
    return (
        <Modal show={props.show}>
            <ModalHeader className="bg-gray-200 p-3">
                <h4 className="pb-0 mb-0 font-weight-bold">Workflow Details</h4>
            </ModalHeader>
            <ModalBody>
                <WorkflowDetails workflow={props.workflow} saved={props.saved}/>
            </ModalBody>
        </Modal>
    );
}

export const WorkflowDetails = (props:any)=> {

    const [wflow, setWflow] = useState<ML_Workflow>(props.workflow);
    const [sourceWfId, setSourceWfId] = useState(0);

    const handleChange = (e:any)=> {
        let val = e.target.value;
        setWflow({...wflow, WorkflowName : val});
    }

    const saveWorkflow = ()=> {
        if(sourceWfId > 0){
            wdb.copyWorkflow(wflow.WorkflowName,sourceWfId).then(res=>{
                props.saved(true);
            })
        } else {
            wdb.saveWorkflow(wflow).then(res => {
                props.saved(+res.data);
            });
        }
    }

    return (
        <div className="row">
            <div className="col-12">
                <label>Workflow Name</label>
                <input type="text" className="form-control" value={wflow.WorkflowName} onChange={handleChange}/>
            </div>
            <div className="col-12 mt-3">
                <label>Copy Settings from</label>
                <WorkflowSelectorDropDown selectedId={sourceWfId} onChange={(e:number)=>setSourceWfId(e)}/>
            </div>
            <div className="col-12 text-center pt-3">
                {/* <button className="btn btn-success mr-3" onClick={saveWorkflow}>Save</button>
                <button className="btn btn-secondary" onClick={()=>props.saved(0)}>Cancel</button> */}

                <SaveButtonUI  onClick={saveWorkflow} className="mr-3" />
                <CloseButtonUI onClick={()=>props.saved(0)} />
            </div>
        </div>
    );
}