import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { ReportFilter, ReportTableColumn } from '../globals/dto';
import * as consts from '../globals/GlobalConsts';

const ReportFilterControls = (props: any) => {

    const [filter, setFilter] = useState<ReportFilter>(props.filter);

    const onChange = (e: any) => {
        let name = e.target.name;
        let val = e.target.value;
        setFilter({ ...filter, [name]: val });
    }

    const saveFilterInfo = () => {
        console.log(JSON.stringify(filter));

        if (!filter.ColumnName || !filter.FilterValue || !filter.Operator) {
            toast.error('Insufficient info to save filter');
            return;
        }
        if (!filter.FilterName){
            console.log('no name found');
            props.onSave({ ...filter, "FilterName" : filter.ColumnName });
            props.onClosed();
            return;
        }

        props.onSave(filter);
        props.onClosed();
    }

    return (

        <div className="card">
            <div className="card-header">
                <div className="d-flex justify-content-between align-items-center">
                    <div>Report Name</div>
                    <div className="flex-grow-1 pl-2"><input type="text" className="form-control"
                        value={filter.FilterName} onChange={onChange} name="FilterName" /></div>
                </div>
            </div>
            <div className="card-body">


                <div className="row">
                    <div className="col-5">
                        <label>Column Name</label>
                        <select className="form-control form-select" value={filter.ColumnName} name="ColumnName" onChange={onChange}>
                            <option key="fc_00" value="">&nbsp;</option>
                            {props.columns && props.columns.map((c: ReportTableColumn, idx: number) => {
                                return <option key={"fc_" + idx} value={c.ColumnActualName}>{c.ColumnActualName}</option>
                            })}
                        </select>
                    </div>
                    <div className="col-3">
                        <label>Datatype</label>
                        <select className="form-control form-select" value={filter.ColumnDataType} name="ColumnDataType" onChange={onChange}>
                            <option value=""></option>
                            <option value={consts.DATATYPE_STRING}>String</option>
                            <option value={consts.DATATYPE_INT}>Integer</option>
                        </select>
                    </div>
                    <div className="col-4">
                        <label>Operator</label>
                        <select className="form-control form-select" value={filter.Operator} name="Operator" onChange={onChange}>
                            <option key="fx_00" value="">&nbsp;</option>
                            <option value={consts.OPERATOR_EQUAL}>=</option>
                            <option value={consts.OPERATOR_NOT_EQUAL}>!=</option>
                            <option value={consts.OPERATOR_GREATER}>&gt;=</option>
                            <option value={consts.OPERATOR_LESSER}>=&lt;</option>
                            <option value={consts.OPERATOR_CONTAINS}>Contains</option>
                            <option value={consts.OPERATOR_NOT_CONTAINS}>Not-Contains</option>
                        </select>
                    </div>
                    <div className="col-12 pt-3">
                        <label>Filter CSV value(s)</label>
                        <input type="text" className="form-control" value={filter.FilterValue} name="FilterValue" onChange={onChange} />
                    </div>
                </div>
            </div>
            <div className="card-footer">
                <div className="d-flex flex-row justify-content-between">
                    <div>
                        <button className="btn btn-success" onClick={saveFilterInfo}>Save</button>
                    </div>
                    <div>
                        <button className="btn btn-primary" onClick={props.onClosed}>Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    );


}

export default ReportFilterControls;