import axios from 'axios';
import * as global from '../globals/ApiUrlConsts';
import {ML_TagLink, ML_Tag, CallActionResult} from "../globals/dto";

const base_tax_api_url = global.global_api_url_base + '/api/tags';

export const GetAll = async (tagType:number) => 
await axios.get(base_tax_api_url + '/getall', {params: {tagType : tagType}});

export const GetSupplierTagLink = async (entId :number) => 
await axios.get(base_tax_api_url + '/getLink', {params: {etType : 1, tagID : entId}});

export const GetCustomerTagLink = async (entId :number) => 
await axios.get(base_tax_api_url + '/getLink', {params: {etType : 2, tagID : entId}});

export const GetBrokerTagLink = async (entId :number) => 
await axios.get(base_tax_api_url + '/getLink', {params: {etType : 3, tagID : entId}});

export const Save = async (tx:ML_Tag) => await axios.post<CallActionResult>(base_tax_api_url + '/SaveTag',tx);

export const DeleteTag = async (id : number) => 
await axios.post(base_tax_api_url + '/RemoveTag', null, {params: {groupId: id}});

export const LinkWithTag = async(tl : ML_TagLink)=> await axios.post(base_tax_api_url + "/LinkWithTag",tl)

export const UnLinkWithTag = async(tagId : number, entityId :number)=>
{ await axios.post(base_tax_api_url + "/UnlinkWithTag",null,{params: {tagId : tagId, etId:entityId }});}

