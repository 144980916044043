import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import { useDispatch } from 'react-redux';
import * as AppAct from '../globals/AppActions';
import { ML_Report, ML_ReportDataset, ReportFilter, ReportSettings, ReportTableColumn } from '../globals/dto';
import * as rsdb from '../db/ReportSettingsData';
import { toast } from 'react-toastify'
import '../globals/Toggler';
import Toggler from '../globals/Toggler';
import ReportFilterPane  from './report-filter-pane';

const ReportDesigner = (props: any) => {

    const appDisp = useDispatch();
    const [datasets, setDatasets] = useState<ML_ReportDataset[]>();
    const [dsColumns, setDsColumns] = useState<Array<ReportTableColumn>>();
    const [config, setConfig] = useState<ReportSettings>(new ReportSettings());
    const [userReport, setUserReport] = useState<ML_Report>(new ML_Report());
    const [colSearch, setColSearch] = useState('');
    const [reportPreview, setReportPreview] = useState([]);

    useEffect(() => {
        appDisp(AppAct.ChangeMainTitle('Report Builder'));
        initializeReport();
    }, [])

    const initializeReport = () => {

        let rep: ML_Report = props.report;
        setUserReport(rep);
        if (props.report !== null){
            setReportPreview([]);
            setConfig(JSON.parse(rep.ReportConfigDetails));
        }

        rsdb.GetAllDataSets().then(res => {
            setDatasets(res.data);
        }).catch(err => toast.error(err));
    }


    useEffect(() => {
        console.log('changed' + props.report.RPTID);
        initializeReport();
    }, [props.report.RPTID])

    useEffect(() => {
        loadColumns();
    }, [userReport.ReportDatasetID])



    const MergeMasterAndUsersList = (masterList: string[], userList: ReportTableColumn[]) => {
        let colmnsViewlist = new Array<ReportTableColumn>();

        masterList.forEach(col => {
            let newCol = new ReportTableColumn();
            newCol.ColumnActualName = col;
            let foundIndex = userList.findIndex(ul => ul.ColumnActualName === col);
            newCol.isEnabled = foundIndex > -1;

            colmnsViewlist.push(newCol);
        });
        setDsColumns(colmnsViewlist);
    }

    // const datasetChangeHandler = (e: any) => {
    //     let val = e.target.value;
    //     rsdb.GetColumns(+val).then(res => {
    //         MergeMasterAndUsersList(res.data, config.Columns);
    //     });
    // }

    const onTextChange = (e: any) => {
        let name = e.target.name;
        let val = e.target.value;

        // if (name === 'ReportDatasetID') {
        //     loadColumns();
        // }
        setUserReport({ ...userReport, [name]: val });
    }

    const loadColumns = () => {
        if(config && config.Columns){
            rsdb.GetColumns(+userReport.ReportDatasetID).then(res => {
                    MergeMasterAndUsersList(res.data, config.Columns);
            });
        }
    }

    const onColumnToggled = (tog: boolean, name: string) => {

        if (tog) {
            if (config && config.Columns.findIndex(x => x.ColumnActualName === name) < 0) {
                let col = new ReportTableColumn();
                col.ColumnActualName = name;
                col.isEnabled = false;

                let configCopy = { ...config };
                configCopy.Columns.push({...col, isEnabled:true});
                configCopy.SumColumns.push(col);
                configCopy.CountColumns.push(col);
                setConfig(configCopy);
                //setConfig((prev:ReportSettings)=> {return {...prev, configCopy}});
            }
        } else {
            if (config && config.Columns.findIndex(x => x.ColumnActualName === name) >= 0) {
                let filteredCols = config.Columns.filter(x => x.ColumnActualName !== name);

                let configCopy = { ...config };
                configCopy.Columns = [...filteredCols];
                configCopy.SumColumns = [...filteredCols];
                configCopy.CountColumns = [...filteredCols];
                setConfig(configCopy);
            }
        }
    }

    const getPreviewColumns = () => {
        if (config && config.Columns) {
            let ret = config.Columns.map((lv: ReportTableColumn) => {
                return { name: lv.ColumnActualName, selector: lv.ColumnActualName, sortable: true }
            });
            return ret;
        } else
            return new Array<any>();
    }


    const SaveAll = () => {
        let rr = { ...userReport };
        rr.ReportConfigDetails = JSON.stringify(config);

        rsdb.Save(rr).then(res => {
            if (res.data > 0) {
                setUserReport( {...userReport, RPTID: res.data, ReportConfigDetails: rr.ReportConfigDetails });
                props.onSaveAll();
                toast.success('Saved!');
            } else {
                toast.error('Unable to save, Sorry!');
            }
        }).catch(err => toast.error(err));
    }

    const toggleEnableItem = (collection: ReportTableColumn[], colName: string) => {
        let index = collection.findIndex(x => x.ColumnActualName === colName);
        collection[index].isEnabled = !collection[index].isEnabled;
        return collection;
    }

    const countColumnSelected = (tog: boolean, colName: string) => {
        setConfig({ ...config, CountColumns: toggleEnableItem([...config.CountColumns], colName) });
    }

    const sumColumnSelected = (tog: boolean, colName: string) => {
        setConfig({ ...config, SumColumns: toggleEnableItem([...config.SumColumns], colName) });
    }

    const previewReport = () => {
        rsdb.GenerateReport(userReport.RPTID).then(res => {
            setReportPreview(res.data);
        })
    }

    if (config && userReport) {
        return (
            <div className="row">
                {/* {console.log('rendered : ' + new Date())} */}
                <div className="col-md-3">

                    <div className="card mb-1 p-1">
                        <select className="form-control form-select border-0" disabled={userReport.RPTID > 0} onChange={onTextChange} value={userReport.ReportDatasetID} name="ReportDatasetID">
                            <option key="0"> -- </option>
                            {datasets && datasets.map(ds => {
                                return <option key={ds.RPTDID} value={ds.RPTDID}>{ds.DatasetName}</option>
                            })}
                        </select>
                    </div>

                    <div className="accordion" id="accordionExample">
                        <div className="card">
                            <div id="headingOne">
                                <h2 className="mb-0">
                                    <button className="btn btn-link btn-block text-left font-weight-bold" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                        Report Columns
                                    </button>
                                </h2>
                            </div>
                            <div id="collapseOne" className="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
                                <div className="card-body p-3">
                                    {/* <input type="text" value={colSearch} className="form-control mb-3" placeholder="Search..." onChange={(e)=>setColSearch(e.target.value)}/> */}
                                    {dsColumns && dsColumns.filter(f => colSearch === '' || f.ColumnActualName.toLowerCase().includes(colSearch)).map((c: ReportTableColumn, idx: number) => {
                                        return <div className="d-flex flex-row align-items-center" key={'ci' + idx}>
                                            <div className="flex-grow-1" >{c.ColumnActualName}</div>
                                            <div><Toggler
                                                onToggle={onColumnToggled}
                                                status={c.isEnabled}
                                                name={c.ColumnActualName}
                                                className="text-black pointer-cursor h3" />
                                            </div>
                                        </div>
                                    })}
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div id="headingTwo">
                                <h2 className="mb-0">
                                    <button className="btn btn-link btn-block text-left collapsed  font-weight-bold" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                        Count Columns
                        </button>
                                </h2>
                            </div>
                            <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                                <div className="card-body p-3">
                                    {config && config.CountColumns.length > 0 && config.CountColumns.map((c: ReportTableColumn, idx: number) => {
                                        return <div className="d-flex flex-row" key={'cci' + idx}>
                                            <div className="flex-grow-1" >{c.ColumnActualName}</div>
                                            <div><Toggler
                                                onToggle={countColumnSelected}
                                                status={c.isEnabled}
                                                name={c.ColumnActualName}
                                                className="h3 text-black pointer-cursor ml-2" />
                                            </div>
                                        </div>
                                    })}
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div id="headingThree">
                                <h2 className="mb-0">
                                    <button className="btn btn-link btn-block text-left collapsed  font-weight-bold" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                        Sum Columns
                        </button>
                                </h2>
                            </div>
                            <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                                <div className="card-body p-3">
                                    {config && config.SumColumns.map((c: ReportTableColumn, idx: number) => {
                                        return <div className="d-flex flex-row" key={'sci' + idx}>
                                            <div className="flex-grow-1" >{c.ColumnActualName}</div>
                                            <div><Toggler
                                                onToggle={sumColumnSelected}
                                                status={c.isEnabled}
                                                name={c.ColumnActualName}
                                                className="h3 text-black pointer-cursor ml-2" />
                                            </div>
                                        </div>
                                    })}
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div id="headingFour">
                                <h2 className="mb-0">
                                    <button className="btn btn-link btn-block text-left collapsed  font-weight-bold" type="button" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseThree">
                                        Filters
                        </button>
                                </h2>
                            </div>
                            <div id="collapseFour" className="collapse" aria-labelledby="headingFour" data-parent="#accordionExample">
                                <div className="card-body p-3">
                                    <ReportFilterPane filters = {config.Filters} columns={config.Columns} onChanged={(filters:ReportFilter[])=>{
                                        console.log('received parent: ' + JSON.stringify(filters,null,1));
                                        setConfig({...config, Filters:filters});
                                }}></ReportFilterPane>
                                    {/* <button className="btn btn-sm btn-primary" onClick={()=>{setShowFilterModal(true)}}>+Add New</button>
                                    <ReportFilterControls show={showFilterModal} filter={}></ReportFilterControls> */}

                                </div>
                            </div>
                        </div>


                    </div>

                    <div className="card mb-1">
                        <div className="card-body">
                            <label>Group by</label>
                            <select className="form-control form-select">
                                <option key="gb_0"> -- </option>
                                {config && config.Columns.map((c: ReportTableColumn, idx: number) => {
                                    return <option key={"gb_" + idx} value={c.ColumnActualName}>{c.ColumnActualName}</option>
                                })}
                                {/* {columns && columns.map((c:string,idx:number)=>{
                            return <option key={"gb_"+idx} value={c}>{c}</option>
                        })} */}

                            </select>
                        </div>
                    </div>

                </div>

                <div className="col-md-9">
                    <div className="d-flex flex-row justify-content-between align-items-end mb-3">
                        <div>
                            <label>Report Name</label>
                        </div>
                        <div className="flex-grow-1 px-1">

                            <input type="text" onChange={onTextChange} value={userReport.ReportName} name={"ReportName"} className="form-control" />
                        </div>
                        <div className="px-1">
                            <button className="btn btn-info" onClick={previewReport}>Preview</button>
                        </div>
                        <div className="px-1">
                            <button className="btn btn-success" onClick={SaveAll}>Save All</button>
                        </div>
                    </div>
                    <div>
                        {reportPreview && reportPreview.length > 0 &&
                            <DataTable className="table-responsive"
                                title="Preview Table"
                                columns={getPreviewColumns()}
                                data={reportPreview}
                            />
                        }
                    </div>
                </div>

            </div>);
    } else {
        return <></>
    }

}

export default ReportDesigner;
