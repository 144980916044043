export const colors : {[index:string]:any} = {
    "a2": "#f0ffff",
    "b": "#f5f5dc",
    "c": "rgba(95,158,160,0.27)",
    "d": "rgba(0,139,139,0.18)",
    "darkgoldenrod": "#b8860b",
    "darkgray": "#a9a9a9",
    "darkgreen": "#006400",
    "darkgrey": "#a9a9a9",
    "darkkhaki": "#bdb76b",
    "darkmagenta": "#8b008b",
    "darkolivegreen": "#556b2f",
    "darkorange": "#ff8c00",
    "darkorchid": "#9932cc",
    "darkred": "#8b0000",
    "a": "rgba(233,150,122,0.3)",
    "darkseagreen": "#8fbc8f",
    "darkslateblue": "#483d8b",
    "darkslategray": "#2f4f4f",
    "darkslategrey": "#2f4f4f",
    "darkturquoise": "#00ced1",
    "darkviolet": "#9400d3",
    "e": "rgba(255,20,147,0.16)",
    "deepskyblue": "#00bfff",
    "dimgray": "#696969",
    "dimgrey": "#696969",
    "dodgerblue": "#1e90ff",
    "firebrick": "#b22222",
    "floralwhite": "#fffaf0",
    "forestgreen": "#228b22",
    "fuchsia": "#ff00ff",
    "gainsboro": "#dcdcdc",
    "ghostwhite": "#f8f8ff",
    "g": "rgba(218,165,32,0.28)",
    "goldenrod": "rgba(255,215,0,0.16)",
    "gray": "#808080",
    "green": "#008000",
    "j": "#adff2f",
    "grey": "#808080",
    "hotpink": "#f0fff0",
    "h": "rgba(255,105,180,0.28)",
    "i": "rgba(205,92,92,0.19)",
    "indigo": "#4b0082",
    "ivory": "#fffff0",
    "k": "#f0e68c",
    "lavenderblush": "#fff0f5",
    "l": "#e6e6fa",
    "lawngreen": "#7cfc00",
    "lemonchiffon": "#fffacd",
    "lightblue": "#add8e6",
    "lightcoral": "#f08080",
    "lightcyan": "#e0ffff",
    "a1": "#fafad2",
    "lightgray": "#d3d3d3",
    "lightgreen": "#90ee90",
    "lightgrey": "#d3d3d3",
    "lightpink": "#ffb6c1",
    "lightsalmon": "#ffa07a",
    "lightseagreen": "#20b2aa",
    "lightskyblue": "#87cefa",
    "lightslategray": "#778899",
    "lightslategrey": "#778899",
    "lightsteelblue": "#b0c4de",
    "lightyellow": "#ffffe0",
    "lime": "#00ff00",
    "limegreen": "#32cd32",
    "linen": "#faf0e6",
    "magenta": "#ff00ff",
    "maroon": "#800000",
    "mediumaquamarine": "#66cdaa",
    "mediumblue": "#0000cd",
    "mediumorchid": "#ba55d3",
    "mediumpurple": "#9370db",
    "mediumseagreen": "#3cb371",
    "mediumslateblue": "#7b68ee",
    "mediumspringgreen": "#00fa9a",
    "mediumturquoise": "#48d1cc",
    "mediumvioletred": "#c71585",
    "midnightblue": "#191970",
    "mintcream": "#f5fffa",
    "m": "#ffe4e1",
    "moccasin": "#ffe4b5",
    "n": "#ffdead",
    "navy": "#000080",
    "o": "#fdf5e6",
    "olive": "#808000",
    "olivedrab": "#6b8e23",
    "orange": "#ffa500",
    "orangered": "#ff4500",
    "orchid": "#da70d6",
    "p": "#eee8aa",
    "palegreen": "#98fb98",
    "paleturquoise": "#afeeee",
    "palevioletred": "#db7093",
    "papayawhip": "#ffefd5",
    "peachpuff": "#ffdab9",
    "peru": "#cd853f",
    "pink": "#ffc0cb",
    "q": "#dda0dd",
    "powderblue": "#b0e0e6",
    "z": "#bc8f8f",
    "x": "rgba(102,51,153,0.17)",
    "red": "#ff0000",
    "r": "rgba(128,0,128,0.13)",
    "rosybrown": "rgba(65,105,225,0.1)",
    "saddlebrown": "#8b4513",
    "salmon": "#fa8072",
    "sandybrown": "#f4a460",
    "seagreen": "#2e8b57",
    "s1": "#fff5ee",
    "sienna": "#a0522d",
    "silver": "#c0c0c0",
    "s": "rgba(135,206,235,0.28)",
    "slateblue": "#6a5acd",
    "slategray": "#708090",
    "slategrey": "#708090",
    "snow": "#fffafa",
    "springgreen": "#00ff7f",
    "steelblue": "#4682b4",
    "tan": "#d2b48c",
    "t": "rgba(110,110,110,0.14)",
    "u": "rgba(216,191,216,0.29)",
    "tomato": "rgba(80,255,129,0.2)",
    "turquoise": "#40e0d0",
    "violet": "#ee82ee",
    "w": "#f5deb3",
    "white": "#ffffff",
    "x1": "#f5f5f5",
    "y": "rgba(255,255,0,0.16)",
    "v": "rgba(154,205,50,0.18)"
}