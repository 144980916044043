import React, { useEffect, useState } from 'react';
import {Modal, ModalBody} from "react-bootstrap";
import {SaleInvoiceView} from "../sales/SaleInvoiceView";
import {InwardInvoiceView} from "../inwards/InwardInvoiceView";

type attributes = {
    saleBillID :number,
    inwardID :number,
    onClose():void
}

export const InvoiceViewer = (props:attributes)=> {
    const [showSaleBill, setShowSaleBill] = useState(false);
    const [showInward, setShowInward] = useState(false);

    useEffect(()=>{
        if(props.saleBillID > 0)
            setShowSaleBill(true);
        else if(props.inwardID > 0)
            setShowInward(true);
    },[props.saleBillID, props.inwardID])

    const closeView = ()=>{
        props.onClose();
        setShowSaleBill(false);
        setShowInward(false);
    }

    return(<>
        <Modal size={"lg"} show={showSaleBill} keyboard={true} dialogClassName="modal-corner"
               onHide={closeView}
               onEscapeKeyDown={closeView} >
            <ModalBody>
                <SaleInvoiceView key={"sbl"+props.saleBillID} sbid={props.saleBillID} />
            </ModalBody>
        </Modal>

        <Modal size={"lg"} show={showInward} keyboard={true} dialogClassName="modal-corner"
               onHide={closeView}
               onEscapeKeyDown={closeView} >
            <ModalBody>
                <InwardInvoiceView key={"iwbl"+props.inwardID} iwid={props.inwardID} />
            </ModalBody>
        </Modal>
    </>)
}
