import { Button } from "antd";
import React from "react";
import { Add } from 'react-ionicons';
import { PlusOutlined } from '@ant-design/icons';

export const AddNewButtonUI = (props: any) => {
    return <button {...props}
        className={'btn btn-az-primary pr-3 ' + props.className}
        onClick={props.onClick}
        accessKey={props.accessKey ? props.accessKey : (!props.title ? "n" : "")}
    >
        <Add style={{ 'paddingBottom': '2px' }} color={'white'} />
        {!props.buttonText && <span>Add <u>N</u>ew</span>}
        {props.buttonText && <span dangerouslySetInnerHTML={{ __html: props.buttonText }} />}
    </button>
}
