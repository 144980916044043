import React, {useState} from 'react';
import {ML_Company} from "../globals/dto";
import StoresSelect from "../shared/StoresSelect";
import * as pdb from '../db/ProcessingData';
import {toast} from "react-toastify";
import TextBoxNumeric from '../ui-controls/TextBoxNumeric';

const StockMove = (props:any)=> {
    const [move, setMove]=useState(
        {
            fromStore : props.store? props.store.CMPID : 0,
            toStoreID: 0,
            qty : 0,
            prodID:props.product?.PRDID,
            batchID: props.batch? props.batch.BATID : 0,
            closingQty : props.closingQty
        }
    );

    const handleInput = (e :any)=>{
        let name = e.target.name;
        let val = e.target.value;

        if(name === 'qty'){
            setMove({...move, qty: val})
        } /*else if(name=='store'){
            setMove({...move, toStoreID: +val})
        }*/
    }

    const handleStoreChange = (e:any)=> {
        setMove({...move, toStoreID: e.CMPID});
    }

    const TransferStock =()=>{
        if(move.qty > props.closingQty)
            toast.error('Move Qty is more than stock on-hand');
        else {
            if(move.toStoreID <= 0 || move.qty <= 0)
                toast.error("Set proper 'To store' and 'Qty'");
            else if(move.toStoreID === move.fromStore)
                toast.error("To and From stores are same. Move not required");
            else {
                pdb.MoveToOtherStore(move.fromStore, move.toStoreID, move.prodID, move.batchID, move.qty).then((res: any) => {
                    props.close()
                });
            }
        }
    }

    return(
        <>
        <div className="card">
            <div className="card-header">
                Moving Product..<br/>
                <span className="text-indigo tx-20-f font-weight-bold">{props.product?.ProductName}</span>
                &nbsp;Qty:<span className="text-danger tx-20-f font-weight-bold">{props.closingQty}</span>
            </div>
            <div className="card-body">
                <div className="row form-group">
                    <div className="col-md-12">
                        <label className="form-label">From Store</label>
                        <h5>{props.store?.CompanyName}</h5>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-9">
                        <label className="form-label">To Store</label>
                        <StoresSelect onChange={handleStoreChange}/>
                        {/*<select className="form-control" name="store" value={move.toStoreID}>
                            {props.stores?.map((s:ML_Company, idx :number)=>{return (
                            <option value={s.CMPID}>{s.CompanyName}</option>
                            )})}
                        </select>*/}
                    </div>
                    <div className="col-md-3">
                        <label className="form-label">Move Qty</label>
                        <TextBoxNumeric onChange={handleInput} name={"qty"} value={move.qty} className="form-control" />
                    </div>
                </div>

            </div>
            <div className="card-footer d-flex flex-row justify-content-between">
                <button className="btn btn-success" onClick={TransferStock}>Move the stock</button>
                <button className="btn btn-secondary" onClick={props.close}>Cancel</button>
            </div>
        </div>
        </>
    )

}

export default StockMove;