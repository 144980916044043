import React, { useEffect, useState } from 'react';
import { ML_Ledger } from "./dto";
import * as ldb from "../db/LedgerData";
import Select, { components, OptionProps } from "react-select";
import * as utils from "./../globals/Utils";
import { AppReduxState } from '../infra-redux/AppStateReduxStore';
import { useSelector } from 'react-redux';
import * as consts from './../globals/GlobalConsts';


type attributes = {
    selectLedgerId: number,
    isEditable: boolean,
    onLedgerSelected(e: ML_Ledger): void,
    isError: boolean
}

const LedgerWithStatsDropDownControl = (props: attributes) => {

    const reduxState = useSelector((state: AppReduxState) => state);
    const [ledgers, setLedgers] = useState(new Array<any>());
    const [ledgerSelected, setLedgerSelected] = useState(new ML_Ledger());
    const [errorStyle, setErrorStyle] = useState({});

    const notifyError = (tog: boolean, preStyle: any) => {
        if (tog) {
            return {
                ...preStyle,
                backgroundColor: consts.CONTROL_ERROR_BACKGROUND_COLOR,
                border: "1px solid " + consts.CONTROL_ERROR_BORDER_COLOR
            }
        }
        return { ...preStyle };
    }

    const LoadAllLedgersWithBalance = () => {
        ldb.getAllWithBalance().then(res => {
            setLedgers(res.data);
            selectLedger(res.data);
        });
    }

    useEffect(() => {
        LoadAllLedgersWithBalance();
    }, [])

    useEffect(() => {
        setErrorStyle({ control: (style: any) => notifyError(props.isError, style) });
    }, [props.isError])

    const selectLedger = (allLedgers: any[]) => {
        if (props.selectLedgerId > 0) {
            let ldg = allLedgers.find((m: any) => m.Ledger.LDGID === props.selectLedgerId);
            console.log('Select Ledger found', ldg, allLedgers);
            if (!ldg)
                setLedgerSelected(new ML_Ledger());
            else
                setLedgerSelected(ldg);
            props.onLedgerSelected(ldg);
        }
    }

    const handleLedgerSelect = (e: any) => {
        if (e) {
            props.onLedgerSelected(e);
            setLedgerSelected(e);
        }
    }

    const optionTemplate = (props: any) => (
        <components.Option {...props}>
            <b>{props.data.Ledger?.LedgerName}</b>
            {props.data.CurrentBalance &&
                <span>&nbsp;&nbsp;(Balance: <b
                    className={props.data.CurrentBalance.LedgerBalance > 0 ? 'text-success' : 'text-danger'}>
                    {utils.formatCurrencyValue(reduxState, props.data.CurrentBalance.LedgerBalance)}</b>)</span>}
        </components.Option>)


    const selectedTemplate = (props: any) => {
        return (<components.SingleValue {...props}>
            <b>{props.data.Ledger?.LedgerName}</b>
            {props.data.CurrentBalance &&
                <span>&nbsp;&nbsp;(Balance: <b
                    className={props.data.CurrentBalance.LedgerBalance > 0 ? 'text-success' : 'text-danger'}>
                    {utils.formatCurrencyValue(reduxState, props.data.CurrentBalance.LedgerBalance)}</b>)</span>}
        </components.SingleValue>);
    }

    return (<>
        <Select classNamePrefix="srushti"
            styles={errorStyle}
            options={ledgers}
            isDisabled={!props.isEditable}
            value={ledgerSelected}
            theme={theme => ({ ...theme })}
            getOptionValue={(option: any) => option.Ledger?.LDGID.toString()}
            components={{ Option: optionTemplate, SingleValue: selectedTemplate }}
            onChange={handleLedgerSelect}
        />
        </>)
}

export default LedgerWithStatsDropDownControl;
