import React from 'react';
import * as AppAct from '../globals/AppActions';
import { mapStateToProps } from '../globals/AppActions';
import { InwardLineItemView, InwardView } from "../globals/dto";
import { connect, useSelector } from "react-redux";
import * as idb from '../db/InwardsData';
import InwardVerify from "./InwardVerify";
import cloneDeep from 'lodash/cloneDeep';
import TransitManager from "../workflows/TransitManager";
import { Modal } from 'react-bootstrap';
import { MachinesSelect } from "../shared/MachinesSelect";
import * as utils from '../globals/Utils';
import InwardBayProduction from "./InwardBayProduction";
import InwardBayStores from "./InwardBayStores";
import { AppReduxState } from '../infra-redux/AppStateReduxStore';


const InwardBay = (props: any) => {

    const reduxState = useSelector((state: AppReduxState) => state);

    if (reduxState.appReduxer.GlobalSettings == null)
        return <></>;

    return <>
        {utils.isProductionTrackingEnabled(reduxState.appReduxer.GlobalSettings) &&
            <InwardBayProduction />
        }

        {!utils.isProductionTrackingEnabled(reduxState.appReduxer.GlobalSettings) &&
            <InwardBayStores />
        }
    </>
}

export default InwardBay;
