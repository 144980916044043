import axios, {AxiosResponse} from 'axios';
import * as global from '../globals/ApiUrlConsts';
import {CallActionResult, ML_UnitMeasure, ProductView} from "../globals/dto";

const base_prod_api = global.global_api_url_base + '/api/products';

export const GetAllProducts = (): Promise<AxiosResponse<ProductView[]>> => {
    return axios.get(base_prod_api + '/allProducts');
}

export const GetAllUnits = async (): Promise<AxiosResponse<ML_UnitMeasure[]>> => {
    return await axios.get(base_prod_api + '/allUnits');
}

export const GetProduct = async (id: number): Promise<AxiosResponse<ProductView>> => {
    return await axios.get(base_prod_api + '/getProduct?prid=' + id);
}

export const SuggestUnits = async (id: number): Promise<AxiosResponse<string[]>> => {
    return await axios.get(base_prod_api + '/suggestUnits');
}

export const SuggestProducts = async (): Promise<AxiosResponse<string>> => {
    return await axios.get(base_prod_api + '/suggestProducts');
}

export const SaveProduct = async (pv: ProductView) => {
    return await axios.post(base_prod_api + "/save", pv);
}

export const SaveUnit = async (un: ML_UnitMeasure) => {
    return await axios.post(base_prod_api + "/SaveUnit", un);
}