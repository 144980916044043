import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { ML_Company, ML_Product, ML_ProductTransits, ML_UnitMeasure } from "../globals/dto";
import ProductSplitReport from './product-transits/product-split-report';
import ProductOpenStocksHistoryReport from './product-transits/product-openstocks-report';
import ProductStoresMoveReport from './product-transits/product-store-move-report';
import * as AppAct from '../globals/AppActions';



class SplitStockResult {
    FromProduct: ML_Product;
    FromUnit: ML_UnitMeasure;
    FromCompany: ML_Company;
    ToProduct: ML_Product;
    ToUnit: ML_UnitMeasure;
    Transit: ML_ProductTransits
}

const ProductTransitHistory = (props: any) => {

    const AppDisp = useDispatch();

    useEffect(() => {
        AppDisp(AppAct.ChangeMainTitle("Products Transit Report"));
    }, []);

    return (<>

        <div className="row justify-content-center mg-b-20">
            <div className="col-md-12">
                <ProductSplitReport />
            </div>
            <div className="col-md-12 mt-4">
                <ProductOpenStocksHistoryReport />
            </div>
            <div className="col-md-12 mt-4">
                <ProductStoresMoveReport />
            </div>
        </div>

    </>)
}

export default ProductTransitHistory;