import { result } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import Select, { components, OptionProps } from 'react-select';
import BrokersDropdown from '../brokers/BrokersDropdown';
import { BrokerPaymentVM, ML_Broker } from '../globals/dto';
import * as utils from '../globals/Utils';
import { AppReduxState } from '../infra-redux/AppStateReduxStore';
import TextBoxNumeric from '../ui-controls/TextBoxNumeric';

export type BrokerUpdateEventArgs = {
    commMode: number,
    lumpSumAmount: number,
    brokerSelected: ML_Broker
}

type attributes = {
    totalQty: number,
    onUpdate(res: BrokerUpdateEventArgs): void
    commissionMode: number,
    broker: ML_Broker,
    totalAmount: number
}

const BrokerCommission = (props: attributes) => {

    const [commissionMode, setCommissionMode] = useState(props.commissionMode);
    const [lumpSum, setLumpSum] = useState(0);
    const reduxState = useSelector((state: AppReduxState) => state);

    const handleCommissionModeChange = (e: any) => {
        let val = +e.target.value;
        setCommissionMode(val);
        props.onUpdate({ commMode: val, lumpSumAmount: lumpSum, brokerSelected: props.broker });
    }

    const handleLumpSumInput = (e: any) => {
        let value = +e.target.value;
        setLumpSum(value);
        props.onUpdate({ commMode: commissionMode, lumpSumAmount: value, brokerSelected: props.broker });
    }

    const handleBrokerChange = (e: ML_Broker) => {
        props.onUpdate({
            commMode: commissionMode, lumpSumAmount: lumpSum,
            brokerSelected: e === null ? new ML_Broker() : e
        });
    }

    return (
        <>
            {/* <div className="card mb-3 shadow-sm">
                <div className="card-header"> */}
                    <h5 className='mt-3 az-content-label'>Brokerage</h5>
                {/* </div>

                <div className="card-body text-black"> */}
                    <div className="row">
                        <div className="col-12 col-md-6 mb-3 mb-md-0">
                            <label className='form-label'>Broker</label>
                            <BrokersDropdown onSelect={handleBrokerChange} selectedBrokerId={props.broker.BRKID} />
                        </div>
                        <div className="col-6 col-md-3">
                            <label className='form-label'>Commission</label>
                            {props.broker &&
                                <select className="form-control form-select" name="comMode" value={commissionMode} onChange={handleCommissionModeChange}>
                                    <option key="brksel0" value="0">&nbsp;</option>
                                    <option key={"brksel1"} value="1">{utils.formatCurrencyValue(reduxState, props.broker.CommissionAmount)} X {props.totalQty} Qty.</option>
                                    <option key={"brksel2"} value="2">Lump-Sum</option>
                                </select>}
                        </div>
                        <div className="col-6 col-md-3 text-right">
                            <label className='form-label'>Total</label>
                            {commissionMode === 1 &&
                                <b>{utils.formatCurrencyValue(reduxState, props.totalAmount)}</b>
                            }

                            {commissionMode === 2 && props.broker &&
                                <TextBoxNumeric className="form-control text-right" style={{ precision: 2 }}
                                    name="lumpSum"
                                    value={props.totalAmount}
                                    id="brokerManualComm" onChange={handleLumpSumInput} />
                            }
                        </div>
                    </div>
                {/* </div>
            </div> */}
        </>
    );

}
export default BrokerCommission;
