import React, { useEffect, useState } from 'react';
import { ML_Broker, ML_Product, ML_ProductBatch, ML_Stock, SaleBillLineItemView, SaleLineProductBatchView, TaxAppliedDTO, TaxAppliedView, TaxView } from "../globals/dto";
import * as factory from "../globals/factory";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { TaxDropDown } from "../tax/TaxDropDown";
import { StoreProductsDropDown } from "../products/StoreProductsDropDown";
import * as stdb from '../db/StocksData';
import * as util from '../globals/Utils';
import * as _ from 'lodash';
import { SaveButtonUI } from '../globals/AppControls/SaveButtonUI';
import { CloseButtonUI } from '../globals/AppControls/CloseButtonUI';

export const SaleBillLineItem = (props: any) => {

    const [lineItemView, setLineItemView] = useState<SaleBillLineItemView>(props.lineItem);
    const [taxSelected, setTaxSelected] = useState(new TaxView());
    const [allotedStockBatchItems, setAllotedStockBatchItems] = useState<SaleLineProductBatchView[]>(props.lineItem.AllotedStockBatchItems);
    const [isSaleQtyMore, setIsSaleQtyMore] = useState(false);
    const [isItemBatchesLoaded, setIsItemBatchesLoaded] = useState(false);

    useEffect(() => {
        setTaxSelected(factory.toTaxView(lineItemView.TaxDetails?.TaxSetting));
    }, []);

    useEffect(() => {
        calcTotal();
    }, [taxSelected?.TaxSetting?.TXID, lineItemView.LineItem.BulkQty])

    const calculateBulkQty = (newQty: number, index: number) => {
        let isMoreQtyEntered = false;
        let qtyCopy = _.clone(allotedStockBatchItems);
        qtyCopy[index].StockEntry.SoldQty = newQty;
        if (isItemBatchesLoaded) {
            lineItemView.LineItem.BulkQty = 0;
            qtyCopy.forEach((x: any) => {
                if (x.StockEntry.SoldQty > x.OnHandQty) {
                    toast.error("Sale Qty more than On-Hand qty");
                    isMoreQtyEntered = true;
                    //setIsSaleQtyMore(true);
                } else if (!isMoreQtyEntered) {
                    lineItemView.LineItem.BulkQty += +x.StockEntry.SoldQty;
                }
            });

            setIsSaleQtyMore(isMoreQtyEntered);

            if (!isMoreQtyEntered) {
                setLineItemView(lineItemView);
                setAllotedStockBatchItems([...qtyCopy]);
            }
        }
    }

    useEffect(() => {
        //console.log('sale line item', lineItemView);
        if (lineItemView.LineItem.ProductID > 0) {
            //console.log('AllotedStockBatchItems', lineItemView.AllotedStockBatchItems, lineItemView.LineItem.SLITID)
            if (lineItemView.LineItem.SLITID > 0) {
                stdb.StockBatchWiseSold(lineItemView.LineItem.SLITID).then((res: any) => {
                    setAllotedStockBatchItems(res.data);
                    setIsItemBatchesLoaded(true);
                    if (res.data) {
                        let result: SaleLineProductBatchView[] = res.data;
                        console.log('StockBatchWiseSold', result);
                        result.forEach((val: SaleLineProductBatchView) => {
                            //The found logic is required to manage when line items are in edit mode, this important to manage batches/qty
                            //same batches will be "merged" in found logic, else parts simply adds new batch entry
                            //let foundIndex = allotedStockBatchItems.findIndex((x: any) => x.Batch.BATID === val.Batch.BATID);
                            // let foundIndex = allotedStockBatchItems.findIndex((x: SaleLineProductBatchView) => x.StockEntry.StockID === val.StockEntry.StockID);
                            // console.log('foundIndex',foundIndex)
                            //if (foundIndex >= 0) {
                            //console.log('found val', val);
                            //let copyAllotBatches = [...allotedStockBatchItems];
                            //copyAllotBatches[foundIndex].OnHandQty = val.OnHandQty;
                            //setAllotedStockBatchItems([...copyAllotBatches]);
                            //setIsItemBatchesLoaded(true);
                            // } else {
                            //     var sbv = factory.createSaleLineProductBatchView();
                            //     sbv.Batch = val.Batch;
                            //     sbv.OnHandQty = val.OnHandQty;
                            //     if (sbv.OnHandQty > 0) {
                            //         setAllotedStockBatchItems([...allotedStockBatchItems, sbv]);
                            //         setIsItemBatchesLoaded(true);
                            //     }
                            // }
                        });
                        console.log(allotedStockBatchItems);
                    }
                });
            } else {
                stdb.StockBatchWiseOnHand(props.companyId, lineItemView.LineItem.ProductID).then(res => {
                    console.log('StockBatchWiseOnHand', res.data)
                    // let batchItems :SaleLineProductBatchView[] = [];
                    // res.data.forEach((val: SaleLineProductBatchView) => {
                    //     console.log(val);
                    //     var sbv = factory.createSaleLineProductBatchView();
                    //     sbv.Batch = val.Batch;
                    //     sbv.OnHandQty = val.OnHandQty;
                    //     if (sbv.OnHandQty > 0) {
                    //         batchItems.push(sbv);
                    //     }
                    // })
                    //setAllotedStockBatchItems([...batchItems]);
                    setAllotedStockBatchItems(res.data);
                    setIsItemBatchesLoaded(true);
                })
            }
        }


        //     stdb.GetOnProductIDCompanyID(props.storeID, lineItemView.LineProduct.Product.PRDID)
        //         .then((res: any) => {
        //             console.log('line entries',res.data);
        //             if (res.data) {
        //                 res.data[0].BatchWise.forEach((val: any) => {
        //                     //The found logic is required to manage when line items are in edit mode, this important to manage batches/qty
        //                     //same batches will be "merged" in found logic, else parts simply adds new batch entry
        //                     //let foundIndex = allotedStockBatchItems.findIndex((x: any) => x.Batch.BATID === val.Batch.BATID);
        //                     let foundIndex = allotedStockBatchItems.findIndex((x: SaleLineProductBatchView) => x.StockEntry.STMVID === val.StockID);
        //                     if (foundIndex >= 0) {
        //                         let copyAllotBatches = [...allotedStockBatchItems];
        //                         copyAllotBatches[foundIndex].OnHandQty = val.TotalMilled - val.TotalSold;
        //                         setAllotedStockBatchItems([...copyAllotBatches]);
        //                         setIsItemBatchesLoaded(true);
        //                     } else {
        //                         var sbv = factory.createSaleLineProductBatchView();
        //                         sbv.Batch = val.Batch;
        //                         sbv.OnHandQty = val.TotalMilled - val.TotalSold;
        //                         if (sbv.OnHandQty > 0) {
        //                             setAllotedStockBatchItems([...allotedStockBatchItems, sbv]);
        //                             setIsItemBatchesLoaded(true);
        //                         }
        //                     }
        //                 });
        //                 console.log(allotedStockBatchItems);
        //             }
        //         });
        // }
    }, [lineItemView.LineItem.ProductID])

    const onSave = () => {
        if (isSaleQtyMore) {
            toast.error("Sale qty is more than On-Hand Qty");
        }
        else if (+lineItemView.LineProduct.Product.PRDID > 0
            && +lineItemView.LineItem.BulkQty > 0
            && +lineItemView.LineItem.PricePerBulkUnit > 0) {
            props.saved(lineItemView);
        } else {
            toast.error("Product, Qty, Price info should be properly supplied.");
        }
    }

    const onClose = () => {
        props.closed();
    }

    const onDelete = () => {
        Swal.fire({
            title: 'Delete Line Item',
            html: "Are you sure want to delete it?",
            showCancelButton: true,
            confirmButtonColor: 'red',
            focusCancel: true,
        }).then((res) => {
            if (res.value) {
                props.deleted(lineItemView);
                props.closed();
            }
        })
    }

    const handleProductChagne = (p: any) => {
        let ip = p;
        setLineItemView({
            ...lineItemView,
            LineProduct: ip,
            LineItem: {
                ...lineItemView.LineItem,
                ProductID: ip.Product.PRDID
            }
        });
    }

    const handleChange = (e: any) => {
        if (e.target.name != '') {
            let obj: any = { ...lineItemView };
            obj.LineItem[e.target.name] = +e.target.value;

            setLineItemView({ ...obj });
            calcTotal();
        }
    }

    const calcTotal = () => {
        if (lineItemView.LineProduct && lineItemView.LineProduct.UnitMeasure && lineItemView.LineItem.BulkQty > 0) {
            let ret = util.calculateDiscountAndTaxes(lineItemView, taxSelected);
            setLineItemView({ ...ret });
        }

        if (allotedStockBatchItems)
            setLineItemView({ ...lineItemView, AllotedStockBatchItems: [...allotedStockBatchItems] });
    }

    return (
        <>
            <div className="row form-group">
                <div className="col-12">
                    <label className="form-label">Item Product</label>
                    <StoreProductsDropDown
                        onChange={handleProductChagne}
                        storeID={props.storeID}
                        isdisabled={lineItemView.LineItem.SLITID > 0}
                        product={lineItemView.LineProduct} />
                </div>
            </div>

            {allotedStockBatchItems && allotedStockBatchItems.length > 0 &&
                <div className="row form-row">
                    <label className="form-label col-md-3">Batches</label>
                    <div className="col-md-9">
                        <table className="table table-sm">
                            <thead className="border-top">
                                <tr>
                                    <th>Batch</th>
                                    <th className="text-right">On Hand Qty</th>
                                    <th className="text-right">Selling Qty</th>
                                </tr>
                            </thead>
                            <tbody>
                                {allotedStockBatchItems.map((b: any, idx: number) => {
                                    return (
                                        <tr className="bg-light" key={'ab' + idx}>
                                            <td>{b.Batch?.BatchName}</td>
                                            <td className="text-right">{b.OnHandQty}</td>
                                            <td className="text-right">
                                                <input
                                                    onChange={(e: any) => calculateBulkQty(e.target.value, idx)}
                                                    type="number"
                                                    className="form-control text-right wd-90-f float-right"
                                                    value={b.StockEntry.SoldQty}
                                                />
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            }


            <div className="row form-group">
                <label className="col-md-3 form-label">Sale Qty</label>
                <div className="col-md-9">
                    <div className="input-group">
                        <input type="text" className="form-control"
                            disabled={true}
                            value={lineItemView.LineItem.BulkQty}
                        /*name={"BulkQty"}
                        onChange={handleChange}*/
                        />
                        <div className="input-group-append">
                            <span
                                className="input-group-text">{lineItemView.LineProduct?.UnitMeasure?.BulkMeasureName}</span>
                            <span className="input-group-text bg-white">{lineItemView.LineItem.RetailQty}</span>
                            <span
                                className="input-group-text">{lineItemView.LineProduct?.UnitMeasure?.RetailMesaureName}</span>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row form-group align-items-center">
                <label className="col-md-3 form-label">Rate</label>
                <div className="col-md-4">
                    <input type="text" className="form-control"
                        value={lineItemView.LineItem.PricePerBulkUnit}
                        onChange={handleChange}
                        name={"PricePerBulkUnit"}
                    />
                </div>
                {lineItemView.LineProduct?.UnitMeasure &&
                    <>
                        <div className="col-md-1 text-center">Per</div>
                        <div className="col-md-4">
                            <select className="form-control form-select"
                                value={lineItemView.LineItem.IsCalcDoneOnBulkQty}
                                name="IsCalcDoneOnBulkQty"
                                onChange={handleChange}
                            >
                                <option value={0}>{lineItemView.LineProduct.UnitMeasure.RetailMesaureName}</option>
                                <option value={1}>{lineItemView.LineProduct.UnitMeasure.BulkMeasureName}</option>
                            </select>
                        </div>
                    </>
                }
            </div>

            <div className="row form-group">
                <label className="col-md-3 form-label">Tax</label>
                <div className="col-md-6">
                    <TaxDropDown tax={taxSelected} onChange={(e: any) => {
                        setTaxSelected({ ...e });
                        /*calcTotal();*/
                    }} />
                </div>
            </div>
            <div className="row form-group">
                <label className="col-md-3 form-label">Discounts</label>
                <div className="col-md-9">
                    <div className="input-group-append">
                        <span className="input-group-text">%</span>
                        <input type="text" className="form-control"
                            name={"DiscountPercentage"}
                            value={lineItemView.LineItem.DiscountPercentage}
                            onChange={handleChange}
                        />
                        <span className="input-group-text font-weight-bold">OR</span>
                        <span className="input-group-text">Rs.</span>
                        <input type="text" className="form-control"
                            name={"DiscountedAmount"}
                            value={lineItemView.LineItem.DiscountedAmount}
                            onChange={handleChange}
                            disabled={lineItemView.LineItem.DiscountPercentage > 0} />

                    </div>
                </div>
            </div>

            <div className="row form-group mb-0">
                <label className="col-md-3 form-label">Total:</label>
                <div className="col-md-6">
                    <span className="form-control font-weight-bolder bg-white">
                        {lineItemView.LineItem.SubTotal}
                    </span>
                </div>

            </div>


            <div className="d-flex justify-content-between mt-4">

                {lineItemView.LineItem.SLITID === 0 &&
                    <SaveButtonUI className="mr-3" onClick={onSave} />}
                <CloseButtonUI onClick={onClose} />
            </div>
        </>);
}
