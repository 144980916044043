import React, { useEffect, useState } from 'react';
import * as udb from "../db/UsersData";

import { QL_User } from '../globals/dto';
import { Modal } from 'react-bootstrap';
import { toast } from "react-toastify";
import { connect, useDispatch } from 'react-redux';
import * as ac from '../globals/AppActions';
import { AppProgressBar } from "../globals/AppProgressBar";
import { IUser } from '../globals/data.dto';
import { UserDetailsEx } from "./UserDetailsEx";
import { mapStateToProps } from "../globals/AppActions";
import { AddNewButtonUI } from '../globals/AppControls/AddNewButtonUI';

const UsersListEx = (props: any) => {
    const dispatch = useDispatch();

    const [state, setState] = useState({
        users: new Array<IUser>(),
        userSelected: new QL_User(),
        hash: '', localProgress: false
    });

    const [showPop, setShowPop] = useState(false);

    useEffect(() => {
        dispatch(ac.ChangeMainTitle('User Logins'));
        getAllUsers();
    }, [])

    const showUserDetails = (u: QL_User) => {
        openModal(u);
    }

    const getAllUsers = () => {
        toast.dismiss();
        dispatch(ac.ToggleProgress(true));
        udb.GetAllUsers().then(r => {
            setState({ ...state, users: r.data });
            dispatch(ac.ToggleProgress(false));
        });
    }

    const openModal = (u: QL_User) => {
        setShowPop(true);
        setState({ ...state, userSelected: u, hash: new Date().getTime().toString() });
    };

    const closeModal = () => {
        setShowPop(false);
    }

    const showProgress = (tog: boolean) => {
        setState({ ...state, localProgress: tog });
    }



    //alert(JSON.stringify(allusers));
    return (
        <>
            {/* <div className="card">
                        <div className="card-body">*/}
            {/* <input type="button" className="btn btn-az-primary" value="+ Add New" autoFocus
                onClick={() => {
                    openModal(new QL_User());
                }}
            /> */}
            <AddNewButtonUI autoFocus buttonText="Add <u>N</u>ew" onClick={() => {
                openModal(new QL_User());
            }} />

            <div className="row">
                {state.users.map((u: IUser, idx) => {
                    return (
                        <div className="col-sm-6 col-md-4 mt-3 mb-2" key={u.AID}>
                            <div className="card card-minimal-four pointer-cursor"
                                onClick={() => showUserDetails(u)}>
                                <div className="card-header border p-3">
                                    <div className="media">
                                        <div
                                            className={"coin-logo " + (u.Active === 1 ? 'bg-success' : 'bg-gray-500')}>
                                            <div className={"az-avatar-nodot bg-gray-800"}>
                                                {u.ShowName.charAt(0).toUpperCase()}</div>
                                        </div>
                                        <div className="media-body">
                                            <h6>{u.ShowName}</h6>
                                            <p>{u.UserTypeName}
                                                <span
                                                    className={"typcn font-medium-small typcn-media-record " + (u.Active === 1 ? 'text-success' : 'text-secondary')}>
                                                    {u.Active === 1 ? 'Active' : 'Disabled'}
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>

            <Modal show={showPop} dialogClassName="modal-corner"
                onHide={closeModal}
                backdrop={'static'}>
                {state.localProgress && <AppProgressBar />}
                <Modal.Header className="bg-gray-200"><h3 className="pb-0 mb-0">User Details</h3></Modal.Header>
                <Modal.Body>
                    <UserDetailsEx user={state.userSelected}
                        loadUsers={getAllUsers}
                        closePop={closeModal}
                        togProgress={showProgress}
                        hash={state.hash}
                    />
                </Modal.Body>
            </Modal>
        </>
    );


}

export default UsersListEx;
