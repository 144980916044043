import React, { useEffect, useState } from "react";
import { ML_Company } from "../../globals/dto";
import { MultiStoreSelector } from './MultiStoreSelectorUI';
import { CheckToggleUI } from "./CheckToggleUI";
import { Modal, ModalBody, ModalFooter } from "react-bootstrap";
import { SaveButtonUI } from "../../globals/AppControls/SaveButtonUI";
import { CloseButtonUI } from "../../globals/AppControls/CloseButtonUI";

export const SaleBillsFilter = (props: { 
    filterOptions: {
        stores : ML_Company[],
        showCreditBillsOnly : boolean,
        showCashBillsOnly: boolean
    },
    onFilterUpdate(val: any): void, 
    onClose():void 
}) => {
    const [filtersOptions, setFiltersOptions] = useState(props.filterOptions);

    const onUpdateFilter = () => {
        props.onFilterUpdate(filtersOptions);
        closeModal();
    }

    const updateStores = (updatedStoresList:ML_Company[])=>{
        setFiltersOptions({...filtersOptions, stores:[...updatedStoresList]});
    }

    const updateCashBillFlag = (e:boolean) => {
        setFiltersOptions({...filtersOptions, showCashBillsOnly: e});
    }

    const updateCreditBillFlag = (e:boolean)=>{
        setFiltersOptions({...filtersOptions, showCreditBillsOnly:e});
    }

    const closeModal = ()=> {
        props.onClose();
    }

    return <>
        {filtersOptions && <Modal
            size={"lg"}
            dialogClassName="modal-corner"
            show={true}
            backdrop={'static'}
            backdropClassName={'modal-backdrop-ex'}
            animation={true}
            onHide={() => { props.onClose() }}
            onEscapeKeyDown={() => { props.onClose() }} >
            <ModalBody>
                <h3 className="card-title text-black-50">Filter Criteria</h3>
                <h5 className="card-title mt-3">Stores List</h5>
                <MultiStoreSelector onUpdated={updateStores} selectedStores={filtersOptions.stores}/>
                <h5 className="card-title mt-5">Bill Type</h5>
                <div className="d-flex flex-md-row flex-column gap-3">
                    <CheckToggleUI keyValue={'bt1'}
                        idStringValue={'onlyCashBills'}
                        checked={filtersOptions.showCashBillsOnly}
                        label={'Only Cash Bills'}
                        onChecked={updateCashBillFlag} />

                    <CheckToggleUI keyValue={'bt2'}
                                   idStringValue={'onlyCreditBills'}
                                   checked={filtersOptions.showCreditBillsOnly}
                                   label={'Only Credit Bills'}
                                   onChecked={updateCreditBillFlag}/>
                </div>
                <div className="d-flex mt-4 gap-3 justify-content-start">
                    <SaveButtonUI buttonText={'Apply'} onClick={onUpdateFilter} />
                    <CloseButtonUI onClick={closeModal} />
                </div>
            </ModalBody>
        </Modal>
        }
    </>
}
