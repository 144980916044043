import axios from 'axios';
import {ML_Ledger, ML_Transaction} from "../globals/dto";
import * as global from '../globals/ApiUrlConsts';
import * as dateUtils from '../globals/AppDateUtils';

const web_api_url = global.global_api_url_base + '/api/ledgers';

export const getAll = async () => await axios.get(web_api_url + '/getAll')

export const getAllWithBalance = async () => await axios.get(web_api_url + '/getAllWithBalance')

export const getLedgerWithBalance = async (ldid : number) =>
    await axios.get(web_api_url + '/getLedgerWithCurrentBalance', {params : { ldid }});

export const getOnId = async (lgid : number)  => await axios.get(web_api_url + '/getOnID',{params: {id : lgid}})

export const getTrans = async (lgid :number, start :Date, end :Date) =>
    await axios.get(web_api_url + '/getTrans',
        {params: {id: lgid, start: dateUtils.SanitizeDate(start), end: dateUtils.SanitizeDate(end)}})

export const save = async (entry : ML_Ledger) => await axios.post(web_api_url + '/save', entry)

export const LinkTransactionWithParty = async (entry : ML_Transaction, ptype: number, pid : number) =>
    await axios.post(web_api_url + '/LinkTranWithParty',entry,
        {params: {partyType : ptype, partyID: pid  }} );

export const GetLinkTransactionWithParty = async (tranid : number) =>
    await axios.get(web_api_url + '/GetLinkTranWithParty', {params: {tranid}} );