import axios from 'axios';
import * as global from '../globals/ApiUrlConsts';
import {CustomerView, ML_Customer} from "../globals/dto";

const base_customers_api = global.global_api_url_base + '/api/customers';

export const GetAll = async () => await axios.get( base_customers_api + '/all');

export const GetCustomerById = async (cid:number) => await axios.get<ML_Customer>( base_customers_api + '/GetOnId', {params:{cid}});

export const Save = async (cv : CustomerView) => await axios.post(base_customers_api + '/save',cv);
