import React, {useEffect, useState} from "react";
import {ML_Workflow} from "../globals/dto";
import * as wfdb from '../db/WorkflowData';
export const WorkflowSelectorDropDown = (props:any) => {

    const[workflows, SetWorkflows]= useState<ML_Workflow[]>();
    const[selectedWorkflowId, setSelectWorkflowId] = useState(props.wfid);

    useEffect(()=>{
        wfdb.GetAllWorkflows().then(res => {
            SetWorkflows(res.data);
        });
    },[])


    const onWorkflowSelected = (e:any) => {
        let id = +e.target.value;
        setSelectWorkflowId(id);
        props.onChange(id);
    }

    return (
        <select className="form-control form-select" value={selectedWorkflowId} onChange={onWorkflowSelected}>
            <option key="0" value={0}>&nbsp;</option>
            {workflows && workflows.map(k=>{
                return <option key={k.WFID} value={k.WFID}>{k.WorkflowName}</option>
            })}
        </select>
    );
}
