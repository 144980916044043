import {components, OptionProps} from "react-select";
import React from "react";
import { SaleBillLineItemView, SaleBillView } from "../globals/dto";
import * as utils from "../globals/Utils";
import { toast } from "react-toastify";
import { AppReduxState } from "../infra-redux/AppStateReduxStore";

/*********************************************************************************/
/*************** SALE BILL UI HELPERS *********************/
/*********************************************************************************/


export const GrandTotals = (reduxState:AppReduxState, saleBillView : SaleBillView) => {
    return (<>
        <tr key={"k1"}>
            <td className="text-rigth">Total:</td>
            <td className="text-rigth font-weight-bolder pr-0">{utils.formatCurrencyValue(reduxState,saleBillView.SaleBill.AmountUntaxed)}</td>
        </tr>
        <tr key={"k2"}>
            <td className="text-rigth">Tax:</td>
            <td className="text-rigth font-weight-bolder pr-0">{utils.formatCurrencyValue(reduxState,saleBillView.SaleBill.AmountTax)}</td>
        </tr>
        <tr key={"k3"} className="border-top">
            <td className="text-rigth w-50">Grand Total:</td>
            <td className="text-rigth font-weight-bolder pr-0">{utils.formatCurrencyValue(reduxState,saleBillView.SaleBill.AmountTotal)}</td>
        </tr>
    </>);
}

export const ValidateData = (saleBillView : SaleBillView) => {

    //console.log('validating....');


    /*validity.SupplierInoviceNumberErr = saleBillView.SaleBill.SupplierInoviceNumber ? '' : global.CLASS_INPUT_ERROR;
    validity.DespatchedThroughErr = saleBillView.SaleBill.DespatchedThrough ? '' : global.CLASS_INPUT_ERROR;
    setValidity({...validity});

    let res = validity.DespatchedThroughErr === '' && validity.SupplierInoviceNumberErr === '';
    if (res === false) {
        toast.error("Enter mandatory fields");
        return false;
    }*/

    let product = saleBillView.LineItems.filter((si: SaleBillLineItemView) =>
        si.LineProduct &&
        si.LineProduct.Product.PRDID > 0 &&
        si.LineItem.IsCanceled === false);

    if (product.length === 0) {
        toast.error("Add at-least one 'selling product' should present in the bill");
        return false;
    }

    if (saleBillView.SaleBill.CustomerID === 0 && saleBillView.SaleBill.PhantomCustomer.length === 0) {
        toast.error("Customer details required");
        return false;
    }

    if (saleBillView.LineItems.length === 0) {
        toast.error("No items added");
        return false;
    }

    if (saleBillView.SaleBill.CompanyID === 0) {
        toast.error("Select store");
        return false;
    }

    if (+saleBillView.CashPayTrans.TotalAmount > 0 && +saleBillView.CashPayTrans.LedgerID === 0) {
        toast.error('Cash Counter Ledger is not configured!');
        return false;
    }

    if (saleBillView.SaleBill.CustomerID === 0 &&
        +saleBillView.CashPayTrans.TotalAmount !== +saleBillView.SaleBill.PayableTotalAmount) {
        toast.error(`Paid Amount is ${saleBillView.CashPayTrans.TotalAmount}, it should be same as Bill Amount ${saleBillView.SaleBill.PayableTotalAmount}`
            , { autoClose: 10000 });
        return false;
    }
    return true;
}


/*********************************************************************************/
/******* CUSTOMER DROP DOWN SETTINGS *************/
/*********************************************************************************/

export const customerSingleOption = (props: any) => (
    <components.Option {...props}>
        {props.data.CID === -1 &&<div style={{"borderBottom":"1px dashed gray", "paddingBottom":"7px"}}><b className="text-primary">{props.data.CompanyName}</b></div>}

        {props.data.CID > 0 && <>
            <b>{props.data.CompanyName}</b>
            {props.data.PrimaryContactPersonName &&
            <span>&nbsp;|&nbsp;<i>{props.data.PrimaryContactPersonName}</i></span>
            }
            {props.data.PrimaryPhones &&
            <span>&nbsp;|&nbsp;Ph:{props.data.PrimaryPhones}</span>
            }
        </>}
    </components.Option>)

export const customerSingleValue = (props: any) => (
    <components.SingleValue {...props}>
        <b>{props.data.CompanyName}</b>
    </components.SingleValue>
)

export const customerCustomSelectFilter = (option: any, search: string) => {
    return option.data.CompanyName.toLowerCase().includes(search.toLowerCase());
}

/*********************************************************************************/
/******* BROKER DROP DOWN SETTINGS *************/
/*********************************************************************************/

export const brokerSingleOption = (props: any) => (
    <components.Option {...props}>
        <b>{props.data.BrokerName}</b>
        {props.data.GeoCity &&
        <span>&nbsp;|&nbsp;<i>{props.data.GeoCity}</i></span>
        }
        {props.data.ContactNumber &&
        <span>&nbsp;|&nbsp;Ph:{props.data.ContactNumber}</span>
        }
    </components.Option>)

export const brokerSingleValue = (props: any) => (
    <components.SingleValue {...props}>
        <b>{props.data.BrokerName}</b>
        {props.data.ContactNumber &&
        <span>&nbsp;|&nbsp;Ph:{props.data.ContactNumber}</span>
        }
    </components.SingleValue>
)

export const brokerCustomSelectFilter = (option: any, search: string) => {
    return option.data.BrokerName.toLowerCase().includes(search.toLowerCase());
}

/*********************************************************************************/
/******* MODAL : LINE ITEM SETTINGS/METHODS *************/
/*********************************************************************************/

