import React, { useState } from "react";
import * as AppAct from "../globals/AppActions";
import { AxiosResponse } from "axios";
import { useDispatch } from "react-redux";
import * as cdb from '../db/CompanyData';

import * as consts from '../globals/GlobalConsts';
import { toast } from "react-toastify";
import { CompanyView, ML_Company, ML_Ledger, ML_Tag } from "../globals/dto";
import LedgersDropDownControl from "../globals/LedgersDropDownControl";
import TextBoxNumeric from "../ui-controls/TextBoxNumeric";
import { SaveButtonUI } from "../globals/AppControls/SaveButtonUI";
import { CloseButtonUI } from "../globals/AppControls/CloseButtonUI";
import { TagsDropdown } from "../tags/TagsDropdown";

const StoreDetails = (props: any) => {
    const dispatch = useDispatch();
    console.log(props.company);
    const [state, setState] = useState({
        comp: props.company,
        validity: { CompanyNameErr: '' }
    });

    const save = () => {
        if (isValidInput()) {
            dispatch(AppAct.ToggleProgress(true));
            cdb.Save(state.comp).then((res: AxiosResponse<number>) => {
                dispatch(AppAct.ToggleProgress(false));
                if (res.data === -1)
                    toast.error('Company name already present!')
                else
                    props.close();
            });
        } else {
            toast.error('Enter mandatory field');
        }
    }

    const handleCompanyChange = (e: any) => {
        let name = e.target.name;
        let val = e.target.value;
        setState({
            ...state, comp: {
                Company: { ...state.comp.Company, [name]: val },
                CompanySettings: { ...state.comp.CompanySettings }
            }
        });
    }

    const handleSettingsChange = (e: any) => {
        let name = e.target.name;
        let val = e.target.value;

        //This is very important to deal with when new properties were added
        state.comp.CompanySettings[name] = val;

        setState({
            ...state,
            comp: {
                Company: { ...state.comp.Company },
                CompanySettings: { ...state.comp.CompanySettings }
            }
        }
        );
    }
    const handleLedgerSettingChange = (e: ML_Ledger) => {
        handleSettingsChange({ target: { name: "DefaultCashCounterLedgerID", value: e.LDGID } });
    }

    const isValidInput = (): boolean => {
        setState({
            ...state, validity: {
                'CompanyNameErr': state.comp.Company.CompanyName === '' ? consts.CLASS_INPUT_ERROR : ''
            }
        });
        return state.comp.Company.CompanyName !== '';
    }


    if (!state.comp)
        return <></>;

    return (<>
        <div className="row">
            <div className=" col-md-12 text-right">
                <SaveButtonUI onClick={save} className="mr-3" />
                <CloseButtonUI onClick={props.close} />
            </div>
        </div>

        <h4 className="az-content-label">Business Details</h4>
        <hr />


        <div className="row">
            <div className="col-md-2 text-center">
                <i className="fas fa-university fa-3x pt-2 text-warning mb-2"></i>
            </div>
            <div className="col-md-10">
                <div className="d-flex flex-md-row flex-column justify-content-between">
                    <div className="pr-3 pl-2 flex-md-fill">
                        <label className="form-label">Company/Business Name<span className="tx-danger">*</span></label>
                        <input type="text" className={"form-control col-md-6 " + state.validity.CompanyNameErr}
                            name="CompanyName"
                            autoFocus
                            value={state.comp.Company.CompanyName}
                            onChange={handleCompanyChange}
                        />
                    </div>
                </div>

                <div className="mt-3 d-flex flex-md-row flex-column justify-content-between">
                    <div className="pr-3 pl-2 flex-md-fill">
                        <label className="form-label">Full Address</label>
                        <textarea className="form-control col-md-6" rows={5} />
                    </div>

                </div>

                <div className="mt-3 d-flex flex-md-row flex-column justify-content-between">
                    <div className="pr-3 pl-2 flex-md-fill">
                        <label className="form-label">Landline Number</label>
                        <input type="text" className="form-control"
                            value={state.comp.Company.PhoneNumber}
                            name="PhoneNumber"
                            onChange={handleCompanyChange}
                        />
                    </div>
                    <div className="pr-3 pl-2 flex-md-fill">
                        <label className="form-label">Mobile Number</label>
                        <input type="text" className="form-control"
                            value={state.comp.Company.MobileNumber}
                            name="MobileNumber"
                            onChange={handleCompanyChange}
                        />
                    </div>
                    <div className="pr-3 pl-2 flex-md-fill">
                        <label className="form-label">Fax Number</label>
                        <input type="text" className="form-control"
                            value={state.comp.Company.FaxNumber}
                            name="FaxNumber"
                            onChange={handleCompanyChange}
                        />
                    </div>
                </div>

                <div className="mt-3 d-flex flex-md-row flex-column justify-content-between">
                    <div className="pr-3 pl-2 flex-md-fill">
                        <label className="form-label">Email</label>
                        <input type="text" className="form-control"
                            value={state.comp.Company.EmailAdddress}
                            name="EmailAdddress"
                            onChange={handleCompanyChange}
                        />
                    </div>
                    <div className="pr-3 pl-2 flex-md-fill">
                        <label className="form-label">Website</label>
                        <input type="text" className="form-control"
                            value={state.comp.Company.WebSiteAddress}
                            name="WebSiteAddress"
                            onChange={handleCompanyChange}
                        />
                    </div>
                </div>
            </div>
        </div>


        <h5 className="az-content-label mt-5">Statutary Settings</h5>
        <hr />

        <div className="row">
            <div className="col-md-2 text-center">
                <i className="fas fa-rupee-sign fa-3x pt-2  text-indigo mb-2"></i>
            </div>
            <div className="col-md-8">
                <div className="d-flex flex-md-row flex-column justify-content-between">
                    <div className="pr-3 pl-2 flex-md-fill">
                        <label className="form-label">PAN</label>
                        <input type="text" className="form-control"
                            value={state.comp.CompanySettings.PAN}
                            name={"PAN"}
                            onChange={handleSettingsChange}
                        />
                    </div>
                    <div className="pr-3 pl-2 flex-md-fill">
                        <label className="form-label">GSTN Number</label>
                        <input type="text" className="form-control"
                            value={state.comp.CompanySettings.GSTN}
                            name={"GSTN"}
                            onChange={handleSettingsChange}
                        />
                    </div>
                </div>
                <div className="pl-2 d-flex flex-md-row mt-3">
                    <div className="col-6" >
                        <label className="form-label">Default Cash Counter Ledger</label>
                        <LedgersDropDownControl selectLedgerId={state.comp.CompanySettings.DefaultCashCounterLedgerID}
                            isDisabled={false}
                            isError={false}
                            onLedgerSelected={(e: ML_Ledger) => {
                                if (e)
                                    handleSettingsChange(
                                        { target: { name: "DefaultCashCounterLedgerID", value: e.LDGID } });
                            }} />
                    </div>
                    <div className="col-6">
                        <label className="form-label">Transaction Type</label>
                        <TagsDropdown
                            tagType={consts.TagTypes.TRANSACTION_GROUP}
                            onSelected={(e: ML_Tag) => {
                                handleSettingsChange({
                                    target: { name: 'DefaultLedgerEntryCategory', value: +e.GRPID }
                                })
                            }}
                            isError={false}
                            selectedTagId={state.comp.CompanySettings.DefaultLedgerEntryCategory}
                            key={'tgd1'}
                        />
                    </div>
                </div>

            </div>
        </div>


        <h5 className="az-content-label mt-5">Settings</h5>
        <hr />
        <div className="row">
            <div className="col-md-2 text-center">
                <i className="fas fa-desktop fa-3x pt-2  text-info mb-2"></i>
            </div>
            <div className="col-md-3">
                <div className="d-flex flex-md-row flex-column justify-content-between">
                    <div className="pr-3 pl-2 flex-md-fill">
                        <label className="form-label">SaleBill Prefix</label>
                        <input type="text" className="form-control"
                            value={state.comp.CompanySettings.SaleBillPrefix}
                            name={"SaleBillPrefix"}
                            onChange={handleSettingsChange}
                        />
                    </div>
                    <div className="pr-3 pl-2 flex-md-fill">
                        <label className="form-label">SaleBill Size</label>

                        <TextBoxNumeric className="form-control"
                            value={state.comp.CompanySettings.SaleBillNumberSize}
                            name={"SaleBillNumberSize"}
                            onChange={handleSettingsChange}
                        />
                    </div>
                </div>
            </div>
        </div>

    </>);
}

export default StoreDetails;
