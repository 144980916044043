import React, {useRef, useState} from 'react';
import {FileAttachment} from "../globals/dto";
import FileAttached from "./FileAttached";
import * as rdb from './../db/ReviewData';

type attributes = {
    files: FileAttachment[],
    onFilesUpdated(fileList: FileAttachment[]): void
}

const FileAttachPanel = (props: attributes) => {

    const fileRef = useRef<any>();
    const [isUploading, setIsUploading] = useState(false);

    const handleFileClick = () => {
        if (fileRef)
            fileRef.current.click();
    }

    const handleFileControlChange = (event: any) => {
        if (event.target.files.length > 0) {
            setIsUploading(true);
            let file: File = event.target.files[0];
            rdb.UploadFile(file).then(res => {
                let fle = new FileAttachment();
                fle.UniqueFileName = res.data;
                fle.FileName = file.name;
                fle.DateUploaded = new Date();
                props.onFilesUpdated([...props.files, fle]);
                setIsUploading(false);
            }).catch(err => setIsUploading(false));
        }
    }

    const handleOnFileDeleted = (name: string) => {
        rdb.DeleteFile(name).then(res => {
            let files = [...props.files];
            let index = files.findIndex(f => f.UniqueFileName === name);
            files.splice(index, 1);
            props.onFilesUpdated(files);
        })
    }

    return (<>
        <div className="block mb-1">Attachements</div>
        <div className="d-flex flex-row justify-content-start align-items-center">

            <button className="btn btn-outline-primary px-3 py-1 mr-3" onClick={handleFileClick}>
                <i className="fa fa-paperclip"></i>
            </button>

            <div className="flex-fill">
                    {props.files.map((f: FileAttachment, i: number) => {
                        return <div className="d-inline-block mr-2 mb-2">
                            <FileAttached fileInfo={f} isReadOnly={false}
                                          onDelete={handleOnFileDeleted}key={'FLE' + i}/>
                        </div>
                    })}
            </div>

            {/*<div className="px-3 py-1 border border-bd-gray-100 rounded-10 mr-3">*/}
            {/*    Inspection_photo.jpg <i className="fa fa-trash tx-gray-400 ml-2"></i>*/}
            {/*</div>*/}
            <input type="file" ref={fileRef} onChange={handleFileControlChange}
                   style={{opacity: 0, width: 0, height: 0}}/>
        </div>

    </>);
}

export default FileAttachPanel;