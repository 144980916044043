import React, {useEffect, useState} from 'react';
import * as AppAct from '../globals/AppActions';
import {connect, useDispatch, useSelector} from "react-redux";
import * as wgdb from '../db/WagesData';
import * as rpt from '../db/ReportsData';
import {mapStateToProps} from "../globals/AppActions";
import * as utils from '../globals/Utils';
import * as dateUtils from '../globals/AppDateUtils';
import DateRangeControlEx from "../globals/DateRangeControlEx";
import parse from 'html-react-parser';
import { AppReduxState } from '../infra-redux/AppStateReduxStore';
import { ML_Inward, ML_InwardPayment, ML_Transaction } from '../globals/dto';
import { PopTags } from '../globals/PopTags/PopTags';

class ResultData {
    Inward : ML_Inward;
    Payment : ML_InwardPayment;
    Trans :ML_Transaction;
}

const CashPurchaseList = (props: any) => {

    const [list, setList] = useState([]);
    const [start, setStart] = useState(dateUtils.MinusDays(new Date(),1));
    const [end, setEnd] = useState(new Date());
    const [grandTotal, setGrandTotal] = useState(0);
    const [grandQtyTotal, setGrandQtyTotal] = useState(0);

    const AppDisp = useDispatch();
    const reduxState = useSelector((state:AppReduxState)=>state);

    useEffect(() => {
        AppDisp(AppAct.ChangeMainTitle('Cash Purchases Statement'));
        LoadTrans(start, end);
    }, []);

    const LoadTrans = (st: Date, ed: Date) => {
        let title = 'Cash Purchases : ' + dateUtils.FormatDate(st) + " - to - " + dateUtils.FormatDate(ed);
        console.log(title);
        
        AppDisp(AppAct.ChangeMainTitle(title));
        AppDisp(AppAct.ToggleProgress(true));
        rpt.getCashPurchasesList(st, ed).then((res: any) => {
            setList(res.data);
            AppDisp(AppAct.ToggleProgress(false));
            setGrandTotal(res.data.reduce((total: number, cv: any) => {
                return total + cv.Trans.TotalAmount;
            }, 0));

            setGrandQtyTotal(res.data.reduce((total: number, cv: any) => {
                return total + cv.SaleBill.TotalBulkQty;
            }, 0));
        });
    }

    const handleDateChange = (sd: Date, ed: Date) => {
        setStart(sd);
        setEnd(ed);
        LoadTrans(sd, ed);
    }

    return (
        <>
                <div className="row">
                    <div className="col-md-12">
                        <DateRangeControlEx
                            start={start} end={end}
                            onChange={handleDateChange}/>
                    </div>
                </div>
                <hr/>
                <div className="table-responsive">
                    <table className="table table-hover">
                        <thead className="border-top">
                        <tr key={"head0"}>
                            <th key={"hd21"}>Date</th>
                            <th key={"hd26"}>Bill #</th>
                            <th key={"hd23"} className="text-right">Qty</th>
                            <th key={"hd25"} className="text-right">Amount</th>
                        </tr>
                        </thead>
                        <tbody>
                        {list.map((t: ResultData, idx: number) => {
                            return (
                                <>
                                    <tr key={idx}>
                                        <td key={"t1" + idx}>{dateUtils.FormatDateTime(t.Inward.RowCreateDate)}</td>
                                        <td key={"t6" + idx}>{t.Inward.INWID}&nbsp; <PopTags transaction={t.Trans} /></td>
                                        <td key={"t3" + idx} className="text-right">{t.Inward.TotalBulkQty}</td>
                                        <td key={"t5" + idx} className="text-right font-weight-bold">{utils.formatCurrencyValue(reduxState,t.Trans.TotalAmount)}</td>
                                    </tr>
                                </>
                            )
                        })}
                        {/*<tr>*/}
                        {/*    <td colSpan={4} className="text-right font-weight-bold">Total Qty: {groupPrevQtyTotal}</td>*/}
                        {/*    <td colSpan={2} className="text-right font-weight-bold">Total Amount: {groupProvTotal}</td>*/}
                        {/*</tr>*/}
                        </tbody>
                        <tfoot>
                        <tr key={"ft001"}>
                            <td key={"ft21"}  colSpan={3} className="text-right"><h5 className="font-weight-bold">Qty: {grandQtyTotal}</h5></td>
                            <td key={"ft22"}  className="text-right"><h5 className="font-weight-bold">Grand Total: {utils.formatCurrencyValue(reduxState,grandTotal)}</h5></td>
                        </tr>
                        </tfoot>
                    </table>
                </div>
            
        </>
    )
}

export default CashPurchaseList;
