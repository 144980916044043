import React, { useState } from "react";
import * as wdb from "../db/WorkflowData";
import { Modal, ModalBody } from "react-bootstrap";
import ModalHeader from "react-bootstrap/ModalHeader";
import { MachineModal } from "./machine-components";
import { ML_Plant } from "../globals/dto";
import Swal from "sweetalert2";
import * as AppAct from "../globals/AppActions";
import { toast } from "react-toastify";
import { AddNewButtonUI } from "../globals/AppControls/AddNewButtonUI";
import { SaveButtonUI } from "../globals/AppControls/SaveButtonUI";
import { CloseButtonUI } from "../globals/AppControls/CloseButtonUI";



export const LabelPlantName = (props: any) => {
    return (<b className="text-uppercase tx-22-f tx-gray-500">{props.index + 1}. {props.name}</b>)
}
export const ButtonNewPlant = (props: any) => {
    const [showModal, setShowModal] = useState(false);
    const handleClosed = (id: number) => {
        setShowModal(false);
        props.saved(id);
    }

    return (
        <span key={new Date().getMilliseconds()}>
            <PlantModal plant={new ML_Plant()} wfid={props.wfid} runOrder={props.runOrder} show={showModal} saved={handleClosed} />
            {/* <button className="btn btn-primary" onClick={()=>setShowModal(true)}>+ Add New Plant</button> */}
            <AddNewButtonUI buttonText="Add <u>N</u>ew Plant" onClick={() => setShowModal(true)} />
        </span>
    );
}

export const ButtonDeletePlant = (props: any) => {

    const clickDelete = () => {

        Swal.fire({
            title: 'Delete Plant',
            html: "Are you sure want to delete this plant?",
            showCancelButton: true,
            confirmButtonColor: 'red',
            focusCancel: true,
        }).then((res: any) => {
            if (res.value) {
                wdb.deletePlant(props.plantID).then(res => {
                    if (res.data) {
                        props.saved();
                    }
                });
            }
        });
    }
    return (<a className="pointer-cursor text-danger font-weight-bold mr-3" title={"Delete plant"} onClick={clickDelete}>
        <i className="fa fa-trash text-danger" />
    </a>);
}
export const ButtonEditPlant = (props: any) => {
    const [showModal, setShowModal] = useState(false);
    const handleClosed = (id: number) => {
        setShowModal(false);
        props.saved(id);
    }

    return (
        <span key={new Date().getMilliseconds()}>
            <PlantModal plant={props.plant} wfid={props.wfid} runOrder={props.runOrder} show={showModal} saved={handleClosed} />
            <a className="pointer-cursor text-secondary font-weight-bold mr-3" title={"Edit plant"}
                onClick={() => setShowModal(true)}>
                <i className="fa fa-ellipsis-h text-primary" />
            </a></span>);
}

export const ButtonCopyPlant = (props: any) => {
    const clickCopy = () => {
        wdb.copyPlant(props.plantID).then(res => {
            if (res.data === true)
                props.saved();
        });
    }
    return (<a className="pointer-cursor text-secondary font-weight-bold mr-3" title={"Create duplicate plant"} onClick={clickCopy}>
        <i className="fa fa-clone text-info" />
    </a>);
}

const PlantModal = (props: any) => {

    return (
        <Modal show={props.show}>
            <ModalHeader className="bg-gray-200 p-3">
                <h4 className="pb-0 mb-0 font-weight-bold">Plant Details</h4>
            </ModalHeader>
            <ModalBody>
                <PlantDetails plant={props.plant} saved={props.saved} runOrder={props.runOrder} wfid={props.wfid} />
            </ModalBody>
        </Modal>);

}

export const PlantDetails = (props: any) => {
    const [plant, setPlant] = useState(props.plant);
    const [runOrder, setRunOrder] = useState(props.runOrder);


    const savePlant = () => {
        wdb.savePlant(plant, props.wfid, runOrder).then(res => {
            if (+res.data > 0) {
                if (props.runOrder !== runOrder) {
                    wdb.updatePlantRunOrder(props.wfid, +res.data, runOrder)
                        .then(ret =>
                            props.saved(+res.data));
                } else
                    props.saved(+res.data); //success
            }
            else
                props.saved(-1); //error
        });
    }

    const handleChange = (e: any) => {
        let name = e.target.name;
        let val = e.target.value;
        if (name === 'name')
            setPlant({ ...plant, PlantName: val });
        else
            setRunOrder(+val);
    }

    return (<>
        <div className="row">
            <div className="col-8">
                <label>Plant name</label>
                <input type="text" maxLength={35} id={"name"} name={"name"} className="form-control"
                    value={plant.PlantName}
                    onChange={handleChange} />
            </div>
            <div className="col-4">
                <label>Line Order</label>
                <input type="number" className="form-control"
                    value={runOrder}
                    id="order" name="order"
                    onChange={handleChange} />
            </div>
            <div className="col-12 text-center mt-3">
                {/* <button className="btn btn-success mr-3" onClick={savePlant}>Save</button>
                <button className="btn btn-secondary" onClick={() => props.saved(0)}>Cancel</button> */}

                <SaveButtonUI  onClick={savePlant} className="mr-3" />
                <CloseButtonUI onClick={() => props.saved(0)} />
            </div>
        </div>
    </>);
}