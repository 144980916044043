import React, { useEffect, useState } from 'react';
import * as AppAct from '../globals/AppActions';
import { connect, useDispatch, useSelector } from "react-redux";
import * as rdb from '../db/ReportsData';
import { DateControlEx } from "../globals/DateControlEx";
import { mapStateToProps } from "../globals/AppActions";
import * as utils from '../globals/Utils';
import * as dateUtils from '../globals/AppDateUtils';
import { AppReduxState } from '../infra-redux/AppStateReduxStore';
import * as _ from 'lodash';
import { stat } from 'fs';
import { DayBookItemView } from '../globals/dto';
import { PopTags } from '../globals/PopTags/PopTags';

const DayBook = (props: any) => {

    const [allTrans, setAllTrans] = useState({
        trans: new Array<any>(),
        inFlowAmt: 0,
        outFlowAmt: 0,
        inFlowTrans: 0,
        outFlowTrans: 0
    });
    const [today, setToday] = useState(new Date());

    const AppDisp = useDispatch();
    const reduxState = useSelector((state: AppReduxState) => state);

    useEffect(() => {
        AppDisp(AppAct.ChangeMainTitle('Day Book'));
        //setToday(new Date());
        LoadTrans(new Date());
    }, []);

    const LoadTrans = (dt: Date) => {
        AppDisp(AppAct.ChangeMainTitle('Day Book : ' + dateUtils.FormatDate(dt)));
        AppDisp(AppAct.ToggleProgress(true));
        let stateCopy = _.clone(allTrans);

        rdb.getDayBookStatement(dt).then((res: any) => {
            stateCopy.trans = res.data;
            stateCopy.inFlowAmt = 0;
            stateCopy.inFlowTrans = 0;
            stateCopy.outFlowAmt = 0;
            stateCopy.outFlowTrans = 0;
            res.data.forEach((x: any) => {

                stateCopy.inFlowAmt = (x.Tran.IsExpense === false ? stateCopy.inFlowAmt + x.Tran.TotalAmount : stateCopy.inFlowAmt);
                stateCopy.inFlowTrans = (x.Tran.IsExpense === false ? stateCopy.inFlowTrans + 1 : stateCopy.inFlowTrans);
                stateCopy.outFlowAmt = (x.Tran.IsExpense ? stateCopy.outFlowAmt + x.Tran.TotalAmount : stateCopy.outFlowAmt);
                stateCopy.outFlowTrans = (x.Tran.IsExpense ? stateCopy.outFlowTrans + 1 : stateCopy.outFlowTrans);
            });
            setAllTrans(stateCopy);
            AppDisp(AppAct.ToggleProgress(false));
        });
    }

    const handleDateChange = (e: string) => {
        //console.log(e[0]);
        setToday(new Date(e));
        LoadTrans(new Date(e));
    }

    return (<>
        <div className="row align-items-center">
            <div className="col-md-3">
                <DateControlEx
                    datevalue={today}
                    onDateChange={handleDateChange} />
            </div>
            <div className="col-md-9">
                <div className="row">
                    <div className="col-md-12">
                        <div className="row row-sm card-dashboard-twentyfive">
                            <div className="col-6 col-sm-4 col-lg text-right">
                                <label className="card-label">Inflow Amount</label>
                                <h6 className="card-value text-success">
                                    {utils.formatCurrencyValue(reduxState, allTrans.inFlowAmt)}
                                </h6>

                            </div>
                            <div className="col-6 col-sm-4 col-lg text-right">
                                <label className="card-label">Outflow Amount</label>
                                <h6 className="card-value text-danger">
                                    {utils.formatCurrencyValue(reduxState, allTrans.outFlowAmt)}
                                </h6>

                            </div>
                            {/*<div className="col-6 col-sm-4 col-lg mg-t-20 mg-sm-t-0 text-right">*/}
                            {/*    <label className="card-label">Inflow Count</label>*/}
                            {/*    <h6 className="card-value">{allTrans.inFlowTrans}</h6>*/}

                            {/*</div>*/}
                            {/*<div className="col-6 col-sm-4 col-lg mg-t-20 mg-lg-t-0 text-right">*/}
                            {/*    <label className="card-label">Outflow Count</label>*/}
                            {/*    <h6 className="card-value">{allTrans.outFlowTrans}</h6>*/}
                            {/*</div>*/}
                            <div className="col-6 col-sm-4 col-lg mg-lg-t-0 text-right">
                                <label className="card-label">Closing Balance</label>
                                <h6 className="card-value">{utils.formatCurrencyValue(reduxState, allTrans.inFlowAmt - allTrans.outFlowAmt)}</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <hr />
        <div className="table-responsive">
            <table className="table table-hover">
                <thead className="border-top">
                    <tr>
                        <th>Ledger</th>
                        {/* <th>Group</th>
                        <th>Company</th> */}
                        {/* <th>Bill Ref</th> */}
                        <th>Pay Notes</th>
                        {/* <th>Pay Mode</th> */}
                        <th>Date/Time</th>
                        <th className="text-right">Amount</th>
                    </tr>
                </thead>
                <tbody>
                    {allTrans.trans.map((t: DayBookItemView, idx: number) => {
                        return (
                            <tr key={idx}>
                                <td key={"td1" + idx}>
                                    {t.Ledger &&
                                        <div>{t.Ledger.LedgerName}</div>}
                                </td>
                                {/* <td key={"td2" + idx}>{t.Group}</td> */}
                                {/* <td key={"td3" + idx}>{t.Company}</td> */}
                                {/* <td key={"td4" + idx}>{t.BillRef && t.BillRef !== '0'? t.BillRef : '--'}</td> */}
                                <td key={"td5" + idx}>
                                    {utils.FormTransDetailsMessage(t.Tran, t.Category)} &nbsp; <PopTags transaction={t.Tran} />
                                </td>
                                {/* <td key={"td6" + idx}>{t.Tran.PayMode}</td> */}
                                <td key={"td7" + idx}>{dateUtils.FormatDate(t.Tran.TransDate)}</td>
                                <td key={"td8" + idx} className={"text-right font-weight-bold " + (t.Tran.IsExpense ? 'text-danger' : 'text-success')}>
                                    {utils.formatCurrencyValue(reduxState, t.Tran.TotalAmount)}
                                </td>
                            </tr>)
                    })}
                </tbody>
            </table>
        </div>
    </>)
}

export default DayBook;
