import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from "react-redux";
import * as AppAct from "../globals/AppActions";
import { mapStateToProps } from "../globals/AppActions";
import { GlobalConstantsVM, ML_Company, ML_Stock, SaleBillView } from "../globals/dto";
import * as sbdb from "../db/SaleBillData";
import { toast } from "react-toastify";
import { Modal, ModalBody } from "react-bootstrap";
import { DateControlEx } from "../globals/DateControlEx";
import * as utils from "../globals/Utils";
import * as dateUtils from '../globals/AppDateUtils';
import SalesInvoiceEntry from "./SaleInvoiceEntry";
import { SaleInvoiceView } from "./SaleInvoiceView";
import StoresSelect from "../shared/StoresSelect";
import { IsDuplicateUserExist } from "../db/UsersData";
import { start } from "repl";
import { AppReduxState } from '../infra-redux/AppStateReduxStore';
import { AddOutline } from 'react-ionicons';
import { AddNewButtonUI } from '../globals/AppControls/AddNewButtonUI';


const SalesList = (props: any) => {
    const [allSales, setAllSales] = useState(new Array<SaleBillView>());
    //const [allSalesMasterList, setAllSalesMasterList] = useState(new Array<SaleBillView>());
    const [startDate, setStartDate] = useState(dateUtils.MinusDays(new Date(), 1));
    const [endDate, setEndDate] = useState(new Date());
    const [searchText, setSearchText] = useState('');
    const [openSaleBill, setOpenSaleBill] = useState(0);
    const [showSaleBillEdit, setSaleBillEdit] = useState(false);
    const [viewSaleBill, setViewSaleBill] = useState(false);
    const [selectedStore, setSelectedStore] = useState(null);
    const startDtp = new Date();
    const endDtp = dateUtils.MinusDays(new Date(), 1);

    const appDisp = useDispatch();
    const reduxState = useSelector((state: AppReduxState) => state);

    useEffect(() => {
        appDisp(AppAct.ChangeMainTitle('Sale Bills'));
        LoadAllSaleBills(startDate, endDate);
    }, []);

    const LoadAllSaleBills = (st: Date, ed: Date) => {
        setStartDate(st);
        setEndDate(ed);
        if (st.getTime() <= ed.getTime()) {
            //appDisp(AppAct.ToggleProgress(true));
            sbdb.GetSaleBills(st, ed).then((res: any) => {
                if (res) {
                    setAllSales(res.data);
                }
                appDisp(AppAct.ToggleProgress(false));
            });
        } else {
            toast.error('FROM date should be earlier than TO date.');
        }
    };

    /*useEffect(() => {
        filterInvoices();
    }, [searchText, allSalesMasterList, storeID]);

    const filterInvoices = () => {

        if(searchText.length <= 0 && storeID === 0)
            return allSalesMasterList;

        let searchFound = false;
        let companyFound = false;
        if (allSalesMasterList.length > 0) {
            let res = allSalesMasterList.filter((i: SaleBillView) => {
                companyFound = storeID > 0  ? (i.SaleBill.CompanyID === storeID) : storeID === 0 ? true : false;
                console.log(storeID + ":" + companyFound);
                searchFound=  searchText.length > 0 ? i.CustomerDetails.CompanyName.toLowerCase().includes(searchText.toLowerCase()) : false;
                return searchFound || companyFound;
            });
            setAllSales([...res]);
        }
    };*/

    const filterInvoicesList = (sb: SaleBillView) => {

        let searchFound = false;
        let companyFound = false;
        companyFound = selectedStore?.CMPID > 0 ? (sb.SaleBill.CompanyID === selectedStore?.CMPID) : selectedStore?.CMPID === 0 ? true : false;
        if (selectedStore === null)
            companyFound = true;
        searchFound = searchText.length > 0 ? sb.CustomerDetails.CompanyName.toLowerCase().includes(searchText.toLowerCase()) : false;
        let res = searchFound || companyFound;
        return res;
    }

    const newSaleBill = () => {
        setOpenSaleBill(0);
        setSaleBillEdit(true);
    };

    const editSaleBill = (id: number) => {
        setOpenSaleBill(id);
        setSaleBillEdit(true);
    };

    const showSaleBill = (id: number) => {
        setOpenSaleBill(id);
        setViewSaleBill(true);
    };

    const closeEditSaleBillView = () => {
        setSaleBillEdit(false);
        LoadAllSaleBills(startDate, endDate);
        //filterInvoices();
    };

    const handleSearch = (e: any) => {
        setSearchText(e.target.value);
        //filterInvoices();
    };

    const handleStoreSelectChange = (e: any) => {
        if (!e) {
            setSelectedStore(new ML_Company());
            //setStoreID(0);
        }
        else {
            setSelectedStore(e);
            //setStoreID(e.CMPID);
        }
    }

    const deriveCustomerAlias = (aliasName: string) => {
        if (aliasName && aliasName.trim().length > 0) {
            aliasName = aliasName.replaceAll(`<p>`, '').replaceAll(`</p>`, '').replaceAll(`\t`, '').replaceAll(' ', '');
            return aliasName;
        } else return '';
    }

    const deriveCustomerName = (b: SaleBillView) => {
        let customerAliasName = deriveCustomerAlias(b.SaleBill.PhantomCustomer);
        if (customerAliasName != '') {
            return (<>
                <div className='d-flex flex-row'>
                    <div dangerouslySetInnerHTML={{ __html: b.SaleBill.PhantomCustomer.trim() }}></div>
                    <div><span className="badge badge-secondary ml-2">Alias</span></div>
                </div>
            </>);
        } else if (b.CustomerDetails) {
            return (<>
                <span>{b.CustomerDetails.CompanyName}</span>
                {customerAliasName != '' && <span className="badge badge-secondary ml-2">Alias</span>}
            </>);
        } else {
            return "--";
        }
    }


    return (
        <>
            <Modal show={viewSaleBill} dialogClassName="modal-corner"
                size="lg"
                onHide={() => setViewSaleBill(false)}
            >
                <ModalBody className="p-1">
                    <SaleInvoiceView sbid={openSaleBill} />
                </ModalBody>
            </Modal>

            {showSaleBillEdit &&
                <SalesInvoiceEntry store={selectedStore} saleBillID={openSaleBill} close={closeEditSaleBillView} />
            }
            {!showSaleBillEdit &&
                <>

                    <div className="row align-items-center g-4">
                        <div className='col-md-6'>
                            <StoresSelect onChange={handleStoreSelectChange} company={selectedStore} />
                        </div>
                        <div className='col-md-6 text-right'>
                            {selectedStore?.CMPID > 0 &&
                                // <button className="btn btn-az-primary" onClick={newSaleBill}
                                //     accessKey={"n"}>
                                //     <AddOutline color={'white'} width={'18px'} height={'18px'} /> <u>N</u>ew Sale Bill
                                // </button>

                                <AddNewButtonUI
                                    onClick={newSaleBill}
                                />
                            }
                        </div>
                        <div className='col-md-9'>
                            <div className="d-flex flex-row align-items-center">
                                <div className="pr-1">From</div>
                                <div className="pr-2">
                                    <DateControlEx
                                        datevalue={startDate}
                                        onDateChange={(e: string) => {
                                            let start: Date = new Date(e);
                                            //setStartDate(start);
                                            LoadAllSaleBills(start, endDate);
                                        }} /></div>
                                <div className="pr-1">To</div>
                                <div>
                                    <DateControlEx
                                        datevalue={endDate}
                                        onDateChange={(e: string) => {
                                            let end: Date = new Date(e);
                                            //setEndDate(end);
                                            LoadAllSaleBills(startDate, end);
                                        }} />
                                </div>
                            </div>
                        </div>

                        <div className='col-md-3'>
                            <input type="search" className=" form-control"
                                placeholder=" Search by customer..."
                                value={searchText}
                                onChange={handleSearch} />
                        </div>
                    </div>


                    <div className="row mt-4">
                        <div className="col-md-12">
                            <div className="table-responsive">
                                <table className="table table-hover mg-b-3">
                                    <thead className="border-top">
                                        {/*<tr>
                                        <td colSpan={5}>Total Bills : {allSales.filter(filterInvoicesList).length}</td>
                                    </tr>*/}
                                        <tr>
                                            <th>Bill #</th>
                                            <th>Customer</th>
                                            <th className="text-right">Total Qty</th>
                                            <th className="text-center">Bill Date</th>
                                            <th className="text-right">Total Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {allSales.filter(filterInvoicesList).map((b: SaleBillView, index: number) => {
                                            return (<tr key={"sale_" + index}>
                                                <td className="pointer-cursor" title="Edit Sale Bill" onClick={() => {
                                                    if (b.SaleBill.BillType === 'cancel')
                                                        showSaleBill(b.SaleBill.SBID);
                                                    else
                                                        editSaleBill(b.SaleBill.SBID);
                                                }
                                                }>
                                                    <i className="text-info fas fa-edit mr-2"></i> {b.SaleBill.BillNumber}
                                                    {b.SaleBill.BillType === 'cancel' && <span className="badge badge-danger ml-2">Canceled</span>}
                                                </td>
                                                <td>
                                                    {deriveCustomerName(b)}
                                                </td>
                                                <td className="text-right">{b.SaleBill.TotalBulkQty}</td>
                                                <td className="text-center">{dateUtils.FormatDate(b.SaleBill.BillingDate)}</td>
                                                <td className="text-right pointer-cursor" title="View Sale Bill"
                                                    onClick={() => {
                                                        showSaleBill(b.SaleBill.SBID);
                                                    }
                                                    }>&nbsp;{utils.formatCurrencyValue(reduxState, b.SaleBill.PayableTotalAmount)}</td>
                                            </tr>)
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                </>
            }
        </>)
};

export default SalesList;
