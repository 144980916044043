import React from 'react'

const TextBoxNumeric = (props:any)=> {

    const handleFocus = (e:any)=> {e.target.select()}

    return <input type={"number"} {...props} onClick={handleFocus} />
}

export default  TextBoxNumeric;
