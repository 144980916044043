import React from 'react';
import { SaleBillView } from '../globals/dto';
import BrokerCommission, { BrokerUpdateEventArgs } from './BrokerCommission';
import * as _ from 'lodash';

type attributes = {
    saleBillView : SaleBillView,
    onSaleBillViewUpdate(sb:SaleBillView):void
}

const SaleBrokerage = (props:attributes)=> {

    const handleBrokerChange = (e: BrokerUpdateEventArgs) => {
        if (e.brokerSelected) {
            let copy = _.clone(props.saleBillView);
            
            //Resets commission calculation entries in BrokCommission component.
            copy.BrokerPayVM.Broker = e.brokerSelected;
            
            if (e.brokerSelected.BRKID === 0) {
                copy.BrokerPayVM.TransactionEntry.TotalAmount = 0;
            } else {
                copy.BrokerPayVM.BrokerPay.CommissionMode = e.commMode;
                if(e.commMode === 2){
                    copy.BrokerPayVM.TransactionEntry.TotalAmount = e.lumpSumAmount;
                } else if (e.commMode === 1){
                    copy.BrokerPayVM.TransactionEntry.TotalAmount =
                    copy.BrokerPayVM.Broker.CommissionAmount * copy.SaleBill.TotalBulkQty;
                }
            }
            //calculateAllTotalsEx(copy);
            props.onSaleBillViewUpdate(copy);
        }
    }

    return (<>
    
    <BrokerCommission
                broker={props.saleBillView.BrokerPayVM.Broker}
                commissionMode={props.saleBillView.BrokerPayVM.BrokerPay.CommissionMode}
                totalAmount={props.saleBillView.BrokerPayVM.TransactionEntry.TotalAmount}
                totalQty={props.saleBillView.SaleBill.TotalBulkQty}
                onUpdate={handleBrokerChange}
            />
    </>);

}

export default SaleBrokerage;