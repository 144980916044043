import React, { useEffect, useState } from 'react';
import { ProductsDropDown } from "../products/ProductsDropDown";
import { ArrowDownCircle } from "react-bootstrap-icons";
import TextBoxNumeric from "../ui-controls/TextBoxNumeric";
import ProductNameSuggest from '../ui-controls/ProductNameSuggest';
import * as pdb from '../db/ProductsData';
import { ML_Product, ProductView } from '../globals/dto';
import * as sdb from '../db/StocksData';
import * as _ from 'lodash';
import { toast } from 'react-toastify';
import { totalmem } from 'os';


type attributes = {
    prodId: number,
    compId: number,
    stockId:number,
    onSplit(): void,
    onCancel(): void
}


const SplitStockSimple = (props: attributes) => {

    const [baseProduct, setBaseProduct] = useState<ProductView>();
    const [targetProduct, setTargetProduct] = useState<ProductView>();

    const [inComingQty, setIncomingQty] = useState(0);
    const [outgoingQty, setOutgoingQty] = useState(0);


    useEffect(() => {
        pdb.GetProduct(props.prodId).then(res => {
            setBaseProduct(res.data);
        });
    }, [props.prodId]);

    const onSave = () => {

        if (!targetProduct || targetProduct.Product?.PRDID <= 0 || inComingQty <= 0 || outgoingQty <= 0) {
            toast.error('Split qty or Product name missing');
            return;
        }
        //compid, basePrdID, baseProdStockId, inBlkQty, targetPrdId, splitQty
        sdb.SplitStock(props.compId, props.prodId, props.stockId, inComingQty, targetProduct.Product.PRDID, outgoingQty).then(res => {
            if (res.data === 'OK') {
                toast.success('Stock split successful!');
                props.onSplit();
            } else
                toast.error(res.data);
        });
    }

    const afterSplitUnit = () => {
        if (targetProduct && targetProduct.Product?.PRDID > 0)
            return `${outgoingQty} ${targetProduct.UnitMeasure.BulkMeasureName} of ${targetProduct.UnitMeasure.RetailQty} ${targetProduct.UnitMeasure.RetailMesaureName}`;
        else
            return 'Qty. after split';
    }

    const beforeSplitUnit = () => {

    }

    if (!baseProduct)
        return <div className="p-4 text-center">Please wait...</div>;

    if (baseProduct)
        return (<>

            <div className="d-flex flex-column text-center align-content-center">
                <div className="h4 font-weight-bold border-bottom">
                    {baseProduct.Product.ProductName}
                    <h5>Unit: (1 {baseProduct.UnitMeasure.BulkMeasureName} / {baseProduct.UnitMeasure.RetailQty} {baseProduct.UnitMeasure.RetailMesaureName})</h5>
                </div>

                {/* <div>
                <label className="form-label">In Stock</label>
                <span className="font-weight-bold h5">2500 Bags</span>
            </div>*/}
                <div className="py-3">
                    <ArrowDownCircle className="tx-28" />
                </div>
                <div>
                    <label className="form-label">{inComingQty} {baseProduct.UnitMeasure.BulkMeasureName} to split</label>
                    <TextBoxNumeric className="form-control form-control-free text-center w-25"
                        name="bulkQty"
                        value={inComingQty}
                        onChange={(e: any) => setIncomingQty(e.target.value ? e.target.value : 0)} />
                </div>
                <div className="py-3">
                    <ArrowDownCircle className="tx-28" />
                </div>
                <div>
                    <label className="form-label">{afterSplitUnit()}</label>
                    <TextBoxNumeric className="form-control form-control-free text-center w-25"
                        onChange={(e: any) => setOutgoingQty(e.target.value ? e.target.value : 0)}
                        value={outgoingQty} />
                    {/* <span className="font-weight-bold h4 text-primary">{data.qty} {baseProduct.UnitMeasure.RetailMesaureName}</span> */}
                </div>
                <div className="py-3">
                    <ArrowDownCircle className="tx-28" />
                </div>
                <div className="text-left">
                    <label>Product Name</label>
                    <ProductsDropDown
                        onChange={(p: ProductView) => { setTargetProduct(p) }}
                        showOnlyPurchasable={false}
                        showOnlySubProducts={false}
                        showOnlySaleable={false}
                        showWithStocks={true}
                        product={targetProduct}
                    />
                    {/* <ProductNameSuggest onChange={handleOnChange} name="prodName"/> */}
                </div>
                {/* <div className="text-right">
                <a className="link pointer-cursor">+Create New Product</a>
            </div> */}
                <div className="mt-4">
                    <button className="btn btn-success mr-2" onClick={onSave}>Split</button>
                    <button className="btn btn-secondary" onClick={props.onCancel}>Cancel</button>

                </div>
            </div>

        </>)
}

export default SplitStockSimple;
