import {ML_Ledger, ML_Tag, ML_Tax} from "../../globals/dto";

export class ReportSettingDto {
    public start: Date = new Date();
    public end: Date = new Date();
    public ledgers: number[];
    public groups: number[];
}


