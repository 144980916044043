import React, {useEffect, useState} from 'react';
import {SaleBillLineItemView, SaleBillView, TaxAppliedDTO} from "../globals/dto";
import * as sbdb from '../db/SaleBillData';
import qs from 'query-string';
import * as utils from '../globals/Utils';
import * as dateUtils from '../globals/AppDateUtils';
import {InvoiceMiscNotes} from '../globals/data.dto';
import {AxiosResponse} from 'axios';
import {AppReduxState} from '../infra-redux/AppStateReduxStore';
import {useSelector} from 'react-redux';

//props
//sbid : number


export const SaleInvoiceView = (props: any) => {

    const [saleView, setSaleView] = useState(new SaleBillView());
    const [miscNotes, setMiscNotes] = useState<InvoiceMiscNotes>(new InvoiceMiscNotes());
    const reduxState = useSelector((state: AppReduxState) => state);

    useEffect(() => {
        let sbid = 0;

        if (props.sbid) {
            sbid = props.sbid;
        } else {
            if (props.location) {
                let args = qs.parse(props.location.search);
                if (+args.iwid > 0) {
                    sbid = +args.sbid;
                }
            }
        }
        if(sbid > 0) {
            sbdb.GetSaleBill(sbid).then((res: AxiosResponse<SaleBillView>) => {
                if (res && res.data) {
                    setSaleView({...res.data});
                    if (res.data.SaleBill.MiscItemsJson)
                        setMiscNotes(JSON.parse(res.data.SaleBill.MiscItemsJson));
                }
            });
        }
    }, [])

    if (!saleView.SaleBill) {
        return <></>
    }

    return (
        <>
            {/*{JSON.stringify(saleView)}*/}
            <div className="card">
                <div className="card-body">
                    <div className="row text-black pt-2">
                        <div className="col-md-6  border-top border-bottom pb-2 border-right">
                            <b>{saleView.Company.CompanyName}</b><br/>
                            {saleView.Company.CompanyAddress}<br/>
                            {/*GSTIN/UIN : 234234234234<br/>
                        StateName : karnataka, Code :29*/}
                        </div>
                        <div className="col-md-6  border-top border-bottom text-right">
                            <h1 className="invoice-title">Sale Invoice</h1>
                            <div className="d-flex justify-content-around pt-4">
                                <div className="text-right">
                                    <span className="font-weight-normal">Invoice No.</span><br/>
                                    <b>{saleView.SaleBill.BillNumber}</b>
                                </div>
                                <div className="text-right">
                                    <span className="font-weight-normal">Invoice Date</span><br/>
                                    <b>{dateUtils.FormatDate(saleView.SaleBill.BillingDate)}</b>
                                </div>
                                {/*<div className="text-right">
                                    <span className="font-weight-normal">Received Date</span><br/>
                                    <b>{saleView.SaleBill.InvoiceReceivedDate.toDateString()}</b>
                                </div>*/}
                            </div>
                        </div>
                    </div>
                    <div className="row  text-black">
                        <div className="col-md-7">
                            <b>Buyer</b>
                            {saleView.SaleBill.PhantomCustomer.trim().length === 0 &&
                                <div>
                                    <b>{saleView.CustomerDetails.CompanyName}</b><br/>
                                    {saleView.CustomerDetails.AddressOne}<br/>
                                    {saleView.CustomerDetails.AddressTwo},{saleView.CustomerDetails.GeoCity}<br/>
                                    GSTIN/UIN: {saleView.CustomerDetails.GSTN}<br/>
                                    State Name : {saleView.CustomerDetails.GeoState},
                                    Code: {saleView.CustomerDetails.GeoBusinessCode}
                                </div>
                            }

                            {saleView.SaleBill.PhantomCustomer.trim().length > 0 &&
                                <p style={{marginTop: "5px"}}
                                   dangerouslySetInnerHTML={{__html: saleView.SaleBill.PhantomCustomer}}></p>
                            }

                        </div>
                        <div className="col-md-5 text-right pt-2">
                            <table className="table-sm float-right">
                                <tbody>
                                <tr className="border-bottom">
                                    <td>Vehicle No</td>
                                    <td>{saleView.SaleBill.DispatchVehicleNumber}</td>
                                </tr>
                                <tr className="border-bottom">
                                    <td className="wd-150">Delivery Notes:</td>
                                    <td className="text-left">{miscNotes?.DeliveryNotes}</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div className="row  text-black mt-3">

                        <div className="col-md-12">
                            <div className="table-responsive">
                                <table className="table table-bordered table-hover">
                                    <thead className="border-top">
                                    <tr>
                                        <th>S#</th>
                                        <th>Description of Goods</th>
                                        <th>HSN/SAC</th>
                                        <th>Quantity</th>
                                        <th className="text-center">Rate</th>
                                        <th>Disc.</th>
                                        <th className="text-right">Tax</th>
                                        <th className="text-right">Amount</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {saleView.LineItems.map((li: SaleBillLineItemView, lindx: number) => {
                                        return (
                                            <>
                                                {li.LineItem.OnFlyItemPrice === 0 && li.LineProduct && 
                                                    <tr key={utils.uuidv4()}>
                                                        <td>{lindx + 1}</td>
                                                        <td>{li.LineProduct.Product.ProductName}</td>
                                                        <td>{li.LineProduct.Product.HSNSACNumber}</td>
                                                        <td>
                                                            <b>{li.LineItem.BulkQty}</b> {li.LineProduct.UnitMeasure.BulkMeasureName} / <b>{li.LineItem.RetailQty}</b> {li.LineProduct.UnitMeasure.RetailMesaureName}
                                                        </td>
                                                        <td className="text-center">
                                                            <b>{li.LineItem.PricePerBulkUnit}</b> per <b>{li.LineProduct.UnitMeasure.BulkMeasureName}</b>
                                                        </td>
                                                        <td className="text-right">{utils.formatCurrencyValue(reduxState, li.LineItem.DiscountedAmount)}</td>
                                                        <td className="text-right">{utils.formatCurrencyValue(reduxState, li.LineItem.AmountTaxed)}</td>
                                                        <td className="text-right">{utils.formatCurrencyValue(reduxState, li.LineItem.SubTotal)}</td>
                                                    </tr>
                                                }
                                                {li.LineItem.OnFlyItemPrice !== 0 &&
                                                    <tr key={utils.uuidv4()} className="pointer-cursor">
                                                        <td>{lindx + 1}</td>
                                                        <td colSpan={6}>{li.LineItem.OnFlyItem}</td>
                                                        <td className="text-right">{utils.formatCurrencyValue(reduxState, li.LineItem.AmountUntaxed)}</td>
                                                    </tr>
                                                }

                                                {li.TaxDetails?.SlabsApplied.map((item: TaxAppliedDTO, rnum: number) => {
                                                    return (<tr key={lindx + "_" + rnum}>
                                                        <td colSpan={7}>
                                                            <div
                                                                className="float-right font-weight-normal">{item.TaxName} : {utils.formatCurrencyValue(reduxState, item.TaxValue)}</div>
                                                        </td>
                                                        <td className="text-right">{utils.formatCurrencyValue(reduxState, item.TotalTaxedAmount)}</td>
                                                    </tr>)
                                                })}

                                                {/*{li.TaxDetails && li.TaxDetails.SlabsApplied.length > 0 &&
                                            <tr key={'txli'+lindx}>
                                                <td></td>
                                                <td colSpan={5} className="text-right"><i>Taxes Applied</i>:
                                                    {li.TaxDetails.SlabsApplied.map((td:TaxAppliedDTO)=>{
                                                        return <span className="pl-3">
                                                            ({td.TaxValue}% {td.TaxName} = Rs.<b>{td.TotalTaxedAmount}</b>)
                                                        </span>
                                                    })}
                                                </td>
                                                <td className="text-right">{li.LineItem.AmountTaxed}</td>
                                            </tr>
                                           }*/}

                                                {/* {li.LineItem.DiscountAmount > 0 &&
                                           <tr key={'dlili'+lindx}>
                                               <td></td>
                                               <td colSpan={5} className="text-right">
                                                   Discount &nbsp;
                                                   {li.LineItem.DiscountPercentage > 0 && <span>{li.LineItem.DiscountPercentage}%</span> }
                                               </td>
                                               <td className="text-right">-</td>
                                           </tr>
                                           }*/}
                                            </>);
                                    })}

                                    {saleView.AdditionalExpense.filter(x => x.IsDeductInInvoice === 1).map(ai => {
                                        return (
                                            <tr key={"trd" + ai.Transaction.EXPID}>
                                                <td></td>
                                                <td colSpan={6}>{ai.Transaction.Details}</td>
                                                <td colSpan={6}
                                                    className="text-right">-{utils.formatCurrencyValue(reduxState, ai.Transaction.TotalAmount)}</td>
                                            </tr>
                                        )
                                    })}

                                    </tbody>

                                    <tfoot>
                                    <tr>
                                        <td></td>
                                        <td colSpan={2} className="text-right"></td>
                                        <td>Total Qty: {saleView.SaleBill.TotalBulkQty}</td>
                                        <td colSpan={3} className="text-right">Grand Total</td>
                                        <td className="text-right">
                                            <b>{utils.formatCurrencyValue(reduxState, saleView.SaleBill.PayableTotalAmount)}</b>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={8}><u>Amount in words</u>: <i
                                            className="text-capitalize">{utils.convertNumberToWords(reduxState, saleView.SaleBill.PayableTotalAmount)}</i> only
                                        </td>
                                    </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>

                        <div className="col-md-12 mt-3">
                            <div className="table-responsive">
                                <table className="table table-bordered table-hover">
                                    <thead className="border-top">
                                    <tr>
                                        <th>HSN/SAC</th>
                                        <th className="text-right wd-200-f">Taxable Value</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {saleView.LineItems.map((li: SaleBillLineItemView) => {
                                        if (li.LineItem.OnFlyItemPrice === 0 && li.LineProduct) {
                                            return (<tr key={"ofy" + li.LineItem.SLITID}>
                                                <td>{li.LineProduct.Product.HSNSACNumber}</td>
                                                <td className="text-right">{utils.formatCurrencyValue(reduxState, li.LineItem.SubTotal)}</td>
                                            </tr>)
                                        }
                                    })}
                                    </tbody>
                                    <tfoot>
                                    <tr>
                                        <td className="text-right">Total</td>
                                        <td className="text-right">
                                            <b>{utils.formatCurrencyValue(reduxState, saleView.SaleBill.PayableTotalAmount)}</b>
                                        </td>
                                    </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>

                    </div>

                    {saleView.SaleBill.PhantomCustomer.trim().length === 0 &&
                        <div className="row text-black">
                            <div className="col-md-6"></div>
                            <div className="col-md-6 text-right">
                                <u>Buyer Bank Details</u><br/>
                                Company PAN: <b>{saleView.CustomerDetails.CompanyPAN}</b> <br/>
                                GSTN: <b>{saleView.CustomerDetails.GSTN}</b> <br/>
                                KT Number: <b>{saleView.CustomerDetails.KTNumber}</b> <br/>
                                Location: <b>{saleView.CustomerDetails.GeoCity},{saleView.CustomerDetails.GeoState}</b>
                                <br/>
                            </div>
                        </div>
                    }
                    <div className="row mt-3 border-top text-black">
                        <div className="col-md-6">
                            <u>Declaration</u><br/>
                            E-Way bill not required as goods sold undert his invioce as expemted under rule 1289813) of
                            a GST act.
                        </div>
                        <div className="col-md-6 text-right border-left">
                            for K L Parameshwarais & Son
                            <br/>
                            <br/>
                            <br/>
                            Authorised Signatory
                        </div>
                    </div>

                    <div className="row border-top text-black">
                        <div className="col-md-12 text-center">
                            SUBJECT TO DAVANGERE JURISDICTION <br/>
                            <span className="font-small">This is a computer generated invoice</span>
                        </div>
                    </div>

                </div>
            </div>
        </>);
}
