import React, { useState } from 'react';
import { CompanySettingsVM, ML_Transaction, SaleBillView } from '../globals/dto';
import AmountPayableManager from './AmountPayableManager';
import OnBillDiscountRow from './OnBillDiscountRow';
import SaleLineItemsView from './SaleLineItemsView';
import * as _ from 'lodash';
import * as helper from "./SaleInoviceHelper";
import OnSpotPay from './OnSpotPay';
import OtherSaleItems from './OtherSaleItems';
import { AppReduxState } from '../infra-redux/AppStateReduxStore';
import { useSelector } from 'react-redux';
import * as cdb from './../db/CompanyData';
import { AddNewButtonUI } from '../globals/AppControls/AddNewButtonUI';

type attributes = {
    saleBillView: SaleBillView,
    onSaleBillViewUpdate(sb: SaleBillView): void,
}

const SaleItemsTable = (props: attributes) => {

    const [newLineItemBell, setNewLineItemBell] = useState(-1);
    const [newOtherItemBell, setNewOtherItemBell] = useState(-1);
    const reduxState = useSelector((state: AppReduxState) => state);

    const popUpLineItem = () => {
        setNewLineItemBell(newLineItemBell + 1);
        console.log(newLineItemBell);
    }

    const popUpOtherItem = () => {
        setNewOtherItemBell(newOtherItemBell + 1);
    }

    const handleOnPayableAmountChange = (amount: number) => {
        let sb = _.clone(props.saleBillView);
        sb.SaleBill.PayableTotalAmount = amount;
        props.onSaleBillViewUpdate(sb);
    }

    const handleOnSpotPayment = (e: ML_Transaction) => {
        props.onSaleBillViewUpdate({ ...props.saleBillView, CashPayTrans: { ...e } });
    }

    return (<>

        {/* <div className="card mb-3">
            <div className="card-header"> */}
                <h5 className='mt-3 az-content-label'>Sale Items</h5>
            {/* </div>
            <div className="card-body"> */}
                <div className="form-row">
                    <div className="col-md-12">
                        <div className="table-responsive">
                            <table className="table table-invoice table-hover table-bordered table-curved">
                                <thead className="border-top">
                                    <tr key="sbx0">
                                        <th className="wd-40p">Product / Description</th>
                                        <th>HSN/SAC</th>
                                        <th className="tx-center wd-40p">Qty</th>
                                        <th className="tx-right wd-10p">Unit Price</th>
                                        <th className="tx-right wd-20p">Discount</th>
                                        {/*<th className="tx-right wd-40p-f">Tax</th>*/}
                                        <th className="tx-right">Amount</th>
                                    </tr>
                                </thead>
                                <tbody>

                                    <SaleLineItemsView
                                        saleBillView={props.saleBillView}
                                        onSaleBillUpdate={props.onSaleBillViewUpdate}
                                        newLineItemBell={newLineItemBell}
                                    />

                                    <OtherSaleItems
                                        newItemRingBell={newOtherItemBell}
                                        onSaleBillViewUpdate={props.onSaleBillViewUpdate}
                                        saleBillView={props.saleBillView}
                                    />

                                    {props.saleBillView.SaleBill.PayableTotalAmount > 0 &&
                                        <OnBillDiscountRow
                                            onSaleBillViewUpdate={props.onSaleBillViewUpdate}
                                            saleBillView={props.saleBillView}
                                        />
                                    }

                                    <tr key={"sbtt" + props.saleBillView.SaleBill.SBID}>
                                        <td>
                                            {/* <button className="btn btn-indigo text-white mr-3" accessKey={"t"}
                                                onClick={() => popUpLineItem()}>+Add I<u>t</u>em
                                            </button>
                                            <button className="btn btn-indigo text-white mr-3" accessKey={"o"}
                                                onClick={popUpOtherItem}>+Add Other
                                            </button> */}
                                            <AddNewButtonUI
                                                className="mr-3"
                                                buttonText="Add I<u>t</u>em"
                                                accessKey={"t"}
                                                onClick={() => popUpLineItem()}
                                            />
                                            <AddNewButtonUI
                                                buttonText="Add <u>O</u>ther"
                                                accessKey={"o"}
                                                onClick={popUpOtherItem}
                                            />
                                        </td>
                                        <td></td>
                                        <td className="text-center">
                                            Total Qty: <b>{props.saleBillView.SaleBill.TotalBulkQty}</b></td>
                                        <td colSpan={3} style={{ lineHeight: "1.1em" }} className="text-right">
                                            {props.saleBillView.SaleBill.PayableTotalAmount > 0 &&
                                                <table className="table table-sm table-borderless mb-0">
                                                    <tbody>
                                                        {helper.GrandTotals(reduxState, props.saleBillView)}
                                                        <tr key={"k4"} className="border-top">
                                                            <AmountPayableManager
                                                                Amount={props.saleBillView.SaleBill.PayableTotalAmount}
                                                                saleBillId={props.saleBillView.SaleBill.SBID}
                                                                onUpdate={handleOnPayableAmountChange}

                                                            />
                                                        </tr>
                                                        <tr key={"k5"} className={"border-top"}>
                                                            {/*<td className="text-rigth w-50">Paid Now:</td>*/}
                                                            <td className="text-right pr-0" colSpan={2}>
                                                                <OnSpotPay
                                                                    payTrans={props.saleBillView.CashPayTrans}
                                                                    onChange={handleOnSpotPayment}
                                                                    buttonTitle="Receive Payment"
                                                                    headerTitle='Cash Sale'
                                                                />
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            }
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            {/* </div>
        </div> */}
    </>);
}

export default SaleItemsTable;
