import React, {useEffect, useRef, useState} from "react";
import {OverlayTrigger, Popover, Spinner} from "react-bootstrap";
import {TelephoneOutbound} from "react-bootstrap-icons";
import {LocationOutline} from "react-ionicons";
import * as utils from '../../globals/Utils';
import {CompanyView, ML_Company} from "../dto";
import * as cdb from '../../db/CompanyData';

type attributes = {
    cssClasses: string,
    id: number,
    elementKey: number
}

export const StorePopTag = (props: attributes) => {
    const [info, setInfo] = useState<CompanyView>(null);
    const keyPrefix = 'cmp'
    const FetchInfo = () => {
        if (info === null && props.id > 0) {
            cdb.GetOnId(props.id).then(res => {
                setInfo(res.data);
            })
        }
    }

    const popoverContent =
        <Popover id={props.elementKey + 'popSub' + keyPrefix} className="shadow-ex-1 border border-gray" placement={'top'}>
            <Popover.Body>
                {!info && <Spinner animation={"border"} variant={"secondary"}/>}
                {info && <>
                    <div className="d-flex flex-column gap-2">
                        <div className="font-weight-bold">{info.Company.CompanyName}</div>
                        {!utils.isEmptyOrSpaces(info.Company.CompanyAddress) && <div>
                            <LocationOutline/>&nbsp;{info.Company.CompanyAddress}</div>
                        }
                        {!utils.isEmptyOrSpaces(info.Company.MobileNumber) &&
                            <div><TelephoneOutbound/>&nbsp;{info.Company.MobileNumber}</div>
                        }
                    </div>
                </>}
            </Popover.Body>
        </Popover>

    return (
        <OverlayTrigger key={props.elementKey + 'pop' + keyPrefix} placement="top" rootClose trigger="click"
                        onToggle={() => FetchInfo()} overlay={popoverContent}>
            <span className={props.cssClasses + " badge-light"}>Store</span>
        </OverlayTrigger>
    )
}
