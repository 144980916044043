import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { ML_Company, ML_Product, ML_ProductTransits, ML_UnitMeasure } from "../../globals/dto";
import * as rpt from '../../db/ReportsData';
import * as AppAct from '../../globals/AppActions';
import * as dateUtils from '../../globals/AppDateUtils';
import { AirplaneOutline, ArrowForwardCircle, BusOutline, CodeWorkingOutline } from "react-ionicons";

class ResultData {
    FromProduct: ML_Product;
    FromUnit: ML_UnitMeasure;
    FromCompany: ML_Company;
    ToCompany: ML_Company;
    Transit: ML_ProductTransits
}

const ProductStoresMoveReport = (props: any) => {
    const AppDisp = useDispatch();
    const [data, setData] = useState<ResultData[]>([]);


    useEffect(() => {
        GetAllStockMoveHistory();
    }, []);

    const GetAllStockMoveHistory = () => {
        rpt.GetAllStoreMovedStocks().then(res => {
            if (res.data)
                setData(res.data);
        })
    }

    return (<>
        <div className="az-content-label mg-b-5 tx-22-f d-flex align-items-center">
            <BusOutline width={'28px'} height={'28px'} /> &nbsp; Product Move
        </div>

        <div className="table-responsive">
            <table className="table table-bordered table-striped">
                <thead className="border-top">
                    <tr>
                        <th>Date/Time</th>
                        <th>Product</th>
                        <th>Qty</th>
                        <th>From Store</th>
                        <td></td>
                        <th>To Store</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((row: ResultData, idx: number) => {
                        return (
                            <tr key={'GPMV' + idx}>
                                <td>{dateUtils.FormatDateTime(row.Transit.CreateDate)}</td>
                                <td>{row.FromProduct.ProductName} <br />
                                    1 {row.FromUnit.BulkMeasureName} = {row.FromUnit.RetailQty} {row.FromUnit.RetailMesaureName}
                                </td>
                                <td>{row.Transit.ToQty}</td>
                                <td>{row.FromCompany.CompanyName}</td>
                                <td className="text-center"><ArrowForwardCircle /></td>
                                <td>{row.ToCompany.CompanyName}</td>
                            </tr>);
                    })}
                </tbody>
            </table>
        </div>



    </>)
}

export default ProductStoresMoveReport;
