import axios from 'axios';
import * as global from '../globals/ApiUrlConsts';

const web_api_url = global.global_api_url_base + '/api/process';
const flow_design_api = global.global_api_url_base + '/api/flows'

export const GetAllPlants = async (workflowID) =>
    await axios.get( web_api_url + '/GetAllPlants?workflowId='+workflowID)

export const GetPlantReviewStats = async (pltId) =>
    await axios.get( web_api_url + '/GetPlantReviewStats?pltId='+pltId)

export const GetMachineDetails = async (machineID) =>
    await axios.get(web_api_url +'/GetMachine?machineID=' + machineID)

export const GetPlantsSorted = async (wfid) =>
    await axios.get(web_api_url + '/GetSortedPlantList?workflowID=' + wfid)

export const GetWorkflowDetails = async (workflowID) =>
    await axios.get(web_api_url + '/AllMachines?wid='+workflowID)

export const GetWorkflowInfo = async (workflowID) =>
    await axios.get(flow_design_api + '/GetWorkflowInfo?wfid='+workflowID)

//---------------WORKFLOW DESIGNER METHODS----------------------//
export const GetAllWorkflows = async () =>
    await axios.get( flow_design_api + '/GetAll')
export const saveWorkflow = async (workflow) =>
    await axios.post(flow_design_api + '/SaveWorkflow', workflow);

export const copyWorkflow = async (wfname, srcid) =>
    await axios.post(flow_design_api + '/CopyWorkflow',null,
        {params: {newWorkflowName:wfname, sourcewfid:srcid}});

export const deleteWorkflow = async (wfid) =>
    await axios.post(flow_design_api + '/DeleteWorkflow',null, {params: {wfid}});

export const savePlant = async (plant,wfid,exeorder) =>
    await axios.post(flow_design_api + '/SavePlant', plant, {params : {wfid, exeorder}});

export const copyPlant = async (pid) =>
    await axios.post(flow_design_api + '/CopyPlant', null, {params : {plantid : pid}});

export const updatePlantRunOrder = async (wfid,pltid,exeorder) =>
    await axios.post(flow_design_api + '/UpdatePlantRunOrder',null, {params : {wfid, plantid:pltid, execorder:exeorder}});

export const deletePlant = async (pltid)=>
    await axios.post(flow_design_api + '/DeletePlant', null, {params : {ptid : pltid}})

export const saveMachine = async (machine) =>
    await axios.post(flow_design_api + '/SaveMachine', machine);

export const deleteMachine = async (mcid )=>
    await axios.post(flow_design_api + '/DeleteMachine', null, {params: {mcid}});

export const linkFlowMachinePlant = async (mcid, plid, wfid, exeorder)=>
    await axios.post(flow_design_api + '/LinkWorkPlantMachine', null,
        { params : {macid : mcid, plid : plid, wfid : wfid, exeorder: exeorder}})
    