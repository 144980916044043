import { type } from 'os';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { preProcessFile } from 'typescript';
import {FileAttachment, ML_ProcessTracker, ProcessorView, ProductView, ReviewComment} from '../globals/dto';
import { MachinesSelect } from '../shared/MachinesSelect';
import { WorkflowSelectorDropDown } from '../shared/WorkflowSelectorDropDown';
import * as _pdb from './../db/ProcessingData';
import * as _rbl from './../db/ReviewData';
import ReviewHistory from './ReviewHistory';
import FileAttachPanel from "./FileAttachPanel";
import * as factory from '../globals/factory';
import { toast } from "react-toastify";
import * as pdb from "../db/ProcessingData";

type attributes = {
    currentProcessId: number,
    workflowId: number,
    lineItemId:number,

    parentProcessId:number,
    batchId:number,
    prodId:number
    qty:number
}

const ReviewTab = (props: attributes) => {

    const [processView, setProcessView] = useState<ProcessorView>();
    const [workflowId, setWorkflowId] = useState(props.workflowId);
    const [review, setReview] = useState<ReviewComment>(factory.createReviewComment());
    const [showPostButton, setShowPostButton] = useState(true);
    const [oldComments, setOldComments] = useState<Array<ML_ProcessTracker>>();
    const [nextProcessFlowId, setNextProcessFlowId] = useState(0);

    const LoadOldReviews = () => {
        _rbl.GetRelatedReviews(props.lineItemId).then(resComment=>{
            // console.log(res.data)
            setOldComments(resComment.data);
        });
    }

    const moveToNextMachine = ()=> {
        if(props.prodId > 0 && props.qty > 0 && nextProcessFlowId > 0) {
            pdb.MoveToNextMachineEx(props.parentProcessId, nextProcessFlowId, props.qty, 
                props.batchId, props.prodId, true, false)
                .then((res: any) => {
                    toast.info('Process re-routed successfully');
                })
        } else {
            toast.error('Please enter all data properly');
        }
    }

    useEffect(() => {
        console.log('ReviewTab',props);
        LoadOldReviews();
        _pdb.GetProcessorView(props.currentProcessId).then(res => setProcessView(res.data));

    }, [])

    const handleNewProcessFlowSelected = (flowId: number) => {
        setNextProcessFlowId(flowId);
    }

    const handleFilesListUpdate = (files:FileAttachment[])=>{
        let copy = {...review};
        copy.Attachments = files;
        setReview(copy);
    }

    const handlePostReview = ()=> {
        console.log('saving review');
        _rbl.PostQCReview(review,processView.ProcessEntry.ProcessingInItemsCount, processView.ProcessEntry.PID,0).then(res=>{
            setShowPostButton(false);
            toast.success('You review saved!');
            LoadOldReviews();
            setReview(factory.createReviewComment());
        });
    }

    const handleReviewDetails = (e:any)=>{
        review.Details = e.target.value;
        setReview(review);
    }

    return (<>
        {processView &&
            <div className="d-flex flex-column text-black py-1">

                <div className="d-flex flex-rows align-items-end mt-1 mb-3 pt-2 p-3 rounded-5 border bg-light">
                    <div className="mr-3 flex-fill">
                        <label className="label-b-0">Workflow</label>
                        <WorkflowSelectorDropDown
                            selectedId={workflowId}
                            onChange={(e:number) => setWorkflowId(e)}
                        />
                    </div>
                    <div className="wd-350-f mr-3">
                        <label className="label-b-0">Move to</label>
                        <MachinesSelect
                            workflowId={workflowId}
                            onProcessFlowIdSelected={handleNewProcessFlowSelected}
                        />
                    </div>
                    <div>
                        <button className="btn btn-success" onClick={moveToNextMachine}>Re-Route</button>
                    </div>
                </div>


                <div className="d-flex flex-column p-3 border rounded-5 bd-gray-100 bg-gray-100">
                    <div className="mt-1">
                        <span>Review Comments</span>
                        <textarea onChange={handleReviewDetails} className="form-control" rows={2}></textarea>
                    </div>

                    <div className="mt-2">
                        <FileAttachPanel
                            files={review.Attachments}
                            onFilesUpdated={handleFilesListUpdate}
                        />
                    </div>
                    <div className="text-right">
                        <button className="btn btn-az-darkblue" onClick={(e)=>handlePostReview()}>Post Review</button>
                    </div>
                </div>

                <div className="mt-4 border-bottom bd-gray-400 mb-1 tx-gray-700">Previous Comments: {oldComments?.length}</div>
                <div>
                    <ReviewHistory reviewsList={oldComments} />
                </div>

            </div>
        }
    </>);
}

export default ReviewTab;