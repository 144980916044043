import React from 'react';
import {ML_Company} from "../../globals/dto";

export const FiltersAppliedInfo = (props: { filters: {
        stores: ML_Company[],
        showCashBillsOnly: boolean,
        showCreditBillsOnly: boolean,
    }
}) => {

    const storesList = () => {
        if (props.filters.stores && props.filters.stores.length > 0) {
            return props.filters.stores.map((s: ML_Company) => {
                return <span className="badge badge-pill badge-light tx-14-f mr-1">{s.CompanyName}</span>
            })
        } else {
            return <span className="badge badge-pill badge-light tx-14-f mr-1">All</span>
        }
    }

    const cashSaleFlag = () => {
        if (props.filters.showCashBillsOnly && props.filters.showCreditBillsOnly ===false)
            return <span className="badge badge-pill badge-light tx-14-f mr-1">Cash bills only</span>
        else if (props.filters.showCashBillsOnly == false && props.filters.showCreditBillsOnly)
            return <span className="badge badge-pill badge-light tx-14-f mr-1">Credit bills only</span>
        else
            return <span className="badge badge-pill badge-light tx-14-f mr-1">All</span>;
    }

    return <p className="tx-14-f" style={{lineHeight:'2.2em'}}>
        <div className="tx-14-f text-black-50">Filters Applied</div>
        <span>
            <span className="font-weight-bold mr-1">Stores</span>
            {storesList()}
        </span>
        <span className="font-weight-bold mr-1">Bill Types</span>
        {cashSaleFlag()}

    </p>
}
