import React, { useEffect, useState } from 'react';
import Select, { components, OptionProps } from 'react-select';
import { ML_Company } from "../globals/dto";
import * as cdb from '../db/CompanyData';

//props
//onChange(ML_Company)
//company : ML_Company

export const storeSingleOption = (props: any) => (

    <components.Option {...props}>
        <b>{props.data.CompanyName}</b>
    </components.Option>
)

export const storeSingleValue = (props: any) => (
    <components.SingleValue {...props}>
        <b>{props.data.CompanyName}</b>
    </components.SingleValue>
)

const StoresSelect = (props: any) => {

    const [state, setState] = useState({
        stores: new Array<ML_Company>(),
        selStore: new ML_Company(),
        loading: false
    });

    useEffect(() => {
        setState({ ...state, loading: true });
        cdb.GetAll().then(res => {
            setState({ ...state, stores: res.data, loading: false });
        });
    }, [])

    useEffect(() => {

        if (state.selStore && props.company) {
            if (state.selStore.CMPID != props.company.CMPID) {
                setState({ ...state, selStore: props.company });
            }
        }
    }, [props.company?.CMPID])

    const handleChange = (e: ML_Company) => {
        setState({ ...state, selStore: { ...e } });
        props.onChange(e);
    }

    const customStyles = {
        option: (provided: any, state: any) => ({
            ...provided,
        }),
        control: (p: any, st: any) => {
            return { ...p, minHeight: 30, padding: 0, margin: 0 }
        },
        singleValue: (provided: any, state: any) => {
            //   const opacity = state.isDisabled ? 0.5 : 1;
            //   const transition = 'opacity 300ms';

            //return { ...provided, opacity, transition };
            return { ...provided }
        }
    }

    
        return <Select
            classNamePrefix="srushti"
            options={state.stores}
            isClearable={true}
            isLoading={state.loading}
            value={props.company}
            defaultValue={state.stores[0]}
            theme={theme => ({ ...theme, borderRadius: 0, width: "100%" })}
            components={{
                Option: storeSingleOption,
                SingleValue: storeSingleValue
            }}
            placeholder={"Select Store..."}
            onChange={handleChange}
            getOptionValue={option => option.CMPID.toString()}></Select>;

        /*return <Select
        Options={state.stores}
        value={state.selStore}
        onChange={handleChange}
        isLoading={state.loading}
        defaultValue={state.stores[0]}
        theme={theme => ({...theme, borderRadius: 0})}
        components={{
            Option:customDropOption,
            SingleValue: storeSingleValue
        }}
        getOptionValue={opt => opt.CMPID.toString()}

        />;*/
    
}

export default StoresSelect;