import axios, {AxiosResponse} from 'axios';
import * as glob from '../globals/ApiUrlConsts';
import {CompanySettingsVM, CompanyView, ML_Company} from "../globals/dto";

const api_comapy = glob.global_api_url_base + '/api/company';

export const GetAll = async (): Promise<AxiosResponse<ML_Company[]>> => await axios.get(api_comapy + "/all")

export const GetOnId = async (id: number): Promise<AxiosResponse<CompanyView>> => await axios.get(api_comapy + "/GetOnId?cpid=" + id)

export const GetCompanySettings = async (id: number): Promise<AxiosResponse<CompanySettingsVM>> => await axios.get(api_comapy + "/GetCompanySettings?companyID=" + id)

export const Save = async (c: CompanyView): Promise<AxiosResponse<number>> => await axios.post(api_comapy + '/save', c);