import React, { useState } from 'react';
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import '@sweetalert2/theme-bootstrap-4/bootstrap-4.min.css';
import * as ud from '../db/UsersData';
import { QL_User } from '../globals/dto';
import { CloseButtonUI } from '../globals/AppControls/CloseButtonUI';
import { SaveButtonUI } from '../globals/AppControls/SaveButtonUI';
import { DeleteButtonUI  } from '../globals/AppControls/DeleteButtonUI';

export function UserDetailsEx(props: any) {

    const [user, setUser] = useState<QL_User>(props.user);
    const [validate, setValidate] = useState({
        ShowNameErr: '',
        UNameErr: '',
        PWordErr: ''
    });

    const handleChange = (e: any) => {
        let name = e.target.name;
        let val = e.target.value;
        setUser({ ...user, [name]: val });
        setValidate({ ...validate, [name + "Err"]: (val.length === 0 ? 'input-error' : '') })
    }

    const IsSaveReady = () => {
        if (!user.UName || !user.PWord || !user.ShowName)
            return false;

        return true;
    }

    const saveUserDetails = async () => {

        if (IsSaveReady()) {
            props.togProgress(true);
            toast.dismiss();
            await ud.IsDuplicateUserExist(user.UName, user.AID)
                .then(async (res) => {
                    if (res.data === false) {
                        let usr = user;
                        if (usr.CreationStamp === '')
                            usr.CreationStamp = new Date(0).toISOString();
                        if (usr.LastLoginStamp === '')
                            usr.LastLoginStamp = new Date(0).toISOString();
                        await ud.SaveUser(usr).then((res) => {
                            closeModal();
                        });
                    } else {
                        props.togProgress(false);
                        toast.error("Username already exist. Unable to save, sorry!");
                    }
                });

        } else {
            props.togProgress(false);
            toast.error('Please enter all mandatory fields');
        }
    }

    const closeModal = () => {
        props.togProgress(false);
        props.loadUsers();
        props.closePop();
    }
    const deleteUser = () => {
        props.togProgress(true);
        ud.DeleteUser(user.AID).then(res=>{
        closeModal();
        })
    };

    const confirmDelete = () => {
        Swal.fire({
            title: 'Delete User',
            html: "Are you sure want to delete user - <b>" + user.ShowName + "</b>?",
            showCancelButton: true,
            confirmButtonColor: "red",
            focusCancel: true,

        }).then((res) => {
            if (res.value) {
                deleteUser();
            } //else cancel it simply.
        });
    };



    return <>
        <div className="row" key={user.AID}>
            <div className="col-md-12">
                <div className="row form-group">
                    <div className="col-md-12">
                        <label className="form-label">Full Name: <span className="tx-danger">*</span></label>
                        <input type="text"
                            name="ShowName"
                            value={user.ShowName}
                            onChange={handleChange}
                            className={"form-control " + validate.ShowNameErr}
                            required
                            autoFocus
                            tabIndex={1}
                        />

                    </div>
                </div>

                <div className="row form-group">
                    <div className="col-md-6">
                        <label className="form-label">Account Type: <span className="tx-danger">*</span></label>
                        <select className="form-control form-select"
                            tabIndex={2}
                            name="UserType"
                            value={user.UserType}
                            onChange={handleChange}
                        >
                            <option value="0">Administrator</option>
                            <option value="1">Officer</option>
                            <option value="2">User</option>
                            <option value="3">Manager</option>
                        </select>
                    </div>

                    <div className="col-md-6">
                        <label className="form-label">Account Status: <span
                            className="tx-danger">*</span></label>
                        <select className="form-control form-select"
                            tabIndex={3}
                            name="Active"
                            value={user.Active}
                            onChange={handleChange}
                        >
                            <option value="0">Disable</option>
                            <option value="1">Enable</option>
                        </select>
                    </div>
                </div>

                <div className="row form-group">
                    <div className="col-md-6">
                        <label className="form-label">Username: <span className="tx-danger">*</span></label>
                        <input type="text" className={"form-control " + validate.UNameErr}
                            tabIndex={4}
                            name="UName"
                            value={user.UName}
                            onChange={handleChange}
                            required />
                    </div>

                    <div className="col-md-6">
                        <label className="form-label">Password: <span className="tx-danger">*</span></label>
                        <input type="password" className={"form-control " + validate.PWordErr}
                            tabIndex={5}
                            name="PWord"
                            value={user.PWord}
                            onChange={handleChange}
                            required />
                    </div>
                </div>

                <div className="row form-group mt-4">
                    <div className="col-md-12">
                        {/* <button type="submit" className="btn btn-success" tabIndex={6}
                            onClick={saveUserDetails}>Save
                        </button>
                        <button className="btn btn-outline-secondary text-black ml-3 pointer-cursor"
                            onClick={props.closePop} tabIndex={7}
                        >Close</button> */}

                        <SaveButtonUI className="mr-3" onClick={saveUserDetails}/>
                        <CloseButtonUI onClick={props.closePop}/>
                        <DeleteButtonUI className="float-right" onClick={confirmDelete}/>
                    </div>
                </div>
            </div>
        </div>
    </>

}

