import React, {useEffect, useRef, useState} from "react";
import {OverlayTrigger, Popover, Spinner} from "react-bootstrap";
import {ML_Supplier} from "../../globals/dto";
import * as sdb from '../../db/SupplierData';
import {TelephoneOutbound} from "react-bootstrap-icons";
import {LocationOutline} from "react-ionicons";
import * as utils from '../../globals/Utils';

type attributes = {
    cssClasses: string,
    id: number,
    elementKey: number
}

export const SupplierPopTag = (props: attributes) => {
    const [info, setInfo] = useState<ML_Supplier>(null);
    const FetchInfo = () => {
        if (info === null && props.id > 0) {
            sdb.GetOnID(props.id).then(res => {
                setInfo(res.data);
            })
        }
    }

    const popoverContent =
        <Popover id={props.elementKey + 'popSubSup'} className="shadow-ex-1 border border-gray" placement={'top'}>
            <Popover.Body>
                {!info && <Spinner animation={"border"} variant={"secondary"}/>}
                {info && <>
                    <div className="d-flex flex-column gap-2">
                        <div className="font-weight-bold">{info.CompanyName}</div>
                        {!utils.isEmptyOrSpaces(info.PrimaryPhones) &&
                            <div><TelephoneOutbound/>&nbsp;{info.PrimaryPhones}</div>
                        }
                        {!utils.isEmptyOrSpaces(info.GeoCity) && <div>
                            <LocationOutline/>&nbsp;{info.GeoCity}</div>
                        }
                    </div>
                </>}
            </Popover.Body>
        </Popover>

    return (
        <OverlayTrigger key={props.elementKey + 'popSup'} placement="top" rootClose trigger="click"
                        onToggle={() => FetchInfo()} overlay={popoverContent}>
            <span className={props.cssClasses + " badge-warning"}>Supplier</span>
        </OverlayTrigger>
    )
}
