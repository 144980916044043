import React from 'react';
import { SaleBillView } from '../globals/dto';
import * as _ from 'lodash';
import * as utils from '../globals/Utils';
import TextBoxNumeric from '../ui-controls/TextBoxNumeric';

type attributes = {
    saleBillView: SaleBillView,
    onSaleBillViewUpdate(sb: SaleBillView): void
}

const OnBillDiscountRow = (props: attributes) => {

    const onDiscountChange = (e: any) => {
        let val = +e.target.value;
        let sb = _.clone(props.saleBillView);
        let lineItemsCopy = sb.LineItems;
        for (var i = 0; i < lineItemsCopy.length; i++) {
            lineItemsCopy[i].LineItem.DiscountPercentage = val;
            lineItemsCopy[i].LineItem.DiscountedAmount = 0;
            lineItemsCopy[i] = utils.calculateDiscountAndTaxes(lineItemsCopy[i], null);
        }
        props.onSaleBillViewUpdate(sb);
    }

    return (<>

        <tr>
            <td colSpan={3} className="text-right"></td>
            <td colSpan={2} className="text-right">
                <div className="input-group">
                    <TextBoxNumeric className="form-control text-right" placeholder="Discount" style={{ precision: 2 }}
                        id="DiscountPercentage" name="DiscountPercentage" onChange={onDiscountChange} />
                    <div className="input-group-append">
                        <span className="input-group-text" id="basic-addon1">%</span>
                    </div>
                </div>
            </td>
            <td></td>
        </tr>
    </>);
}

export default OnBillDiscountRow;