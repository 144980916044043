import { GlobalSettingsVM } from "./dto";
import { StateToggleWithID } from "../infra-redux/AppStateReduxStore";
import { AppRedux } from "../infra-redux/AppRedux";
import { GlobalSettingsDTO } from "./data.dto";
import * as utils from './Utils';
import * as dateUtils from '../globals/AppDateUtils';

export const APPLAYOUT_MAIN_TITLE = "MainTitle";
export const APPLAYOUT_PROGESSBAR = "ShowProgress";

export const GLOBAL_SETTINGS = "GlobalSettings";
export const USER_SETTINGS = "UserSettings";
export const FIN_SETTINGS = "FinSettings";

//TransitPopUp
export const WF_SHOW_TRANSIT = "ShowTransitPage";
export const WF_PROCESS_ID = "WFProcessID";
//ActivityPopUp
export const WF_SHOW_ACTIVITY_LOG = "ShowProcessLogPage";
export const WF_MAC_ID = "WFMacID";
//SubProductPopUp
export const WF_SHOW_SUBPROD = "ShowSubProductPage";
export const LINE_ITEM_ID = "LineItemID"
//uses same WFMacID above

export const ACTION_CHANGE_MAIN_TITLE = "UPDATE_TITLE";
export const ACTION_TOGGLE_PROGRESSBAR = "TOGGLE_PROGRESSBAR";
export const ACTION_TOGGLE_TRANSIT_VIEW = "TOGGLE_TRANSIT_VIEW";
export const ACTION_TOGGLE_ACTIVITY_VIEW = "TOGGLE_ACTIVITY_VIEW";
export const ACTION_TOGGLE_SUBPROD_VIEW = "TOGGLE_SUBPROD_VIEW";

export const ACTION_SET_GLOBAL_SETTING = "SET_GLOBAL_SETTING";
export const ACTION_SET_USER_SETTING = "SET_USER_SETTING";

export const ChangeMainTitle = (title: any) =>
  AppRedux.actions.ChangeMainTitle(title);

export const ToggleProgress = (tog: boolean) =>
  AppRedux.actions.ToggleProgress(tog);

export const ShowTransitPage = (tog: boolean, pid: number, lid:number) =>
  AppRedux.actions.ShowTransitPage({toggle:tog, lineItemId:lid, pid:pid});

export const ShowActivityPage = (tog: boolean, macid: number) =>
  AppRedux.actions.ShowActivityPage({ toggle:tog, macID:macid });

export const ShowSubProdPage = (tog: boolean, macid: number) =>
  AppRedux.actions.ShowSubProdPage({ toggle:tog, macID:macid });

export const SetGlobalSettings = (payload: string) => {
  if(!utils.isEmptyOrSpaces(payload))
    return AppRedux.actions.SetGlobalSettings(payload);
  return '{}';
}



/// ALL CODE BELOW DEPRECATED-------------------------

//This method also called in index.js
export const InitialAppState = () => {
  return {
    [GLOBAL_SETTINGS]: {},
    [USER_SETTINGS]: {},
    [APPLAYOUT_MAIN_TITLE]: "",
    [APPLAYOUT_PROGESSBAR]: false,
    [WF_SHOW_TRANSIT]: false,
    [WF_PROCESS_ID]: 0,
    [WF_SHOW_ACTIVITY_LOG]: false,
    [WF_SHOW_SUBPROD]: false,
    [WF_MAC_ID]: 0,
    [FIN_SETTINGS]: new GlobalSettingsVM()
  };
};

export function mapStateToProps(state: any) {
  let sets = InitialAppState();
  sets.GlobalSettings = state[GLOBAL_SETTINGS];
  return sets;

  //return InitialAppState();
}
const initialState = InitialAppState();

export const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case ACTION_CHANGE_MAIN_TITLE:
      return {
        ...state, [APPLAYOUT_MAIN_TITLE]: action.title,
      };
    case ACTION_TOGGLE_PROGRESSBAR:
      return {
        ...state,
        [APPLAYOUT_PROGESSBAR]: action.toggleProgress,
      };
    case ACTION_TOGGLE_TRANSIT_VIEW:
      return {
        ...state,
        [WF_SHOW_TRANSIT]: action.togTransit,
        [WF_PROCESS_ID]: action.processID,
      };
    case ACTION_TOGGLE_ACTIVITY_VIEW:
      return {
        ...state,
        [WF_SHOW_ACTIVITY_LOG]: action.tog,
        [WF_MAC_ID]: action.macID,
      };
    case ACTION_TOGGLE_SUBPROD_VIEW:
      return {
        ...state,
        [WF_SHOW_SUBPROD]: action.tog,
        [WF_MAC_ID]: action.macID,
      };
    case ACTION_SET_GLOBAL_SETTING:
      return {
        ...state,
        [GLOBAL_SETTINGS]: action.settings,
      };
    default:
      return initialState;
  }
};
