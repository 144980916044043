import React, {useEffect, useState} from "react";
import {Button, OverlayTrigger, Popover, Spinner} from "react-bootstrap";
import {ML_Transaction, TrackDetails} from "../../globals/dto";
import {SupplierPopTag} from './SupplierPopTag';
import {StorePopTag} from "./StorePopTag";
import {CustomerPopTag} from "./CustomerPopTag";
import {BrokerPopTag} from "./BrokerPopTag";
import * as consts from '../GlobalConsts';
import {InvoiceViewer} from "../InvoiceViewer";
import { InwardBadgeUI } from './InwardBadgeUI';
import {SaleBillBadgeUI} from './SaleBillBadgeUI';

type attributes = {
    transaction: ML_Transaction
    // onClick(staker: string, id: number): void
}

export const PopTags = (props: attributes) => {

    const style = 'badge badge-pill mr-2 mb-1 pointer-cursor '

    const [details, setDetails] = useState<TrackDetails>(null);
    const [showSaleBillNumber, setShowSaleBillNumber] = useState(0);
    const [showInwardBill, setShowInwardBill] = useState(0);

    useEffect(() => {
        if (props.transaction) {
            if (props.transaction.TrackDetails && props.transaction.TrackDetails != '') {
                setDetails(JSON.parse(props.transaction.TrackDetails));
            }
        }
    }, [])

    // const SaleBillTag = (id: number) => {
    //     return <span onClick={() => showRequest(consts.PopTagOwner.SaleBill, id)}
    //           className={style + " bg-info"}>Sale Bill</span>
    // }

    // const InwardTag = (id: number) => {
    //     return <span onClick={() => showRequest(consts.PopTagOwner.InwardBill, id)}
    //           className={style + " bg-primary"}>
    //         Inward Bill</span>
    // }

    const showRequest=(e:string,id:number)=>{
        if(e==consts.PopTagOwner.SaleBill)
            setShowSaleBillNumber(id);
        else if(e===consts.PopTagOwner.InwardBill)
            setShowInwardBill(id);
    }

    const invoiceViewClosed = ()=>{
        setShowInwardBill(0);
        setShowSaleBillNumber(0);
    }

    if (details === null)
        return <></>


    return (<>

        <InvoiceViewer key={"ivr"+props.transaction.EXPID} onClose={invoiceViewClosed} saleBillID={showSaleBillNumber} inwardID={showInwardBill}/>

        {details.SaleBillID > 0 &&<SaleBillBadgeUI key={"sbuitg"+Math.random()} showRequest={showRequest} id={details.SaleBillID}/>}
        {details.InwardID > 0 && <InwardBadgeUI key={"ibuitg"+Math.random()} showRequest={showRequest} id={details.InwardID}/>}

        {details.StoreID > 0 && <StorePopTag
            key={props.transaction.EXPID + "_store"}
            cssClasses={style}
            id={details.StoreID}
            elementKey={props.transaction.EXPID}/>}

        {details.SupplierID > 0 && <SupplierPopTag
            key={props.transaction.EXPID + "_sup"}
            cssClasses={style}
            id={details.SupplierID}
            elementKey={props.transaction.EXPID}/>}

        {details.CustomerID > 0 && <CustomerPopTag
            key={props.transaction.EXPID + "_custo"}
            cssClasses={style}
            id={details.CustomerID}
            elementKey={props.transaction.EXPID}/>}

        {details.BrokerID > 0 && <BrokerPopTag
            key={props.transaction.EXPID + "_bro"}
            cssClasses={style}
            id={details.BrokerID}
            elementKey={props.transaction.EXPID}/>}

    </>)
}
