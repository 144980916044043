import axios, {AxiosResponse} from 'axios';
import * as global from '../globals/ApiUrlConsts';
import {InwardPaymentView, ML_Supplier} from "../globals/dto";

export const base_supplier_api_url = global.global_api_url_base + "/api/suppliers";

export const GetAll= async () : Promise<AxiosResponse<ML_Supplier[]>> => await axios.get(base_supplier_api_url+'/all');

export const GetOnID = async(id:number) : Promise<AxiosResponse<ML_Supplier>> => await axios.get(base_supplier_api_url +'/getonid?id='+id);

export const Save = async (sup : InwardPaymentView) => await axios.post(base_supplier_api_url +'/save', sup);

export const Delete = async(id : number) => await axios.post(base_supplier_api_url + '/remove?id='+id);