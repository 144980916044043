import React, { useState, useEffect } from 'react';
import * as AppAct from '../globals/AppActions';
import * as wdb from '../db/WorkflowData';
import {PlantView, MachineView, ML_Workflow} from '../globals/dto';
import {connect, useDispatch} from 'react-redux';
import {mapStateToProps} from "../globals/AppActions";
import * as utils from '../globals/Utils';
import {
    ButtonDeleteMachine,
    ButtonEditMachine,
    ButtonNewMachine,
    LabelMachineName, MachineDetails
} from "../workflow-design/machine-components";
import {
    ButtonCopyPlant,
    ButtonDeletePlant,
    ButtonEditPlant,
    ButtonNewPlant,
    LabelPlantName
} from "../workflow-design/plant-components";
import {Modal} from "react-bootstrap";
import {DateTime} from "luxon";
import { useParams, useSearchParams } from 'react-router-dom';
import * as qs from 'query-string';
import {WorkflowHeader} from "./WorkflowComponents";
import { AddNewButtonUI } from '../globals/AppControls/AddNewButtonUI';



const WorkflowDesigner = (props:any)=> {
    const appDisp = useDispatch();
    const params = useParams();

    const [workflowDetails, setWorkflowDetails] = useState<PlantView[]>();
    const [workflow, setWorkflow] = useState<ML_Workflow>();

    // const workflowID = +urlParams.get("id");
    // console.log(urlParams);

    let runOrder = 0;
    useEffect(()=>{
        appDisp(AppAct.ChangeMainTitle("Workflow Designer"));
        loadWorkflowDetails();
        loadWorkflow(+params.id);
    },[]);


    const loadWorkflowDetails = () => {
        appDisp(AppAct.ToggleProgress(true));
        wdb.GetWorkflowDetails(+params.id).then((res:any)=>{
            setWorkflowDetails(res.data);
            appDisp(AppAct.ToggleProgress(false));
        });
    }

    const loadWorkflow = (id :number ) => {
        if(id > 0) {
            wdb.GetWorkflowInfo(id).then(res =>{
                console.log(res.data);
                setWorkflow(res.data)
            });
        }
    }

    const machineSaved = (mcid :number, plantid: number, exeOrder :number)=> {
        if(mcid > 0) {
            wdb.linkFlowMachinePlant(mcid, plantid, +params.id, exeOrder).then(res => {
                loadWorkflowDetails();
            });
        }
    }

    return <>
        <div className="row justify-content-center">
            <div className="col-md-6">

                {workflow &&
                <div className="card  mb-3">
                    <div className="card-body">
                        <WorkflowHeader workflow={workflow} saved={(id:number)=> loadWorkflow(id)}/>
                    </div>
                </div>
                }

                {/*<div className="text-center m-3" style={{backgroundColor: 'white', borderRadius: 10}}><span*/}
                {/*    className="tx-26-f font-weight-bold">Start</span></div>*/}

                {workflowDetails && workflowDetails.map((p: PlantView, pdx: number) => {
                    runOrder = pdx;
                    if(p.PlantDetails.PLTID > 0){
                        return (
                            <div className="card mb-3">
                                <div className="card-body">
                                    <table className="table table-borderless mb-0 table-hover">
                                        <tbody>
                                        <tr className="border-bottom" key={"pl" + p.PlantDetails.PLTID}>
                                            <td>
                                                <LabelPlantName name={p.PlantDetails.PlantName} index={pdx}/>
                                            </td>
                                            <td className="text-right pt-3" style={{width:"30%"}}>
                                                <ButtonCopyPlant plantID={p.PlantDetails.PLTID} saved={()=>loadWorkflowDetails()}/>
                                                <ButtonDeletePlant plantID={p.PlantDetails.PLTID} saved={()=>loadWorkflowDetails()}/>
                                                <ButtonEditPlant plant={p.PlantDetails} wfid={+params.id} runOrder={p.ProcessFlow.ExecutionOrder}
                                                                 saved={(pid:number)=> {if(pid>0) loadWorkflowDetails()}}/>
                                            </td>
                                        </tr>
                                        {p.MachineViewsList?.map((m: MachineView, mdx: number) => {
                                            return (
                                                <tr key={"mc" + mdx} className="border-bottom">
                                                    <td className="pt-3">
                                                        <LabelMachineName name={m.MachineDetails.MachineName}/>
                                                    </td>
                                                    <td className="text-right pt-3">
                                                        <ButtonDeleteMachine MachineID={m.MachineDetails.MCID} saved={()=>loadWorkflowDetails()}/>
                                                        <ButtonEditMachine machine={m.MachineDetails}
                                                                           saved={(mcid: number) => machineSaved(mcid, p.PlantDetails.PLTID, p.ProcessFlow.ExecutionOrder)}/>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                        <tr key={"ft" + p.PlantDetails.PLTID}>
                                            <td colSpan={2}>
                                                <ButtonNewMachine
                                                    saved={(mcid: number) => machineSaved(mcid, p.PlantDetails.PLTID, p.ProcessFlow.ExecutionOrder)}/>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        )
                    }
                })}
                <div className="text-center">
                    <ButtonNewPlant wfid={+params.id} runOrder={runOrder === 0? 1 : runOrder}
                                    saved={(pltid : number)=> {if(pltid> 0) loadWorkflowDetails()}}/>

                                    
                </div>
            </div>
        </div>
    </>;
}

export default WorkflowDesigner;