import React, {useEffect, useState} from 'react';
import {MachineInfo} from "./MachineInfo";
import {MachineView} from "../globals/dto";
import {Doughnut} from 'react-chartjs-2';
import * as wdb from '../db/WorkflowData';

export const PlantInfo = (props: any) => {

    const [data, setData] = useState({})
    const [showLegend, setShowLegend] = useState(false);

    // useEffect(() => {
    //     GetChart(props.plantId);
    // }, [props.plantId, props.RefreshChart])

    const GetChart = (ptid: number) => {
        wdb.GetPlantReviewStats(ptid).then(res => {
            let stat = res.data;

            if (stat) {
                let data = {
                    labels: ['Starts', 'Ends', 'Reviews', 'ReRoutes'],
                    datasets: [{
                        label: 'Review Stats',
                        data: [stat.Starts, stat.Ends, stat.Reviews, stat.ReRoutes],
                        backgroundColor: [
                            'rgba(255, 99, 132, 0.2)',
                            'rgba(54, 162, 235, 0.2)',
                            'rgba(255, 206, 86, 0.2)',
                            'rgba(75, 192, 192, 0.2)',
                            'rgba(153, 102, 255, 0.2)',
                            'rgba(255, 159, 64, 0.2)',
                        ],
                        borderColor: [
                            'rgba(255, 99, 132, 1)',
                            'rgba(54, 162, 235, 1)',
                            'rgba(255, 206, 86, 1)',
                            'rgba(75, 192, 192, 1)',
                            'rgba(153, 102, 255, 1)',
                            'rgba(255, 159, 64, 1)',
                        ],
                        borderWidth: 1,
                    }],
                    plugins: {
                        legend: {
                            position: 'bottom',
                        }
                    },
                };
                setData(data);
                setShowLegend((stat.Starts + stat.Ends + stat.Reviews + stat.ReRoutes) >= 1);
            }
        })
    }

    return <>

        <div className="card mb-3">
            <div className="card-body">
                <table className="table table-borderless mb-0">
                    <tbody>
                    <tr>
                        <td className="pb-5" style={{width: "30%"}}>
                            <b className="text-uppercase tx-26-f tx-gray-500">{props.plant.PlantDetails.PlantName}</b><br/>
                            {/* <div className="text-center mt-3" style={{width: 250, height: 150}}>
                                <Doughnut className="m-auto" type={'doughnut'} data={data} options={{maintainAspectRatio:false, plugins: {
                                        legend: {
                                            display:showLegend,
                                            position: 'right',
                                        }
                                    }}}/>

                                <br/><i className="tx-gray-500">Overall Plant Performance</i>
                            </div> */}
                        </td>
                        <td className="border-left">
                            <table className="table table-hover mb-0">
                                <tbody>
                                {props.plant.MachineViewsList.map((m: MachineView, mdx: number) => {
                                    return (
                                        <tr key={mdx}>
                                            <td className="pb-4 pt-3">
                                                <MachineInfo machine={m}/>
                                            </td>
                                        </tr>
                                    )
                                })}
                                </tbody>
                            </table>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </>
}

