import React from "react";
import { CheckmarkOutline, CloseOutline, TrashOutline } from 'react-ionicons';

export const DeleteButtonUI = (props: any) => {
    return <button
        {...props}
        className={'btn btn-danger pr-3 ' + props.className}
    >
        <TrashOutline width={'18px'} height={'18px'} style={{ 'paddingBottom': '2px' }} color={'white'} />
        {!props.buttonText && <span>Delete</span>}
        {props.buttonText && <span dangerouslySetInnerHTML={{ __html: props.buttonText }} />}
    </button>
}