import React, { useEffect, useState } from 'react';
import * as AppAct from '../globals/AppActions';
import { mapStateToProps } from '../globals/AppActions';
import * as idb from '../db/InwardsData';
import { connect, useDispatch, useSelector } from "react-redux";
import { ML_Supplier, TransactionLineView } from "../globals/dto";
import CreditorStatement from "./CreditorStatement";
import * as utils from '../globals/Utils';
import { AppReduxState } from '../infra-redux/AppStateReduxStore';

const CreditorsList = (props: any) => {

    const [state, setState] = useState({
        allCreds: Array<TransactionLineView[]>(),
        totalBilled: 0,
        totalPaid: 0,
        totalBal: 0,
        selectedSupplier: new ML_Supplier(),
        supplierID: 0,
        tempGroup: ''
    });

    const [showStatement, setShowStatement] = useState(false);

    const reduxState = useSelector((state: AppReduxState) => state);
    const dispatch = useDispatch();

    const pageTitle = 'All Creditors Summary Statement';

    useEffect(() => {
        dispatch(AppAct.ChangeMainTitle(pageTitle));
        LoadCreditorsSummary();
    }, [])

    const LoadCreditorsSummary = () => {
        dispatch(AppAct.ToggleProgress(true));

        idb.getAllCreditorsEx().then(res => {
            console.log(res.data);
            if(res.data && res.data[0]) {
                let paid = res.data[0].map((o: any) => o.DebitAmount).reduce((tot: number = 0, obj: number) => tot + obj);
                let billed = res.data[0].map((o: any) => o.CreditAmount).reduce((tot: number = 0, obj: number) => tot + obj);

                setState({
                    ...state,
                    allCreds: [...res.data],
                    totalPaid: paid,
                    totalBal: paid - billed,
                    totalBilled: billed
                });
            }
            dispatch(AppAct.ToggleProgress(false));
        });
    }

    const showCreditorDetails = (supid: number) => {
        setState({
            ...state,
            supplierID: supid,
        });
        setShowStatement(true);
    }

    const closeCreditorDetails = (refreshlist: boolean = false) => {
        setShowStatement(false);
        dispatch(AppAct.ChangeMainTitle(pageTitle));
        if (refreshlist) {
            LoadCreditorsSummary();
        }
    }



    if (showStatement)
        return <CreditorStatement suppleirID={state.supplierID} close={closeCreditorDetails} />

    if (!showStatement)
        return <>
            <div className="row">
                <div className="col-md-12">
                    <div className="table-responsive">
                        <table className="table mg-b-0">
                            <thead>
                                <tr key={"head0"}>
                                    <th className="w-50">Supplier</th>
                                    <th className="text-right">Account Status</th>
                                    <th className="text-right border-left">Billed</th>
                                    <th className="text-right">Paid</th>
                                    {/*<th className="text-right">Balance</th>*/}
                                </tr>
                            </thead>
                            <tbody>
                                {state.allCreds.map((spg: TransactionLineView[], spgIndex: number) => {
                                    return (<>
                                        <tr key={"rgn_" + spgIndex}>
                                            <td colSpan={5}><h4 className="pt-4 mb-0">{spg[0].MoreInfoTypeFlag}</h4></td>
                                        </tr>
                                        {spg.map((c: TransactionLineView, index: number) => {
                                            return (

                                                <tr key={"tran" + index}>
                                                    <th scope="row" className="pointer-cursor text-primary"
                                                        onClick={() => showCreditorDetails(c.PartyID)}>
                                                        <b className="text-indigo">{c.AdditionDetails}</b>
                                                    </th>
                                                    <td className="text-right">{utils.formatCurrencyValue(reduxState, c.BalanceAmount)}</td>
                                                    <td className="text-right border-left">{utils.formatCurrencyValue(reduxState, c.CreditAmount)}</td>
                                                    <td className="text-right">{utils.formatCurrencyValue(reduxState, c.DebitAmount)}</td>
                                                    {/*<td className="text-right">{utils.formatCurrencyValue(c.DebitAmount - c.CreditAmount)}</td>*/}
                                                </tr>
                                            )
                                        })}
                                    </>)
                                })}

                            </tbody>
                            <tfoot>
                                <tr key={"foot0"}>
                                    <td></td>
                                    <td className="text-right font-weight-bold">{utils.formatCurrencyValue(reduxState, state.totalBal)}</td>
                                    <td className="text-right border-left font-weight-bold">{utils.formatCurrencyValue(reduxState, state.totalBilled)}</td>
                                    <td className="text-right font-weight-bold">{utils.formatCurrencyValue(reduxState, state.totalPaid)}</td>
                                    {/*<td className="text-right font-weight-bold">{utils.formatCurrencyValue(state.totalBal)}</td>*/}
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>

        </>
}

export default CreditorsList;
