import * as consts from "../globals/ApiUrlConsts";
import axios from 'axios';

const base_user_api_url  = consts.global_api_url_base + '/api/users';

export const GetAllUsers = async () => {
        return await axios.get( base_user_api_url +'/all');
    };

export const SaveUser = async(user:any) => {
    await  axios({
        method: 'post',
        url: base_user_api_url +'/store',
        data : user,
    });
    //return await axios.post(base_user_api_url + '/store', user);
}

export const Login = async(uname:string, pass:string) => {
    // return await  axios({
    //     method: 'post',
    //     url: base_user_api_url +'/login',
    //     data : {username: uname, pass },
    // });
    return await axios.post(base_user_api_url + '/login', null, {params: {username: uname, pass}});
}

export const IsDuplicateUserExist = async (uname:string, id:number) => {
    return await axios.get(base_user_api_url + '/IsDuplicateUserExist?uname='+uname+"&uid="+id);

}

export const DeleteUser  = async (id:number) => {
    await axios.post(base_user_api_url +'/remove?uid='+id);
}

