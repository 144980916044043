import * as wdb from '../db/WorkflowData';
import React, { useEffect, useState } from "react";
import * as AppAct from '../globals/AppActions';
import { ML_Workflow } from "../globals/dto";
import { mapStateToProps } from "../globals/AppActions";
import { connect, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { ButtonNewWorkflow } from "./WorkflowComponents";

const WorkflowList = (props: any) => {
    const [list, setList] = useState([]);
    const appDisp = useDispatch();

    useEffect(() => {
        loadAllWorkflows();
    }, [])

    const loadAllWorkflows = () => {
        appDisp(AppAct.ToggleProgress(true));
        appDisp(AppAct.ChangeMainTitle("Workflows"));
        wdb.GetAllWorkflows().then(res => {
            appDisp(AppAct.ToggleProgress(false));
            setList(res.data);
        });
    }


    return (
        <>
            <ButtonNewWorkflow saved={() => loadAllWorkflows()} className="mb-3" />
            <div className="row">
                {list.map((x: ML_Workflow, idx: number) => {
                    return (

                        <div className="col-sm-4 mb-4">
                            <div className="card card-dashboard-thirteen">
                                <div className="media mb-0">
                                    <div className="media-icon">
                                        <i className="tx-26 icon ion-ios-albums"></i>
                                    </div>
                                    <div className="media-body pointer-cursor text-overflow">
                                        <Link to={"/wfdesigner/" + x.WFID}>
                                            <h6>{x.WorkflowName}</h6>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
        </>
    )
}

export default connect(mapStateToProps)(WorkflowList)
