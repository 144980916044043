import React from "react";
import { CheckmarkOutline, CloseOutline, TrashOutline } from 'react-ionicons';

export const DangerButtonUI = (props: any) => {
    return <button
        {...props}
        className={'btn btn-danger px-3 ' + props.className}
    >
        <span dangerouslySetInnerHTML={{ __html: props.buttonText }} />
    </button>
}
