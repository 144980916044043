import axios, {AxiosResponse} from 'axios';
import * as global from '../globals/ApiUrlConsts';
import { ML_Report, ML_ReportDataset } from "../globals/dto";

const base_brokers_api_url = global.global_api_url_base + "/api/reporter"

export const GetAllDataSets = async () : Promise<AxiosResponse<Array<ML_ReportDataset>>> => await axios.get(base_brokers_api_url +'/GetAllSets')

export const GetAllSettings = async () : Promise<AxiosResponse<ML_Report[]>> => await axios.get(base_brokers_api_url +'/GetAllSettings')

export const Save = async (rep : ML_Report) : Promise<AxiosResponse<number>> => await axios.post(base_brokers_api_url+'/SaveSettings',rep);

export const GetColumns = async (dsID:number) : Promise<AxiosResponse<string[]>> => await axios.get(base_brokers_api_url +'/GetColumns', {params: {dsID}})

export const GenerateReport = async (settingsID:number) : Promise<AxiosResponse<Array<any>>> => await axios.get(base_brokers_api_url +'/GenerateReport', {params: {settingsID}})

export const DeleteReport = async (repID: number) : Promise<AxiosResponse<boolean>> => await axios.post(base_brokers_api_url + "/DeleteReport",
 null, {params : { rpid: repID }} );

export const DeleteDataSet = async (dsID: number) : Promise<AxiosResponse<boolean>> => await axios.post(base_brokers_api_url + "/DeleteDataSet", 
null, {params: { dsid : dsID}});