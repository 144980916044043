import React, { useState } from 'react';
import { CompanySettingsVM, InwardView, ML_Transaction, SaleBillView } from '../globals/dto';
import * as _ from 'lodash';
import InwardLineItemsView from './InwardLineItemsView';
import AmountPayableToSupplier from './AmountPayableToSupplier';
import * as helper from './InwardInvoiceEntryHelper';
import OtherPurchaseItems from './OtherPurchaseItems'
import { AddNewButtonUI } from '../globals/AppControls/AddNewButtonUI';
import OnSpotPay from '../sales/OnSpotPay';
import * as utils from '../globals/Utils';

type attributes = {
    inwardView: InwardView,
    onInwardBillViewUpdate(sb: InwardView): void,
}

const InwardItemsTable = (props: attributes) => {

    const [newLineItemBell, setNewLineItemBell] = useState(-1);
    const [newOtherItemBell, setNewOtherItemBell] = useState(-1);

    console.log(props.inwardView);

    const popUpLineItem = () => {
        setNewLineItemBell(newLineItemBell + 1);
    }

    const popUpOtherItem = () => {
        setNewOtherItemBell(newOtherItemBell + 1);
    }

    const handleOnPayableAmountChange = (amount: number) => {
        let sb = _.clone(props.inwardView);
        sb.InwardEntry.PayableTotalAmount = amount;
        props.onInwardBillViewUpdate(sb);
    }

    const handleOnSpotPayment = (e: ML_Transaction) => {
        props.onInwardBillViewUpdate({...props.inwardView, CashPayTrans:{...e}});
    }

    

return (<>

    {/* <div className="card mb-3  shadow-sm">
        <div className="card-header"> */}
            <h5 className='mt-4 az-content-label'>Purchase Items</h5>
        {/* </div>
        <div className="card-body"> */}
            <div className="form-row">
                <div className="col-md-12">
                    <div className="table-responsive">
                        <table className="table table-invoice table-hover table-bordered table-curved">
                            <thead className="border-top">
                                <tr key="sbx0">
                                    <th className="wd-40p">Product / Description</th>
                                    <th>HSN/SAC</th>
                                    <th className="tx-center wd-40p">Qty</th>
                                    <th className="tx-right wd-10p">Unit Price</th>
                                    <th className="tx-right wd-20p">Discount</th>
                                    {/*<th className="tx-right wd-40p-f">Tax</th>*/}
                                    <th className="tx-right">Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                <InwardLineItemsView
                                    inwardView={props.inwardView}
                                    onInwardUpdated={props.onInwardBillViewUpdate}
                                    newLineItemBell={newLineItemBell}
                                />

                                <OtherPurchaseItems
                                    newItemRingBell={newOtherItemBell}
                                    onInwardBillViewUpdate={props.onInwardBillViewUpdate}
                                    inwardView={props.inwardView}
                                />

                                {/*  <OnBillDiscountRow
                                        onSaleBillViewUpdate={props.onSaleBillViewUpdate}
                                        saleBillView={props.saleBillView}
                                    /> */}

                                <tr key={"sbtt" + props.inwardView.InwardEntry.INWID}>
                                    <td>
                                        {/* <button className="btn btn-indigo text-white mr-3" accessKey={"t"}
                                                onClick={() => popUpLineItem()}>+Add I<u>t</u>em
                                            </button> */}
                                        {/* <button className="btn btn-indigo text-white mr-3" accessKey={"o"}
                                                onClick={popUpOtherItem}>+Add Other
                                            </button> */}
                                        <AddNewButtonUI
                                            className="mr-3"
                                            buttonText="Add I<u>t</u>em"
                                            accessKey={"t"}
                                            onClick={() => popUpLineItem()}
                                        />
                                        <AddNewButtonUI
                                            buttonText="Add <u>O</u>ther"
                                            accessKey={"o"}
                                            onClick={popUpOtherItem}
                                        />
                                    </td>
                                    <td></td>
                                    <td className="text-center">
                                        Total Qty: <b>{props.inwardView.InwardEntry.TotalBulkQty}</b></td>
                                    <td colSpan={3} style={{ lineHeight: "1.1em" }} className="text-right">
                                        <table className="table table-sm table-borderless mb-0">
                                            <tbody>
                                                {helper.GrandTotals(props.inwardView)}
                                                <tr key={"k4"} className="border-top">
                                                    <AmountPayableToSupplier
                                                        Amount={props.inwardView.InwardEntry.PayableTotalAmount}
                                                        inwardId={props.inwardView.InwardEntry.INWID}
                                                        onUpdate={handleOnPayableAmountChange}

                                                    />
                                                </tr>
                                                <tr key={"k5"} className={"border-top"}>
                                                    <td className="text-right pr-0" colSpan={2}>
                                                        <OnSpotPay
                                                            payTrans={props.inwardView.CashPayTrans}
                                                            buttonTitle="Make Payment"
                                                            headerTitle="Inward Payment"
                                                            onChange={handleOnSpotPayment}
                                                        />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        {/* </div>
    </div> */}
</>);
}

export default InwardItemsTable;
