import React, {useEffect, useState} from 'react';
import Select, {components, OptionProps} from "react-select";
import {TaxSlabDTO, TaxView} from "../globals/dto";
import * as tdb from '../db/TaxData';

export const TaxDropDown = (props: any) => {

    const [taxes, setTaxes] = useState(new Array<TaxView>());

    useEffect(() => {
        tdb.GetAllTaxes().then((res) => {
            setTaxes(res.data);
            getSelectedTax();
        })
    }, []);

    const getSelectedTax = () => {
        if (props.tax.TXID > 0) {
            let tx = taxes.find((t: TaxView) => t.TaxSetting.TXID === props.tax.TXID);
        }
    }

    const optionTemplate = (pp: any) => (
        <components.Option {...pp}>
            <div style={{width: "100%"}}>
                <b>{pp.data.TaxSetting.TaxGroupName}</b> ({pp.data.TaxSetting.TotalTaxValue}%)
                {pp.data.Slabs.map((t: TaxSlabDTO, idx: number) => {
                    return (
                        <div key={idx}
                             style={{margin: "3px", marginLeft: "10px", padding: "3px", border: "1px solid #d3d3d3"}}>
                            {t.TaxName} = {t.TaxValue}%
                        </div>
                    );
                })}
            </div>
        </components.Option>
    );

    const singleSelectTemplate = (pp: any) => (
        <components.SingleValue {...pp}>
            <div><b>{pp.data.TaxSetting?.TaxGroupName}</b> ({pp.data.TaxSetting?.TotalTaxValue}%)</div>
        </components.SingleValue>
    );

    return (
        <Select classNamePrefix="srushti"
            value={props.tax}
            options={taxes}
            defaultValue={new TaxView()}
            isClearable={true}
            theme={theme => ({...theme, borderRadius: 0})}
            components={{Option: optionTemplate, SingleValue: singleSelectTemplate}}
            getOptionValue={option => option.TaxSetting?.TXID.toString()}
            onChange={props.onChange}
        ></Select>
    )
}

