import axios from 'axios';
import * as global from '../globals/ApiUrlConsts';
import {ReviewComment} from "../globals/dto";

const web_api_url = global.global_api_url_base + '/api/Reviews';

export const PostQCReview  = async (rc:ReviewComment, qty:number, processId:number,logId:number)=> {
    console.log('Review',ReviewComment);
    return await axios.post(web_api_url + "/PostReview",rc,
        {params: { processorId: processId, trackLogId: logId, inprocessQuty:qty}})
}

export const UploadFile = async (fileAttch: File) =>{
    const formData = new FormData();
    formData.append("attachFile",fileAttch);
    return await axios.post(web_api_url + "/UploadFile", formData)
}

export const DeleteFile = async (filename:string)=> {
    return await axios.post(web_api_url + "/DeleteFile",null, {params: {uqFileName: filename}});
}
export const DownloadFile = (uqFile:string,showName:string) => {
    return axios.get(web_api_url + '/GetFileByUniqName', {params:{uniqName:uqFile, showFileName:showName}});
}

export const GetSaleBill = async (sbid :number) => await axios.get(web_api_url + '/GetSaleBill?sbid='+sbid);

export const GetRelatedReviews = async (ilid :number) => await axios.get(web_api_url + '/GetRelatedReviews?lineItemId='+ilid);



