import React, { useEffect, useState } from 'react';
import * as idb from '../db/InwardsData';
import * as AppAct from '../globals/AppActions';
import * as utils from '../globals/Utils';
import * as dateUtils from '../globals/AppDateUtils';
import { mapStateToProps } from "../globals/AppActions";
import { connect, useDispatch, useSelector } from "react-redux";
import DateRangeControlEx from "../globals/DateRangeControlEx";
import CreditorPay from "./CreditorPay";
import { Modal, ModalBody } from "react-bootstrap";
import ModalHeader from "react-bootstrap/ModalHeader";
import { ML_Supplier, TransactionLineView } from '../globals/dto';
import { AxiosResponse } from 'axios';
import { InwardInvoiceView } from '../inwards/InwardInvoiceView';
import * as spdb from '../db/SupplierData';
import { AppReduxState } from '../infra-redux/AppStateReduxStore';
import { date } from 'yup';
import {InwardBadgeUI} from "../globals/PopTags/InwardBadgeUI";
import { InvoiceViewer } from '../globals/InvoiceViewer';
import {WarningButtonUI} from "../globals/AppControls/WarningButtonUI";
import {CloseButtonUI} from "../globals/AppControls/CloseButtonUI";

const CreditorStatement = (props: any) => {

    const reduxState = useSelector((state: AppReduxState) => state);
    const dispatch = useDispatch();

    const [state, setState] = useState({
        allTrans: new Array<TransactionLineView>(),
        startDate: dateUtils.MinusDays(new Date(), 30),
        endDate: new Date(),
        grandBillingTotal: 0,
        grandPaidTotal: 0,
        showBillNumber: 0
    });

    const [showPayModal, setShowPayModal] = useState(false);

    const [supplier, setSupplier] = useState(new ML_Supplier());

    useEffect(() => {
        dispatch(AppAct.ChangeMainTitle('Supplier Credit Statement'));
        LoadInwardPayments(state.startDate, state.endDate);
    }, [])

    const debitTotal = (allRow: Array<TransactionLineView>) => {
        return allRow.reduce((total, t) => {
            //if(isDebitEntry(t)){
            return total + t.DebitAmount;
            //}
            //return total;
        }, 0);
    }

    const creditTotal = (allRow: Array<TransactionLineView>) => {
        return allRow.reduce((total, t) => {
            //if(isCreditEntry(t)){
            return total + t.CreditAmount;
            // }
            // return total;
        }, 0);
    }

    const GetSupplierInfo = () => {
        spdb.GetOnID(props.suppleirID).then(res => setSupplier(res.data));
    }

    const LoadInwardPayments = (st: Date, ed: Date) => {
        console.log('Dates',st,ed)
        GetSupplierInfo();
        dispatch(AppAct.ToggleProgress(true));
        idb.getSupplyInwardDetailsEx(props.suppleirID, st, ed)
            .then((res: AxiosResponse<TransactionLineView[]>) => {
                setState({
                    ...state,
                    allTrans: [...res.data],
                    "grandBillingTotal": creditTotal(res.data),
                    "grandPaidTotal": debitTotal(res.data)
                });
                dispatch(AppAct.ToggleProgress(false));
            })
    }

    const handleDateChange = (st: Date, et: Date) => {

        setState({ ...state, startDate: st, endDate: et });
        LoadInwardPayments(st, et);
    }

    const showInwardInvoice = (invid: number) => { }

    const isCreditEntry = (t: any) => (t.Trans.IsExpense)

    const isDebitEntry = (t: any) => (!t.Trans.IsExpense)


    return <>

        {/* <Modal show={state.showBillNumber > 0} dialogClassName="modal-corner"
            size="lg"
            onHide={() => setState({ ...state, "showBillNumber": 0 })}>
            <ModalBody className="p-3">
                <InwardInvoiceView iwid={state.showBillNumber} />
            </ModalBody>
        </Modal> */}

        <InvoiceViewer inwardID={state.showBillNumber} saleBillID={0} onClose={()=>setState({ ...state, "showBillNumber": 0 })}/>

        {showPayModal &&
            <Modal show={showPayModal} dialogClassName="modal-corner">
                <div className="card">
                    <div className="card-body">
                        <h4 className="az-content-label mb-3">Credit Payment</h4>
                        <CreditorPay
                            supplier={supplier}
                            close={() => { setShowPayModal(false)}}
                            saved={() => { LoadInwardPayments(state.startDate, state.endDate) }}
                        />
                    </div>
                </div>
            </Modal>
        }

        <div className="row mt-0">
            <div className="col-md-12 text-black">
                {supplier.CompanyName &&
                    <span className="font-weight-bold tx-20-f">{supplier.CompanyName}</span>}
                {supplier.PrimaryContactPersonName &&
                    <span className="font-weight-bold">&nbsp;|&nbsp;{supplier.PrimaryContactPersonName}</span>}
                {supplier.PrimaryPhones &&
                    <span className="font-weight-bold">&nbsp;|&nbsp;{supplier.PrimaryPhones}</span>}
            </div>
        </div>
        <hr/>
                <div className="row">
                    <div className="col-md-8">
                        <DateRangeControlEx start={state.startDate} end={state.endDate} onChange={handleDateChange} />
                    </div>
                    <div className="col-md-4 text-right mt-3 mt-md-0">

                        <WarningButtonUI className="mr-2 px-4" buttonText={"Pay"} onClick={() => { setShowPayModal(true) }}/>
                        <CloseButtonUI onClick={() => props.close(true)}/>
                        {/*<button className="btn btn-warning font-weight-bold"*/}
                        {/*    onClick={() => { setShowPayModal(true) }}>*/}
                        {/*    Pay</button>*/}
                        {/*<button className="btn btn-primary ml-3" onClick={() => props.close(true)}>&lt; Back</button>*/}
                    </div>
                </div>
            
                <div className="row mt-3">
                    <div className="col-12">
                        <div className="table-responsive">
                            <table className="table table-bordered table-striped mg-b-0">
                                <thead className="border-top">
                                    <tr>
                                        <th>Date</th>
                                        <th>Description</th>
                                        <th>Mode</th>
                                        <th className="text-right">Debit</th>
                                        <th className="text-right">Credit</th>
                                        <th className="text-right">Balance</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {state.allTrans.map((il: TransactionLineView, idx: number) => {
                                        return (
                                            <tr key={idx}>
                                                <td>{dateUtils.FormatStringDate(il.TranDate)}</td>

                                                {/*Description PUCHASES/payments/receipts invoice*/}

                                                <td>
                                                    {il.Details} &nbsp;
                                                    {il.InvoiceID > 0 &&
                                                        <InwardBadgeUI key={"ibui"+Math.random()} id={il.InvoiceID} showRequest={() => setState({ ...state, "showBillNumber": il.InvoiceID })} />
                                                    }
                                                    
                                                </td>
                                                <td>{il.PayMode}</td>
                                                {/*Debit*/}
                                                <td className="text-right">
                                                    {utils.formatCurrencyValue(reduxState, il.DebitAmount)}
                                                    {/* {il.Trans.LedgerID > 0 &&
                                                    <div>{il.Trans.TotalAmount}</div>}
                                                    {il.InwardPay.IsAdjustmentEntry && !il.Trans.IsExpense &&
                                                    <div>{il.Trans.TotalAmount}</div>} */}
                                                    {/* {isDebitEntry(il) && <div>{il.Trans.TotalAmount}</div>} */}
                                                </td>
                                                {/*Credit*/}
                                                <td className="text-right">
                                                    {utils.formatCurrencyValue(reduxState, il.CreditAmount)}
                                                    {/* {isCreditEntry(il) && <div>{il.Trans.TotalAmount}</div>} */}
                                                    {/* {il.Trans.LedgerID === 0 && il.InwardPay.IsAdjustmentEntry === false &&
                                                    <div>{il.Trans.TotalAmount}</div>}
                                                    {il.Trans.LedgerID === 0 && il.InwardPay.IsAdjustmentEntry && il.Trans.IsExpense &&
                                                    <div>{il.Trans.TotalAmount}</div>} */}
                                                </td>

                                                {/*Balance*/}
                                                <td className="text-right">{utils.formatCurrencyValue(reduxState, il.BalanceAmount)}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td className="text-right font-weight-bold">{utils.formatCurrencyValue(reduxState, state.grandPaidTotal)}</td>
                                        <td className="text-right font-weight-bold">{utils.formatCurrencyValue(reduxState, state.grandBillingTotal)}</td>
                                        <td className="text-right font-weight-bold">
                                            {state.allTrans && state.allTrans && state.allTrans.length > 0 &&
                                                <b>{utils.formatCurrencyValue(reduxState, state.allTrans[state.allTrans.length - 1].BalanceAmount)}</b>
                                            }
                                        </td>
                                    </tr>
                                </tfoot>
                            </table>

                        </div>
                    </div>
                </div>
            
    </>
}

export default CreditorStatement;
