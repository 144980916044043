import axios from 'axios';
import * as global from '../globals/ApiUrlConsts';
import {ML_Tax} from "../globals/dto";

const base_tax_api_url = global.global_api_url_base + '/api/tax';

export const GetAll = async () => await axios.get(base_tax_api_url + '/getall');

export const GetAllTaxes = async () =>{ return await axios.get(base_tax_api_url + '/getalltaxes');}

export const Save = async (tx:ML_Tax) => await axios.post(base_tax_api_url + '/save',tx);

export const DeleteTax = async (id : number) => await axios.get(base_tax_api_url + '/TaxDel?id='+id);