import React, { useEffect, useState } from 'react';
import * as AppAct from '../globals/AppActions';
import { mapStateToProps } from '../globals/AppActions';
import * as bdb from '../db/BrokersData';
import { connect, useDispatch, useSelector } from "react-redux";
import * as utils from '../globals/Utils';
import * as dateUtils from '../globals/AppDateUtils';
import DateRangeControlEx from "../globals/DateRangeControlEx";
import { Modal, ModalBody } from "react-bootstrap";
import ModalHeader from "react-bootstrap/ModalHeader";
import BrokerPay from "./BrokerPay";
import { SaleInvoiceView } from "../sales/SaleInvoiceView";
import { AppReduxState } from '../infra-redux/AppStateReduxStore';
import {PopTags} from "../globals/PopTags/PopTags";
import * as consts from '../globals/GlobalConsts';
import {InwardInvoiceView} from "../inwards/InwardInvoiceView";
import {InvoiceViewer} from "../globals/InvoiceViewer";

const BrokerStatement = (props: any) => {
    const AppDisp = useDispatch();
    const [dates, setDates] = useState({ start: dateUtils.MinusMonths(new Date(), 6), end: new Date() });
    const [broker, setBroker] = useState(props.broker);
    const [showPayModal, setShowPayModal] = useState(false);

    const [allTrans, setAllTrans] = useState(
        {
            trans: new Array<any>(),
            debitGrandTotal: 0,
            creditGrandTotal: 0,
        }
    );
    const reduxState = useSelector((state: AppReduxState) => state);

    useEffect(() => {
        AppDisp(AppAct.ChangeMainTitle("Broker Credit Statement"));
        LoadBrokerStatment(dates.start, dates.end);
    }, []);

    const LoadBrokerStatment = (start: Date = dates.start, end: Date = dates.end) => {

        AppDisp(AppAct.ToggleProgress(true));
        bdb.GetBrokerWiseCreditStatement(broker.BRKID, start, end).then((res: any) => {
            setAllTrans({
                ...allTrans,
                trans: [...res.data],
                debitGrandTotal: debitTotal(res.data),
                creditGrandTotal: creditTotal(res.data)
            }
            );
            AppDisp(AppAct.ToggleProgress(false));
        });
    }

    const isDebit = (il: any) => (il.Trans.IsExpense === false || il.Trans.LedgerID > 0)
    const isCredit = (il: any) => (il.Trans.IsExpense && il.Trans.LedgerID === 0)

    const creditTotal = (items: Array<any>) => {
        return items.reduce((total, item) => {
            if (isCredit(item))
                return total + item.Trans.TotalAmount;
            else
                return total;
        }, 0)
    }

    const debitTotal = (items: Array<any>) => {
        return items.reduce((total, item) => {
            if (isDebit(item))
                return total + item.Trans.TotalAmount;
            else
                return total;
        }, 0)
    }

    const handleDateChange = (sd: Date, ed: Date) => {
        setDates({ start: sd, end: ed }); //for UI to remember what is selected
        LoadBrokerStatment(sd, ed); //setDates() might get affected quickly so explicit calling
    }



    return <>



        {/*<Modal size={"lg"} show={(showSaleBillNumber > 0)} keyboard={true} dialogClassName="modal-corner"*/}
        {/*    onHide={() => { setShowSaleBillNumber(0) }}*/}
        {/*    onEscapeKeyDown={() => { setShowSaleBillNumber(0) }} >*/}
        {/*    <ModalBody>*/}
        {/*        <SaleInvoiceView sbid={showSaleBillNumber} />*/}
        {/*    </ModalBody>*/}
        {/*</Modal>*/}

        {/*<Modal size={"lg"} show={(showInwardBill > 0)} keyboard={true} dialogClassName="modal-corner"*/}
        {/*       onHide={() => { setShowInwardBill(0) }}*/}
        {/*       onEscapeKeyDown={() => { setShowInwardBill(0) }} >*/}
        {/*    <ModalBody>*/}
        {/*        <InwardInvoiceView iwid={showInwardBill} />*/}
        {/*    </ModalBody>*/}
        {/*</Modal>*/}

        {showPayModal &&
            <Modal show={showPayModal} dialogClassName="modal-corner">
                <ModalHeader>
                    <h3>Credit Payment</h3>
                </ModalHeader>
                <ModalBody>
                    <BrokerPay
                        broker={props.broker}
                        close={() => setShowPayModal(false)}
                        saved={LoadBrokerStatment}
                    />
                </ModalBody>
            </Modal>
        }

        <div className="row">
            <div className="col-md-12 text-black">
                <span className="font-weight-bold tx-20-f">{props.broker.BrokerName}</span>
                &nbsp;|&nbsp;
                <span className="font-weight-bold">{props.broker.ContactNumber}</span>
            </div>
        </div>

        <hr/>

        <div className="row mb-3">
            <div className="col-md-8">
                <DateRangeControlEx start={dates.start} end={dates.end} onChange={handleDateChange} />
            </div>
            <div className="col-md-4 text-right mt-3 mt-md-0">
                <button className="btn btn-warning font-weight-bold"
                    onClick={() => setShowPayModal(true)}>
                    Pay
                </button>
                <button className="btn btn-primary ml-3" onClick={() => props.close(true)}>&lt; Back</button>
            </div>
        </div>

        <div className="row">
            <div className="col-md-12">
                <div className="table-responsive">
                    <table className="table table-bordered table-striped mg-b-0">
                        <thead className="border-top">
                            <tr>
                                <th>Date</th>
                                {/*<th>Invoice</th>*/}
                                <th>Description</th>
                                <th className="text-right">Debit</th>
                                <th className="text-right">Credit</th>
                                <th className="text-right">Balance</th>
                            </tr>
                        </thead>
                        <tbody>
                            {allTrans.trans.map((il: any, idx: number) => {
                                return (
                                    <tr key={idx}>
                                        <td>{dateUtils.FormatDate(il.Trans.TransDate)}</td>
                                        {/*<td>
                                                {il.Inward &&
                                                <span className="pointer-cursor tx-primary">
                                                    Inward # <b>{il.Inward.SupplierInoviceNumber}</b></span>
                                                }
                                                {il.SaleBill &&
                                                <span className="pointer-cursor tx-primary"
                                                      onClick={()=>setShowSaleBillNumber(il.SaleBill.SBID)}>
                                                    SaleBill # <b>{il.SaleBill.BillNumber}</b></span>}
                                            </td>*/}
                                        <td>
                                            {il.Trans.PayMode &&
                                                <span>{il.Trans.PayMode}</span>}
                                            {il.Trans.Details &&
                                                <span>&nbsp;{il.Trans.Details}</span>}
                                                <br/>
                                                <PopTags transaction={il.Trans} />
                                        </td>
                                        <td className="text-right">
                                            {isDebit(il) &&
                                                <div>{utils.formatCurrencyValue(reduxState, il.Trans.TotalAmount)}</div>}
                                        </td>
                                        <td className="text-right">
                                            {isCredit(il) &&
                                                <div>{utils.formatCurrencyValue(reduxState, il.Trans.TotalAmount)}</div>}
                                        </td>
                                        <td className="text-right">{utils.formatCurrencyValue(reduxState, il.BrokeragePay.CurrentBalance)}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                        <tfoot>
                            <tr>
                                <td></td>
                                {/*<td></td>*/}
                                <td></td>
                                <td className="text-right font-weight-bold">{utils.formatCurrencyValue(reduxState, allTrans.debitGrandTotal)}</td>
                                <td className="text-right font-weight-bold">{utils.formatCurrencyValue(reduxState, allTrans.creditGrandTotal)}</td>
                                <td className="text-right font-weight-bold">
                                    {allTrans.trans.length > 0 &&
                                        <div>{utils.formatCurrencyValue(reduxState, allTrans.trans[allTrans.trans.length - 1].BrokeragePay.CurrentBalance)}</div>}</td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
        </div>

    </>
}

export default BrokerStatement;
