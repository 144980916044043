import React from 'react';
import Switch from "react-switch";

export const CheckToggleUI = (props:{
    keyValue:string,
    idStringValue:string,
    checked:boolean,
    label :string,
    onChecked(val:boolean):void,
})=> {
    return <div key={'d1'+props.keyValue} className="d-flex flex-row align-items-start justify-content-start gap-2">
        <div key={'d2'+props.keyValue}  style={{paddingTop:'2px'}}>
            <Switch
                key={props.keyValue}
                height={20}
                width={40}
                onColor="#3366ff"
                id={props.idStringValue}
                uncheckedIcon={false}
                checkedIcon={false}
                checked={props.checked}
                onChange={(val, event, id) =>
                { props.onChecked(val) }}
            />
        </div>
        <div className='pointer-cursor form-control-font' onClick={()=>{props.onChecked(!props.checked)}}>{props.label}</div>
    </div>
}
