import React, { useEffect, useState } from "react";
import { ML_Company } from "../../globals/dto";
import * as stdb from './../../db/CompanyData';
import * as _ from 'lodash';
import { CheckToggleUI } from "./CheckToggleUI";

class StoresCheckItem {
    store: ML_Company;
    isChecked: boolean;
}

export const MultiStoreSelector = (props: {
    selectedStores: ML_Company[],
    onUpdated(stores: ML_Company[]): void
}) => {

    const [selectedList, setSelectedList] = useState<StoresCheckItem[]>();

    useEffect(() => {
        stdb.GetAll().then(res => {
            let list = res.data.map((s: ML_Company) => {
                let sci = new StoresCheckItem();
                sci.store = s;
                let found = props.selectedStores ? props.selectedStores?.filter(f => f.CMPID === s.CMPID) : [];
                sci.isChecked = found.length > 0;
                return sci;
            });
            setSelectedList(list);
        });
    }, [])


    const updateStoreCheckStatus = (item: StoresCheckItem) => {
        let copy = _.clone(selectedList);
        copy.forEach(f => {
            if (f.store.CMPID === item.store.CMPID) {
                f.isChecked = item.isChecked;
            }
        });
        setSelectedList(copy);
        props.onUpdated(selectedList.filter(f => f.isChecked).map((s: StoresCheckItem) => s.store));
    }


    if (selectedList && selectedList.length > 0) {
        return <div className="d-flex flex-column gap-2">
            {selectedList.map((sc: StoresCheckItem, index: number) => {
                return <CheckToggleUI key={"ctu" + index} keyValue={"sw" + sc.store.CMPID}
                    idStringValue={"isChecked"}
                    label={sc.store.CompanyName}
                    onChecked={(val: boolean) => { updateStoreCheckStatus({ ...sc, isChecked: val }) }}
                    checked={sc.isChecked}
                />
            })}
        </div>
    }

    return <></>
}
