import React, {useEffect, useState} from 'react';
import * as stdb from '../db/StocksData';
import Select, {components, OptionProps} from 'react-select';
import {ProductView} from "../globals/dto";
import {AxiosResponse} from "axios";

export const StoreProductsDropDown = (props: any) => {
    const [products, setProducts] = useState(new Array<ProductView>());
    useEffect(() => {
        stdb.GetAllProductsWithStocksByStore(props.storeID).then( (res:AxiosResponse<ProductView[]>) => {
            let filteredData = res.data.filter(x=>x.Product.CanBeSold);
            setProducts(filteredData);
            // setProducts((prev: any) => {
            //     return [...prev, filteredData]
            // });
            //Remove products with zero qty
            // console.log("calling with stock/shopt");
            // res.data.forEach((x: any) => {
            //     if (+x.OnHand > 0 && x.Product.CanBeSold ) {
            //         console.log(JSON.stringify(x, null, 3));
            //         setProducts((prev: any) => {
            //             return [...prev, x]
            //         });
            //     }
            // });
        });
    }, []);

    const optionTemplate = (props: any) => (
        <components.Option {...props}>
            <div><b>{props.data.Product?.ProductName}</b>
                <span style={{float: "right"}}><span
                    className="font-small">In-Stock:</span> <b>{props.data.OnHand}</b>&nbsp;{props.data.UnitMeasure.BulkMeasureName}</span>
            </div>
        </components.Option>
    );

    const singleSelectTemplate = (props: any) => (
        <components.SingleValue {...props}>
            <b>{props.data.Product?.ProductName}</b>
        </components.SingleValue>
    );

    const productFilter = (option: any, search: string) => {
        return option.data.Product?.ProductName.toLowerCase().includes(search.toLowerCase());
    }

    if (!products)
        return <></>

    if (products)
        return (
            <Select classNamePrefix="srushti"
                value={props.product}
                isDisabled={props.isdisabled}
                options={products}
                theme={theme => ({...theme, borderRadius: 0})}
                components={{Option: optionTemplate, SingleValue: singleSelectTemplate}}
                getOptionValue={option => option.Product?.PRDID.toString()}
                onChange={props.onChange}
                filterOption={productFilter}></Select>
        )
}
