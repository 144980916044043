import React, { useEffect, useState } from "react";
import { ML_Tag } from "../globals/dto";
import * as tgdb from '../db/TagData';
import * as consts from '../globals/GlobalConsts';
import { TableRowDeleteButtonUI } from "../globals/AppControls/TableRowDeleteButtonUI";
import { TableRowEditButtonUI } from "../globals/AppControls/TableRowEditButtonUI";
import { AddNewButtonUI } from "../globals/AppControls/AddNewButtonUI";
import { SaveButtonUI } from "../globals/AppControls/SaveButtonUI";
import { CloseButtonUI } from "../globals/AppControls/CloseButtonUI";
import { LoaderUI } from "../globals/AppControls/LoaderUI";
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from "react-redux";
import * as AppAct from '../globals/AppActions';


export const TagsList = (props:{
    screenTitle:string,
    tagType:number
}) => {

    const dispatch = useDispatch();

    const [dataLoading, setDataLoading] = useState(false);
    const [cat, setCat] = useState<ML_Tag>(null);
    const [dataList, setDataList] = useState<ML_Tag[]>();

    useEffect(()=>{
        dispatch(AppAct.ChangeMainTitle(props.screenTitle));
        loadAll();
    },[])

    const loadAll = () => {
        setDataLoading(true);
        tgdb.GetAll(props.tagType).then(res => {
            setDataList(res.data);
            setDataLoading(false);
        })
    }

    return <LoaderUI show={dataLoading}>
        <TagInput tagObj={cat} onSaved={loadAll} tagType={props.tagType} />
        <TagsTable
            data={dataList}
            onDeleted={loadAll}
            onEdit={(e)=>setCat(e)}
        />
    </LoaderUI>
}

const TagInput = (props: {
    tagType: consts.TagTypes,
    tagObj: ML_Tag,
    onSaved(): void
}) => {
    const [input, setInput] = useState<ML_Tag>(null);

    useEffect(()=>{
        setInput(props.tagObj);
    },[props.tagObj])
    

    const onChange = (e: any) => {
        setInput({ ...input, "TagName": e.target.value });
    }

    const createNewTag = () => {
        let t = new ML_Tag();
        t.TagType = props.tagType;
        return t;
    }

    const saveNow = () => {
        tgdb.Save(input).then(res => {
            if (res.data.StatusCode === consts.CALL_RESPONSE_STATUS_OK) {
                props.onSaved();
                setInput(null);
            } else {
                toast.error(res.data.Message);
            }
        })
    }

    if (input === null)
        return <AddNewButtonUI autoFocus onClick={()=>setInput(createNewTag())} />

    if (input && input.GRPID >=0)
        return <div className="d-flex justify-content-start gap-2 align-items-end mb-2">
            <div className="flex-grow-1">
                <label>Group Type</label>
                <input autoFocus type="text" className="form-control" value={input.TagName} onChange={onChange} />
            </div>
            <div>
                <SaveButtonUI
                    className="mr-2"
                    disabled={!(input.TagName && input.TagName.length > 0)}
                    onClick={saveNow}
                />
                <CloseButtonUI buttonText="Close" accessKey="" onClick={()=>setInput(null)}/>
            </div>
        </div>
}


const TagsTable = (props: {
    data: ML_Tag[],
    onEdit(cat: ML_Tag): void,
    onDeleted(): void
}) => {
    return (
        <div className="table-responsive mt-3">
            <table className="table">
                <tbody>
                    {props.data && props.data.map((p: ML_Tag, i: number) => (
                        <tr key={'tcid' + i}>
                            <td className="w-75">{p.TagName}</td>
                            <td className="text-right">
                                <TagDeleteButton tagId={p.GRPID} onDeleted={props.onDeleted} />
                                <TableRowEditButtonUI cssClasses="ml-2" onClick={() => props.onEdit(p)} />
                            </td>
                        </tr>))
                    }
                </tbody>
            </table>
        </div>
    )
}

const TagDeleteButton = (props: { tagId: number, onDeleted(): void }) => {

    const deleteCategory = () => {
        tgdb.DeleteTag(props.tagId).then(res => {
            if (res.data.StatusCode == consts.CALL_RESPONSE_STATUS_OK)
                props.onDeleted();
            else
                toast.error(res.data.Message);
        })
    }
    return <><TableRowDeleteButtonUI cssClasses="mr-2" onClick={deleteCategory} /></>
}

