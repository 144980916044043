import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useStore } from "react-redux";
import { mapStateToProps } from "../globals/AppActions";
import * as AppAct from '../globals/AppActions';
import * as global from '../globals/GlobalConsts';
import { ML_Broker } from "../globals/dto";
import * as bdb from '../db/BrokersData';
import { toast } from "react-toastify";
import { AxiosResponse } from "axios";
import TextBoxNumeric from '../ui-controls/TextBoxNumeric';
import { Field, Formik, getIn } from 'formik';
import * as yup from 'yup';
import { SaveButtonUI} from '../globals/AppControls/SaveButtonUI';
import { CloseButtonUI } from '../globals/AppControls/CloseButtonUI';

const BrokerDetails = (props: any) => {

    const [isSubmitted, setIsSubmitted] = useState(false);

    const brokerValidation = yup.object().shape({
        bro: yup.object().shape({
            BrokerName: yup.string().required(),
            ContactNumber: yup.string().required(),
        })
    })

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(AppAct.ChangeMainTitle('Broker Details'));
        window.scrollTo(0, 0);
    }, []);


    if (!props.data)
        return <></>

    return <>
        <Formik
            initialValues={{ bro: { ...props.data }, balance: 0 }}
            validationSchema={brokerValidation}
            onSubmit={(value, actions) => {
                console.log(value);
                dispatch(AppAct.ToggleProgress(true));
                let payload = new ML_Broker();
                payload = value.bro;
                bdb.Save(payload).then((res: AxiosResponse<number>) => {
                    dispatch(AppAct.ToggleProgress(false));
                    if (res.data === 0 || res.status !== 200) {
                        toast.error('Unable to save, system error');
                    } else {
                        setIsSubmitted(false);
                        props.close();
                    }
                });
            }}
        >
            {(formikProps) => (<>

                <div className="row">
                    <div className=" col-md-12 text-right">
                        {/* <button type="button" className="btn btn-lg btn-success" accessKey={"s"} onClick={() => {
                            setIsSubmitted(true);
                            if (!formikProps.isValid)
                                toast.error("Resolve data entry errors");
                            else
                                formikProps.submitForm();
                        }}><u>S</u>ave</button> */}


                        <SaveButtonUI className="mr-2" onClick={() => {
                            setIsSubmitted(true);
                            if (!formikProps.isValid)
                                toast.error("Resolve data entry errors");
                            else
                                formikProps.submitForm();
                        }} />
                        {/*<Link to={'/suppliers?q=dhan'} className="btn btn-primary ml-3">Back</Link>*/}
                        <CloseButtonUI onClick={props.close} buttonText="<u>C</u>lose" accessKey={"c"} />
                        {/* <button className="btn btn-lg btn-primary ml-3"  onClick={props.close}><u>C</u>lose</button> */}
                    </div>
                </div>


                <h5 className="az-content-label">Broker Details</h5>
                <hr />
                <div className="row">
                    <div className="col-md-2 text-center">
                        <i className="fas fa-user-circle fa-3x pt-2 text-warning mb-2"></i>
                    </div>
                    <div className="col-md-10">
                        <div className="d-flex flex-md-row flex-column justify-content-between">
                            <div className="pr-3 pl-2 flex-md-fill">
                                <label className="form-label">Broker Name<span className="tx-danger">*</span></label>
                                <Field
                                    className={"form-control " + (getIn(formikProps.errors, 'bro.BrokerName') && isSubmitted ? 'err' : '')}
                                    autoFocus
                                    name="bro.BrokerName"
                                />
                                {/* <input type="text"
                                            className={"form-control " + (getIn(formikProps.errors,'bro.BrokerName') && isSubmitted ? 'err' : '')}
                                            autoFocus
                                            value={formikProps.values.bro["BrokerName"]}
                                            onChange={formikProps.handleChange("bro.BrokerName")}
                                        /> */}
                            </div>
                            <div className="pr-3 pl-2 flex-md-fill">
                                <label className="form-label">Mobile Phone<span className="tx-danger">*</span></label>
                                <Field
                                    className={"form-control " + (getIn(formikProps.errors, 'bro.ContactNumber') && isSubmitted ? 'err' : '')}
                                    name="bro.ContactNumber"
                                />
                            </div>

                        </div>

                        <div className="mt-3 d-flex flex-md-row flex-column justify-content-between">
                            <div className="pr-3 pl-2 flex-md-fill">
                                <label className="form-label">Address - 1</label>
                                <input type="text" className="form-control"
                                    name="AddressLine1"
                                    value={formikProps.values.bro["AddressLine1"]}
                                    onChange={formikProps.handleChange("bro.AddressLine1")}
                                />
                            </div>
                            <div className="pr-3 pl-2 flex-md-fill">
                                <label className="form-label">Address - 2</label>
                                <input type="text" className="form-control"
                                    name="AddressLine2"
                                    value={formikProps.values.bro["AddressLine2"]}
                                    onChange={formikProps.handleChange("bro.AddressLine2")}
                                />
                            </div>
                        </div>

                        <div className="mt-3 pb-4 d-flex flex-md-row flex-column justify-content-between">
                            <div className="pr-3 pl-2 flex-md-fill">
                                <label className="form-label">City</label>
                                <input type="text" className="form-control"
                                    name="GeoCity"
                                    value={formikProps.values.bro["GeoCity"]}
                                    onChange={formikProps.handleChange("bro.GeoCity")}
                                />
                            </div>
                            <div className="pr-3 pl-2 flex-md-fill">
                                <label className="form-label">PIN Code</label>
                                <input type="text" className="form-control"
                                    name="GeoCityPIN"
                                    value={formikProps.values.bro["GeoCityPIN"]}
                                    onChange={formikProps.handleChange("bro.GeoCityPIN")}
                                />
                            </div>
                            <div className="pr-3 pl-2 flex-md-fill">
                                <label className="form-label">State</label>
                                <input type="text" className="form-control"
                                    name="GeoState"
                                    value={formikProps.values.bro["GeoState"]}
                                    onChange={formikProps.handleChange("bro.GeoState")}
                                />
                            </div>
                        </div>


                    </div>
                </div>

                <h5 className="az-content-label mt-5">Commission Setting</h5>
                <hr />
                <div className="row">
                    <div className="col-md-2 text-center">
                        <i className="fas fa-rupee-sign fa-3x pt-2  text-indigo mb-2"></i>
                    </div>
                    <div className="col-md-5">
                        <div className="d-flex flex-md-row flex-column justify-content-between">
                            <div className="pr-3 pl-2 flex-md-fill">
                                <label className="form-label">Commission amount per unit<span className="tx-danger">*</span></label>
                                <TextBoxNumeric style={{ precision: 2 }} className={"form-control"}
                                    value={formikProps.values.bro["CommissionAmount"]}
                                    onChange={formikProps.handleChange("bro.CommissionAmount")}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <h5 className="az-content-label mt-5">Opening Balance</h5>
                <hr />
                <div className="row">
                    <div className="col-md-2 text-center">
                        <i className="fas fa-piggy-bank fa-3x pt-2  text-primary mb-2"></i>
                    </div>
                    <div className="col-md-5">
                        <div className="d-flex flex-md-row flex-column justify-content-between">
                            <div className="pr-3 pl-2 flex-md-fill">
                                <label className="form-label">Amount</label>
                                <TextBoxNumeric className="form-control"
                                    value={formikProps.values.balance}
                                    onChange={formikProps.handleChange("balance")}
                                />
                                <span>(Enter -ve value for credit/pending amount)</span>
                            </div>
                        </div>
                    </div>
                </div>



            </>)}
        </Formik>
    </>;
}

export default BrokerDetails;
