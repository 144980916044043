import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { ML_Company, ML_Product, ML_ProductTransits, ML_UnitMeasure } from "../../globals/dto";
import * as rpt from '../../db/ReportsData';
import * as AppAct from '../../globals/AppActions';
import { AddCircleOutline, AddCircleSharp, AddSharp, ArchiveOutline, ArrowForwardCircle, CodeDownloadOutline, CodeWorkingOutline } from "react-ionicons";
import * as dateUtils from './../../globals/AppDateUtils';

class ResultData {
    Product: ML_Product;
    Unit: ML_UnitMeasure;
    Company: ML_Company;
    Transit: ML_ProductTransits;
}

const ProductOpenStocksHistoryReport = (props: any) => {

    const AppDisp = useDispatch();
    const [data, setData] = useState<ResultData[]>([]);


    useEffect(() => {
        GetAllOpenStocksHistory();
    }, []);

    const GetAllOpenStocksHistory = () => {
        rpt.GetAllOpenedStocks().then(res => {
            if (res.data)
                setData(res.data);
        })
    }

    return (<>
        <div className="az-content-label mg-b-5 tx-22-f d-flex align-items-center">
            <ArchiveOutline width={'28px'} height={'28px'} />&nbsp; Opening Stock
        </div>

        <div className="table-responsive">
            <table className="table table-bordered table-striped">
                <thead className="border-top">
                    <tr>
                        <th>Date/Time</th>
                        <th>Store</th>
                        <th>Product</th>
                        <th>Unit</th>
                        <th>Qty</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((row: ResultData, idx: number) => {
                        return (
                            <tr key={'GOSH' + idx}>
                                <td>{dateUtils.FormatDateTime(row.Transit.CreateDate)}</td>
                                <td>{row.Company.CompanyName}</td>
                                <td>{row.Product.ProductName}</td>
                                <td>1 {row.Unit.BulkMeasureName} = {row.Unit.RetailQty} {row.Unit.RetailMesaureName}</td>
                                <td>{row.Transit.ToQty}</td>
                            </tr>);
                    })}
                </tbody>
            </table>
        </div>

    </>)
}

export default ProductOpenStocksHistoryReport;
