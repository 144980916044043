import React, { useState } from 'react';
import { DateControlEx } from "./DateControlEx";
import { toast } from "react-toastify";
import * as utils from '../globals/Utils';

const DateRangeControlEx = (props: {
    start: Date,
    end: Date,
    onChange(start: Date, end: Date): void
}) => {

    const updateState = (startDate: Date, endDate: Date) => {
        if (props.onChange) {
            if (startDate)
                props.onChange(startDate, props.end);

            if (endDate)
                props.onChange(props.start, endDate);
        }
    }

    const handleChange = (e: Date, dname: string) => {
        if (dname === 'start') {
            console.log('end',props.end);
            if (e.getTime() <= new Date(props.end).getTime()) {
                updateState(e, null);
            } else {
                toast.error('FROM date should be before TO Date.');
            }
        } else {
            console.log('start',props.start);
            if (new Date(props.start).getTime() <= e.getTime()) {
                updateState(null, e);
            } else {
                toast.error('TO date should be later than FROM date');
            }
        }
    }


    return <>
        <div className="d-flex flex-row align-items-center">
            <div className="pr-1" key={1}>From</div>
            <div className="pr-2" key={2}>
                <DateControlEx
                    //placeholder = {utils.FormatDate(state.start)}
                    datevalue={props.start}
                    onDateChange={(e: string) => {
                        handleChange(new Date(e), 'start')
                    }} />
            </div>

            <div className="pr-1" key={3}>To</div>
            <div key={4}>
                <DateControlEx
                    datevalue={props.end}
                    //placeholder={utils.FormatDate(state.end)}
                    onDateChange={(e: string) => {
                        handleChange(new Date(e), 'end')
                    }} />
            </div>
        </div>
    </>
}

export default DateRangeControlEx;