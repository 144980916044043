import React, { useEffect, useState } from 'react';
import * as AppAct from '../globals/AppActions';
import { connect, useDispatch, useSelector } from "react-redux";
import * as wgdb from '../db/WagesData';
import { mapStateToProps } from "../globals/AppActions";
import * as utils from '../globals/Utils';
import * as dateUtils from '../globals/AppDateUtils';
import DateRangeControlEx from "../globals/DateRangeControlEx";
import { AppReduxState } from '../infra-redux/AppStateReduxStore';

const WagesStatement = (props: any) => {

    const [allWages, setAllWages] = useState([]);
    const [start, setStart] = useState(dateUtils.MinusDays(new Date(), 1));
    const [end, setEnd] = useState(new Date());
    const [grandTotoal, setGrandTotal] = useState(0);
    const [grandQtyTotal, setGrandQtyTotal] = useState(0);
    // const [unitGroup, setUnitGroup] = useState(0);
    // const [groupTotal,setGroupTotal] = useState(0);

    var groupUnitID = 0;
    var groupQtyTotal = 0;
    var groupPrevQtyTotal = 0;
    var groupTotal = 0;
    var groupProvTotal = 0;
    var showSubTotal = false;

    const AppDisp = useDispatch();
    const reduxState = useSelector((state: AppReduxState) => state);

    useEffect(() => {
        AppDisp(AppAct.ChangeMainTitle('Wages Statement'));
        LoadTrans(start, end);
    }, []);

    const LoadTrans = (st: Date, ed: Date) => {
        AppDisp(AppAct.ChangeMainTitle('Wages Statement : ' + dateUtils.FormatDate(st) + " - to - " + dateUtils.FormatDate(ed)));
        AppDisp(AppAct.ToggleProgress(true));
        wgdb.CalculateWages(st, ed).then((res: any) => {
            setAllWages(res.data);
            setGrandTotal(res.data.reduce((total: number, cv: any) => {
                return total + cv.Total;
            }, 0));

            setGrandQtyTotal(res.data.reduce((total: number, cv: any) => {
                return total + cv.Tracker.ProcessedQty;
            }, 0));

            AppDisp(AppAct.ToggleProgress(false));
        });
    }

    const handleDateChange = (sd: Date, ed: Date) => {
        setStart(sd);
        setEnd(ed);
        LoadTrans(sd, ed);
    }

    return (
        <>
            <div className="row">
                <div className="col-md-12">
                    <DateRangeControlEx
                        start={start} end={end}
                        onChange={handleDateChange} />
                </div>
            </div>
            <hr/>
            <div className="table-responsive">
                <table className="table table-hover">
                    <thead className="border-top">
                        <tr key={"head0"}>
                            <th key={"hd21"}>Date</th>
                            <th key={"hd26"}>Location</th>
                            <th key={"hd22"}>Unit</th>
                            <th key={"hd23"} className="text-right">Qty</th>
                            <th key={"hd24"} className="text-right">Rate</th>
                            <th key={"hd25"} className="text-right">Amount</th>
                        </tr>
                    </thead>
                    <tbody>
                        {allWages.map((t: any, idx: number) => {



                            if (groupUnitID != t.UnitMeasure.UCID) {
                                groupUnitID = t.UnitMeasure.UCID;

                                groupProvTotal = groupTotal;
                                groupPrevQtyTotal = groupQtyTotal;

                                groupTotal = t.Total;
                                groupQtyTotal = t.Tracker.ProcessedQty;

                                showSubTotal = true;
                                //console.log("show: " + groupProvTotal + " : " + groupPrevQtyTotal+ "idx : " + idx + " length:" + allWages.length);
                            } else {
                                showSubTotal = false;
                                groupTotal = groupTotal + t.Total;
                                groupQtyTotal = groupQtyTotal + t.Tracker.ProcessedQty;
                                //console.log("process: " + groupProvTotal + " : " + groupPrevQtyTotal + "idx : " + idx + " length:" + allWages.length);
                            }

                            if (idx === allWages.length - 1) {
                                groupProvTotal = groupTotal;
                                groupPrevQtyTotal = groupQtyTotal;
                                //console.log("last: " + groupProvTotal + " : " + groupPrevQtyTotal+ "idx : " + idx + " length:" + allWages.length);
                            }

                            return (
                                <>
                                    {/*{showSubTotal && groupProvTotal > 0 && idx > 0 &&*/}
                                    {/*<tr key={"tot" + idx} className="bg-gray">*/}
                                    {/*    <td key={"t6" + idx}  colSpan={4} className="text-right font-weight-bold">Total Qty: {groupPrevQtyTotal}</td>*/}
                                    {/*    <td key={"t7" + idx}  colSpan={2} className="text-right font-weight-bold">Total Amount: {groupProvTotal}</td>*/}
                                    {/*</tr>}*/}
                                    <tr key={idx}>
                                        <td key={"t1" + idx}>{dateUtils.FormatStringDateTime(t.Tracker.LogDate)}</td>
                                        <td key={"t6" + idx}>{t.Plant.PlantName}</td>
                                        <td key={"t2" + idx}>1 {t.UnitMeasure.BulkMeasureName} / {t.UnitMeasure.RetailQty} {t.UnitMeasure.RetailMesaureName}</td>
                                        <td key={"t3" + idx} className="text-right">{t.Tracker.ProcessedQty}</td>
                                        <td key={"t4" + idx} className="text-right">{utils.formatCurrencyValue(reduxState, t.Wage.WagePerUnit)}</td>
                                        <td key={"t5" + idx} className="text-right font-weight-bold">{utils.formatCurrencyValue(reduxState, t.Total)}</td>
                                    </tr>
                                </>
                            )
                        })}
                        {/*<tr>*/}
                        {/*    <td colSpan={4} className="text-right font-weight-bold">Total Qty: {groupPrevQtyTotal}</td>*/}
                        {/*    <td colSpan={2} className="text-right font-weight-bold">Total Amount: {groupProvTotal}</td>*/}
                        {/*</tr>*/}
                    </tbody>
                    <tfoot>
                        <tr key={"ft001"}>
                            <td key={"ft21"} colSpan={4} className="text-right"><h4 className="font-weight-bold">Qty: {grandQtyTotal}</h4></td>
                            <td key={"ft21"} colSpan={2} className="text-right"><h4 className="font-weight-bold">Grand Total: {utils.formatCurrencyValue(reduxState, grandTotoal)}</h4></td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </>
    )
}

export default WagesStatement;
