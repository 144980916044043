import React from 'react';
import ReactQuill from 'react-quill';
import { AliasMemberView, ML_Customer, SaleBillView } from '../globals/dto';
import { CustomerSelect } from '../shared/CustomerSelect';
import CustomerDetailsView from './CustomerDetailsView';
import CustomerAliasDropdown from './CustomerAliasDropdown';
import * as glob from './../globals/GlobalConsts';
import * as _ from 'lodash';
import { sumBy } from 'lodash';

type attributes = {
    saleBillView: SaleBillView,
    // customer: ML_Customer,
    // isReadOnly: boolean,
    // phantomCustomerText: string,
    // onCustomerSelected(c: ML_Customer): void,
    // onPhantomCustomerEntered(value: string): void,
    onSaleBillViewUpdate(sb: SaleBillView): void
}

const CustomerEntry = (props: attributes) => {

    const handleCustomerChange = (e: ML_Customer) => {
        let sb = _.clone(props.saleBillView);
        if (e) {
            if (e.CID === -1) {

                //setCustomerModal(true);
                sb.SaleBill.PhantomCustomer = '';

            } else {
                //setCustomerSelected(e);
                sb.CustomerDetails = e;
                sb.SaleBill.CustomerID = e.CID;
                //setSaleBillView({ ...saleBillView });
            }
        } else {
            //setCustomerSelected(new ML_Customer());
            sb.CustomerDetails = new ML_Customer();
            sb.SaleBill.CustomerID = 0;
            sb.SaleBill.PhantomCustomer = '';
        }
        props.onSaleBillViewUpdate(sb);
    }

    const getAliasList = (): AliasMemberView[] => {
        return props.saleBillView.CustomerDetails.AliasVersions 
        ? JSON.parse(props.saleBillView.CustomerDetails.AliasVersions) 
        : new Array<AliasMemberView>();
    }

    const onAliasChange = (e: AliasMemberView) => {
        let sb = _.clone(props.saleBillView);
        sb.SaleBill.PhantomCustomer = e.FirmName + e.FullAddress;
        props.onSaleBillViewUpdate(sb);
    }

    const onQuilAliasChange = (e: string) => {
        let sb = _.clone(props.saleBillView);
        if (e.replace(/<(.|\n)*?>/g, '').trim().length === 0)
            sb.SaleBill.PhantomCustomer = '';
        else
            sb.SaleBill.PhantomCustomer = e;
        props.onSaleBillViewUpdate(sb);
    }

    const onPhantomnameChange = (e:any)=>{
        let sb = _.clone(props.saleBillView);
        sb.SaleBill.PhantomCustomer = e.target.value;
        props.onSaleBillViewUpdate(sb);
    }

    const closeCustomerModal = () => {
        //appDisp(AppAct.ChangeMainTitle('Sale Invoice'));
        //setCustomerModal(false);
    }

    return (<>

            {/* <Modal show={customerModal} size={'lg'} dialogClassName="modal-corner">
                <div className="card">
                    <div className="card-body">
                        <CustomerDetails data={() => new ML_Customer()} close={closeCustomerModal} />
                    </div>
                </div>
            </Modal> */}


        <div className="form-row pb-3">
            <div className="col-12">
                <label className="form-label">Customer</label>
                <CustomerSelect isDisabled={props.saleBillView.SaleBill.SBID > 0}
                    onCustomerChanged={handleCustomerChange}
                    customerSelected={props.saleBillView.CustomerDetails} />

            </div>
            {props.saleBillView.CustomerDetails && props.saleBillView.CustomerDetails.CID > 0 &&
                <div className="col-12 mt-3">
                    <CustomerDetailsView customerSelected={props.saleBillView.CustomerDetails} />
                </div>
            }

            {getAliasList().length > 0 &&
                <div className="col-12">
                    <label>Bill to Alias</label>
                    <CustomerAliasDropdown
                        isReadOnly={props.saleBillView.SaleBill.SBID > 0}
                        aliasList={getAliasList()}
                        onAliasChanged={onAliasChange}
                    />
                </div>
            }

            {(getAliasList().length === 0 || (props.saleBillView.SaleBill.PhantomCustomer && 
            props.saleBillView.SaleBill.PhantomCustomer.trim().length > 0)) &&
                <div className="col-12 mt-2">
                    <label className="form-label">Bill To:</label>
                    {/*<input className="form-control" type="text" name={"PhantomCustomer"} onChange={onPhantomnameChange} />*/}
                    <ReactQuill
                        modules={glob.QuillTabBinding}
                        theme="bubble"
                        value={props.saleBillView.SaleBill.PhantomCustomer}
                        onChange={onQuilAliasChange} />
                </div>
            }
        </div>
    </>);

}

export default CustomerEntry;
