import React, { useEffect, useState } from 'react';
import { ML_Broker } from "../globals/dto";
import { useDispatch } from "react-redux";
import * as AppAct from '../globals/AppActions';
import * as bdb from '../db/BrokersData';
import BrokerDetails from "./BrokerDetails";
import * as graphic from "../globals/colors";

import { AddNewButtonUI  } from '../globals/AppControls/AddNewButtonUI';

const BrokersList = (props: any) => {

    const [state, setState] = useState({
        brokers: new Array<ML_Broker>(),
        brokersMaster: new Array<ML_Broker>(),
        validity: {},
        selectedBroker: new ML_Broker(),
        filterString: '',
    });

    const [showBrokerDetails, setShowBrokerDetails] = useState(false);

    const dispatch = useDispatch();

    useEffect(() => {
        showBrokersList();
    }, [])

    const filterBrokers = (s: string, masterlist: ML_Broker[]) => {
        if (s !== '') {
            setState({
                ...state,
                filterString: s,
                brokersMaster: [...masterlist],
                brokers: masterlist.filter((s) =>
                    s.BrokerName.toLowerCase().includes(state.filterString.toLowerCase()))
            });
        } else {
            setState({ ...state, filterString: s, brokers: masterlist, brokersMaster: masterlist });
        }
    }

    const loadAllBrokers = () => {
        dispatch(AppAct.ToggleProgress(true));
        bdb.GetAll().then((res) => {
            filterBrokers(state.filterString, res.data);
            dispatch(AppAct.ToggleProgress(false));
        });
    }

    const handleSearch = (e: any) => {
        filterBrokers(e.target.value, state.brokersMaster);
    }

    const edit = (selSup: ML_Broker) => {
        setState({ ...state, selectedBroker: selSup });
        setShowBrokerDetails(true);
    }


    const showBrokersList = () => {
        setShowBrokerDetails(false);
        dispatch(AppAct.ChangeMainTitle('Brokers'));
        loadAllBrokers();
    }


    return (
        <>
            {showBrokerDetails === false &&
                <>
                    <div className="row mb-4">
                        <div className="col-md-8">
                            {/* <input type="button" className="btn btn-az-primary"
                                value="+ New Broker"
                                onClick={() => edit(new ML_Broker())}
                            /> */}
                            <AddNewButtonUI buttonText="New Broker" onClick={() => edit(new ML_Broker())} />
                        </div>
                        <div className="col-md-4">
                            <div className="az-header-center m-0">
                                <span style={{ "display": state.filterString.length === 0 ? 'block' : 'none' }}
                                    className="btn"><i className="fas fa-search"></i></span>
                                <input type="search" className="form-control"
                                    placeholder="Search by supplier name..."
                                    value={state.filterString}
                                    autoFocus={true}
                                    onChange={handleSearch}
                                />

                            </div>
                        </div>
                    </div>

                    <div className="table-responsive">
                        <table className="table table-hover">
                            <thead className="border-top">
                                <tr>
                                    <th>Broker name</th>
                                    <th>Contact</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>

                                {state.brokers.map((s: ML_Broker, idx: number) => {
                                    return (
                                        <tr key={idx}>
                                            <td>
                                                <div style={{
                                                    display: "inline-block",
                                                    padding: "5px",
                                                    color: "black",
                                                    fontWeight: "bold",
                                                    width: 32,
                                                    textAlign: "center",
                                                    borderRadius: 3,
                                                    marginRight: "4px",
                                                    backgroundColor: graphic.colors[s.BrokerName.charAt(0).toLowerCase()]
                                                }}>
                                                    {s.BrokerName.charAt(0).toUpperCase()}
                                                </div>
                                                {/*<i className="fas fa-university fa-1x pt-2 text-indigo mr-1"></i>*/}
                                                <b>{s.BrokerName}</b>
                                            </td>
                                            <td>
                                                <i className="fas fa-user-alt fa-1x pt-2 text-dark mr-1"></i>
                                                {s.ContactNumber}
                                            </td>
                                            <td className="text-right">
                                                <button className="btn-simple pointer-cursor"
                                                    onClick={() => edit(s)}><i
                                                        className="typcn typcn-pen mr-2"></i>Edit
                                                </button>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>

                        {/*<table className="table table-hover mg-b-0">
                                <thead className="border-top">
                                <tr>
                                    <th>Product Name</th>
                                    <th>Units: Bulk / Retail</th>
                                    <th>Trade Type</th>
                                    <th></th>
                                </tr>
                                </thead>
                                <tbody>

                                {suppliers.map((s: ML_Supplier, idx) => {
                                    return (
                                        <tr key={idx}>

                                        </tr>);
                                })}
                                </tbody>
                            </table>*/}
                    </div>
                </>
            }

            {showBrokerDetails && <BrokerDetails
                q={state.filterString}
                data={state.selectedBroker}
                close={showBrokersList}
            />
            }
        </>
    );
}

export default BrokersList;
