import React from 'react';
import './App.css';
import '../node_modules/antd/dist/antd.css';
import './Antd.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.min.css';
import * as AppAct from './globals/AppActions';
import AppLayout from './layout/AppLayout';
import Login from './login/login';
import LayoutRouter from './layout/LayoutRouter';

class App extends React.PureComponent<any, any> {

    constructor(props: any) {
        super(props);

        //https://github.com/axios/axios/issues/960 -- check this for more info
        //https://github.com/axios/axios/issues/1383 -- for login token inclusion info
        // axios.interceptors.response.use(
        //     (respons) => {
        //         return respons
        //     },
        //     function (err) {
        //         toast.error("Server problem. " + err.message);
        //         props.dispatch(AppAct.ToggleProgress(false));
        //         console.log(JSON.stringify(err));
        //         return Promise.reject(err);
        //     });
    }

    // componentDidMount(): void {
    //     stdb.GetGlobalSetting().then((res:any)=>{    
    //         let parsed = JSON.parse(res.data.SettingValue);
    //         this.props.dispatch(AppAct.SetGlobalSettings(parsed));
    //     });
    // }

    render() {
        return (<>
                <BrowserRouter>
                    <ToastContainer autoClose={3000} hideProgressBar={true} theme="colored" pauseOnFocusLoss={true} />
                    <AppLayout />
                    {/* <Routes>
                    <AppLayout />
                </Routes> */}
                </BrowserRouter>
        </>
        );
    }
}

function mapAppCompStateToProps(state: any) {
    return {
        [AppAct.APPLAYOUT_MAIN_TITLE]: state[AppAct.APPLAYOUT_MAIN_TITLE],
        [AppAct.APPLAYOUT_PROGESSBAR]: state[AppAct.APPLAYOUT_PROGESSBAR],
        [AppAct.GLOBAL_SETTINGS]: state[AppAct.GLOBAL_SETTINGS]
    };
}

export default App;
