import React from "react";
import { CheckmarkOutline, CloseOutline, TrashOutline } from 'react-ionicons';

export const PrimaryButtonUI = (props: any) => {
    return <button
        {...props}
        className={'btn btn-primary px-3 ' + props.className}
    >
        <span className="text-white" dangerouslySetInnerHTML={{ __html: props.buttonText }} />
    </button>
}
