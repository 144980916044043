import React, { useEffect, useState } from 'react';
import { ML_Report, ReportFilter, ReportSettings, ReportTableColumn } from '../globals/dto';
import * as rsdb from '../db/ReportSettingsData';
import ReportDesigner from '../reports-builder/report-designer';
import { toast } from 'react-toastify';


const UserReport = (props: any) => {

    const [reports, setReports] = useState<ML_Report[]>();
    const [selectedReport, setSelectedReport] = useState<ML_Report>(null);

    useEffect(() => {
        setSelectedReport(new ML_Report());
        loadAllSettings();
    }, []);

    const onReportChangeHandler = (e: any) => {
        let id = +e.target.value;
        let foundReport = reports.find(f => f.RPTID === id);
        if (foundReport === undefined) 
            addNewReport();
        else
            setSelectedReport(foundReport);
    }

    const loadAllSettings = () => {
        rsdb.GetAllSettings().then(res => {
            setReports(res.data);
        });
    }

    const addNewReport = () => {

        let rep = new ML_Report();

        let repConfig = new ReportSettings();
        repConfig.Columns = new Array<ReportTableColumn>();
        repConfig.CountColumns = new Array<ReportTableColumn>();
        repConfig.Filters = new Array<ReportFilter>();
        repConfig.GroupBy = new ReportTableColumn();
        repConfig.SumColumns = new Array<ReportTableColumn>();
        rep.RPTID = -10;

        rep.ReportConfigDetails = JSON.stringify(repConfig);
        rep.ReportName = 'New Report';
        console.log(repConfig);
        setSelectedReport(rep);
    }

    const removeReport = () => {
        rsdb.DeleteReport(selectedReport.RPTID).then(res => {
            if (res.data && res.data === true) {
                loadAllSettings();
                //setSelectedReport(null);
                let resetReport = new ML_Report();
                resetReport.RPTID = -1;
                setSelectedReport(resetReport);
            } else {
                toast.error('Unable to remove report. Sorry');
            }
        })
    }

    return (
        <>
            <div className="d-flex flex-row mb-2 justify-content-between align-items-end">
                <div className="px-2">
                    <button className="btn btn-primary" onClick={addNewReport}>+New Report</button>
                </div>
                <div className="flex-1">
                    {selectedReport && 
                    <select className="form-control form-select" value={selectedReport.RPTID}
                        onChange={onReportChangeHandler}>
                        <option key="ri-0" value={0}>  </option>
                        {reports && reports.map((rp: ML_Report, idx: number) => {
                            return <option key={'ri' + idx} value={rp.RPTID}>{rp.ReportName}</option>
                        })}
                    </select>
                    }
                </div>
                <div className="px-2">
                    {selectedReport && selectedReport.RPTID > 0 && 
                    <button className="btn btn-danger" onClick={removeReport}>Delete</button>
                    }
                </div>
            </div>
            <hr />

            {selectedReport !== null &&
                <div className="row">
                    <div className="col-12">
                        <ReportDesigner report={selectedReport} onSaveAll={loadAllSettings}></ReportDesigner>
                    </div>
                </div>
            }
        </>
    );
}

export default UserReport;
