import axios, {AxiosResponse} from 'axios';
import * as global from '../globals/ApiUrlConsts';
import {BrokerPaymentVM, ML_Broker} from "../globals/dto";
import * as dateUtils from '../globals/AppDateUtils';

const base_brokers_api_url = global.global_api_url_base + "/api/brokers"

export const GetAll = async () : Promise<AxiosResponse<ML_Broker[]>> => await axios.get(base_brokers_api_url +'/all')

export const Save = async (bro : ML_Broker) => await axios.post(base_brokers_api_url+'/save',bro);

export const getOnId = (id: number) => axios.get<ML_Broker>(base_brokers_api_url + '/GetOnId?brid=' + id)

export const GetAllBrokersStatementEx = () => axios.get(base_brokers_api_url + '/GetAllBrokerStatements')

export const GetBrokerWiseCreditStatement = (broid: number, start : Date, end : Date) =>
axios.get(base_brokers_api_url + '/GetBrokerWiseCreditStatement', { params : {broid : broid, start :dateUtils.SanitizeDate(start), end:dateUtils.SanitizeDate(end)} } )

export const GetPendingCreditInwardItems = (broid: number) =>
axios.get(base_brokers_api_url + '/GetPendingCreditInwardItems', { params : {borid : broid} })

export const PayBrokerCredit  = (vm: BrokerPaymentVM) => axios.post<boolean>(base_brokers_api_url + '/PayBrokerCredit', vm)
