//used till tool https://github.com/RicoSuter/NSwag/wiki/NSwagStudio
//if you have json then use this recommended tool: http://json2ts.com/


export class InvoiceMiscNotes {
  public DeliveryNotes: string = ''
}

//GLOBAL EVENTER
export class AppEventResponse {
  Status: string = ''; //ok, err, info
  Data: any;
  StatusDetails: string = '';
}

//USERS-------
export interface IUser {
  AID: number;
  UName: string;
  PWord: string;
  CreationStamp: string;
  Active: number;
  UserType: number;
  LastLoginStamp: string;
  ShowName: string;
  UserTypeName: string;
}
//USERS-------

export class NavLinkItem {
  Link: string;
  Title: string;
}

export class GlobalSettingsDTO {
  public CurrencyName: string = '';
  public SaleBillPrefix: string = '';
  public InwardBillPrefix: string = '';
  public EnableProductBatches: boolean = false;
  public EnableManualBatchNaming: boolean = false;
  public EnableProductionTracking: boolean = false;
  public GlobalWorkflowId: number = 0;
  public CurrencyRegion: string = '';
  public FinancialYearStartDate: string = '';
}

export class LedgerPayData {
  LedgerId : number = 0;
  Amount : number =0;
}

// export type GlobalSetting =  {
//   CurrencyName: "CurrencyName";
//   SaleBillPrefix: "SaleBillPrefix";
//   InwardBillPrefix: "InwardBillPrefix";
//   EnableProductBatches: "EnableProductBatches";
//   EnableManualBatchNaming: "EnableManualBatchNaming";
//   EnableProductionTracking: "EnableProductionTracking";
//   GlobalWorkflowId: "GlobalWorkflowId";
//   CurrencyRegion: "CurrencyRegion";
//   FinancialYearStartDate: "FinancialYearStartDate";
// }