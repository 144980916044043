import React, { useEffect, useState } from 'react';
import { ML_Customer, ML_Supplier, ProductView } from "../globals/dto";
import { connect, useDispatch } from "react-redux";
import * as AppAct from '../globals/AppActions';
import * as cdb from '../db/CustomersData';
import { mapStateToProps } from "../globals/AppActions";
import qs from 'query-string';
import CustomerDetails from "./CustomerDetails";
import * as graphic from "../globals/colors";
import { AddNewButtonUI } from '../globals/AppControls/AddNewButtonUI';

const CustomersList = (props: any) => {

    const dispatch = useDispatch();

    const [state, setState] = useState({
        customers: new Array<ML_Customer>(),
        customersMaster: new Array<ML_Customer>(),
        validity: {},
        selectedCustomer: new ML_Customer(),
        filterString: '',
    });

    const [showCustomerDetails, setShowCustomerDetails] = useState(false);

    useEffect(() => {
        showCustomersList();
    }, [])

    const filterCustomers = (s: string, masterlist: ML_Customer[]) => {
        if (s !== '') {
            setState({
                ...state,
                filterString: s,
                customers: masterlist.filter((s) =>
                    s.CompanyName.toLowerCase().includes(state.filterString.toLowerCase())),
                customersMaster: masterlist
            });
        } else {
            setState({
                ...state,
                filterString: '',
                customers: [...masterlist],
                customersMaster: [...masterlist]
            });
        }
    }

    const loadAllCustomers = () => {
        dispatch(AppAct.ToggleProgress(true));
        cdb.GetAll().then((res) => {
            filterCustomers(state.filterString, res.data);
            dispatch(AppAct.ToggleProgress(false));
        });
    }

    const handleSearch = (e: any) => {
        filterCustomers(e.target.value, state.customersMaster);
    }

    const edit = (selSup: ML_Customer) => {
        setShowCustomerDetails(true);
        setState({ ...state, selectedCustomer: selSup });
    }


    const showCustomersList = () => {
        setShowCustomerDetails(false);
        dispatch(AppAct.ChangeMainTitle('Customers'));
        loadAllCustomers();
    }


    return (
        <>
            {showCustomerDetails === false &&
                <>
                    <div className="row">
                        <div className="col-md-8">
                            {/* <input type="button" className="btn btn-az-primary"
                                value="+ New Customer"
                                onClick={() => edit(new ML_Customer())}
                            /> */}
                            <AddNewButtonUI
                                onClick={() => edit(new ML_Customer())}
                            />
                        </div>
                        <div className="col-md-4">
                            <div className="az-header-center m-0">
                                <span style={{ "display": state.filterString.length === 0 ? 'block' : 'none' }}
                                    className="btn"><i className="fas fa-search"></i></span>
                                <input type="search" className="form-control"
                                    placeholder="Search by customer name..."
                                    value={state.filterString}
                                    autoFocus={true}
                                    onChange={handleSearch}
                                />

                            </div>
                        </div>
                    </div>
                    <div className="table-responsive mt-3">
                        <table className="table table-hover">
                            <thead className="border-top">
                                <tr>
                                    <th>Company name</th>
                                    <th>Owner Name</th>
                                    <th>Contact</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {state.customers.map((s: ML_Customer, idx: number) => {
                                    return (
                                        <tr key={idx}>
                                            <td>
                                                <div style={{
                                                    display: "inline-block",
                                                    padding: "5px",
                                                    color: "black",
                                                    fontWeight: "bold",
                                                    width: 32,
                                                    textAlign: "center",
                                                    borderRadius: 3,
                                                    marginRight: "4px",
                                                    backgroundColor: graphic.colors[s.CompanyName.charAt(0).toLowerCase()]
                                                }}>
                                                    {s.CompanyName.charAt(0).toUpperCase()}
                                                </div>
                                                {/*<i className="fas fa-university fa-1x pt-2 text-indigo mr-1"></i>*/}
                                                <b>{s.CompanyName}</b>
                                            </td>
                                            <td>
                                                <i className="fas fa-user-alt fa-1x pt-2 text-dark mr-1"></i>
                                                {s.PrimaryContactPersonName}
                                            </td>
                                            <td>
                                                <i className="fas fa-phone fa-1x pt-2 text-info mr-1"></i>
                                                {s.PrimaryPhones}
                                            </td>
                                            <td className="text-right">
                                                <button className="btn-simple pointer-cursor"
                                                    onClick={() => edit(s)}><i
                                                        className="typcn typcn-pen mr-2"></i>Edit
                                                </button>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </>
            }

            {showCustomerDetails && <CustomerDetails
                q={state.filterString}
                data={state.selectedCustomer}
                close={showCustomersList}
            />

            }
        </>
    );
}

export default CustomersList;

