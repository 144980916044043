import React, { useEffect, useState } from 'react';
import Select, { components, OptionProps } from 'react-select';
import { ML_Broker } from '../globals/dto';
import * as bdb from '../db/BrokersData';
import * as _ from 'lodash';

type attribute = {
    selectedBrokerId: number
    onSelect(e: ML_Broker): void
}

const BrokersDropdown = (props: attribute) => {

    const [brokers, setBrokers] = useState<ML_Broker[]>();
    const [brokerSelected, setBrokerSelected] = useState(new ML_Broker());
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        LoadAllBrokers();
    }, [])

    const LoadAllBrokers = () => {
        setIsLoading(true);
        bdb.GetAll().then(res => {
            setIsLoading(false);
            if(res.data){
                let allBrokers : ML_Broker[] = res.data;
                setBrokers(allBrokers);

                if(props.selectedBrokerId > 0){
                    let br = allBrokers.find(x => x.BRKID === props.selectedBrokerId);
                    br ? setBrokerSelected(br) : setBrokerSelected(new ML_Broker());
                }
            }
        });
    }
    
    const handleBrokerChange = (e: ML_Broker) => {
        let copy = _.clone(e);
        setBrokerSelected(copy);
        props.onSelect(copy);
    }
    
    const brokerSingleOption = (props: any) => (
        <components.Option {...props}>
            <b>{props.data.BrokerName}</b>
            {props.data.GeoCity &&
                <span>&nbsp;|&nbsp;<i>{props.data.GeoCity}</i></span>
            }
            {props.data.ContactNumber &&
                <span>&nbsp;|&nbsp;Ph:{props.data.ContactNumber}</span>
            }
        </components.Option>)

    const brokerSingleValue = (props: any) => (
        <components.SingleValue {...props}>
            <b>{props.data.BrokerName}</b>
            {props.data.ContactNumber &&
                <span>&nbsp;|&nbsp;Ph:{props.data.ContactNumber}</span>
            }
        </components.SingleValue>
    )

    const brokerCustomSelectFilter = (option: any, search: string) => {
        return option.data.BrokerName.toLowerCase().includes(search.toLowerCase());
    }

    return (<>
        <Select classNamePrefix="srushti"
            options={brokers}
            isClearable={true}
            value={brokerSelected}
            onChange={handleBrokerChange}
            isLoading={isLoading}
            theme={theme => ({ ...theme, borderRadius: 0 })}
            components={{
                Option: brokerSingleOption,
                SingleValue: brokerSingleValue
            }}
            getOptionValue={option => option.BRKID?.toString()}
            filterOption={brokerCustomSelectFilter}></Select></>);
}

export default BrokersDropdown;