import React, {useEffect, useState} from 'react';
import {Modal, ModalBody} from "react-bootstrap";
import ModalHeader from "react-bootstrap/ModalHeader";
import {mapStateToProps} from "../globals/AppActions";
import * as pdb from '../db/ProcessingData';
import * as idb from '../db/InwardsData';
import * as dateUtil from '../globals/AppDateUtils';
import {ML_ProcessTracker, ProductView} from "../globals/dto";
import { WorkflowSelectorDropDown } from '../shared/WorkflowSelectorDropDown';
import * as _ from 'lodash';
import * as Consts from './../globals/AppActions';
import ReviewHistory from "./ReviewHistory";

type attributes = {
    lineItemID: number,
    showModal: boolean,
    workflowId: number,
    close(): void
}

export const ProcessLineItemLogs = (props: attributes) => {

    const [logs, setLogs] = useState([]);
    const [LogsCache, setLogsCache] = useState([]);
    const [prodView, setProdView] = useState<ProductView>(new ProductView());
    const [workflowId, setWorkflowId] = useState(props.workflowId);

    useEffect(() => {
        if (+props.lineItemID > 0)
            pdb.GetLogsOfLineItem(props.lineItemID).then(res => {
                setLogs(res.data);
                setLogsCache(res.data);
                filterData(res.data,props.workflowId);
                idb.GetProductViewOnLineItemID(props.lineItemID).then(res => setProdView(res.data));
            });
        else
            setLogs([]);
    }, [props.lineItemID]);

    const filterData = (data:any,wfid:number) => {
        if(wfid > 0)
            setLogs(data.filter((d:any) => d.Workflow.WFID == wfid));
    }

    const fileterByWorkflow = (wfid: number) => {
        if (wfid > 0) {
            setWorkflowId(wfid);
            let cache = _.clone(LogsCache);
            filterData(cache,wfid);
            //setLogs(cache.filter(f => f.Workflow.WFID == wfid));
        } else {
            setLogs(_.clone(LogsCache));
        }
    }

    const getLogArray = (l:any) : ML_ProcessTracker[] =>{
        let arr : ML_ProcessTracker[] = new Array<ML_ProcessTracker>();
        arr.push(l.Log);
        return arr;
    }

    return <>
        <Modal show={props.showModal} size={"lg"} dialogClassName="modal-corner">
            <div className="card">
                <div className="card-header">
                    <div className="d-flex flex-column flex-md-row justify-content-between align-items-center">
                        <div>
                            Processing History: &nbsp;
                            {prodView &&
                                <b>{prodView.Product?.ProductName}</b>
                            }
                        </div>
                        <div>
                            <WorkflowSelectorDropDown
                                selectedId={workflowId}
                                onChange={fileterByWorkflow}
                            ></WorkflowSelectorDropDown>
                        </div>
                        {/*<div>*/}
                        {/*    <DateRangeControlEx start={new Date()} end ={new Date()} />*/}
                        {/*</div>*/}
                    </div>


                </div>
                <div className="card-body">
                    <table className="table mb-0">
                        <thead className="border-top">
                            <tr key={"log-1"}>
                                <th>Date/Time</th>
                                {/*<th>Plant</th>*/}
                                <th>
                                    <span className="font-small tx-gray-500">Workflow</span> / Machine
                                </th>
                                <th>Product -&gt; Qty</th>
                                <th>Status</th>
                                {/*<th>System Date/Time</th>*/}
                            </tr>
                        </thead>
                        <tbody>
                            {logs && logs.map((l: any, index: number) => {
                                return (
                                    <>
                                    { index == 0 && 
                                        <tr><td colSpan={4}>
                                            <b>{l.Product.ProductName}</b>
                                            </td></tr> }

                                    { index > 0 && logs[index-1].Product.PRDID != l.Product.PRDID && 
                                        <tr><td colSpan={4}>
                                            <b>{l.Product.ProductName}</b>
                                            </td></tr> }
                                    <tr key={"log" + index}>
                                        <td>{dateUtil.FormatDateTime(l.Log.LogDate)}</td>
                                        {/*<td>{l.Plant.PlantName}</td>*/}
                                        <td>
                                            <span className="font-small tx-gray-500">{l.Workflow.WorkflowName}</span><br/>
                                            {l.Machine.MachineName}
                                        </td>
                                        <td>{l.Product.ProductName} -&gt; {l.Log.ProcessedQty} {l.UnitMeasure?.BulkMeasureName}</td>
                                        <td>
                                            {l.Log.ProcessStatus?.indexOf("START") > -1 && <i className="fa fa-play tx-primary mr-2"></i>}
                                            {l.Log.ProcessStatus?.indexOf("INIT") > -1 && <i className="fa fa-flag tx-violet mr-2"></i>}
                                            {l.Log.ProcessStatus?.indexOf("END") > -1 && <i className="fa fa-check-circle tx-success mr-2"></i>}
                                            {l.Log.ProcessStatus?.indexOf("REVIEW") > -1 && <i className="fa fa-info-circle tx-warning mr-2"></i>}
                                            {l.Log.ProcessStatus?.indexOf("REROUTE") > -1 && <i className="fa fa-redo tx-danger mr-2"></i>}
                                            {l.Log.LogDetails}
                                        </td>
                                        {/*<td>{util.FormatDateTime(l.Log.EntryCreateDateTime)}</td>*/}
                                    </tr>
                                        {l.Log.ProcessStatus?.indexOf("REVIEW") > -1 &&
                                        <tr>
                                         <td colSpan={4}>
                                             <ReviewHistory reviewsList={getLogArray(l)}/>
                                         </td>
                                        </tr>
                                        }
                                    </>)
                            })}
                        </tbody>
                    </table>
                </div>
                <div className="card-footer">
                    <button className="btn btn-primary float-right" onClick={() => props.close()}>Close</button>
                </div>
            </div>
        </Modal>
    </>
}
