import React, { useEffect, useState } from 'react';
import { SaleBillLineItemView, SaleBillView, TaxAppliedDTO } from '../globals/dto';
import * as utils from "../globals/Utils";
import * as _ from 'lodash';
import { toast } from "react-toastify";
import * as factory from '../globals/factory';
import { SaleBillLineItem } from './SaleBillLineItem';
import { Modal, ModalBody } from 'react-bootstrap';
import ModalHeader from 'react-bootstrap/esm/ModalHeader';
import { AppReduxState } from '../infra-redux/AppStateReduxStore';
import { useSelector } from 'react-redux';

type attribute = {
    saleBillView: SaleBillView,
    newLineItemBell: number,
    onSaleBillUpdate(e: SaleBillView): void,
}

const SaleLineItemsView = (props: attribute) => {

    const [editingIndex, setEditingIndex] = useState(0);
    const [lineItem, setLineItem] = useState({});
    const [showPopup, setShowPopUp] = useState(false);
    const [copyLineItem, setCopyLineItem] = useState('');

    const reduxState = useSelector((state: AppReduxState) => state);

    useEffect(() => {
        if (props.newLineItemBell > -1) {
            let newItem = factory.createSaleBillLineItemView();
            popUpLineItem(newItem, -1);
        }
    }, [props.newLineItemBell])

    //DELETE *****************
    const lineItemDelete = (lineItem: SaleBillLineItemView, index: number) => {
        let sb = _.clone(props.saleBillView);
        sb.LineItems[index].LineItem.IsCanceled = true;
        props.onSaleBillUpdate(sb);
    }


    //NEW or EDIT item *****************
    const popUpLineItem = (li: SaleBillLineItemView, editx: number) => {
        if (props.saleBillView.SaleBill.CompanyID === 0) {
            toast.error('Select the "store"');
            return;
        }
        setEditingIndex(editx);
        setCopyLineItem(JSON.stringify(li)); //Javascript object reference is nasty, no way to solve except this.
        setLineItem(li);
        setShowPopUp(true);
    }


    //SAVE  *****************
    const lineItemSaved = (lineItem: SaleBillLineItemView) => {
        console.log('calling save')
        let sb = _.clone(props.saleBillView);
        if (editingIndex > -1) {
            sb.LineItems[editingIndex] = lineItem;
        } else {
            sb.LineItems.push(lineItem);
        }
        setShowPopUp(false);
        props.onSaleBillUpdate(sb);
    }

    const closePopUp = () => {
        lineItemSaved(JSON.parse(copyLineItem));
    }

    return (<>
        <Modal show={showPopup} dialogClassName="modal-corner"
            onHide={closePopUp}
            backdrop={'static'}
            keyboard={true}>

            <ModalBody className="p-3">
                <h3 className="pb-2 az-content-label">Sale Bill Item</h3>
                <SaleBillLineItem
                    allowEdit={props.saleBillView.SaleBill.SBID === 0}
                    storeID={props.saleBillView.SaleBill.CompanyID}
                    companyId={props.saleBillView.SaleBill.CompanyID}
                    lineItem={lineItem}
                    saved={lineItemSaved}
                    deleted={lineItemDelete}
                    closed={closePopUp} />
            </ModalBody>
        </Modal>
        {props.saleBillView.LineItems.map((il: SaleBillLineItemView, idx: number) => {
            return (
                <>
                    {il.LineItem.OnFlyItemPrice === 0 && il.LineItem.ProductID > 0 && il.LineItem.IsCanceled === false &&
                        <tr key={"item_" + idx} >
                            <td>{il.LineProduct.Product.ProductName}<br />
                                {props.saleBillView.SaleBill.SBID === 0 && <>
                                    (<span className="font-small text-danger font-weight-bold pointer-cursor"
                                        onClick={() => lineItemDelete(il, idx)}>Remove</span>)
                                    (<span className="font-small text-primary font-weight-bold pointer-cursor"
                                        onClick={() => popUpLineItem(il, idx)}>View</span>)
                                </>}
                            </td>
                            <td>{il.LineProduct.Product.HSNSACNumber}</td>
                            <td className="text-center">
                                <b>{il.LineItem.BulkQty}</b> {il.LineProduct.UnitMeasure.BulkMeasureName}<br />
                                <b>{il.LineItem.RetailQty}</b> {il.LineProduct.UnitMeasure.RetailMesaureName}
                            </td>
                            <td className="text-right">{utils.formatCurrencyValue(reduxState, il.LineItem.PricePerBulkUnit)}</td>
                            <td className="text-right">
                                {il.LineItem.DiscountPercentage > 0 &&
                                    <span>{il.LineItem.DiscountPercentage}% = </span>}

                                {il.LineItem.DiscountedAmount > 0 &&
                                    <span>{utils.formatCurrencyValue(reduxState, il.LineItem.DiscountedAmount)}</span>}
                            </td>
                            {/*<td></td>*/}
                            <td className="text-right">{utils.formatCurrencyValue(reduxState, il.LineItem.AmountUntaxed)}</td>
                        </tr>
                    }

                    {/* {il.LineItem.OnFlyItemPrice !== 0 &&
                        <tr key={"oofly" + idx} className="pointer-cursor"
                            onClick={() => editOtherItem(il, idx)}>
                            <td>{il.LineItem.OnFlyItem}</td>
                            <td>-</td>
                            <td className="text-center">-</td>
                            <td className="text-right">-</td>
                            <td className="text-right">-</td>
                            <td className="text-right">{utils.formatCurrencyValue(reduxState,il.LineItem.AmountUntaxed)}</td>
                        </tr>
                    } */}
                    {il.LineItem.IsCanceled === false &&
                        il.TaxDetails?.SlabsApplied.map((item: TaxAppliedDTO, rnum: number) => {
                            return (<tr key={idx + "_" + rnum}>
                                <td colSpan={5}>
                                    <div
                                        className="float-right font-weight-normal">{item.TaxName} : {item.TaxValue}</div>
                                </td>
                                <td className="text-right">{utils.formatCurrencyValue(reduxState, item.TotalTaxedAmount)}</td>
                            </tr>)
                        })}
                </>
            )
        })}

        {props.saleBillView.AdditionalExpense.filter(x => x.IsDeductInInvoice > 0).map((inExp, rnum) => {
            return (
                <tr key={"adx_" + rnum}>
                    <td colSpan={5}>{inExp.Transaction.Details}</td>
                    <td className="text-right">-{utils.formatCurrencyValue(reduxState, inExp.Transaction.TotalAmount)}</td>
                </tr>)
        })}
    </>);
}

export default SaleLineItemsView;