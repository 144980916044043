import React from 'react';
import { AliasMemberView } from '../globals/dto';

type attributes = {
    isReadOnly : boolean,
    aliasList: AliasMemberView[],
    onAliasChanged(alias: AliasMemberView): void
}

const CustomerAliasDropdown = (props: attributes) => {

    const onAliasChange = (e:any)=> {
        let val = +e.target.value;

        val > -1 ?
            props.onAliasChanged(props.aliasList[+e.target.value]) :
            props.onAliasChanged(new AliasMemberView());
    }

    return (<>
        {props.aliasList && props.aliasList.length > 0 &&
            <select className="form-control form-select" onChange={onAliasChange} disabled={props.isReadOnly}>
                <option value={-1}>-- Choose Alias --</option>
                {props.aliasList.map((al: AliasMemberView, idx: number) => {
                    return (
                        <option value={idx}>{al.FirmName}</option>
                    )
                })}
            </select>
        }</>);

}
export default CustomerAliasDropdown;