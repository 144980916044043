import React, { useEffect, useState } from 'react';
import { Modal, ModalBody } from 'react-bootstrap';
import ModalHeader from 'react-bootstrap/esm/ModalHeader';
import { InwardLineItemView, InwardView, TaxAppliedDTO } from "../globals/dto";
import * as utils from "../globals/Utils";
import * as factory from '../globals/factory';
import * as _ from 'lodash';
import { toast } from 'react-toastify';
import { InwardLineItem } from './InwardLineItem';
import { useSelector } from 'react-redux';
import { AppReduxState } from '../infra-redux/AppStateReduxStore';

type attributes = {
    inwardView: InwardView,
    newLineItemBell: number,
    onInwardUpdated(iv: InwardView): void
}

const InwardLineItemsView = (props: attributes) => {

    const reduxState = useSelector((state: AppReduxState) => state);

    const [editingIndex, setEditingIndex] = useState(0);
    const [lineItem, setLineItem] = useState({});
    const [showPopUp, setShowPopUp] = useState(false);


    useEffect(() => {
        if (props.newLineItemBell > -1) {
            let newItem = factory.createInwardLineItemView();
            popUpLineItem(newItem, -1);
        }
    }, [props.newLineItemBell])

    //DELETE *****************
    const lineItemDelete = (lineItem: InwardLineItemView, index: number) => {
        let sb = _.clone(props.inwardView);
        sb.LineItems[index].LineItem.IsCanceled = true;
        props.onInwardUpdated(sb);
    }


    //NEW or EDIT item *****************
    const popUpLineItem = (li: InwardLineItemView, editx: number) => {

        if (props.inwardView.InwardEntry.CompanyID === 0) {
            toast.error('Select the "store"');
            return;
        }
        setEditingIndex(editx);
        setLineItem(li);
        //!li ? setLineItem({ ...factory.createSaleBillLineItemView() }) : setLineItem({ ...li });
        setShowPopUp(true);
    }


    //SAVE  *****************
    const lineItemSaved = (lineItem: InwardLineItemView) => {
        let sb = _.clone(props.inwardView);
        if (editingIndex > -1) {
            sb.LineItems[editingIndex] = lineItem;
        } else {
            sb.LineItems.push(lineItem);
        }
        closePopUp();
        props.onInwardUpdated(sb);
    }

    const closePopUp = () => setShowPopUp(false);
    return (<>

        <Modal show={showPopUp} dialogClassName="modal-corner"
            onHide={closePopUp}
            backdrop={'static'}
            keyboard={false}>
            <ModalBody className="p-3">
                <h4 className="mb-3 az-content-label">Purchase Item</h4>
                <InwardLineItem
                    lineItem={lineItem}
                    saved={lineItemSaved}
                    deleted={lineItemDelete}
                    closed={closePopUp} />
            </ModalBody>
        </Modal>


        {props.inwardView.LineItems.map((il: InwardLineItemView, idx: number) => {
            return (
                <>
                    {il.LineItem.BulkQty > 0 && il.LineItem.ProductID > 0 && il.LineItem.IsCanceled === false &&
                        <tr key={idx}>
                            <td>{il.LineProduct.Product.ProductName}<br />
                                {props.inwardView.InwardEntry.INWID === 0 && <>
                                    (<span className="font-small text-danger font-weight-bold pointer-cursor"
                                        onClick={() => lineItemDelete(il, idx)}>Remove</span>)

                                    (<span className="font-small text-primary font-weight-bold pointer-cursor"
                                        onClick={() => popUpLineItem(il, idx)}>Edit</span>)
                                </>}
                            </td>
                            <td>{il.LineProduct.Product.HSNSACNumber}</td>
                            <td className="text-center">
                                <b>{il.LineItem.BulkQty}</b> {il.LineProduct.UnitMeasure.BulkMeasureName}<br />
                                <b>{il.LineItem.RetailQty}</b> {il.LineProduct.UnitMeasure.RetailMesaureName}
                            </td>
                            <td className="text-right">{il.LineItem.Rate}</td>
                            <td className="text-right">
                                {il.LineItem.DiscountPercentage > 0 &&
                                    <span>{il.LineItem.DiscountPercentage}% = </span>}

                                {il.LineItem.DiscountAmount > 0 &&
                                    <span>{utils.formatCurrencyValue(reduxState, il.LineItem.DiscountAmount)}</span>}
                            </td>
                            {/*<td></td>*/}
                            <td className="text-right">{utils.formatCurrencyValue(reduxState, il.LineItem.AmountUntaxed)}</td>
                        </tr>
                    }

                    {il.TaxDetails?.SlabsApplied.map((item: TaxAppliedDTO, rnum: number) => {
                        return (<tr key={idx + "_" + rnum}>
                            <td colSpan={5}>
                                <div
                                    className="float-right font-weight-normal">{item.TaxName} : {item.TaxValue}</div>
                            </td>
                            <td className="text-right">{utils.formatCurrencyValue(reduxState, item.TotalTaxedAmount)}</td>
                        </tr>)
                    })}
                </>
            )
        })}

        {props.inwardView.AdditionalExpense.filter(x => x.IsDeductInInvoice > 0).map((inExp, rnum) => {
            return (
                <tr key={"adx_" + rnum}>
                    <td colSpan={5}>{inExp.Transaction.Details}</td>
                    <td className="text-right">-{utils.formatCurrencyValue(reduxState, inExp.Transaction.TotalAmount)}</td>
                </tr>)
        })}

    </>);

}

export default InwardLineItemsView;