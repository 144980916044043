import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { StateToggleWithID } from './AppStateReduxStore';
import { GlobalSettingsDTO } from "../globals/data.dto";
import { stat } from "fs";

export const AppRedux = createSlice({
    name: 'AppStateRedux',
    initialState: {
        "GlobalSettings" : '{}',
        "UserSettings": {},
        "MainTitle": "Welcome",
        "ShowProgress": false,
        "ShowTransitPage": false,
        "WFProcessID": 0,
        "ShowProcessLogPage": false,
        "ShowSubProductPage": false,
        "WFMacID": 0,
        "LineItemID":0
        //"FinSettings": {...new GlobalSettingsDTO()}
    },
    reducers: {
        ChangeMainTitle: (state, action: PayloadAction<string>) => {
            state.MainTitle = action.payload;
        },
        ToggleProgress: (state, action: PayloadAction<boolean>) => {
            state.ShowProgress = action.payload;
        },
        ShowTransitPage: (state, action: PayloadAction<{ toggle:boolean, pid:number, lineItemId:number }>) => {
            state.ShowTransitPage = action.payload.toggle;
            state.WFProcessID = action.payload.pid;
            state.LineItemID = action.payload.lineItemId;
        },
        ShowActivityPage: (state, action: PayloadAction<{toggle:boolean, macID:number}>) => {
            state.ShowProcessLogPage = action.payload.toggle;
            state.WFMacID = action.payload.macID;
        },
        ShowSubProdPage: (state, action: PayloadAction<{toggle:boolean, macID:number}>) => {
            state.ShowSubProductPage = action.payload.toggle;
            state.WFMacID = action.payload.macID;
        },
        SetGlobalSettings: (state, action: PayloadAction<string>) => {
            state.GlobalSettings = action.payload;
        },
    }
})