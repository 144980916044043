import React, { useEffect, useState } from 'react';
import { Modal } from "react-bootstrap";
import CashAndLedgerSelector from "../shared/CashAndLedgerSelector";
import * as utils from '../globals/Utils';
import { AppReduxState } from '../infra-redux/AppStateReduxStore';
import { useSelector } from 'react-redux';
import { ML_Transaction } from '../globals/dto';

type attribute = {
    payTrans:ML_Transaction,
    onChange(e: ML_Transaction): void,
    buttonTitle: string,
    headerTitle:string
}

const OnSpotPay = (props: attribute) => {

    const [showModal, setShowModal] = useState(false);

    const reduxState = useSelector((state: AppReduxState) => state);

    const showCashLedgerPay = (e: any) => setShowModal(true);

    const onCancel  =() =>{
        setShowModal(false);
    }

    const onSubmit =(e:ML_Transaction) => {
        props.onChange(e);
        onCancel();
    }

    return (<>

        <Modal onHide={() => { }} show={showModal} size={'sm'} dialogClassName="modal-corner">
            <div className="card">
                <div className="card-body">
                    <div className="h4 mb-3 font-weight-bold">{props.headerTitle}</div>
                    <CashAndLedgerSelector
                        onSubmit={onSubmit}
                        onDiscard={onCancel}
                        payTrans={props.payTrans}
                         />
                </div>
            </div>
        </Modal>

        {props.payTrans.TotalAmount === 0 &&
            <button
                className="btn btn-outline-primary w-100 font-weight-bold"
                onClick={showCashLedgerPay}>{props.buttonTitle}
            </button>
        }
        {props.payTrans.TotalAmount > 0 &&
            <div className="d-flex flex-row justify-content-between align-items-center">
                <div>Paid Now: </div>
                <button className="btn btn-outline-primary flex-grow-1 font-weight-bold ml-2"
                    style={{ fontSize: '0.9em' }}
                    onClick={showCashLedgerPay}>
                    {utils.formatCurrencyValue(reduxState, props.payTrans.TotalAmount)}
                </button>
            </div>
        }

    </>)
}
export default OnSpotPay;
