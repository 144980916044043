import React from 'react';
import { ML_Customer } from '../globals/dto';

type attribute = {
    customerSelected: ML_Customer
}
const CustomerDetailsView = (props: attribute) => {

    return (<>
        <label className="form-label">Customer Details</label>
        <div className="table-responsive mb-0">
            <table className="table table-bordered">
                <tbody>
                    <tr key={"tk3"}>
                        <td style={{ width: "50%" }} className="font-small">Company's GSTIN/UIN<br /><b>{props.customerSelected.GSTN}</b></td>
                        <td className="font-small">Bank Name<br /><b>{props.customerSelected.CompanyName}</b>
                        </td>
                    </tr>

                    <tr key={"tk2"}>
                        <td className="font-small">Company's CST No.<br /><b>{props.customerSelected.CSTNo}</b></td>
                        <td className="font-small">KTR No<br />
                            <b>{props.customerSelected.KTNumber}</b></td>
                    </tr>


                    <tr key={"tk1"}>
                        <td className="font-small">Company's PAN<br /><b>{props.customerSelected.CompanyPAN}</b></td>
                        <td className="font-small">GSTIN<br /><b>{props.customerSelected.GSTN}</b></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </>)
}

export default CustomerDetailsView;