import { date } from "yup/lib/locale";
import {
    BrokerPaymentVM,
    CompanySettingsVM,
    CompanyView,
    DebitTransactionVM, InvoiceExpenseView, InwardLineItemView,
    InwardPaymentsVM,
    InwardPaymentView,
    InwardTransactionVM,
    ML_Broker,
    ML_BrokerPayment,
    ML_Company,
    ML_Customer, ML_Inward,
    ML_InwardExpense, ML_InwardLineItem,
    ML_InwardPayment, ML_InwardToStockMoveLog, ML_Product, ML_ProductBatch,
    ML_SaleBill,
    ML_SaleBillExpense, ML_SaleBillLine, ML_SaleBillLineStockBatch,
    ML_SaleBillPayment,
    ML_Stock,
    ML_Supplier,
    ML_Tax,
    ML_Transaction, ProductView, SaleBillLineItemView, SaleLineProductBatchView, TaxAppliedDTO, TaxAppliedView,
    TaxView,FileAttachment, ReviewComment, 
} from "./dto";

export const toTaxView = (mlTax : ML_Tax)=>{
    let tv = new TaxView();
    if(mlTax) {
        tv.TaxSetting = mlTax;
        if (mlTax.SlabSettingsJson !== '')
            tv.Slabs = JSON.parse(mlTax.SlabSettingsJson);
        else tv.Slabs = [];
    }
    return tv;
}

export const createCompanyView = () : CompanyView => {
    let cv = new CompanyView();
    cv.Company = new ML_Company();
    cv.CompanySettings = new CompanySettingsVM();
    return cv;
}

export const createBrokerVM = () => {
    let bv = new BrokerPaymentVM();
    bv.Broker = new ML_Broker();
    bv.BrokerPay = new ML_BrokerPayment();
    bv.TransactionEntry = new ML_Transaction();
    return bv;
}

export const createSupplier = ()=>{
    let s = new ML_Supplier();
    s.DateCreated = new Date();
    return s;
}

export const createCustomer = () =>{
    let c = new ML_Customer();
    c.DateCreated = new Date();
    c.LastUpdated = new Date();
    return c;
}

export const createTransactionML = (): ML_Transaction  =>{
    var t = new ML_Transaction();
    t.LastUpdateDate =  new Date();
    t.TransDate = new Date();
    return t;
}

export const createSaleBill = (): ML_SaleBill =>{
    let s = new ML_SaleBill();
    s.CreateDate =new Date();
    s.BillingDate = new Date();
    s.DispatchVehicleNumber = '';
    s.MiscItemsJson = '';
    s.AmountTotal = 0;
    s.TotalBulkQty = 0;
    return s;
}

export const createInvoiceExpenseEntry = (): InvoiceExpenseView  =>{
    var t = new InvoiceExpenseView();
    t.IsDeductInInvoice = 0;
    t.Transaction = new ML_Transaction();
    t.Transaction.LastUpdateDate = new Date();
    t.Transaction.TransDate = new Date();
    return t;
}

export const createInwardTrasactionVM = () : InwardTransactionVM  => {
    var t = new InwardTransactionVM();
    t.InwardExpense = new ML_InwardExpense();
    t.InwardPay = new ML_InwardPayment();
    t.Supplier = createSupplier();
    t.TransactionEntry = createTransactionML();
    return t;
}
export const createDebitTransactionVM = () : DebitTransactionVM => {
    var t = new DebitTransactionVM();
    t.Customer = new ML_Customer();
    t.Customer.DateCreated = new Date();
    t.SaleBillExpense = new ML_SaleBillExpense();
    t.SaleBillPay = new ML_SaleBillPayment();
    t.SaleBillPay.DateUpdated = new Date();
    t.TransactionEntry = createTransactionML();
    return t;
}
export const createBrokerPaymentVM =(): BrokerPaymentVM => {
    var t = new BrokerPaymentVM();
    t.Broker = new ML_Broker();
    t.BrokerPay = new ML_BrokerPayment();
    t.BrokerPay.DateCreated = new Date();
    t.Inward = new ML_Inward(); 
    t.Inward.SupplierDated =new Date();
    t.Inward.RowCreateDate =new Date();
    t.Inward.RowUpdateDate = new Date();
    t.Inward.BuyerOrderDated = new Date();
    t.Inward.InvoiceReceivedDate = new Date();
    t.TransactionEntry = createTransactionML();
    return t;
}


export const createSaleLineProductBatchView =() : SaleLineProductBatchView => {
    var sbl = new SaleLineProductBatchView();
    sbl.Batch = new ML_ProductBatch();
    sbl.StockEntry = new ML_SaleBillLineStockBatch();
    sbl.Stock = new ML_Stock();
    return sbl;
}

let newItem = new SaleBillLineItemView();
export const createSaleBillLineItemView = () : SaleBillLineItemView => {
    newItem.LineItem = new ML_SaleBillLine();
    newItem.LineItem.BulkQty = 0;
    newItem.LineItem.SubTotal = 0;
    newItem.LineItem.LineItemOrderNumber = 0;
    newItem.LineProduct = new ProductView();
    newItem.LineProduct.Product = new ML_Product();
    newItem.TaxDetails = new TaxAppliedView();
    newItem.TaxDetails.SlabsApplied = new Array<TaxAppliedDTO>();
    newItem.TaxDetails.TaxSetting = new ML_Tax();
    return  newItem;
}

export const createInwardLineItemView = () : InwardLineItemView => {
    let newItem = new InwardLineItemView();
    newItem.LineItem = new ML_InwardLineItem();
    newItem.LineItem.BulkQty = 0;
    newItem.LineItem.LineTotal = 0;
    newItem.LineItem.LineItemOrderNumber = 0;
    newItem.LineProduct = new ProductView();
    newItem.LineProduct.Product = new ML_Product();
    newItem.TaxDetails = new TaxAppliedView();
    newItem.TaxDetails.SlabsApplied = new Array<TaxAppliedDTO>();
    newItem.TaxDetails.TaxSetting = new ML_Tax();
    return  newItem;
}

export const createInwardPaymentView= ():InwardPaymentView => {
    let v = new InwardPaymentView();
    v.Inward = new ML_Inward();
    v.InwardPayment = new ML_InwardPayment();
    v.InwardPayment.DateUpdated = new Date();
    v.Supplier = createSupplier();
    return v;
}

export const createReviewComment = () :ReviewComment =>{
    let com = new ReviewComment();
    com.Attachments = new Array<FileAttachment>();
    com.ReviewedDateTime = new Date();
    com.Details = '';
    return com;
}
