import React, {useEffect, useState} from "react";
import {ML_UnitMeasure} from "../globals/dto";
import * as pdb from '../db/ProductsData';
import {AxiosResponse} from "axios";

//INPUT
//props.UnitID -> number
//props.showCreateNewOption -> true/false

//OUTPUT
//props.onChange(e:ML_UnitMeasure)

export const UnitsSelect = (props:any)=> {
    const [showCreateNewOption, setShowCreateNewOption] = useState(false);
    const [unitsList,setUnitsList] = useState<ML_UnitMeasure[]>();
    const [selectedUnit,setSelectedUnit ] = useState<ML_UnitMeasure>(new ML_UnitMeasure());

    useEffect(()=>{

        if(props.showCreateNewOption){
            setShowCreateNewOption(props.showCreateNewOption);
        }
        pdb.GetAllUnits().then( async (res:AxiosResponse<ML_UnitMeasure[]>) => {
            setUnitsList(res.data);
            if(props.UnitID > 0) {
                setSelectedUnit( {...selectedUnit, UCID: props.UnitID});
            }
        })
    },[props.UnitID]);



    const handleSelectChange = (e:any)=> {
        let name = e.target.name;
        let val = e.target.value;

        if(+val === -1){
            selectedUnit.UCID = -1; //Don't want react to know about this change, so its OK.
            props.onChange(selectedUnit);
        }

        unitsList.find(x=> {
            if(x.UCID===+val){
                setSelectedUnit(x);
                return props.onChange(x);
            }
        });
    }

    return (<>

        <select className="form-control form-select" value={selectedUnit.UCID} onChange={handleSelectChange}>
            <option value={0}>&nbsp;</option>
            {showCreateNewOption && <option key={"unc-1"} value={-1}>-- Create New --</option>}
            {unitsList && unitsList.map((u:ML_UnitMeasure, index :number)=>{
                return(
                    <option key={"unc"+index} value={u.UCID}>1 {u.BulkMeasureName} / {u.RetailQty} {u.RetailMesaureName}</option>
                )
            })}
        </select>

       </>);
}