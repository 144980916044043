import React, { useEffect, useState } from 'react';
import { OtherLineItem } from '../shared/OtherLineItem';
import * as utils from "../globals/Utils";
import { InwardLineItemView, InwardView, SaleBillLineItemView, SaleBillView } from '../globals/dto';
import * as factory from '../globals/factory';
import * as _ from 'lodash';
import DayBook from '../reports/DayBook';
import { AppReduxState } from '../infra-redux/AppStateReduxStore';
import { useSelector } from 'react-redux';

type attributes = {
    inwardView: InwardView,
    newItemRingBell: number
    onInwardBillViewUpdate(sb: InwardView): void,
}

const OtherPurchaseItems = (props: attributes) => {

    const [editingIndex, setEditingIndex] = useState(0);
    const [lineItem, setLineItem] = useState({});
    const [showPopup, setShowPopUp] = useState(false);
    const reduxState = useSelector((state:AppReduxState)=>state);

    useEffect(() => {
        if (props.newItemRingBell > -1) {
            newItem();
        }
    }, [props.newItemRingBell])


    const newItem = () => {
        setEditingIndex(-1);
        setLineItem({ desc: '', price: 0 });
        setShowPopUp(true);
    }

    const saveItem = (item: any) => {
        let sb = _.clone(props.inwardView);
        let onFlyItem = factory.createInwardLineItemView();
        onFlyItem.LineItem.OnFlyItem = item.desc;
        onFlyItem.LineItem.OnFlyItemPrice = +item.price;
        onFlyItem.LineItem.OnFlyItemQty = 1;
        onFlyItem.LineItem.AmountUntaxed = +item.price;
        onFlyItem.LineItem.LineTotal = +item.price;

        if (editingIndex === -1) {
            sb.LineItems.push(onFlyItem);
        } else {
            sb.LineItems[editingIndex] = onFlyItem;
        }
        props.onInwardBillViewUpdate(sb);
        setShowPopUp(false);
    }

    const editItem = (item: InwardLineItemView, idex: number) => {
        setEditingIndex(idex);
        setLineItem({ price: item.LineItem.OnFlyItemPrice, desc: item.LineItem.OnFlyItem });
        setShowPopUp(true);
    }

    const deleteItem = (item: InwardLineItemView, idex: number) => {
        let sb = _.clone(props.inwardView);
        sb.LineItems[idex].LineItem.OnFlyItemPrice = 0;
        sb.LineItems[idex].LineItem.OnFlyItemQty = 0;
        props.onInwardBillViewUpdate(sb);
    }

    return (<>

        <OtherLineItem show={showPopup}
            item={lineItem}
            save={saveItem}
            close={() => setShowPopUp(false)} />

        {props.inwardView.LineItems.map((il: InwardLineItemView, idx: number) => {
            return (<>
                {il.LineItem.OnFlyItemPrice !== 0 &&
                    <tr key={"oofly" + idx} className="pointer-cursor"
                        onClick={() => editItem(il, idx)}>
                        <td>{il.LineItem.OnFlyItem}</td>
                        <td>-</td>
                        <td className="text-center">-</td>
                        <td className="text-right">-</td>
                        <td className="text-right">-</td>
                        <td className="text-right">{utils.formatCurrencyValue(reduxState,il.LineItem.AmountUntaxed)}</td>
                    </tr>
                }</>
            )
        })}
    </>);

}

export default OtherPurchaseItems;