import React, { useState } from 'react';
import LedgersDropDownControl from "../globals/LedgersDropDownControl";
import { ML_Ledger, ML_Tag, ML_Transaction } from "../globals/dto";
import LedgerWithStatsDropDownControl from "../globals/LedgerWithStatsDropDownControl";
import TextBoxNumeric from '../ui-controls/TextBoxNumeric';
import { TagsDropdown } from '../tags/TagsDropdown';
import * as consts from '../globals/GlobalConsts';
import { toast } from 'react-toastify';
import { SuccessButtonUI } from '../globals/AppControls/SuccessButtonUI';
import { CloseButtonUI } from '../globals/AppControls/CloseButtonUI';
import { SecondaryButtonUI } from '../globals/AppControls/SecondaryButtonUI';
import { preProcessFile } from 'typescript';

type attributes = {
    payTrans: ML_Transaction
    onSubmit(e: ML_Transaction): void,
    onDiscard(): void,
}

const CashAndLedgerSelector = (props: attributes) => {

    const [tran, setTran] = useState<ML_Transaction>(props.payTrans);

    const validateAndSubmit = () => {
        if (tran.LedgerID > 0 && tran.CategoryID > 0) {
            props.onSubmit(tran);
        }
        else
            toast.error('Enter missing data');
    }

    return (<>
        <div className="d-flex flex-column gap-3">
            <div>
                <label className="form-label">Ledger</label>
                {tran.IsExpense &&
                    <LedgerWithStatsDropDownControl
                        isError={!(tran.LedgerID > 0)}
                        selectLedgerId={tran.LedgerID}
                        isEditable={true}
                        onLedgerSelected={(e: any) => setTran({
                            ...tran, LedgerID: e.Ledger.LedgerId
                        })} />}

                {!tran.IsExpense &&
                    <LedgersDropDownControl
                        selectLedgerId={tran.LedgerID}
                        isDisabled={false}
                        isError={!(tran.LedgerID > 0)}
                        onLedgerSelected={(e: ML_Ledger) => {
                            if (e)
                                setTran({ ...tran, LedgerID: e.LDGID })
                        }
                        } />}
            </div>

            <div>
                <label className='form-label'>Ledger Transaction Group</label>
                <TagsDropdown
                    isError={!(tran.CategoryID > 0)}
                    onSelected={(e: ML_Tag) => setTran({
                        ...tran,
                        CategoryID: e.GRPID
                    })}
                    selectedTagId={tran.CategoryID}
                    tagType={consts.TagTypes.TRANSACTION_GROUP}
                />
            </div>

            <div>
                {tran.IsExpense && <label className="form-label">Cash Payment</label>}
                {!tran.IsExpense && <label className="form-label">Cash Receiving</label>}
                <TextBoxNumeric value={tran.TotalAmount}
                    onChange={(e: any) => {
                        console.log(+e.target.value);
                        setTran({
                            ...tran,
                            TotalAmount: +e.target.value
                        });

                    }
                    }
                    className={"form-control w-75"} />
            </div>

            <div className="mt-3">
                <div className="d-flex flex-row justify-content-between">
                    <SuccessButtonUI
                        buttonText="<u>A</u>pply"
                        accessKey="a"
                        onClick={validateAndSubmit}
                    />
                    <SecondaryButtonUI
                        buttonText="<u>C</u>ancel"
                        accessKey="c"
                        onClick={props.onDiscard}
                    />
                    {/* <button type={"submit"} className="btn btn-success p-2 wd-90-f" onClick={validateAndSubmit}>OK</button> */}
                    {/* <button className="btn btn-outline-primary" onClick={props.onDiscard}>Cancel</button> */}
                </div>
            </div>
        </div>

    </>)
}

export default CashAndLedgerSelector;
