import React, { useEffect, useRef, useState } from 'react';
import * as stdb from './../db/SettingsDB';
import './../TitleText.scss';
import './../App.css';
import { BrowserRouter, Link, Route, Routes, useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.min.css';
import UsersListEx from "./../users/UsersListEx";
import { useDispatch, useSelector } from "react-redux";
import * as AppAct from './../globals/AppActions';
import { AppProgressBar } from "./../globals/AppProgressBar";
import ProductsList from './../products/ProductsList';
import SuppliersList from "./../suppliers/SuppliersList";
import SupplierDetails from "./../suppliers/SupplierDetails";
import CustomersList from "./../customers/CustomersList";
import BrokersList from "./../brokers/BrokersList";
import TaxList from "./../tax/TaxList";
import InwardsList from "./../inwards/InwardsList";
import axios from 'axios';
import InwardInvoiceEntry from "./../inwards/InwardInvoiceEntry";
import { Settings } from "./../settings/settings";
import { InwardInvoiceView } from "./../inwards/InwardInvoiceView";
import StoresList from "./../stores/StoresList";
import InwardBay from "./../inwards/InwardBay";
import SaleStocks from "./../stocks/SaleStocks";
import SalesList from "./../sales/SalesList";
import LedgersList from "./../ledgers/LedgersList";
import LedgerDetails from "./../ledgers/LedgerDetails";
import CreditorsList from "./../creditors/CreditorsList";
import DebtorsList from "./../debtors/DebtorsList";
import DayBook from "./../reports/DayBook";
import AllAccountsSummary from './../reports/AllAccountsSummary';
import ChequeClearance from "./../Transactions/ChequeClearance";
import BrokersCreditList from "./../creditors/BrokersCreditList";
import Dashboard from "./../workflows/Dashboard";
import TransitManager from "./../workflows/TransitManager";
import WorkflowDesigner from "./../workflow-design/workflow-designer";
import WagesConfig from './../settings/WagesConfig'
import ProcessingHistory from "./../workflows/ProcessingHistory";
import WagesStatement from "./../reports/WagesStatement";
import WorkflowList from "./../workflow-design/workflow-list";
import UserReports from "./../reports-builder/user-reports";
import CashSalesList from './../reports/CashSalesList';
import Login from './../login/login';
import CashPurchaseList from "../reports/CashPurchasesList";
import { AppReduxState } from '../infra-redux/AppStateReduxStore';
import { GlobalSettingsDTO } from '../globals/data.dto';
import * as utils from './../globals/Utils';
import { GlobalSettingsVM } from '../globals/dto';
import ProductTransitHistory from '../reports/ProductTransitHistory';
import { ProductCategories } from '../products/ProductCategories';
import { TransactionCategories } from '../Transactions/TransactionCategories';
import {
    CartOutline,
    FileTrayFullOutline,
    InfiniteOutline,
    Layers,
    LayersOutline,
    LogOut,
    LogOutOutline,
    Notifications,
    NotificationsCircle,
    NotificationsOutline,
    PieChart,
    PieChartOutline,
    SettingsOutline,
    SettingsSharp,
    StatsChart
} from 'react-ionicons'
import { LoaderUI } from '../globals/AppControls/LoaderUI';
import logo from '../assets/tracker-logo.png';
import { LedgersReporterMain } from '../reports/LedgerReports/LedgersReporterMain';

//For icons search here: https://react-ionicons.netlify.app/

const AppLayout = (props: any) => {
    const navigate = useNavigate();

    const menu = () => {
        return {
            'settings': '',
            'production': '',
            'inventory': '',
            'sales': '',
            'finance': '',
            'reports': ''
        };
    }

    const menuIconStyle: any = {
        //color: '#1D273A',
        color: 'var(--text-navy)',
        style: { marginRight: '5px' },
        width: '18px',
        height: '18px'
    }

    const [loginOk, setLoginOk] = useState(false);

    const loginToken: string = 'LOGIN';

    const appSharedState = useSelector((state: AppReduxState) => state.appReduxer);
    const dispatch = useDispatch();


    useEffect(() => {

        //https://github.com/axios/axios/issues/960 -- check this for more info
        //https://github.com/axios/axios/issues/1383 -- for login token inclusion info
        axios.interceptors.response.use(
            (response) => {
                return response
            },
            function (err) {
                toast.error("Server problem. " + err.message);
                dispatch(AppAct.ToggleProgress(false));
                //console.log(JSON.stringify(err));
                /*if(err.response)
                    return Promise.reject(err.response);
                else*/
                return Promise.reject(err);
            });


        removeMenuHandlingScript();

        if (!loginOk && localStorage.getItem(loginToken) == 'ok') {
            setLoginOk(true);
            loadMenuHandlingScript();
            resolveGlobalState();
        }

    }, [])

    const loadMenuHandlingScript = () => {
        const script = document.createElement('script');
        script.src = "/assets/theme/js/azia.js";
        script.async = true;
        document.body.appendChild(script);
    }

    const removeMenuHandlingScript = () => {
        $("[src='/assets/theme/js/azia.js']").remove();
    }

    const loginSuccess = () => {
        localStorage.setItem(loginToken, 'ok');
        setLoginOk(true);
        navigate('/welcome');

        loadMenuHandlingScript();
        resolveGlobalState();
    }

    const resolveGlobalState = () => {
        stdb.GetGlobalSetting().then((res: any) => {
            if (res.data && !utils.isEmptyOrSpaces(res.data.SettingValue))
                dispatch(AppAct.SetGlobalSettings(res.data.SettingValue));
        });
    }

    const logout = () => {
        removeMenuHandlingScript();
        localStorage.removeItem(loginToken);
        setLoginOk(false);
        navigate('/')
    }

    const autoCloseSideMenu = () => {
        $('li.nav-item').removeClass('show');
        $('body').removeClass('az-navbar-show');
    }

    const isEnableProductionTracking = () => {
        let f = utils.GetGlobalSettingsObject(appSharedState.GlobalSettings).EnableProductionTracking;
        return f;
    }


    if (!loginOk)
        return (<Routes>
            <Route path="/" element={<Login onOk={loginSuccess} />} />
        </Routes>);

    return (
        <>
            <div>
                <div className="az-header theme-bar shadow-sm">
                    <div className="container-fluid">
                        <div className="az-header-left">
                            <a href='/' target="_blank" className="az-logo text-capitalize text-navy">
                                <img src={logo} style={{ width: '5%', height: '5%', marginRight: '5px' }}
                                    alt='TrackerPlus-ERP' />
                                Tracker<span>+</span></a>
                            <a href="" id="azNavShow" className="az-header-menu-icon d-lg-none"><span /></a>
                            <a id="azNavbarShow" className="main-title pointer-cursor d-lg-none">
                                <img src={logo} style={{ width: '7%', height: '7%' }} alt='TrackerPlus-ERP' /> Tracker
                            </a>
                        </div>
                        {/* az-header-left */}
                        <div className="az-header-center">
                            {/* <div className="main-title border-left pl-5"
                                dangerouslySetInnerHTML={{ __html: appSharedState.MainTitle }}>
                            </div> */}
                            {/* <input type="search" class="form-control" placeholder="Search for anything...">
    <button class="btn"><i class="fas fa-search"></i></button> */}
                        </div>
                        {/* az-header-center */}
                        <div className="az-header-right">
                            {/* <div className="az-header-message">
                                <a href="app-chat.html"><i className="typcn typcn-messages" /></a>
                            </div> */}
                            <div className="dropdown az-header-notification">
                                {/*<a href="" className="new">*/}
                                {/*    <NotificationsCircle color={'#dc3545'} width={'36px'} height={'36px'}/>*/}
                                {/*</a>*/}
                                <a href="" className="">
                                    <NotificationsCircle color={'#8c8c8c'} width={'36px'} height={'36px'} />
                                </a>
                                <div className="dropdown-menu">
                                    <div className="az-dropdown-header mg-b-20 d-sm-none">
                                        <a href="" className="az-header-arrow"><i
                                            className="icon ion-md-arrow-back" /></a>
                                    </div>
                                    <h6 className="az-notification-title">Notifications</h6>
                                    <p className="az-notification-text">You have 2 unread notification</p>
                                    <div className="az-notification-list">
                                        <div className="media new">
                                            <div className="az-img-user"><img src="https://via.placeholder.com/500x500"
                                                alt="" /></div>
                                            <div className="media-body">
                                                <p>Congratulate <strong>Socrates Itumay</strong> for work anniversaries
                                                </p>
                                                <span>Mar 15 12:32pm</span>
                                            </div>
                                            {/* media-body */}
                                        </div>
                                        {/* media */}
                                        <div className="media new">
                                            <div className="az-img-user online"><img
                                                src="https://via.placeholder.com/500x500" alt="" /></div>
                                            <div className="media-body">
                                                <p><strong>Joyce Chua</strong> just created a new blog post</p>
                                                <span>Mar 13 04:16am</span>
                                            </div>
                                            {/* media-body */}
                                        </div>
                                        {/* media */}
                                        <div className="media">
                                            <div className="az-img-user"><img src="https://via.placeholder.com/500x500"
                                                alt="" /></div>
                                            <div className="media-body">
                                                <p><strong>Althea Cabardo</strong> just created a new blog post</p>
                                                <span>Mar 13 02:56am</span>
                                            </div>
                                            {/* media-body */}
                                        </div>
                                        {/* media */}
                                        <div className="media">
                                            <div className="az-img-user"><img src="https://via.placeholder.com/500x500"
                                                alt="" /></div>
                                            <div className="media-body">
                                                <p><strong>Adrian Monino</strong> added new comment on your photo</p>
                                                <span>Mar 12 10:40pm</span>
                                            </div>
                                            {/* media-body */}
                                        </div>
                                        {/* media */}
                                    </div>
                                    {/* az-notification-list */}
                                    <div className="dropdown-footer"><a href="">View All Notifications</a></div>
                                </div>
                                {/* dropdown-menu */}
                            </div>
                            {/* az-header-notification */}
                            <div className="dropdown az-profile-menu">
                                <a href="" className="az-img-user"><img src="https://via.placeholder.com/500x500"
                                    alt="" /></a>
                                <div className="dropdown-menu">
                                    <div className="az-dropdown-header d-sm-none">
                                        <a href="" className="az-header-arrow"><i
                                            className="icon ion-md-arrow-back" /></a>
                                    </div>
                                    <div className="az-header-profile">
                                        <div className="az-img-user">
                                            <img src="https://via.placeholder.com/500x500" alt="" />
                                        </div>
                                        {/* az-img-user */}
                                        <h6>Aziana Pechon</h6>
                                        <span>Premium Member</span>
                                    </div>
                                    {/* az-header-profile */}
                                    <a href="" className="dropdown-item"><i className="typcn typcn-user-outline" /> My
                                        Profile</a>
                                    <a href="" className="dropdown-item"><i className="typcn typcn-edit" /> Edit Profile</a>
                                    <a href="" className="dropdown-item"><i className="typcn typcn-time" /> Activity Logs</a>
                                    <a href="" className="dropdown-item"><i
                                        className="typcn typcn-cog-outline" /> Account Settings</a>
                                    <a href="page-signin.html" className="dropdown-item"><i
                                        className="typcn typcn-power-outline" /> Sign Out</a>
                                </div>
                                {/* dropdown-menu */}
                            </div>
                        </div>
                        {/* az-header-right */}
                    </div>
                    {/* container-fluid */}
                </div>
                {/* az-header */}


                {/* MENU BAR */}
                <div className="az-navbar ">
                    <div className="container-fluid">
                        {/* <div className="az-navbar-search">
                            <input type="search" className="form-control" placeholder="Search for anything..." />
                            <button className="btn"><i className="fas fa-search " /></button>
                        </div> */}
                        {/* az-navbar-search */}
                        <div className="az-navbar-search m-3">
                            <a href='#' className="az-logo text-capitalize text-navy">
                                <img src={logo} style={{ width: '20%', height: '20%', marginRight: '5px' }}
                                    alt='TrackerPlus-ERP' />
                                Tracker<span>+</span></a>
                        </div>
                        <ul className="nav">
                            <li className="nav-label">Main Menu</li>
                            <li className="nav-item">
                                <a id="aaaa" href="" className="nav-link with-sub">
                                    <SettingsOutline {...menuIconStyle} />
                                    <div className='nav-link-icon-title'>Settings</div>
                                </a>
                                <nav className="nav-sub shadow-lg">
                                    <Link onClick={() => autoCloseSideMenu()} to={'/users'} className="nav-sub-link">Login
                                        Accounts</Link>
                                    <Link onClick={() => autoCloseSideMenu()} to={'/settings'} className="nav-sub-link">Global
                                        Settings</Link>
                                    <Link onClick={() => autoCloseSideMenu()} to={'/stores'}
                                        className="nav-sub-link">Company</Link>
                                    <Link onClick={() => autoCloseSideMenu()} to={'/products'}
                                        className="nav-sub-link">Products</Link>
                                    <Link onClick={() => autoCloseSideMenu()} to={'/product-cats'}
                                        className="nav-sub-link">Product Groups</Link>
                                    <Link onClick={() => autoCloseSideMenu()} to={'/suppliers'}
                                        className="nav-sub-link">Suppliers</Link>
                                    <Link onClick={() => autoCloseSideMenu()} to={'/customers'}
                                        className="nav-sub-link">Customers</Link>
                                    <Link onClick={() => autoCloseSideMenu()} to={'/brokers'}
                                        className="nav-sub-link">Brokers</Link>
                                    <Link onClick={() => autoCloseSideMenu()} to={'/tax'}
                                        className="nav-sub-link">Taxes</Link>
                                    <Link onClick={() => autoCloseSideMenu()} to={'/tran-cats'}
                                        className="nav-sub-link">Transaction Groups</Link>
                                    <Link onClick={() => autoCloseSideMenu()} to={'/wflist'}
                                        className="nav-sub-link">Workflows</Link>
                                    {isEnableProductionTracking() &&
                                        <Link onClick={() => autoCloseSideMenu()} to={'/wages'}
                                            className="nav-sub-link">Wages Settings</Link>
                                    }
                                    {/*<Link onClick={()=>autoCloseSideMenu()} to={'/wfdesigner'} className="nav-sub-link">Workflow Designer</Link>*/}
                                </nav>
                            </li>

                            {/* PRODUCTION */}
                            <li className="nav-item">
                                <a href="" className="nav-link with-sub"><InfiniteOutline {...menuIconStyle} />
                                    <div className='nav-link-icon-title'>Production</div>
                                </a>
                                <nav className="nav-sub shadow-lg">
                                    <Link onClick={() => autoCloseSideMenu()} to={'/inwards'}
                                        className="nav-sub-link">Inwards</Link>
                                    <Link onClick={() => autoCloseSideMenu()} to={'/inwardbay'}
                                        className="nav-sub-link">Inwards Bay</Link>
                                    {isEnableProductionTracking() &&
                                        <>
                                            <Link onClick={() => autoCloseSideMenu()} to={'/workflow'}
                                                className="nav-sub-link">Work Tracker</Link>
                                            <Link onClick={() => autoCloseSideMenu()} to={'/workflowhistory'}
                                                className="nav-sub-link">History</Link>
                                        </>
                                    }
                                </nav>
                            </li>


                            {/* INVENTORY */}
                            <li className="nav-item">
                                <a href="" className="nav-link with-sub"><LayersOutline {...menuIconStyle} />
                                    <div className='nav-link-icon-title'>Inventory</div>
                                </a>
                                <nav className="nav-sub shadow-lg">
                                    <Link onClick={() => autoCloseSideMenu()} to={'/stocks'}
                                        className="nav-sub-link">Stocks</Link>
                                </nav>
                            </li>


                            {/* SALES */}
                            <li className="nav-item">
                                <a href="" className="nav-link with-sub"><CartOutline {...menuIconStyle} />
                                    <div className='nav-link-icon-title'>Sales</div>
                                </a>
                                <nav className="nav-sub shadow-lg">
                                    <Link onClick={() => autoCloseSideMenu()} to={'/saleslist'}
                                        className="nav-sub-link">Invoices</Link>
                                </nav>
                            </li>


                            {/* FINANCE */}
                            <li className="nav-item">
                                <a href="" className="nav-link with-sub"><PieChartOutline {...menuIconStyle} />
                                    <div className='nav-link-icon-title'>Finance</div>
                                </a>
                                <nav className="nav-sub shadow-lg">
                                    <Link onClick={() => autoCloseSideMenu()} to={'/ledgers'}
                                        className="nav-sub-link">Ledgers</Link>
                                    <Link onClick={() => autoCloseSideMenu()} to={'/creditorslist'}
                                        className="nav-sub-link">Creditors</Link>
                                    <Link onClick={() => autoCloseSideMenu()} to={'/debtorslist'}
                                        className="nav-sub-link">Debtors</Link>
                                    <Link onClick={() => autoCloseSideMenu()} to={'/brokercredits'}
                                        className="nav-sub-link">Brokers Credit</Link>
                                    {/*<Link onClick={()=>autoCloseSideMenu()} to={'/'} className="nav-sub-link">Inwards Statement</Link>*/}
                                    <Link onClick={() => autoCloseSideMenu()} to={'/daybook'}
                                        className="nav-sub-link">Daybook</Link>
                                    <Link onClick={() => autoCloseSideMenu()} to={'/summary'} className="nav-sub-link">All
                                        Accounts</Link>
                                    <Link onClick={() => autoCloseSideMenu()} to={'/cheques'} className="nav-sub-link">Cheque
                                        Clearances</Link>
                                    <Link onClick={() => autoCloseSideMenu()} to={'/wagestat'} className="nav-sub-link">Wages
                                        Statement</Link>
                                    {/* <Link onClick={() => autoCloseSideMenu()} to={'/report-design'}
                                        className="nav-sub-link">Report Designer</Link> */}
                                </nav>
                            </li>


                            {/* REPORTS */}
                            <li className="nav-item">
                                <a href="" className="nav-link with-sub"><FileTrayFullOutline {...menuIconStyle} />
                                    <div className='nav-link-icon-title'>Reports</div>
                                </a>
                                <nav className="nav-sub shadow-lg">
                                    <Link onClick={() => autoCloseSideMenu()} to={'/product-transits'}
                                        className="nav-sub-link">Product Transits</Link>
                                    <Link onClick={() => autoCloseSideMenu()} to={'/cash-sales-list'}
                                        className="nav-sub-link">Sale Invoices</Link>
                                    {/* <Link onClick={() => autoCloseSideMenu()} to={'/cash-purchases-list'}
                                        className="nav-sub-link">Purchase Invoices</Link> */}
                                    <Link onClick={() => autoCloseSideMenu()} to={'/reports-ledger-summary'}
                                        className="nav-sub-link">Ledgers Summary</Link>
                                </nav>
                            </li>


                            {/* SYSTEM */}
                            <li className="nav-item">
                                {/* <a href="" className="nav-link with-sub"><i className="typcn typcn-document" />System</a>
                                <nav className="nav-sub"> */}
                                <a className="nav-link pointer-cursor" onClick={logout} style={{ 'color': 'brown' }}>
                                    <LogOutOutline {...menuIconStyle} color={'brown'} />
                                    <div className='nav-link-icon-title' style={{ color: 'brown' }}>Sign Out</div>
                                </a>
                                {/* </nav> */}
                            </li>

                        </ul>
                        {/* nav */}
                    </div>
                    {/* container-fluid */}
                </div>
                {/* az-navbar */}
                <LoaderUI show={appSharedState.ShowProgress}>
                    <div className="az-content pt-3 pb-1 az-content-dashboard">
                        <div className="d-flex">
                            {appSharedState.MainTitle &&
                                <div className="page-title border-multicolor container-fluid pb-3 flex-grow-1"
                                    dangerouslySetInnerHTML={{ __html: appSharedState.MainTitle }}>
                                </div>

                            }
                        </div>
                        <div id='app-area' className="container-fluid">
                            <div className="az-content-body">
                                <Routes>
                                    <Route path={'*'} element={<AllAccountsSummary />} />
                                    <Route path={'welcome'} element={<AllAccountsSummary />} />
                                    <Route path={'users'} element={<UsersListEx />} />
                                    <Route path={'products'} element={<ProductsList />} />
                                    <Route path={'suppliers'} element={<SuppliersList />} />
                                    <Route path={'supplierdetails'} element={<SupplierDetails />} />
                                    <Route path={'customers'} element={<CustomersList />} />
                                    <Route path={'brokers'} element={<BrokersList />} />
                                    <Route path={'tax'} element={<TaxList />} />
                                    <Route path={'inwards'} element={<InwardsList />} />
                                    {/* <Route path={'inwarddetails'} element={<InwardInvoiceEntry/>}/> */}
                                    <Route path={'inwardview'} element={<InwardInvoiceView />} />
                                    <Route path={'settings'} element={<Settings />} />
                                    <Route path={'stores'} element={<StoresList />} />
                                    <Route path={'inwardbay'} element={<InwardBay />} />
                                    <Route path={'stocks'} element={<SaleStocks />} />
                                    <Route path={'saleslist'} element={<SalesList />} />
                                    <Route path={'ledgers'} element={<LedgersList />} />
                                    <Route path={'ledgerdetails'} element={<LedgerDetails />} />
                                    <Route path={'creditorslist'} element={<CreditorsList />} />
                                    <Route path={'debtorslist'} element={<DebtorsList />} />
                                    <Route path={'daybook'} element={<DayBook />} />
                                    <Route path={'summary'} element={<AllAccountsSummary />} />
                                    <Route path={'cheques'} element={<ChequeClearance />} />
                                    <Route path={'brokercredits'} element={<BrokersCreditList />} />
                                    <Route path={'workflow'} element={<Dashboard />} />
                                    <Route path={'transit'} element={<TransitManager lineItemID={0} close={() => {
                                    }} processID={0} workflowID={0} />} />
                                    <Route path={'wfdesigner/:id'} element={<WorkflowDesigner />} />
                                    <Route path={'wages'} element={<WagesConfig />} />
                                    <Route path={'workflowhistory'} element={<ProcessingHistory />} />
                                    <Route path={'wagestat'} element={<WagesStatement />} />
                                    <Route path={'wflist'} element={<WorkflowList />} />
                                    <Route path={'report-design'} element={<UserReports />} />
                                    <Route path={'cash-sales-list'} element={<CashSalesList />} />
                                    <Route path={'cash-purchases-list'} element={<CashPurchaseList />} />
                                    <Route path={'product-transits'} element={<ProductTransitHistory />} />
                                    <Route path={'product-cats'} element={<ProductCategories />} />
                                    <Route path={'tran-cats'} element={<TransactionCategories />} />
                                    <Route path={'reports-ledger-summary'} element={<LedgersReporterMain />} />
                                    {/*<Route render={() => <div>TrackerPlus</div>}/>*/}
                                </Routes>
                            </div>
                            {/* az-content-body */}
                        </div>
                    </div>
                </LoaderUI>

                {/* az-content */}
                <div className="az-footer border-0">
                    <div className="container-fluid text-right justify-content-end">
                        <span className="tx-gray-400"
                            style={{ "fontSize": "11px" }}>TrackerPlus / Enterprise Resource Planning<br />
                            <a className="tx-gray-400" style={{ "fontSize": "11px" }}
                                href="http://www.srushtisoft.com"
                                target="_blank">&copy; {(new Date().getFullYear())} SrushtiSoft, Davangere</a>
                        </span>
                    </div>
                    {/* container-fluid */}
                </div>
                {/* az-footer */}
            </div>
        </>
    );
}

export default AppLayout;
