import React, { useEffect, useState } from 'react';
import * as AppAct from '../globals/AppActions';
import { mapStateToProps } from '../globals/AppActions';
import { connect, useDispatch } from "react-redux";
import { ML_Ledger } from "../globals/dto";
import * as ldb from '../db/LedgerData'
import LedgerStatement from "./LedgerStatement";
import ModalHeader from "react-bootstrap/ModalHeader";
import { Modal, ModalBody } from "react-bootstrap";
import { InwardLineItem } from "../inwards/InwardLineItem";
import LedgerDetails from "./LedgerDetails";
import {AddNewButtonUI} from "../globals/AppControls/AddNewButtonUI";

const LedgersList = (props: any) => {

    const [state, setState] = useState({
        ledgers: new Array<ML_Ledger>(),
        selectedLedger: new ML_Ledger(),
    });

    const [showDetailsModal, setShowDetailsModal] = useState(false);
    const [showStatement, setShowStatement] = useState(false);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(AppAct.ChangeMainTitle("Ledgers"));
        LoadAll();
    }, []);

    const LoadAll = () => {
        dispatch(AppAct.ToggleProgress(true));
        ldb.getAll().then(res => {
            setState({ ...state, ledgers: res.data });
            dispatch(AppAct.ToggleProgress(false));
        });
    }

    const openLedgerStatement = (l: ML_Ledger) => {
        if (l.LDGID === 0) l.IsActive = true;
        setShowStatement(true); 
        setState({ ...state, selectedLedger: l });
    }

    const closeLedgerStatement = () => {
        setShowStatement(false);
        dispatch(AppAct.ChangeMainTitle("Ledgers"));
    }

    const openLedgerDetails = (l: ML_Ledger) => {
        l.IsActive = true;
        setShowDetailsModal(true);
        setState({ ...state, selectedLedger: l});
    }

    const closeLedgerDetaisModal = (refreshlist: boolean = false) => {
        setShowDetailsModal(false);
        if (refreshlist)
            LoadAll();
    }


    if (showStatement)
        return <><LedgerStatement ledger={state.selectedLedger} close={closeLedgerStatement} /> </>;

    if (!showStatement)
        return <>

            {showDetailsModal &&
                <Modal show={showDetailsModal} dialogClassName="modal-corner"
                    onHide={closeLedgerDetaisModal}
                    backdrop={'static'}
                    keyboard={false}>
                    {/*<ModalHeader className="bg-gray-200"><h3 className="pb-0 mb-0">Ledger Details</h3></ModalHeader>*/}
                    <ModalBody className="p-3">
                        <h4 className="az-content-label mb-4">Ledger Details</h4>
                        <LedgerDetails
                            ledger={state.selectedLedger}
                            close={closeLedgerDetaisModal}
                        />
                    </ModalBody>
                </Modal>
            }

            <div className="row">
                <div className="col-md-6 mb-3">
                    <AddNewButtonUI buttonText={"New Ledger"} onClick={() => openLedgerDetails(new ML_Ledger())}/>
                    {/*<button className="btn btn-az-primary" onClick={() => openLedgerDetails(new ML_Ledger())}>*/}
                    {/*    <i className="fas fa-file-invoice"></i> New Ledger*/}
                    {/*</button>*/}
                </div>
            </div>

            <div className="row">
                {state.ledgers && state.ledgers.map((l: ML_Ledger, index: number) => {
                    return (
                        <div className="col-sm-4 pointer-cursor mb-4" key={index}
                        >
                            <div className={"card card-dashboard-thirteen " + (l.IsActive ? '' : 'bg-transparent border border-light')}>
                                <div className="media mb-0">
                                    <div className={"media-icon " + (l.IsBankLedger ? 'tx-teal' : 'tx-warning')} onClick={() => openLedgerDetails(l)}>
                                        <i className={"tx-50 icon " + (l.IsBankLedger ? 'ion-ios-business' : 'ion-ios-filing')}></i>
                                    </div>

                                    <div className="media-body text-nowrap overflow-hidden pointer-cursor" onClick={() => openLedgerStatement(l)}>
                                        <h6>{l.LedgerName}</h6>
                                        <span className="font-medium"
                                            style={{ letterSpacing: ".5px" }}>
                                            {!l.IsBankLedger && <span>General Ledger</span>}
                                            {l.IsBankLedger && <span>Bank Ledger</span>}    </span>
                                    </div>
                                </div>
                            </div>
                        </div>)
                })}
            </div>
        </>
}

export default LedgersList;
