import React, {useState} from 'react';
import {ProductsDropDown} from "../products/ProductsDropDown";
import doubleright from '../assets/images/chevron-double-right.svg';
import {MachinesSelect} from "../shared/MachinesSelect";
import * as pdb from '../db/ProcessingData';
import {toast} from "react-toastify";

type attributes = {
    parentProcessID : number,
    batchID:number,
    prodID:number,
    workflowID:number,
    close():void,
}

export const SubProductEntry = (props:attributes) => {
    const [destProcessFlowID,setDestProcessFlowID ] = useState(0);
    const [moveQty, setMoveQty] =useState(0);
    const [prodID, setProdID] =useState(props.prodID);

    const handleMachineSelect = (flowId:number)=> {
        setDestProcessFlowID(flowId);
    }

    const handleProductChange = (e:any)=>{
        console.log(e);
        setProdID(e.Product.PRDID);
    }

    const handleChange = (e:any)=> {
        setMoveQty(e.target.value);
    }

    const moveToNextMachine = ()=> {
        if(+prodID > 0 && +moveQty > 0 && +destProcessFlowID > 0) {
            pdb.MoveSubProductToAnotherMachineEx(props.parentProcessID, destProcessFlowID, moveQty, props.batchID, prodID)
                .then((res: any) => {
                    props.close();
                })
        } else {
            toast.error('Please enter all data properly');
        }
    }

    return<>
                <div className="row form-group">
                    <div className="col-12">
                        <div className="d-flex flex-grow-1 flex-column flex-md-row justify-content-between">
                            <div className="flex-fill p-3" style={{width:"50%"}}>
                                <label>Sub-Product</label>
                                <ProductsDropDown onChange={handleProductChange}/>
                            </div>
                            <div className="p-3 wd-150">
                                <label>Qty</label>
                                <input type="number" className="form-control"
                                value={moveQty} onChange={handleChange}/>
                            </div>
                            <div className="flex-fill p-3 align-self-center text-center text-success">
                                <img className="text-success" src={doubleright} width={48} height={48}/>
                            </div>
                            <div className=" flex-grow-1 p-3"  style={{width:"40%"}}>
                                <label>Next Process</label>
                                <MachinesSelect workflowId={props.workflowID} onProcessFlowIdSelected={handleMachineSelect}/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 text-center pb-3">
                        <hr/>
                        <button onClick={moveToNextMachine} className="btn btn-indigo">Save &amp; Move</button>
                    </div>
                </div>

    </>

}